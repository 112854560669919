import React, { useEffect, useState, useRef, KeyboardEvent } from 'react'
import {
  Avatar,
  Box,
  Button,
  InputGroup,
  InputRightElement,
  VStack,
  Text,
  Textarea,
  useToast,
  Input,
} from '@chakra-ui/react'
import { Subject, of } from 'rxjs'
import { switchMap, catchError } from 'rxjs/operators'
import { ProposalInput, PreprocessingDTO, Scoring } from '@/_clients/proposals'
import { ProposalApi } from '@/api/apis'
import LogoAvatar from '@/components/LogoAvatar'
import TypingAnimation from '@/components/TypingAnimation'
import { ContextItem, Message, Role } from '../types'
import { chatApi, extractAndValidate, ExtractAndValidateResult, filterContext } from '../helpers'
import { authenticator } from '@/auth/authenticator'

interface Props {
  views: Array<string>
  instance: string
  toiContext: ContextItem[]
  extractorContext: ContextItem[]
  resetKey: boolean
  useTextarea: boolean
  handleValidationResults: (results: ExtractAndValidateResult) => void
  setStatus: React.Dispatch<React.SetStateAction<'idle' | 'pending' | 'success' | 'error'>>
  setResponse: React.Dispatch<React.SetStateAction<any>>
  setError: React.Dispatch<React.SetStateAction<any>>
}

const chatSubject = new Subject<{
  message: Message
  conversation: Message[]
  contextData: ContextItem[] | null
  proposalInput: ProposalInput
  toiContext: ContextItem[]
  extractorContext: string
}>()

const ChatWidget = ({
  views,
  instance,
  toiContext,
  extractorContext,
  resetKey,
  useTextarea,
  handleValidationResults,
  setStatus,
  setResponse,
  setError,
}: Props) => {
  const [message, setMessage] = useState('')
  const [conversation, setConversation] = useState<Message[]>([])
  const [contextData, setContextItem] = useState<ContextItem[] | null>(null)
  const [isTyping, setIsTyping] = useState(false)
  const inputRef = useRef(null)
  const user = authenticator.currentUser()
  const [proposalInput, setProposalInput] = useState<ProposalInput>({
    text: '',
    scoring: Scoring.Recall,
    views: [],
    limit: 5,
    preprocessing: PreprocessingDTO.CleaningTranslating,
  })
  const toast = useToast()
  const [validationResults, setValidationResults] = useState<ExtractAndValidateResult | null>(null)
  useEffect(() => {
    if (conversation.length > 0 && validationResults && validationResults.extractedJson) {
      const updatedValidationResults = { ...validationResults }
      if (updatedValidationResults.extractedJson) {
        updatedValidationResults.extractedJson['description'] = conversation
          .filter((conv) => conv.role !== Role.SYSTEM)
          .map((message) => message.content)
          .join('\n\n')
        updatedValidationResults.extractedJson['businessCase'] = contextData?.map((context) => context.name).join('\n')
        updatedValidationResults.extractedJson['origin'] = 'interactive form'
        updatedValidationResults.extractedJson['priority'] = 'normal'
        updatedValidationResults.extractedJson['case_title'] = 'Deepsearch DEMO'
        updatedValidationResults.extractedJson['subject'] = 'Anliegen / Inquiry'

        setValidationResults(updatedValidationResults)
        handleValidationResults(updatedValidationResults)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextData, conversation])

  useEffect(() => {
    setProposalInput({
      text: '',
      scoring: Scoring.Recall,
      views: views,
      limit: 5,
      preprocessing: PreprocessingDTO.CleaningTranslating,
    })
  }, [views])

  useEffect(() => {
    setConversation([])
    setContextItem(null)
    setMessage('')
  }, [views, instance, toiContext, resetKey])

  const createChatSubscription = () => {
    return chatSubject
      .pipe(
        switchMap(({ message, conversation, contextData, proposalInput, toiContext, extractorContext }) => {
          setStatus('pending')
          const allUserMessages = conversation
            .filter((message) => message.role !== Role.SYSTEM)
            .map((message) => message.content)
            .join(' ')

          return ProposalApi.postForProposal({
            instance,
            proposalInput: { ...proposalInput, text: allUserMessages + ' ' + message.content },
          }).pipe(
            switchMap((proposal) => {
              setStatus('success')
              setResponse(proposal)
              let newContextItem: ContextItem[] = []

              if (proposal.businessCases) {
                const tois = proposal.businessCases.map((toi) => toi.name)
                newContextItem = filterContext(toiContext, contextData, tois)
              }

              return of(newContextItem)
            }),
            catchError((error) => {
              setStatus('error')
              setError(error)
              console.log('error: ', error)
              return of(toiContext[0])
            }),
            switchMap((newContextItem: ContextItem[]) => {
              setContextItem(newContextItem)
              const instructions = `In the JSON below you have the scheme for values which are neccessary to perform the task. Your aim is to obtain all the values from user in correct format and follow all other following instructions. If the user provides incorrect inputs, please, tell him that the input is incorrect and politely ask him to fix it. Keep in mind the user is not technician. Open API scheme: \n\n ${JSON.stringify(
                extractorContext
              )}`

              const newConversation = [...conversation, message]
              return chatApi(newContextItem, newConversation, '', instructions)
            })
          )
        }),
        catchError((error) => {
          console.error('error: ', error)
          return of({
            response: {
              choices: [
                {
                  message: { role: Role.ASSISTANT, content: 'Sorry, an error occurred while processing your request.' },
                },
              ],
            },
          })
        })
      )
      .subscribe(
        (data) => {
          setIsTyping(false)
          if (data.response && data.response.choices && data.response.choices[0]) {
            console.log(data)
            setConversation((prev) => [
              ...prev,
              { role: Role.ASSISTANT, content: data.response.choices[0].message.content.trim() },
            ])
          }
        },
        (err) => {
          setIsTyping(false)
          console.log(err)
          setConversation((prev) => [
            ...prev,
            { role: Role.ASSISTANT, content: 'Sorry, an error occurred while processing your request.' },
          ])
          createChatSubscription() // Recreate the subscription after an error occurs
        }
      )
  }

  useEffect(() => {
    const subscription = createChatSubscription()

    return () => {
      subscription.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNewUserMessage = async () => {
    if (views.length === 0) {
      toast({
        title: 'Error',
        description: 'At least one view must be selected',
        status: 'error',
        isClosable: true,
      })
      return
    }
    const chatMessage = { role: Role.USER, content: message }
    const newConversation = [...conversation, chatMessage]
    setConversation(newConversation)
    setIsTyping(true)
    setMessage('')

    const description = newConversation
      .filter((conv) => conv.role !== Role.SYSTEM)
      .map((message) => message.content)
      .join('\n\n')

    const businessCase = contextData?.map((context) => context.name).join('\n')

    const chatSubjectPromise = new Promise((resolve) => {
      chatSubject.next({
        message: chatMessage,
        conversation,
        contextData,
        proposalInput,
        toiContext,
        extractorContext: extractorContext[0].context,
      })
      resolve(null)
    })

    const [validationResults] = await Promise.all([
      extractAndValidate(
        'Please, check whether all values defined in the scheme below are present in the text. If found, send the values in the given structure in JSON (only in JSON). Assign null to the missing values.',
        extractorContext[0].context,
        newConversation
      ),
      chatSubjectPromise,
    ])
    setValidationResults(validationResults)
    if (validationResults) {
      validationResults.extractedJson = validationResults.extractedJson || {}
      validationResults.extractedJson['description'] = description
      validationResults.extractedJson['businessCase'] = businessCase
      validationResults.extractedJson['origin'] = 'interactive form'
      validationResults.extractedJson['priority'] = 'normal'
      validationResults.extractedJson['case_title'] = 'Deepsearch DEMO'
      validationResults.extractedJson['subject'] = 'Anliegen / Inquiry'
    }
    handleValidationResults(validationResults)
  }

  const handleKeyPress = (event: KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleNewUserMessage()
    }
  }

  return (
    <Box p={5}>
      <VStack align="start" spacing={4}>
        {conversation.map((message, i) => {
          const isUser = message.role === Role.USER
          return (
            <Box key={i} display="flex" justifyContent={isUser ? 'flex-start' : 'flex-end'} width="100%">
              <Box maxWidth="60%" ml={isUser ? '2' : 'auto'} mr={isUser ? 'auto' : '2'}>
                <Box bg={isUser ? 'blue.100' : 'green.100'} p={2} borderRadius="md" pt="24px" position="relative">
                  {isUser ? (
                    <Avatar position="absolute" top="-10px" left="-10px" size="sm" name={user?.name} />
                  ) : (
                    <LogoAvatar
                      position="absolute"
                      top="-10px"
                      right="-10px"
                      width="32px"
                      height="32px"
                      borderRadius="50%"
                      overflow="hidden"
                      bg={'yellow.300'}
                    />
                  )}
                  {message.content
                    .split('\n')
                    .map((item, i) => (item === '' ? <Box key={i} h="20px"></Box> : <Text key={i}>{item}</Text>))}
                </Box>
              </Box>
            </Box>
          )
        })}
        {isTyping && (
          <Box display="flex" justifyContent="flex-end" width="100%">
            <Box maxWidth="60%" mr="2">
              <Box bg="green.100" p={2} borderRadius="md" pt="24px" position="relative">
                <LogoAvatar
                  position="absolute"
                  top="-10px"
                  right="-10px"
                  width="32px"
                  height="32px"
                  borderRadius="50%"
                  overflow="hidden"
                  bg={'yellow.300'}
                />
                <TypingAnimation isTyping={isTyping} />
              </Box>
            </Box>
          </Box>
        )}
      </VStack>
      <InputGroup mt={5}>
        {useTextarea ? (
          <Textarea
            placeholder="Type your message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            ref={inputRef}
          />
        ) : (
          <Input
            placeholder="Type your message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            ref={inputRef}
          />
        )}
        <InputRightElement width="4.5rem" pr={4}>
          <Button h="1.75rem" size="sm" onClick={handleNewUserMessage}>
            Send
          </Button>
        </InputRightElement>
      </InputGroup>
    </Box>
  )
}

export default ChatWidget
