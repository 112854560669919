import { Menu, MenuItemOption, MenuList, MenuOptionGroup } from '@chakra-ui/react'
import React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectAllInstances } from '@/store/instances/state/instances.selectors'
import { InstanceSwitcherButton } from './InstanceSwitcherButton'

interface Props {
  activeInstance: string
  currentPageName: string
}

export const InstanceSwitcher = ({ activeInstance, currentPageName }: Props) => {
  const navigate = useNavigate()
  const instances = useSelector(selectAllInstances)
  return (
    <Menu>
      <InstanceSwitcherButton activeInstance={activeInstance} />
      <MenuList>
        <MenuOptionGroup
          defaultValue={activeInstance}
          value={activeInstance}
          onChange={(instanceName: string) => {
            navigate(`/${instanceName}${currentPageName === '' ? '' : '/' + currentPageName}`)
          }}
        >
          {instances.map((instance, index) => (
            <MenuItemOption
              value={instance.name}
              rounded="md"
              key={instance.name}
              data-cy={`instance-switcher-option-${index}`}
              color={'brand.primary.default'}
            >
              {instance.name}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}
