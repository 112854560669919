import { Action } from 'redux'

export enum AsyncActionPhaseKeys {
  START = 'app/ASYNC_START',
  SUCCESS = 'app/ASYNC_SUCCESS',
  ERROR = 'app/ASYNC_ERROR',
}
export interface AsyncAction extends Action {
  phase: AsyncActionPhaseKeys
}
export interface AsyncErrorAction extends AsyncAction {
  phase: AsyncActionPhaseKeys.ERROR
  error: any // string
}
