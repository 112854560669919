export interface ContextItem {
  name: string
  context: string
}

export interface Message {
  role: string
  content: string
}

export enum Role {
  USER = 'user',
  ASSISTANT = 'assistant',
  SYSTEM = 'system',
}
