import { Action } from 'redux'
import { AsyncAction, AsyncActionPhaseKeys, AsyncErrorAction } from '@/store/state/AsyncAction'
import { StatementCollectionFilter, StatementCollectionFilterType, StatementCollectionInfos } from '@/_clients/admin'
import { FilterInventoriesType } from './buildingBlocks.reducer'

export enum BuildingBlocksActionKeys {
  LOAD = `building-blocks/LOAD`,
  LOAD_SUCCESS = 'building-blocks/LOAD_SUCCESS',
  LOAD_ERROR = 'building-blocks/LOAD_ERROR',
  FILTER_INVENTORIES_LOAD = 'building-blocks/FILTER_INVENTORIES_LOAD',
  FILTER_INVENTORIES_LOAD_SUCCESS = 'building-blocks/FILTER_INVENTORIES_LOAD_SUCCESS',
  FILTER_INVENTORIES_LOAD_ERROR = 'building-blocks/FILTER_INVENTORIES_LOAD_ERROR',
  PREDICTIVE_CLEAR = 'building-blocks/PREDICTIVE_CLEAR',
  PREDICTIVE_LOAD = 'building-blocks/PREDICTIVE_LOAD',
  PREDICTIVE_LOAD_SUCCESS = 'building-blocks/PREDICTIVE_LOAD_SUCCESS',
  PREDICTIVE_LOAD_ERROR = 'building-blocks/PREDICTIVE_LOAD_ERROR',
}

export type BuildingBlocksAction =
  | BusinessCasesLoadAction
  | BusinessCasesLoadSuccessAction
  | FilterInventoriesLoadAction
  | FilterInventoriesLoadSuccessAction
  | PredictiveClearAction
  | PredictiveLoadAction
  | PredictiveLoadSuccessAction
  | AsyncErrorAction

export interface BusinessCasesLoadAction extends AsyncAction {
  type: BuildingBlocksActionKeys.LOAD
  instance: string
  filters: StatementCollectionFilter
}

export const loadBusinessCases = (instance: string, filters: StatementCollectionFilter): BusinessCasesLoadAction => ({
  type: BuildingBlocksActionKeys.LOAD,
  instance,
  filters,
  phase: AsyncActionPhaseKeys.START,
})

export interface BusinessCasesLoadSuccessAction extends AsyncAction {
  type: BuildingBlocksActionKeys.LOAD_SUCCESS
  businessCases: StatementCollectionInfos
}

export const loadBusinessCasesSuccess = (businessCases: StatementCollectionInfos): BusinessCasesLoadSuccessAction => ({
  type: BuildingBlocksActionKeys.LOAD_SUCCESS,
  businessCases,
  phase: AsyncActionPhaseKeys.SUCCESS,
})

export const loadBusinessCasesError = (error: any): AsyncErrorAction => ({
  type: BuildingBlocksActionKeys.LOAD_ERROR,
  error,
  phase: AsyncActionPhaseKeys.ERROR,
})

// filter inventories //
export interface FilterInventoriesLoadAction extends AsyncAction {
  type: BuildingBlocksActionKeys.FILTER_INVENTORIES_LOAD
  instance: string
  phase: AsyncActionPhaseKeys.START
}
export const loadFilterInventories = (instance: string): FilterInventoriesLoadAction => ({
  type: BuildingBlocksActionKeys.FILTER_INVENTORIES_LOAD,
  phase: AsyncActionPhaseKeys.START,
  instance,
})

export interface FilterInventoriesLoadSuccessAction extends AsyncAction {
  type: BuildingBlocksActionKeys.FILTER_INVENTORIES_LOAD_SUCCESS
  filterInventories: FilterInventoriesType
  phase: AsyncActionPhaseKeys.SUCCESS
}
export const loadFilterInventoriesSuccess = (
  filterInventories: FilterInventoriesType
): FilterInventoriesLoadSuccessAction => ({
  type: BuildingBlocksActionKeys.FILTER_INVENTORIES_LOAD_SUCCESS,
  filterInventories,
  phase: AsyncActionPhaseKeys.SUCCESS,
})
export const loadFilterInventoriesError = (error: any): AsyncErrorAction => ({
  type: BuildingBlocksActionKeys.FILTER_INVENTORIES_LOAD_ERROR,
  error,
  phase: AsyncActionPhaseKeys.ERROR,
})

// predictive //
export interface PredictiveLoadAction extends AsyncAction {
  type: BuildingBlocksActionKeys.PREDICTIVE_LOAD
  instance: string
  requestType: StatementCollectionFilterType
  inputText: string
}
export const loadPredictive = (
  instance: string,
  inputText: string,
  requestType: StatementCollectionFilterType
): PredictiveLoadAction => ({
  type: BuildingBlocksActionKeys.PREDICTIVE_LOAD,
  instance,
  requestType,
  inputText,
  phase: AsyncActionPhaseKeys.START,
})

export interface PredictiveLoadSuccessAction extends AsyncAction {
  type: BuildingBlocksActionKeys.PREDICTIVE_LOAD_SUCCESS
  phase: AsyncActionPhaseKeys.SUCCESS
  requestType: StatementCollectionFilterType
  payload: string[]
}
export const loadPredictiveSuccess = (
  requestType: StatementCollectionFilterType,
  payload: string[]
): PredictiveLoadSuccessAction => ({
  type: BuildingBlocksActionKeys.PREDICTIVE_LOAD_SUCCESS,
  phase: AsyncActionPhaseKeys.SUCCESS,
  requestType,
  payload,
})
export const loadPredictiveError = (error: any): AsyncErrorAction => ({
  type: BuildingBlocksActionKeys.PREDICTIVE_LOAD_ERROR,
  error,
  phase: AsyncActionPhaseKeys.ERROR,
})
export interface PredictiveClearAction extends Action {
  type: BuildingBlocksActionKeys.PREDICTIVE_CLEAR
  subject: StatementCollectionFilterType
}

export const clearPredictive = (subject: StatementCollectionFilterType): PredictiveClearAction => ({
  subject,
  type: BuildingBlocksActionKeys.PREDICTIVE_CLEAR,
})
