import { Epic, ofType } from 'redux-observable'
import * as Rx from 'rxjs'
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators'
import appConfig from '../../../constants/appConfig'
import { RootAction, RootState, Services } from '@/types'
import { View, Views } from '@/_clients/admin'
import { ProposalInput, Scoring } from '@/_clients/proposals'
import {
  clearProposal,
  loadProposalError,
  loadProposalSuccess,
  loadViewInventorySuccess,
  ProposalAction,
  ProposalActionKeys,
  ProposalLoadAction,
  ViewInventoryLoadAction,
} from './proposal.actions'

interface ProposalEpics extends Epic<RootAction, ProposalAction, RootState, Services> {}

const loadProposal$: ProposalEpics = (action$, state$, { ProposalApi }) => {
  return action$.pipe(
    ofType<ProposalLoadAction>(ProposalActionKeys.LOAD),
    debounceTime(appConfig.debounceTime),
    switchMap((action) => {
      if (action.inputText.length === 0) {
        return Rx.of(clearProposal())
      }
      const proposalInput: ProposalInput = {
        text: action.inputText,
        scoring: Scoring.Recall,
        views: [action.viewName],
        preprocessing: action.preprocessing,
        limit: 10,
      }
      return ProposalApi.postForProposal({
        proposalInput,
        instance: action.instance,
      }).pipe(
        map((proposal) => loadProposalSuccess(proposal)),
        catchError((error) => Rx.of(loadProposalError(error)))
      )
    })
  )
}

const viewInventoryToStringArray = (viewInventory: Views): string[] => {
  return viewInventory.elements ? viewInventory.elements.map((elem: View) => (elem.name ? elem.name : '')) : []
}

const loadViewInventory$: ProposalEpics = (action$, state$, { TopicOfInterestApi }) => {
  return action$.pipe(
    ofType<ViewInventoryLoadAction>(ProposalActionKeys.VIEW_INVENTORY_LOAD),
    switchMap((action) => {
      const { instance } = action
      return TopicOfInterestApi.getInstanceTopicsOfInterestViews({ instance }).pipe(
        map((viewInventory) => loadViewInventorySuccess(viewInventoryToStringArray(viewInventory))),
        catchError((error) => Rx.of(loadProposalError(error)))
      )
    })
  )
}

export const proposalEpics = [loadProposal$, loadViewInventory$]
