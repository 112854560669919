// tslint:disable
/**
 * DEEP.assist Billing API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs'
import { BaseAPI, HttpHeaders, OperationOpts, RawAjaxResponse, throwIfNullOrUndefined } from '../runtime'
import { DetailsRequest, DetailsResponse, OverviewRequest, OverviewResponse } from '../models'

export interface PostForOverviewRequest {
    overviewRequest: OverviewRequest;
}

export interface PostForRequestsRequest {
    detailsRequest: DetailsRequest;
}

/**
 * no description
 */
export class BillingApi extends BaseAPI {

    /**
     * Billing overview
     */
    postForOverview({ overviewRequest }: PostForOverviewRequest): Observable<OverviewResponse>
    postForOverview({ overviewRequest }: PostForOverviewRequest, opts?: OperationOpts): Observable<RawAjaxResponse<OverviewResponse>>
    postForOverview({ overviewRequest }: PostForOverviewRequest, opts?: OperationOpts): Observable<OverviewResponse | RawAjaxResponse<OverviewResponse>> {
        throwIfNullOrUndefined(overviewRequest, 'overviewRequest', 'postForOverview');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<OverviewResponse>({
            url: '/billing/overview',
            method: 'POST',
            headers,
            body: overviewRequest,
        }, opts?.responseOpts);
    };

    /**
     */
    postForRequests({ detailsRequest }: PostForRequestsRequest): Observable<DetailsResponse>
    postForRequests({ detailsRequest }: PostForRequestsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<DetailsResponse>>
    postForRequests({ detailsRequest }: PostForRequestsRequest, opts?: OperationOpts): Observable<DetailsResponse | RawAjaxResponse<DetailsResponse>> {
        throwIfNullOrUndefined(detailsRequest, 'detailsRequest', 'postForRequests');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<DetailsResponse>({
            url: '/billing/details',
            method: 'POST',
            headers,
            body: detailsRequest,
        }, opts?.responseOpts);
    };

}
