import { Epic } from 'redux-observable'
import { filter, map } from 'rxjs/operators'
import { RootAction, RootState, Services } from '@/types'
import { registerAsyncCallEnd, registerAsyncCallError, registerAsyncCallStart } from './app.actions'
import { AsyncAction, AsyncActionPhaseKeys, AsyncErrorAction } from './AsyncAction'

interface AppEpics extends Epic<RootAction, any, RootState, Services> {}

const someAsyncCallLoadingStart$: AppEpics = (action$) => {
  return action$.pipe(
    filter((action: AsyncAction) => action.phase === AsyncActionPhaseKeys.START),
    map(() => registerAsyncCallStart())
  )
}

const someAsyncCallLoadingSuccess$: AppEpics = (action$) => {
  return action$.pipe(
    filter((action: AsyncAction) => action.phase === AsyncActionPhaseKeys.SUCCESS),
    map(() => registerAsyncCallEnd())
  )
}

const someAsyncCallLoadingError$: AppEpics = (action$) => {
  return action$.pipe(
    filter((action: AsyncAction) => action.phase === AsyncActionPhaseKeys.ERROR),
    map((action) => {
      const errorAction = action as AsyncErrorAction
      return registerAsyncCallError(errorAction.error)
    })
  )
}

export const appEpics = [someAsyncCallLoadingStart$, someAsyncCallLoadingSuccess$, someAsyncCallLoadingError$]
