import { View } from '@/_clients/admin'
import { Proposal } from '@/_clients/proposals'

export function getFromLS(key: string) {
  let ls = {}
  if (global.localStorage) {
    try {
      ls = JSON.parse(global.localStorage.getItem('rgl-8') || '{}')
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key]
}

export function saveToLS(key: string, value: any) {
  let ls = {}
  if (global.localStorage) {
    ls = JSON.parse(global.localStorage.getItem('rgl-8') || '{}')
    global.localStorage.setItem('rgl-8', JSON.stringify({ ...ls, [key]: value }))
  }
}

export interface Widget {
  i: string
  x: Number
  y: Number
  w: Number
  h: Number
  minW?: Number
  minH?: Number
  maxW?: Number
  maxH?: Number
  render: boolean
}

export interface ViewHolder extends View {
  title: string
  proposal: Proposal | null
}

export const move = <T>(array: Array<T>, element: T, delta: number): Array<T> => {
  let tempArray = [...array]
  const index = tempArray.indexOf(element)
  let newIndex = index + delta
  if (newIndex < 0 || newIndex >= tempArray.length) return tempArray
  let indexes = [index, newIndex].sort((a, b) => a - b)
  tempArray.splice(indexes[0], 2, tempArray[indexes[1]], tempArray[indexes[0]])
  return tempArray
}

export const moveUp = <T>(array: Array<T>, element: T) => move<T>(array, element, -1)
export const moveDown = <T>(array: Array<T>, element: T) => move<T>(array, element, 1)

export const initializeWidgets = (): Widget[] => {
  return [
    {
      i: 'textarea',
      x: 0,
      y: 0,
      w: 12,
      h: 20,
      render: true,
    },
    {
      i: 'extractors',
      x: 12,
      y: 20,
      w: 12,
      h: 20,
      render: true,
    },
    {
      i: 'views',
      x: 0,
      y: 20,
      w: 12,
      h: 20,
      render: true,
    },
    {
      i: 'sentiment',
      x: 0,
      y: 0,
      w: 4,
      h: 8,
      render: false,
    },
    {
      i: 'treemap',
      x: 0,
      y: 0,
      w: 4,
      h: 8,
      render: false,
    },
    {
      i: 'preprocessing',
      x: 12,
      y: 0,
      w: 12,
      h: 20,
      render: true,
    },
  ]
}

export const mapWidgetName = (id: string): string => {
  const mapping = {
    textarea: 'Input',
    extractors: 'Extracted Values',
    views: 'Recognized Topics',
    sentiment: 'Sentiment Analysis',
    treemap: 'Treemap',
    preprocessing: 'Preprocessed Text',
  }
  return mapping[id] ? mapping[id] : 'no text found in the mapping table'
}

export const replaceBetween = (origin: string, startIndex: number, endIndex: number, insertion: string) => {
  return origin.substring(0, startIndex) + insertion + origin.substring(endIndex)
}
