import { applyMiddleware, compose, createStore as createReduxStore, Store } from 'redux'
import { createLogger } from 'redux-logger'
import { createEpicMiddleware } from 'redux-observable'

interface DeepAssistStoreParams {
  rootReducer: any
  rootEpic: any
}

interface DeepAssistStore {
  reduxStore: Store
  epicMiddleware: any
}

export const createStore = ({ rootReducer, rootEpic }: DeepAssistStoreParams): DeepAssistStore => {
  const epicMiddleware: any = createEpicMiddleware()
  const middleware = [epicMiddleware]
  if (process.env.NODE_ENV !== 'production') {
    const logger = createLogger({
      collapsed: true,
    })
    middleware.push(logger)
  }
  const w: any = window as any
  const devToolsExtension =
    typeof window !== 'undefined' && w.__REDUX_DEVTOOLS_EXTENSION__ ? w.__REDUX_DEVTOOLS_EXTENSION__() : (f: any) => f
  const deepAssistStore = {
    reduxStore: createReduxStore(rootReducer, undefined, compose(applyMiddleware(...middleware), devToolsExtension)),
    epicMiddleware,
  }
  epicMiddleware.run(rootEpic)
  return deepAssistStore
}
