import React from 'react'
import { Box, Text } from '@chakra-ui/react'
import { useParams } from 'react-router-dom'
import { PageHeader } from '@/components/PageHeader'
import ChatBotFeature from '@/features/Chatbot/ChatBotFeature'

const ChatBotPage = () => {
  const instance = useParams<'instance'>().instance || ''

  return (
    <Box>
      <PageHeader title={'Grounding Large Language Models'} />
      <Text mb={4}>
        Grounding is the process of using large language models (LLMs) with information that is use-case specific,
        relevant, and not available as part of the LLM's trained knowledge
      </Text>
      <ChatBotFeature instance={instance} />
    </Box>
  )
}

export default ChatBotPage
