import { extendTheme } from '@chakra-ui/react'
import { styles } from '@/theme/styles'
import { colors, fonts } from '@/theme/foundations'

const overrides = {
  styles,
  colors,
  fonts,
  components: {},
}

export default extendTheme(overrides)
