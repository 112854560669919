export const colors = {
  brand: {
    primary: {
      '750': '#124050BF',
      '500': '#12405080',
      '300': '#1240504D',
      default: '#124050',
    },
    secondary: {
      '750': '#1DB09DBF',
      '500': '#1DB09D80',
      '300': '#1DB09D4D',
      default: '#1DB09D',
    },
    tertiary: {
      '750': '#2D8AAEBF',
      '500': '#2D8AAE4D',
      '300': '#2D8AAE4D',
      default: '#2D8AAE',
    },
    quarternary: {
      '750': '#7AA9C5BF',
      '500': '#7AA9C580',
      '300': '#7AA9C54D',
      default: '#7AA9C5',
    },
    quinary: {
      '750': '#EC6608BF',
      '500': '#EC660880',
      '300': '#EC66084D',
      default: '#EC6608',
    },
  },
  text: '#000000',
  gray: {
    '900': '#171717',
    '800': '#262626',
    '700': '#3F3F46',
    '600': '#52525B',
    '500': '#71717A',
    '400': '#A1A1AA',
    '300': '#D4D4D4',
    '200': '#E5E5E5',
    '100': '#F5F5F5',
    '50': '#FAFAFA',
  },
  status: {
    error: '#FF0000',
    warning: '#EC6608',
    success: '#00A600',
  },
}
