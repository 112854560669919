import { Epic, ofType } from 'redux-observable'
import { map, switchMap } from 'rxjs/operators'
import { AppActionKeys, AppInitializedAction } from '@/store/state/app.actions'
import { RootAction, RootState, Services } from '@/types'
import { InstancesAction, loadInstancesSuccess } from './instances.actions'

interface InstancesEpic extends Epic<RootAction, InstancesAction, RootState, Services> {}

const loadInstances$: InstancesEpic = (action$, state$, { InstancesApi }) => {
  return action$.pipe(
    ofType<AppInitializedAction>(AppActionKeys.INITIALIZED),
    switchMap(() =>
      InstancesApi.getInstances().pipe(map((instances) => loadInstancesSuccess(instances.elements ?? [])))
    )
  )
}

export const instancesEpics = [loadInstances$]
