import { Box } from '@chakra-ui/react'
import { ResponsiveTreeMap } from '@nivo/treemap'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Proposal } from '@/_clients/proposals'

interface TreeMapData {
  name: string
  children: Kachel[]
}

interface Kachel {
  id: string
  name: string
  score: number
}

interface Props {
  proposal: Proposal | null
}

export const TreeMap = ({ proposal }: Props) => {
  const [treeMapData, setTreeMapData] = useState<TreeMapData>({
    name: 'TOI',
    children: [],
  })
  const { t } = useTranslation()

  React.useEffect(() => {
    let treeMapCopy = { ...treeMapData }
    if (proposal) {
      treeMapCopy.children =
        proposal.businessCases?.map((bc, index) => {
          return {
            id: bc.name + '::' + index.toString(),
            name: bc.name,
            score: bc.score,
          }
        }) || []
      setTreeMapData(treeMapCopy)
    } else {
      setTreeMapData({
        name: 'TOI',
        children: [],
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [proposal])

  if (proposal && proposal.businessCases && proposal.businessCases.length > 0) {
    return (
      <ResponsiveTreeMap
        data={treeMapData}
        identity="id"
        value="score"
        //margin={{ top: 10, right: 10, bottom: 10, left: 10 }}
        labelSkipSize={60}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 2.5]],
        }}
        colors={{ scheme: 'spectral' }}
        valueFormat=" >-.2f"
        tooltip={({ node }) => <strong style={{ color: 'black' }}>{'score: ' + node.formattedValue}</strong>}
        //theme={{tooltip: {container: {boxShadow: '', background: 'white'}}}}
        label={function (node) {
          return `${node.data.name}`
        }}
        leavesOnly={true}
        innerPadding={7}
        /*labelTextColor={{
          from: 'color',
          modifiers: [['darker', 1.2]],
        }}
        parentLabelPosition="top"
        parentLabelTextColor={{
          from: 'color',
          modifiers: [['darker', 2]],
        }}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 0.1]],
        }}*/
      />
    )
  } else {
    return <Box>{t('analyzer.widget.views.noTopicsOfInterest')}</Box>
  }
}
