import { Accordion, Box, Flex, IconButton, Image, Spacer, Stack, Text, useDisclosure, VStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import { NavGroup } from '@/components/menu/NavGroup'
import { NavItem } from '@/components/menu/NavItem'
import { getApiKeysPath } from '@/pages/api-keys/apiKeys.paths'
import { getBuildingBlocksPath } from '@/pages/building-blocks/buildingBlocks.paths'
import { getInspectionPath } from '@/pages/inspection/inspection.paths'
import { getPerspectivesPath } from '@/pages/perspectives/perspectives.paths'
import { getUsagePath } from '@/pages/usage/recentRequests.paths'
import { authenticator } from '@/auth/authenticator'
import { getTopicsOfInterestPath } from '@/pages/topics-of-interest/topicsOfInterest.paths'
import { getKnowledgeGraphPath } from '@/pages/knowledge-graph/knowledgeGraph.paths'
import { getAnnotationTestPath } from '@/pages/annotation-testing/annotationTesting.path'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import { checkResource, getUnionOfRolesToResource } from '@/auth/rolesToResourceMapping'

import '@/styles/styleMenu.css'
import { getApiLoggingPath } from '@/pages/api-logging/apiLogging.paths'
import { Link } from 'react-router-dom'
import * as React from 'react'
import { Help } from '@/components/menu/Help'
import { UserInfo } from '@/components/menu/UserInfo'
import {
  BiAnalyse,
  BiBrain,
  BiChat,
  BiCodeCurly,
  BiCopy,
  BiHdd,
  BiKey,
  BiTestTube,
  BiTimer,
  BiBot,
} from 'react-icons/bi'
import { getChatbotPath } from '@/pages/chatbot/chatbot.paths'

function Logo({ href }: { href: string }) {
  return (
    <Link to={href}>
      <Flex px={6} alignItems={'center'}>
        <Image src="/DeepAssist_Logo_2000x2000.svg" width={12} />
        <Text fontSize={'2xl'}>
          deep
          <Text as={'span'} color={'brand.quinary.default'}>
            assist
          </Text>
        </Text>
      </Flex>
    </Link>
  )
}

export const Menu = () => {
  const roles = authenticator.getRoles()
  const resources = getUnionOfRolesToResource(roles || [])
  const { instance = '' } = useParams()
  const { t } = useTranslation()
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true })
  const menuWidth = '256px'

  return (
    <>
      <IconButton
        icon={isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        backgroundColor="teal.100"
        aria-label="Hide"
        position="absolute"
        left={isOpen ? `calc(${menuWidth} - 20px)` : '0px'}
        bottom="40px"
        fontSize="20px"
        isRound
        zIndex={4}
        onClick={onToggle}
      />
      {isOpen ? (
        <Box as="div" h="100vh" w={menuWidth} bg={'white'} zIndex={2} overflow="auto" shadow={'lg'}>
          <Flex as="nav" py={5} h="100%" direction="column" overflow={'auto'} className="menu">
            <Stack spacing="6" flex="1" fontWeight={'medium'} color={'brand.primary.default'}>
              <Logo href={getPerspectivesPath(instance)} />

              <Accordion allowMultiple={true} defaultIndex={[0, 1, 2, 3, 4, 5]}>
                {checkResource(resources, ['meanings', 'building-block', 'topic-of-interest']) && (
                  <NavGroup label={t('menu.editing')}>
                    {checkResource(resources, 'meanings') && (
                      <NavItem
                        href={getKnowledgeGraphPath(instance)}
                        label={t('menu.knowledgeGraph')}
                        cyId="menu-knowledge-graph"
                        icon={<BiBrain />}
                      />
                    )}
                    {checkResource(resources, 'building-block') && (
                      <NavItem
                        href={getBuildingBlocksPath(instance)}
                        label={t('menu.buildingBlocks')}
                        cyId="menu-building-blocks"
                        icon={<BiCopy />}
                      />
                    )}

                    {checkResource(resources, 'topic-of-interest') && (
                      <NavItem
                        href={getTopicsOfInterestPath(instance)}
                        label={t('menu.tois')}
                        cyId="menu-topics-of-interest"
                        icon={<BiChat />}
                      />
                    )}
                  </NavGroup>
                )}
                {checkResource(resources, ['analyzer', 'inspection', 'testing', 'chatbot']) && (
                  <NavGroup label={t('menu.testing')}>
                    {checkResource(resources, 'analyzer') && (
                      <NavItem
                        href={getPerspectivesPath(instance)}
                        label={t('menu.analyzer')}
                        cyId="menu-perspectives"
                        icon={<BiAnalyse />}
                      />
                    )}
                    {checkResource(resources, 'inspection') && (
                      <NavItem
                        href={getInspectionPath(instance)}
                        label={t('menu.inspection')}
                        cyId="menu-inspection"
                        icon={<BiCodeCurly />}
                      />
                    )}
                    {checkResource(resources, 'testing') && (
                      <NavItem
                        href={getAnnotationTestPath(instance)}
                        label="Annotation Tests"
                        cyId="menu-annotation-tests"
                        icon={<BiTestTube />}
                      />
                    )}
                    {checkResource(resources, 'chatbot') && (
                      <NavItem href={getChatbotPath(instance)} label="LLM" cyId="menu-chatbot" icon={<BiBot />} />
                    )}
                  </NavGroup>
                )}
                {checkResource(resources, 'usage') && (
                  <NavGroup label={t('menu.reports')}>
                    <NavItem
                      href={getUsagePath(instance)}
                      label={t('menu.usageAndQuotas')}
                      cyId="menu-usage-and-quotas"
                      icon={<BiTimer />}
                    />
                    {checkResource(resources, 'request-and-data-logging') && (
                      <NavItem
                        href={getApiLoggingPath(instance)}
                        label={t('menu.apiLogging')}
                        cyId="menu-api-logging"
                        icon={<BiHdd />}
                      />
                    )}
                  </NavGroup>
                )}
                {checkResource(resources, 'api-keys') && (
                  <NavGroup label={t('menu.configuration')}>
                    {checkResource(resources, 'api-keys') && (
                      <NavItem href={getApiKeysPath(instance)} label="API keys" cyId="menu-api-keys" icon={<BiKey />} />
                    )}
                  </NavGroup>
                )}
              </Accordion>

              <Spacer />
              <VStack align={'left'} gap={5} px={8}>
                <Help cyId="menu-help" />
                <UserInfo cyId="menu-user-info" />
              </VStack>
            </Stack>
          </Flex>
        </Box>
      ) : (
        <Box as="div" h="calc(100vh - 64px)" onClick={onToggle} zIndex={2} overflow="auto" cursor={'pointer'}></Box>
      )}
    </>
  )
}
