import { Box } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { ExtractedValue, LocatedHint } from '@/_clients/proposals'
import parse from 'html-react-parser'

interface Props {
  text: string | undefined
  hints: LocatedHint[]
  hint: LocatedHint | null
  extractedValues: ExtractedValue[] | null
}

interface Token {
  start: number
  end: number
  type: string
}
enum Highlight {
  Extractor = 'highlight-extractor',
  Hint = 'highlight-hint',
  Mixed = 'highlight-mixed',
  None = 'none',
}
interface Tag {
  tag: string
  class: string
  len: number
}
interface rh {
  char: string
  index: number
  startTag: Tag[]
  endTag: string[]
}

export const AnnotatedText = ({ text, hints, hint, extractedValues }: Props) => {
  const [annotatedText, setAnnotatedText] = useState('')

  useEffect(() => {
    if (text) {
      let atomizedString: rh[] = []
      let i = 0,
        codePoint
      while (!isNaN((codePoint = text.charCodeAt(i)))) {
        atomizedString.push({
          char: String.fromCodePoint(codePoint),
          index: i,
          startTag: [],
          endTag: [],
        })
        i++
      }
      const tempHints = hints && hint ? [...hints, hint] : hints && !hint ? [...hints] : !hints && hint ? [hint] : []
      let tokens = new Set<Token>()
      let startIndexes: number[] = []
      let endIndexes: number[] = []
      let resultStrings: string[] = []
      extractedValues?.forEach((extractedValue) => {
        if (extractedValue && extractedValue.matchStart && extractedValue.matchEnd) {
          tokens.add({ start: extractedValue.matchStart, end: extractedValue.matchEnd, type: Highlight.Extractor })
        }
      })

      if (hints || hint) {
        tempHints.forEach((hint) => {
          hint.locations.forEach((location) => {
            location.tokens.forEach((token) => {
              startIndexes.push(token.start)
              endIndexes.push(token.end)
              tokens.add({ start: token.start, end: token.end, type: Highlight.Hint })
            })
          })
        })
      }
      for (const token of tokens) {
        let startChar = atomizedString[token.start]
        if (atomizedString[token.end] === undefined)
          atomizedString[token.end] = { char: ' ', index: token.end, startTag: [], endTag: [] }
        let endChar = atomizedString[token.end]
        startChar.startTag.push({
          tag: `<span className="${token.type}">`,
          class: token.type,
          len: token.end - token.start,
        })
        startChar.startTag.sort((a, b) => b.len - a.len)
        endChar.endTag.push('</span>')
      }
      for (const atom of atomizedString) {
        let text = ''
        if (atom.endTag.length !== 0) {
          atom.endTag.forEach((tag) => {
            text += tag
          })
        }
        if (atom.startTag.length !== 0) {
          atom.startTag.forEach((tag) => {
            text += tag.tag
          })
        }
        text += atom.char
        resultStrings.push(text)
      }
      setAnnotatedText(resultStrings.length > 0 ? resultStrings.join('') : text)
    } else if (text === '') setAnnotatedText('Preprocessed text is empty!')
  }, [text, hints, hint, extractedValues])
  return (
    <>
      {text || text === '' ? (
        <Box whiteSpace={'pre-wrap'}>{parse(annotatedText)}</Box>
      ) : (
        <Box>No preprocessed text to show.</Box>
      )}
    </>
  )
}

/*const checkPreviousTag = (endIndex: number, arr: rh[]): rh | null => {
  let lastTag = null
  for (let index = 0; index < endIndex; index++) {
    const element = arr[index]
    if (element.startTag.length !== 0 && element.endTag.length === 0) {
      lastTag = element
    } else if (element.startTag.length === 0 && element.endTag.length !== 0) {
      lastTag = null
    } else if (element.startTag.length !== 0 && element.endTag.length !== 0) {
      lastTag = null
    }
  }
  return lastTag
}*/
