import { AsyncAction, AsyncActionPhaseKeys, AsyncErrorAction } from '@/store/state/AsyncAction'
import { CoreConfigurations } from '@/_clients/admin'

export enum ApiKeysActionKeys {
  LOAD = 'apiKeys/LOAD',
  LOAD_SUCCESS = 'apiKeys/LOAD_SUCCESS',
  LOAD_ERROR = 'apiKeys/LOAD_ERROR',
}

export type ApiKeysAction = ApiKeysLoadAction | ApiKeysLoadSuccessAction | AsyncErrorAction

export interface ApiKeysLoadAction extends AsyncAction {
  type: ApiKeysActionKeys.LOAD
  instance: string
}

export const loadApiKeys = (instance: string): ApiKeysLoadAction => ({
  type: ApiKeysActionKeys.LOAD,
  instance,
  phase: AsyncActionPhaseKeys.START,
})

export interface ApiKeysLoadSuccessAction extends AsyncAction {
  type: ApiKeysActionKeys.LOAD_SUCCESS
  coreConfigurations: CoreConfigurations
}

export const loadApiKeysSuccess = (coreConfigurations: CoreConfigurations): ApiKeysLoadSuccessAction => ({
  type: ApiKeysActionKeys.LOAD_SUCCESS,
  coreConfigurations,
  phase: AsyncActionPhaseKeys.SUCCESS,
})

export const loadApiKeysError = (error: any): AsyncErrorAction => ({
  type: ApiKeysActionKeys.LOAD_ERROR,
  error,
  phase: AsyncActionPhaseKeys.ERROR,
})
