import { Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'
import { useParams } from 'react-router'
import { getDocumentationPath } from '@/pages/documentation/documentation.paths'
import { useTranslation } from 'react-i18next'
import { FaRegLightbulb } from 'react-icons/fa'
import { Link } from 'react-router-dom'

interface Props {
  /** A label for cypress */
  cyId?: string
}

export const Help = (props: Props) => {
  const { cyId } = props
  const instance = useParams<'instance'>().instance || ''
  const { t } = useTranslation()

  return (
    <Flex>
      <Menu>
        <MenuButton fontWeight={'medium'} data-cy={cyId}>
          <Flex alignItems={'center'} gap={2}>
            <FaRegLightbulb />
            <Text>Support</Text>
          </Flex>
        </MenuButton>
        <MenuList shadow={'lg'} fontWeight={'regular'}>
          <MenuItem as={Link} to={getDocumentationPath(instance)} data-cy={'menu-help-documentation'}>
            {t('menu.documentation')}
          </MenuItem>
          <MenuItem as={Link} to={'./'} data-cy={'menu-help-link'}>
            {t('menu.help')}
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  )
}
