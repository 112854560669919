import { Box, Heading, Tag } from '@chakra-ui/react'
import React from 'react'
import { ExtractedValue } from '@/_clients/proposals'

interface Props {
  extractedValues?: ExtractedValue[]
}

const renderValue = (extractedValue: ExtractedValue) => {
  const value = extractedValue.value
  if (typeof value === 'string') {
    return value
  }
  if (typeof value === 'number') {
    return value
  }
  if (typeof value === 'object') {
    return JSON.stringify(value, null, '2')
  }
  return 'Unknown value type.'
}

const renderExtractedValues = (extractedValues: ExtractedValue[]) => {
  return (
    <table>
      <tbody>
        {extractedValues?.map((extractedValue) => (
          <tr>
            <td>
              <Box pt={2} pr={4} fontSize="sm">
                <Tag>{extractedValue.name}</Tag>
              </Box>
            </td>
            <td>
              <Box pt={2} fontSize="sm">
                {renderValue(extractedValue)}
              </Box>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export const TextExtraction = ({ extractedValues }: Props) => {
  const noExtractedValues = !extractedValues || extractedValues.length === 0
  const extractedValuesPanel = noExtractedValues ? (
    <Tag data-cy="no-extracted-values">There are no text extractions.</Tag>
  ) : (
    renderExtractedValues(extractedValues)
  )
  return (
    <Box>
      <Heading fontSize="sm" pt={3}>
        Text extraction
      </Heading>
      {extractedValuesPanel}
    </Box>
  )
}
