import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Wrap,
  WrapItem,
} from '@chakra-ui/react'
import { format as formatDate, isWithinInterval, parseISO } from 'date-fns'
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import { MdClear } from 'react-icons/md'
import { FilterMenu } from '../filters/FilterMenu'
import { Column, DataItem } from '../tables/types'
import SkeletonRows from '../SkeletonRows'
import { generateDateTimeFilterLabel } from '@/utils/dateUtils'
import { AiOutlineClear } from 'react-icons/ai'
import { Link } from 'react-router-dom'

interface DataTableProps {
  data: DataItem[]
  columns: Column[]
  isLoading: boolean
  noDataText: string
  rowHref?: string
}

type SortDirection = 'asc' | 'desc' | 'none'
type RangeFilter = { min: number | undefined; max: number | undefined }
type DateTimeFilter = { from: string; to: string }

const findMinMax = (data: DataItem[], columnAccessor: string): { min: number; max: number } => {
  let min: number = Infinity
  let max: number = -Infinity

  data.forEach((item) => {
    const value = item[columnAccessor]

    if (typeof value === 'number') {
      min = Math.min(min, value)
      max = Math.max(max, value)
    }
  })

  return { min, max }
}

const getLabelPosition = (value: number, minValue: number, maxValue: number, sliderWidth: number) => {
  return ((value - minValue) / (maxValue - minValue)) * sliderWidth
}

const generateInitialStates = (cols: Column[]) => {
  const initialFilters = cols.reduce((acc, col) => ({ ...acc, [col.accessor]: '' }), {})
  const initialRangeFilters = cols.reduce(
    (acc, col) => ({ ...acc, [col.accessor]: { min: undefined, max: undefined } }),
    {}
  )
  const initialDateTimeFilters = cols.reduce((acc, col) => ({ ...acc, [col.accessor]: { from: '', to: '' } }), {})
  const initialColumnSort = cols.reduce((acc, col) => ({ ...acc, [col.accessor]: 'none' }), {})
  const initialFilterStates = {
    string: '',
    datetime: { from: '', to: '' },
    number: { min: undefined, max: undefined },
  }

  return {
    initialFilters,
    initialRangeFilters,
    initialDateTimeFilters,
    initialColumnSort,
    initialFilterStates,
  }
}

const DataTable = ({ data, columns, isLoading, noDataText, rowHref }: DataTableProps) => {
  const { initialFilters, initialRangeFilters, initialDateTimeFilters, initialColumnSort, initialFilterStates } =
    generateInitialStates(columns)

  const [filteredData, setFilteredData] = useState(data)
  const [filters, setFilters] = useState(initialFilters)
  const [rangeFilters, setRangeFilters] = useState(initialRangeFilters)
  const [dateTimeFilters, setDateTimeFilters] = useState(initialDateTimeFilters)
  const [columnSort, setColumnSort] = useState<{ [key: string]: SortDirection }>(initialColumnSort)

  useEffect(() => {
    applyFiltersAndSorting()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, dateTimeFilters, rangeFilters, columnSort, data])

  const handleSortToggle = (column: Column) => {
    const currentDirection = columnSort[column.accessor]
    let newDirection: SortDirection

    if (currentDirection === 'asc') {
      newDirection = 'desc'
    } else if (currentDirection === 'desc') {
      newDirection = 'none'
    } else {
      newDirection = 'asc'
    }

    // Reset sort directions of all other columns
    const updatedColumnSort = Object.keys(columnSort).reduce((acc, key) => {
      if (key === column.accessor) {
        acc[key] = newDirection
      } else {
        acc[key] = 'none'
      }
      return acc
    }, {} as { [key: string]: SortDirection })

    setColumnSort(updatedColumnSort)
  }

  const clearFilter = (column: Column) => {
    const initialState = initialFilterStates[column.type]

    if (column.type === 'datetime') {
      setDateTimeFilters((prev) => ({
        ...prev,
        [column.accessor]: initialState as DateTimeFilter,
      }))
    } else if (column.type === 'number') {
      setRangeFilters((prev) => ({
        ...prev,
        [column.accessor]: initialState as RangeFilter,
      }))
    } else {
      setFilters((prev) => ({
        ...prev,
        [column.accessor]: initialState as string,
      }))
    }
  }

  const applyFiltersAndSorting = () => {
    const filtered = data.filter((item) => {
      return columns.every((column) => {
        const cellValue = item[column.accessor]

        if (column.type === 'string' && typeof cellValue === 'string') {
          const filterValue = filters[column.accessor]

          if (!filterValue) return true

          return cellValue.toLowerCase().includes(filterValue.toLowerCase())
        } else if (column.type === 'number' && typeof cellValue === 'number') {
          const range = rangeFilters[column.accessor]

          if (!range.min && !range.max) return true

          return applyRangeFilter(cellValue, range)
        } else if (column.type === 'datetime' && typeof cellValue === 'string') {
          const parsedValue = parseISO(cellValue)

          const from = dateTimeFilters[column.accessor].from ? parseISO(dateTimeFilters[column.accessor].from) : null
          const to = dateTimeFilters[column.accessor].to ? parseISO(dateTimeFilters[column.accessor].to) : null

          if (from && to) {
            return isWithinInterval(parsedValue, { start: from, end: to })
          } else if (from) {
            return parsedValue >= from
          } else if (to) {
            return parsedValue <= to
          } else {
            return true
          }
        }

        return true
      })
    })

    const columnToSort = Object.keys(columnSort).find((key) => columnSort[key] !== 'none')
    const sortDirection = columnSort[columnToSort || 'none']

    if (columnToSort && sortDirection !== 'none') {
      const sortedData = [...filtered].sort((a, b) => {
        const sortColumn = columns.find((col) => col.accessor === columnToSort)
        if (!sortColumn) return 0

        const aValue = a[sortColumn.accessor]
        const bValue = b[sortColumn.accessor]

        if (aValue < bValue) {
          return sortDirection === 'asc' ? -1 : 1
        } else if (aValue > bValue) {
          return sortDirection === 'asc' ? 1 : -1
        } else {
          return 0
        }
      })

      setFilteredData(sortedData)
    } else {
      setFilteredData(filtered)
    }
  }

  const applyRangeFilter = (value: number, range: { min: number | undefined; max: number | undefined }): boolean => {
    if (range.min !== undefined && range.max !== undefined) {
      return value >= range.min && value <= range.max
    } else if (range.min !== undefined) {
      return value >= range.min
    } else if (range.max !== undefined) {
      return value <= range.max
    } else {
      return true
    }
  }

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, column: Column) => {
    const { value } = event.target

    setFilters((prev) => ({
      ...prev,
      [column.accessor]: value,
    }))
  }

  const handleDateTimeFilterChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    column: Column,
    type: 'from' | 'to'
  ) => {
    const { value } = event.target
    setDateTimeFilters((prev) => ({
      ...prev,
      [column.accessor]: { ...prev[column.accessor], [type]: value },
    }))
  }

  const clearFilters = () => {
    setFilters(initialFilters)
    setRangeFilters(initialRangeFilters)
    setDateTimeFilters(initialDateTimeFilters)
  }

  const isFilterActive = () => {
    if (data.length === 0) {
      return false
    }

    return (
      Object.values(filters).some((value) => value !== '') ||
      Object.values(rangeFilters).some(({ min, max }) => min !== undefined || max !== undefined) ||
      Object.values(dateTimeFilters).some(({ from, to }) => from !== '' || to !== '')
    )
  }

  return (
    <>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Wrap align="center" mb={4}>
          {data.length > 0 &&
            columns.map((column, index) => {
              if (!column.filtering) return null

              let filterComponent = null

              switch (column.type) {
                case 'string':
                  filterComponent = (
                    <FilterMenu
                      key={`filter-${column.type}-${index}`}
                      buttonCyId={`filter-menu-${column.accessor}`}
                      title={`${column.Header}`}
                      value={filters[column.accessor]}
                      clearAction={() => clearFilter(column)}
                    >
                      <Input
                        data-cy={`filter-input-${column.accessor}`}
                        type={column.type}
                        value={filters[column.accessor]}
                        onChange={(e) => handleFilterChange(e, column)}
                        placeholder={`Filter by ${column.Header}`}
                      />
                    </FilterMenu>
                  )
                  break
                case 'number':
                  const { min: dataMin, max: dataMax } = findMinMax(data, column.accessor)
                  if (dataMin === dataMax) {
                    return null
                  }
                  filterComponent = (
                    <FilterMenu
                      key={`filter-${column.type}-${index}`}
                      buttonCyId={`filter-menu-${column.accessor}`}
                      title={`${column.Header}`}
                      value={
                        rangeFilters[column.accessor].min === undefined &&
                        rangeFilters[column.accessor].max === undefined
                          ? ''
                          : `From: ${rangeFilters[column.accessor].min} To: ${rangeFilters[column.accessor].max}`
                      }
                      clearAction={() => clearFilter(column)}
                    >
                      <Box position="relative" w="250px" m={8}>
                        <RangeSlider
                          data-cy={`range-slider-${column.accessor}`}
                          min={dataMin}
                          max={dataMax}
                          value={[
                            Number(rangeFilters[column.accessor].min) || dataMin,
                            Number(rangeFilters[column.accessor].max) || dataMax,
                          ]}
                          onChange={([min, max]) =>
                            setRangeFilters((prev) => ({
                              ...prev,
                              [column.accessor]: { min, max },
                            }))
                          }
                        >
                          <RangeSliderTrack>
                            <RangeSliderFilledTrack bg={'brand.secondary'} />
                          </RangeSliderTrack>
                          <RangeSliderThumb index={0} bg={'brand.quinary.500'} />
                          <RangeSliderThumb index={1} bg={'brand.quinary.500'} />
                        </RangeSlider>
                        <FilterLabel
                          type="min"
                          rangeFilters={rangeFilters}
                          dataMin={dataMin}
                          dataMax={dataMax}
                          accessor={column.accessor}
                        />
                        <FilterLabel
                          type="max"
                          rangeFilters={rangeFilters}
                          dataMin={dataMin}
                          dataMax={dataMax}
                          accessor={column.accessor}
                        />
                      </Box>
                    </FilterMenu>
                  )

                  break
                case 'datetime':
                  filterComponent = (
                    <FilterMenu
                      key={`filter-${column.type}-${index}`}
                      buttonCyId={`filter-menu-${column.accessor}`}
                      title={`Filter by ${column.Header}`}
                      value={generateDateTimeFilterLabel(
                        dateTimeFilters[column.accessor].from,
                        dateTimeFilters[column.accessor].to
                      )}
                      clearAction={() => clearFilter(column)}
                    >
                      <Stack direction="row" spacing={2}>
                        <FormControl>
                          <FormLabel>From</FormLabel>
                          <InputGroup>
                            <Input
                              type="date"
                              data-cy={`datetime-input-from-${column.accessor}`}
                              value={dateTimeFilters[column.accessor].from}
                              placeholder="From"
                              onChange={(e) => handleDateTimeFilterChange(e, column, 'from')}
                            />
                            {dateTimeFilters[column.accessor].from && (
                              <InputRightElement>
                                <IconButton
                                  size="xs"
                                  colorScheme="gray"
                                  aria-label="Clear From Date"
                                  icon={<MdClear />}
                                  onClick={() =>
                                    setDateTimeFilters((prev) => ({
                                      ...prev,
                                      [column.accessor]: { ...prev[column.accessor], from: '' },
                                    }))
                                  }
                                />
                              </InputRightElement>
                            )}
                          </InputGroup>
                        </FormControl>
                        <FormControl>
                          <FormLabel>To</FormLabel>
                          <InputGroup>
                            <Input
                              type="date"
                              data-cy={`datetime-input-to-${column.accessor}`}
                              value={dateTimeFilters[column.accessor].to}
                              placeholder="To"
                              onChange={(e) => handleDateTimeFilterChange(e, column, 'to')}
                            />
                            {dateTimeFilters[column.accessor].to && (
                              <InputRightElement>
                                <IconButton
                                  size="xs"
                                  colorScheme="gray"
                                  aria-label="Clear To Date"
                                  icon={<MdClear />}
                                  onClick={() =>
                                    setDateTimeFilters((prev) => ({
                                      ...prev,
                                      [column.accessor]: { ...prev[column.accessor], to: '' },
                                    }))
                                  }
                                />
                              </InputRightElement>
                            )}
                          </InputGroup>
                        </FormControl>
                      </Stack>
                    </FilterMenu>
                  )
                  break
                default:
                  break
              }

              return filterComponent
            })}
          {isFilterActive() && (
            <WrapItem>
              <Button
                leftIcon={<Icon as={AiOutlineClear} />}
                colorScheme="gray"
                variant="outline"
                height={8}
                onClick={clearFilters}
                data-cy="clear-filters-button"
              >
                {'Clear all'}
              </Button>
            </WrapItem>
          )}
        </Wrap>
      </Flex>
      <Box overflowX="auto">
        <Table>
          <Thead verticalAlign="top">
            <Tr>
              {columns.map((column, index) => (
                <Th
                  data-cy={`column-sort-${column.accessor}`}
                  onClick={() => column.sorting && handleSortToggle(column)}
                  key={index + '-header'}
                  cursor="pointer"
                >
                  <Stack direction="row" alignItems="center" spacing={1} justify="space-between">
                    <Text>{column.Header}</Text>
                    {column.sorting && (
                      <Box>
                        <Stack direction="column" align="center" spacing={-2}>
                          <Box as="button" aria-label="Sort ascending">
                            <TriangleUpIcon
                              boxSize="0.75em"
                              color={columnSort[column.accessor] === 'asc' ? 'black' : 'gray.400'}
                            />
                          </Box>
                          <Box as="button" aria-label="Sort descending">
                            <TriangleDownIcon
                              boxSize="0.75em"
                              color={columnSort[column.accessor] === 'desc' ? 'black' : 'gray.400'}
                            />
                          </Box>
                        </Stack>
                      </Box>
                    )}
                  </Stack>
                </Th>
              ))}
            </Tr>
          </Thead>
          <Tbody>
            {isLoading ? (
              <SkeletonRows lines={5} items={columns.length} />
            ) : filteredData.length > 0 ? (
              filteredData.map((row, index) => (
                <Tr key={index + '-row'} data-cy={`table-row-${index}`}>
                  {columns.map((column, colIndex) => (
                    <Td
                      key={colIndex}
                      isNumeric={column.type === 'number'}
                      data-cy={`table-row-${index}-cell-${column.accessor}`}
                    >
                      {/* TODO: ref this properly or move to TR. Issue in Safari prevents wrapping TR with LinkOverlay */}
                      {rowHref ? (
                        <Link to={row[rowHref] as string}>
                          {column.type === 'datetime' && column.format && typeof row[column.accessor] === 'string'
                            ? formatDate(parseISO(row[column.accessor] as string), column.format)
                            : row[column.accessor]}
                        </Link>
                      ) : (
                        <>
                          {column.type === 'datetime' && column.format && typeof row[column.accessor] === 'string'
                            ? formatDate(parseISO(row[column.accessor] as string), column.format)
                            : row[column.accessor]}
                        </>
                      )}
                    </Td>
                  ))}
                </Tr>
              ))
            ) : (
              <Tr data-cy="no-data-row">
                <Td data-cy="no-data-cell" colSpan={columns.length} textAlign="center">
                  {noDataText}
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
    </>
  )
}

export default DataTable

interface FilterLabelProps {
  type: 'min' | 'max'
  rangeFilters: Record<string, RangeFilter>
  dataMin: number
  dataMax: number
  accessor: string
}

const FilterLabel = ({ type, rangeFilters, dataMin, dataMax, accessor }: FilterLabelProps) => {
  const value = type === 'min' ? rangeFilters[accessor].min : rangeFilters[accessor].max
  const defaultValue = type === 'min' ? dataMin : dataMax

  return (
    <Box
      position="absolute"
      top="-30px"
      left={`${getLabelPosition(value || defaultValue, dataMin, dataMax, 250)}px`}
      px={2}
      py={1}
      fontSize="sm"
      borderRadius="sm"
      bg={'brand.quinary.500'}
      color="white"
      sx={{
        transform: 'translateX(-50%)',
      }}
    >
      {value || defaultValue}
    </Box>
  )
}
