import { Box, StackDivider, VStack } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import React from 'react'
import { CardHeader } from './CardHeader'
import { Widget } from '@/pages/perspectives/perspectiveHelpers'
import { Proposal } from '@/_clients/proposals'

interface Props {
  style?: object
  className?: string
  action?: React.ReactNode
  children: React.ReactNode
  proposal?: Proposal | null
  //content: React.ReactNode
  widget: Widget
  footer?: React.ReactNode
}

export const DummyWidget = React.forwardRef<HTMLDivElement, Props>(
  ({ style, className, widget, action, children, proposal, footer, ...props }, ref) => {
    const { t } = useTranslation()
    return (
      <VStack
        style={{ ...style }}
        divider={<StackDivider borderColor="gray.200" />}
        {...props}
        align="stretch"
        ref={ref}
        shadow={'md'}
        bg={'gray.50'}
        rounded={'md'}
        pt={2}
        pb={4}
        pl={2}
        pr={2}
        pos={'relative'}
        className={className}
      >
        <CardHeader title={t(`analyzer.widget.${widget.i}.id`)} action={action} />
        <Box className="scrollbar" overflowY={'auto'} height="100%" margin={2}>
          {children}
        </Box>
        {footer && (
          <Box ml={2} mr={2} height="30px">
            {footer}
          </Box>
        )}
      </VStack>
    )
  }
)
