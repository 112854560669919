import React from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import '@/styles/react-datepicker-style-override.css'
import '@/styles/react-datepicker-style-override-light.css'
import '@/styles/react-datepicker-style-override-dark.css'
import '@/styles/linkbox-overlay.css'
import { Outlet } from 'react-router-dom'

export const TopicsOfInterestPage = () => {
  return (
    <>
      <Outlet />
    </>
  )
}
