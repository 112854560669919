import Keycloak, { KeycloakConfig, KeycloakInitOptions, KeycloakInstance, KeycloakTokenParsed } from 'keycloak-js'
import { AuthenticatedUser, Authenticator } from './Authenticator.types'
import { getAuthConfig } from './getAuthConfig'
import { renderFallbackErrorMessage } from './renderFallbackErrorMessage'

interface DAKeycloakAccessTokenParsed extends KeycloakTokenParsed {
  sub: string
  name: string
  email: string
}

interface DAKeycloakIdTokenParsed extends DAKeycloakAccessTokenParsed {
  groups?: string[]
}

const getKeycloakConfig = (): KeycloakConfig => {
  const authConfig = getAuthConfig()
  if (!authConfig) {
    throw new Error('The configuration is not available.')
  }
  const { url, realm, clientId } = authConfig
  if (!url) {
    throw new Error('The URL is not available.')
  }
  if (!realm) {
    throw new Error('The realm is not available.')
  }
  if (!clientId) {
    throw new Error('The clientId is not available.')
  }
  return {
    url,
    realm,
    clientId,
  }
}

export class KeycloakAuthenticator implements Authenticator {
  private readonly keycloak: KeycloakInstance
  private readonly config: KeycloakConfig
  private initialized: boolean
  private timeout: any
  private readonly applicationGroup?: string

  constructor(applicationGroup?: string) {
    this.config = getKeycloakConfig()
    this.keycloak = Keycloak(this.config)
    this.initialized = false
    this.timeout = null
    this.applicationGroup = applicationGroup

    // Error handling can be tested by removing valid values from the Keycloak client's list of Web Origin
    this.keycloak.onAuthError = (e) => {
      const msg = `Authentication at ${this.config.url} failed. (onAuthError)`
      console.error(msg, e)
      renderFallbackErrorMessage(msg)
      this.keycloak.logout()
    }
    // TODO 2021/01 rpoetz: how to test this?
    this.keycloak.onAuthRefreshError = () => {
      const msg = `Authentication token refresh failed. (onAuthRefreshError)`
      console.error(msg)
      this.keycloak.logout()
    }
  }

  init(done: (isAuthenticated: boolean) => void) {
    const initOptions: KeycloakInitOptions = {
      onLoad: 'login-required',
      silentCheckSsoRedirectUri: `${window.location.origin}/check-login.html`,
      enableLogging: true,
      //checkLoginIframe: false // temp solution for rerendering problem in FF, Safari etc.
    }
    this.keycloak
      .init(initOptions)
      .then((authenticated) => {
        // console.log('Keycloak has been initialized. accessToken -> ', this.getToken())
        this.initialized = true

        if (authenticated) {
          // console.log('Keycloak has successfully authenticated the user. idToken -> ', this.keycloak.idTokenParsed)
          if (this.applicationGroup !== undefined && !this.hasGroup(this.applicationGroup)) {
            const { email } = this.keycloak.idTokenParsed as DAKeycloakIdTokenParsed
            const msg = `The account ${email} is not allowed to access the DEEP.assist App.`
            console.error(msg) // do not log the token itself (-> privacy, security)
            renderFallbackErrorMessage(msg, 'Failed authorization')
            return
          }
          this.initTokenRefresh()
        }
        done(this.getToken() !== undefined)
      })
      .catch((e) => {
        console.error(`Login at ${this.config.url} failed.`, e)
        done(false)
      })

    // Render an error message if keycloak init dies silently during SSO check (e.g. because Keycloak URL isn't available)
    // The rendering has to be deferred because the Keycloak client initialization is done asynchronously.
    setTimeout(() => {
      if (!this.isInitialized()) {
        renderFallbackErrorMessage(`Initializing authentication at ${this.config.url} failed.`)
      }
    }, 2_500)
  }

  isInitialized(): boolean {
    return this.initialized
  }

  getToken(): string | undefined {
    return this.keycloak.token
  }

  hasGroup(expectedGroup: string): boolean {
    if (!expectedGroup) {
      return false
    }
    const idToken = this.keycloak.idTokenParsed as DAKeycloakIdTokenParsed
    if (idToken) {
      const { groups } = idToken
      if (groups && Array.isArray(groups) && groups.includes(expectedGroup)) {
        return true
      }
    } else {
      console.error('There is no identity token available.')
    }
    return false
  }

  hasRole(expectedRole: string): boolean {
    if (!expectedRole) {
      return false
    }
    const idToken = this.keycloak.idTokenParsed
    if (idToken) {
      const { realm_access } = idToken
      if (
        realm_access &&
        realm_access.roles &&
        Array.isArray(realm_access.roles) &&
        realm_access.roles.includes(expectedRole)
      ) {
        return true
      }
    } else {
      console.error('There is no identity token available')
    }
    return false
  }

  isAuthenticated(): boolean {
    if (!this.isInitialized()) {
      return false
    }
    return this.keycloak.authenticated === true
  }

  login(): void {
    if (!this.isInitialized()) {
      return
    }
    const redirectUri = window.location.origin
    this.keycloak.login({ redirectUri })
  }

  logout(): void {
    if (!this.isInitialized()) {
      return
    }
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.keycloak.logout()
  }

  currentUser(): AuthenticatedUser | undefined {
    if (!this.isAuthenticated()) {
      return undefined
    }
    const tokenParsed = this.keycloak.idTokenParsed as DAKeycloakAccessTokenParsed | undefined
    if (!tokenParsed) {
      return undefined
    }
    const { sub, name, email } = tokenParsed
    return {
      sub,
      email,
      name,
    }
  }

  getRoles(): string[] | undefined {
    if (!this.isAuthenticated()) {
      //check if user is authenticated before returning roles assigned to the user
      return undefined //return undefined if user is not authenticated
    }

    const idToken = this.keycloak.idTokenParsed //get the parsed id token from keycloak instance

    if (!idToken) {
      return undefined //if parsed id token does not exist, return undefined
    }

    const { realm_access } = idToken //get realm access from parsed id token

    if (realm_access && realm_access.roles && Array.isArray(realm_access.roles)) {
      //check if realm access and roles exist and are of type array before returning roles assigned to the user

      return realm_access?.['roles'] //return all roles assigned to the user in an array of strings
    } else {
      //if realm access or role does not exist or are not of type array, return undefined

      return undefined
    }
  }

  private initTokenRefresh(): void {
    this.timeout = setInterval(() => {
      // see https://github.com/keycloak/keycloak/blob/66dfa32cd569a7416de21b4dc04db212e8fccce5/adapters/oidc/js/src/main/resources/keycloak.js#L618
      this.keycloak
        .updateToken(5)
        .then(() => {
          //console.log('Keycloak has updated the token', this.getToken())
        })
        .catch((e) => {
          console.error('Error while refreshing the authentication token.', e)
          this.keycloak.logout()
        })
    }, 10_000)
  }

  /*public getRoleAttributes(): string[] {
    if (!this.isAuthenticated()) {
      return [];
    }
  
    const idToken = this.keycloak.idTokenParsed;
    if (idToken) {
      const {realm_access} = idToken;
      if (realm_access && realm_access.roles && Array.isArray(realm_access.roles)) {
        return realm_access.roles;
      } else {
        console.error('There are no roles available.');
      }  
    } else {
      console.error('There is no identity token available.');  
    }
  
    return [];  
  }*/
}

/*const getRole = (realmName: string, roleName: string) => {
  return fetch(`/auth/admin/realms/${realmName}/roles/${roleName}`, {
    method: 'GET'
  });
};*/
