import { useController } from 'react-hook-form'
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import * as React from 'react'
import { UseControllerProps } from 'react-hook-form/dist/types'
import { SelectOptionType } from '@/types'

type ControlledSelectProps = {
  label: string
  options: SelectOptionType[]
  isDisabled: boolean
} & UseControllerProps

export default function ControlledSelect({
  name,
  control,
  label,
  rules,
  options,
  isDisabled,
  ...props
}: ControlledSelectProps) {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  })

  return (
    <FormControl isInvalid={!!error} id={name} isDisabled={isDisabled}>
      <FormLabel>{label}</FormLabel>

      <Select
        name={name}
        ref={ref}
        onBlur={onBlur}
        options={options}
        value={options && value ? options.find((option: any) => option.value === value) : null}
        onChange={(option) => onChange(option.value)}
        {...props}
      />
      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  )
}
