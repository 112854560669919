/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Flex,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  useBoolean,
  useToast,
  VStack,
} from '@chakra-ui/react'
import { Just, Maybe, Nothing } from 'purify-ts'
import * as React from 'react'
import appConfig from '../../constants/appConfig'
import { PageHeader } from '@/components/PageHeader'
import { add, format, formatDistanceStrict, sub } from 'date-fns'
import { BillingApi } from '@/api/apis'
import { HttpRequestState } from '@/types'
import { FiSearch } from 'react-icons/fi'
import { useTranslation } from 'react-i18next'
import DateRangePicker from '@/components/inputs/DateRangePicker'
import DataTable from '@/components/tables/DataTable'
import { Column, DataItem } from '@/components/tables/types'

// comment / uncomment to add or remove the columns from table
const columns: Column[] = [
  { Header: 'Instance', accessor: 'instance', type: 'string', filtering: true, sorting: true },
  { Header: 'API Key', accessor: 'apiKey', type: 'string', filtering: true, sorting: true },
  //{ Header: 'Name', accessor: 'name', type: 'string', filtering: true, sorting: true },
  { Header: 'Session Count (Request Count)', accessor: 'sessionCount', type: 'string', filtering: true, sorting: true },
  { Header: 'Avg Response Time (ms)', accessor: 'durationAvg', type: 'number', filtering: true, sorting: true },
  {
    Header: '95th Percentile Response Time (ms)',
    accessor: 'duration95',
    type: 'number',
    filtering: true,
    sorting: true,
  },
  {
    Header: '99th Percentile Response Time (ms)',
    accessor: 'duration99',
    type: 'number',
    filtering: true,
    sorting: true,
  },
]

export const UsagePage = () => {
  const { t } = useTranslation()
  const toast = useToast()

  const minDate = format(sub(new Date(), { years: 1, days: -1 }), appConfig.dateFormat)
  const maxDate = format(new Date(), appConfig.dateFormat)
  const [startDate, setStartDate] = React.useState(minDate)
  const [endDate, setEndDate] = React.useState(format(new Date(), appConfig.dateFormat))
  const [overview, setOverview] = React.useState<HttpRequestState<Maybe<DataItem[]>>>({
    isLoading: false,
    value: Maybe.empty(),
  })
  const [, setFlag] = useBoolean()

  const getTimeSpan = (start?: string, end?: string) => {
    if (start && end) {
      return formatDistanceStrict(new Date(start), sub(new Date(end), { days: 1 }), { unit: 'day' })
    } else {
      return formatDistanceStrict(new Date(startDate), add(new Date(endDate), { days: 1 }), { unit: 'day' })
    }
  }

  const modifyStartDate = (date: string) => {
    setStartDate(date)
  }

  const modifyEndDate = (date: string) => {
    setEndDate(date)
  }

  const [, setUsedRequest] = React.useState({
    start: minDate,
    end: maxDate,
    period: getTimeSpan(),
    valid: true,
  })

  const getBilling = (start?: string, end?: string) => {
    const from = start || startDate
    const to = end || endDate
    setOverview((prev) => {
      return { ...prev, isLoading: true }
    })
    BillingApi.postForOverview({ overviewRequest: { from, to } }).subscribe({
      next: (overview) => {
        const dataTableData: DataItem[] = (overview.instances ?? []).flatMap((instance) => {
          return (instance.keys ?? []).map((key) => {
            const sessionCount = key.sessionCount ?? '-'
            const requestCount = key.requestCount ? `(${key.requestCount})` : ''

            return {
              instance: instance.instance || '',
              apiKey: key.apiKey || '',
              name: key.name || '',
              sessionCount: `${sessionCount}  ${requestCount}`,
              durationAvg: key.durationAvg || 0,
              duration95: key.duration95 || 0,
              duration99: key.duration99 || 0,
              requestCount: key.requestCount || 0,
            }
          })
        })
        setOverview({ isLoading: false, value: Just(dataTableData) })
        setUsedRequest({ start: minDate, end: maxDate, period: getTimeSpan(to, from), valid: true })
      },
      error: (err) => {
        setOverview({ isLoading: false, value: Nothing, error: err })
        setUsedRequest((prev) => ({ ...prev, valid: false }))
        toast({
          title: err.response ? err.response.name : err.name,
          description: err.response ? err.response.message : err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })
    setFlag.off()
  }

  React.useEffect(() => {
    getBilling()
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleDateRangeChange = (start: string, end: string) => {
    modifyStartDate(start)
    modifyEndDate(end)
    getBilling(start, end)
  }

  return (
    <Box overflow="visible" data-cy="usage-page">
      <PageHeader title={t('usage.pageTitle')} />
      <DateRangePicker onApply={handleDateRangeChange} minDate={minDate} />
      <Stack spacing="5">
        <Box>
          <Stack direction={{ base: 'column', md: 'row' }} justify="space-between" align="center">
            <VStack align="start" spacing={1}>
              <Text fontSize="lg" fontWeight="medium">
                Overview
              </Text>
            </VStack>
            <Flex>
              <InputGroup hidden maxW="xs">
                <InputLeftElement pointerEvents="none">
                  <Icon as={FiSearch} color="muted" boxSize="5" />
                </InputLeftElement>
                <Input placeholder="Search" />
              </InputGroup>
            </Flex>
          </Stack>
        </Box>
        {overview.value?.caseOf({
          Just: (dataTableData) => (
            <DataTable
              columns={columns}
              data={dataTableData}
              isLoading={overview.isLoading}
              noDataText="No data available"
            />
          ),
          Nothing: () => <Box>No data available</Box>,
        })}
      </Stack>
    </Box>
  )
}
