import { ArrowDownIcon, ArrowUpIcon, CloseIcon, ViewIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  Heading,
  HStack,
  Icon,
  Link,
  Skeleton,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
  VStack,
} from '@chakra-ui/react'
import React from 'react'
import { ViewHolder } from '../perspectiveHelpers'
import { useTranslation } from 'react-i18next'
import { LocatedHint, MatchedHintsToOrigin, Proposal, ScoredBusinessCase } from '@/_clients/proposals'
import { useParams } from 'react-router-dom'
import { CgArrowsHAlt } from 'react-icons/cg'
import { StatementType } from '@/_clients/admin'
import { getTopicsOfInterestPath } from '@/pages/topics-of-interest/topicsOfInterest.paths'

interface Props {
  view: ViewHolder
  deleteView: Function
  tableColumns: string[]
  moveUp: Function
  moveDown: Function
  highlightHint: Function
  highlightStatement: Function
  first: boolean
  last: boolean
}

interface GroupStatement {
  type: StatementType.Group
  left: string
  leftStrategy: string
  right: string
  rightStrategy: string
  distance: string
}

interface RangeStatement {
  type: StatementType.Range
  min: string
  leftSign: string
  value: string
  rightSign: string
  max: string
  distance: string
  strategy: string
}

interface TextStatement {
  type: StatementType.Text
  text: string
}
/*const openInNewTab = (url: string): void => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}*/

export const View = ({
  view,
  deleteView,
  tableColumns,
  moveUp,
  moveDown,
  first,
  last,
  highlightHint,
  highlightStatement,
}: Props) => {
  const { t } = useTranslation()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [emphasizedHints, setEmphasizedHints] = React.useState<string[]>([])
  const [emphasizedToi, setEmphasizedToi] = React.useState<string>('')
  const instance = useParams<'instance'>().instance || ''

  const hintTypeToColorScheme = (hintType: string): string => {
    switch (hintType) {
      case 'TEXTUAL':
        return 'green'
      case 'NUMERICAL':
        return 'orange'
      case 'GROUP':
        return 'blue'
      case 'TEXT':
        return 'green'
      case 'RANGE':
        return 'orange'
      default:
        return 'gray'
    }
  }

  const getStatementType = (name: string): GroupStatement | RangeStatement | TextStatement | null => {
    const typeRegex = new RegExp('^[^(,]*', 'i')
    const groupRegex = new RegExp('([^(].*); (.*); (.*); (.*); ([0-9]*)', 'i')
    const rangeRegex = new RegExp('\\((\\d+) (<|<=) (.*?) (<|<=) (\\d+); (\\d+);?(.*?)?\\)', 'i')

    if (!name.includes('GROUP(') && !name.includes('RANGE(')) {
      return { type: StatementType.Text, text: name }
    } else {
      const type = name.match(typeRegex)
      if (type && type[0] === 'GROUP') {
        const sub = name.slice(5)
        const values = sub.match(groupRegex)
        if (values) {
          let [, left, leftStrategy, right, rightStrategy, distance] = values
          if (leftStrategy === '-') leftStrategy = 'DE'
          if (rightStrategy === '-') rightStrategy = 'DE'
          return {
            type: StatementType.Group,
            left,
            leftStrategy,
            right,
            rightStrategy,
            distance,
          } as GroupStatement
        }
      }
      if (type && type[0] === 'RANGE') {
        const sub = name.slice(5)
        const values = sub.match(rangeRegex)
        if (values) {
          let [, min, leftSign, value, rightSign, max, distance, strategy] = values
          strategy = strategy ? strategy : 'DE'
          return {
            type: StatementType.Range,
            min,
            leftSign,
            value,
            rightSign,
            max,
            distance,
            strategy,
          } as RangeStatement
        }
      }
      return null
    }
  }

  const emphasize = (hints: string[], toi: ScoredBusinessCase, view: ViewHolder) => {
    setEmphasizedHints(hints)
    setEmphasizedToi(toi.id!)
    highlightStatement(hints, view)
  }

  const unemphasize = () => {
    setEmphasizedHints([])
    setEmphasizedToi('')
    highlightStatement([], view)
  }

  const renderStatementsCell = (
    view: ViewHolder,
    toi: ScoredBusinessCase,
    matchedHintsToOrigins?: MatchedHintsToOrigin[],
    boostedOrigins?: string[]
  ) => {
    return matchedHintsToOrigins?.map((match, index) => {
      const statementName = match.origin || ''
      const statement = getStatementType(statementName)
      const hints = match.hints || []
      const boosted = boostedOrigins?.includes(statementName)
      if (statement?.type === StatementType.Text) {
        return (
          <Tag
            m={1}
            onMouseOver={() => emphasize(hints, toi, view)}
            onMouseLeave={unemphasize}
            colorScheme={hintTypeToColorScheme(statement.type)}
            variant={boosted ? 'subtle' : 'outline'}
            key={`${statementName}-${index}`}
          >
            {statement.text}
          </Tag>
        )
      } else if (statement?.type === StatementType.Group) {
        return (
          <Tag
            m={1}
            onMouseOver={() => emphasize(hints, toi, view)}
            onMouseLeave={unemphasize}
            colorScheme={hintTypeToColorScheme(statement.type)}
            variant={boosted ? 'subtle' : 'outline'}
            key={`${statementName}-${index}`}
          >
            <Flex m={1} flex="3 1 3" alignItems="center" justifyContent="center">
              <Box mr={2}>
                <Flex flexDir="column" align="center">
                  <Box>
                    <Text as="b">{statement.left}</Text>
                  </Box>
                  <Box>
                    <Text fontSize="xs">{statement.leftStrategy}</Text>
                  </Box>
                </Flex>
              </Box>
              <Box position="relative">
                <Icon as={CgArrowsHAlt} boxSize={4} position="relative" top={2} />
                <Text fontSize="xs" position="absolute" top={-0.5} left={0.5}>
                  {statement.distance}
                </Text>
              </Box>
              <Box ml={2}>
                <Flex flexDir="column" align="center">
                  <Box>
                    <Text as="b">{statement.right}</Text>
                  </Box>
                  <Box>
                    <Text fontSize="xs">{statement.rightStrategy}</Text>
                  </Box>
                </Flex>
              </Box>
            </Flex>
          </Tag>
        )
      } else if (statement?.type === StatementType.Range) {
        return (
          <Tag
            m={1}
            onMouseOver={() => emphasize(hints, toi, view)}
            onMouseLeave={unemphasize}
            colorScheme={hintTypeToColorScheme(statement.type)}
            variant={boosted ? 'subtle' : 'outline'}
            key={`${statementName}-${index}`}
          >
            <HStack m={1} spacing={2}>
              <Box mr={2}>
                <Flex flexDir="column" align="center">
                  <Box>
                    <Text as="b">{statement.value}</Text>
                  </Box>
                  <Box>
                    <Text fontSize="xs">{statement.strategy}</Text>
                  </Box>
                </Flex>
              </Box>
              <Box position="relative">
                <Icon as={CgArrowsHAlt} boxSize={4} position="relative" top={2} />
                <Text fontSize="xs" position="absolute" top={-0.5} left={0.5}>
                  {statement.distance}
                </Text>
              </Box>
              <HStack spacing={0}>
                {statement.leftSign === '<=' ? <Text>&lt;</Text> : <Text>(</Text>}
                <Text as="b">{statement.min}</Text>
                <Text>;</Text>
                <Text as="b">{statement.max}</Text>
                {statement.rightSign === '<=' ? <Text>&gt;</Text> : <Text>)</Text>}
              </HStack>
            </HStack>
          </Tag>
        )
      }
      return <Tag>Undefined</Tag>
    })
  }

  const renderHintsCellContent = (
    highlight: Function,
    view: ViewHolder,
    toi: ScoredBusinessCase,
    hints?: string[],
    boostedHints?: string[]
  ) => {
    return hints?.map((hintInfo: string, index) => {
      let textArray = hintInfo.split('_')
      let hintType = textArray[0]
      let hintText = textArray[2]
      if (textArray.length === 4) {
        hintText = textArray[2] + '_' + textArray[3]
      }
      let boosted = false
      let strategy = 'None'
      let distance = ''
      let firstStatement = ''
      let secondStatement = ''
      const bracketsRegex = new RegExp('([a-z ].*)\\((.*?)\\)', 'i')
      let mix = null
      let firstVal = ''
      let secondVal = ''
      if (boostedHints) {
        boosted = boostedHints.includes(hintInfo)
      }
      if (hintType === 'GROUP') {
        const groupRegex = new RegExp('([a-z ].*) & (?<=& )(.*)(?=;); ([0-9].*)', 'i')

        const groupParts = hintText.match(groupRegex)
        if (groupParts) {
          firstStatement = groupParts[1]
          distance = groupParts[3]
          mix = groupParts[2].match(bracketsRegex)
          if (mix) {
            strategy = mix[1]
            secondStatement = mix[2]
          } else {
            secondStatement = groupParts[2]
          }
          hintText = `${firstStatement} & ${secondStatement}`
        }
      }
      if (hintType === 'NUMERICAL') {
        const numericalRegex = new RegExp('([0-9].*) <= ([A-Za-z].*) <= ([0-9].*); ([0-9].*)')
        const numParts = hintText.match(numericalRegex)
        if (numParts) {
          firstVal = numParts[1]
          secondVal = numParts[3]
          distance = numParts[4]
          mix = numParts[2].match(bracketsRegex)
          if (mix) {
            strategy = mix[1]
            firstStatement = mix[2]
          } else {
            firstStatement = numParts[2]
          }
          hintText = `${firstVal} <= ${firstStatement} <= ${secondVal}`
        }
      }

      return hintType === 'GROUP' || hintType === 'NUMERICAL' ? (
        <Tooltip
          key={`${hintText}-${index}`}
          label={strategy + ' - ' + distance}
          aria-label="hint tooltip"
          placement="right"
        >
          <Tag
            m={1}
            onMouseOver={() => {
              highlight(hintInfo, view)
            }}
            onMouseLeave={() => {
              highlight('', view)
            }}
            colorScheme={
              emphasizedHints.includes(hintInfo) && emphasizedToi.includes(toi.id!)
                ? 'pink'
                : hintTypeToColorScheme(hintType)
            }
            variant={boosted ? 'subtle' : 'outline'}
          >
            {hintText}
          </Tag>
        </Tooltip>
      ) : (
        <Tag
          key={`${hintText}-${index}`}
          m={1}
          onMouseOver={() => {
            highlight(hintInfo, view)
          }}
          onMouseLeave={() => {
            highlight('', view)
          }}
          colorScheme={
            emphasizedHints.includes(hintInfo) && emphasizedToi.includes(toi.id!)
              ? 'pink'
              : hintTypeToColorScheme(hintType)
          }
          variant={boosted ? 'subtle' : 'outline'}
        >
          {hintText}
        </Tag>
      )
    })
  }

  const maxScore =
    view.proposal && view.proposal.businessCases
      ? Math.max.apply(
          Math,
          view.proposal?.businessCases.map(function (bc) {
            return bc.score
          })
        )
      : 0

  const getCheckboxValue = (bc: ScoredBusinessCase, proposal: Proposal | null): LocatedHint[] => {
    let foundHints: LocatedHint[] = []
    let foundHint: LocatedHint
    bc.matchedHints?.forEach((hint) => {
      if (proposal && proposal.hints) {
        foundHint = proposal.hints.filter((h) => h.id === hint)[0]
        foundHints.push(foundHint)
      }
    })
    return foundHints
  }

  return view.proposal ? (
    <VStack align="left" pl={1} pr={1} mb={3} className="view">
      <Flex align="center" justify="space-between" borderBottomWidth="1px" height={'40px'} borderBottom={'2px'}>
        <Tooltip label={view.name} aria-label="A tooltip" placement="right">
          <Heading fontSize="md">{view.title}</Heading>
        </Tooltip>
        <Box>
          <Button disabled={first} onClick={() => moveUp(view)} fontWeight="bold" bg="transparent" size="xs">
            <ArrowUpIcon w={4} h={4} />
          </Button>
          <Button disabled={last} onClick={() => moveDown(view)} fontWeight="bold" bg="transparent" size="xs">
            <ArrowDownIcon w={4} h={4} />
          </Button>
          <Button onClick={() => deleteView(view)} fontWeight="bold" bg="transparent" size="xs">
            <CloseIcon />
          </Button>
        </Box>
      </Flex>
      <Box>
        {view.proposal.businessCases && view.proposal.businessCases.length > 0 ? (
          <Table colorScheme="blackAlpha" size="xs">
            <Thead>
              <Tr>
                <Th w={'3%'} pr={2}>
                  <ViewIcon />
                </Th>
                <Th pr={2} w={'20%'} maxW={'300px'}>
                  {t('analyzer.widget.views.name')}
                </Th>
                {tableColumns.includes('score') && (
                  <Th pr={2} w={'10%'} minW={'150px'}>
                    {t('analyzer.widget.views.score')}
                  </Th>
                )}
                {tableColumns.includes('statement') && <Th w={'30%'}>{t('analyzer.widget.views.statement')}</Th>}
                {tableColumns.includes('hints') && <Th w={'30%'}>Hints</Th>}
              </Tr>
            </Thead>
            <Tbody>
              {view.proposal.businessCases?.map((businessCase, index) => {
                const relativeWidth = (businessCase.score / maxScore) * 100
                return (
                  <Tr key={`table-row-${index}`}>
                    <Td pr={2}>
                      <HStack alignItems={'center'}>
                        <Checkbox value={JSON.stringify(getCheckboxValue(businessCase, view.proposal))} />
                      </HStack>
                    </Td>
                    <Td pr={2}>
                      {businessCase.externalId && businessCase.externalId.includes('http') ? (
                        <Link isExternal color="teal.500" href={businessCase.externalId}>
                          {businessCase.name}
                        </Link>
                      ) : (
                        <Link
                          isExternal
                          href={getTopicsOfInterestPath(instance, businessCase.id!.replace('_null', ''))}
                          cursor="pointer"
                          _hover={{ fontWeight: 'bold' }}
                        >
                          {businessCase.name}
                        </Link>
                      )}
                    </Td>
                    {tableColumns.includes('score') && (
                      <Td pr={2}>
                        <Box rounded={'sm '} bg={'orange'} w={`${relativeWidth}%`}>
                          <Text color={'white'} ml={2}>
                            {Math.round(businessCase.score * 10_0) / 10_0}
                          </Text>
                        </Box>
                      </Td>
                    )}
                    {tableColumns.includes('statement') && (
                      <Td>
                        <HStack flexWrap={'wrap'} shouldWrapChildren={true}>
                          {renderStatementsCell(
                            view,
                            businessCase,
                            businessCase.matchedHintsToOrigins,
                            businessCase.matchedBoostedOrigins
                          )}
                        </HStack>
                      </Td>
                    )}
                    {tableColumns.includes('hints') && (
                      <Td>
                        <HStack flexWrap={'wrap'} shouldWrapChildren={true}>
                          {renderHintsCellContent(
                            highlightHint,
                            view,
                            businessCase,
                            businessCase.matchedHints,
                            businessCase.matchedBoostedHints
                          )}
                        </HStack>
                      </Td>
                    )}
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        ) : (
          <Box data-cy="no-topics-of-interest">{t('analyzer.widget.views.noTopicsOfInterest')}</Box>
        )}
      </Box>
    </VStack>
  ) : (
    <>
      <VStack>
        <Flex
          align="center"
          justify="space-between"
          borderBottomWidth="1px"
          height={'40px'}
          borderBottom={'2px'}
          w="100%"
        >
          <Tooltip label={view.name} aria-label="A tooltip" placement="right">
            <Heading fontSize="md">{view.title}</Heading>
          </Tooltip>
          <Box>
            <Button disabled={first} fontWeight="bold" bg="transparent" size="xs">
              <ArrowUpIcon w={4} h={4} />
            </Button>
            <Button disabled={last} fontWeight="bold" bg="transparent" size="xs">
              <ArrowDownIcon w={4} h={4} />
            </Button>
            <Button fontWeight="bold" bg="transparent" size="xs">
              <CloseIcon />
            </Button>
          </Box>
        </Flex>
      </VStack>
      <Grid templateColumns="repeat(3, 1fr)" gap={3} mb={10}>
        <Skeleton height="30px" />
        <Skeleton height="30px" />
        <Skeleton height="30px" />
        <Skeleton height="30px" />
        <Skeleton height="30px" />
        <Skeleton height="30px" />
        <Skeleton height="30px" />
        <Skeleton height="30px" />
        <Skeleton height="30px" />
        <Skeleton height="30px" />
        <Skeleton height="30px" />
        <Skeleton height="30px" />
      </Grid>
    </>
  )
}
