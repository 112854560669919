import {
  Badge,
  Box,
  Checkbox,
  Flex,
  Heading,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Tag,
  Textarea,
  VStack,
} from '@chakra-ui/react'
import * as React from 'react'
import { useParams } from 'react-router'
import { useBoolean } from 'react-use'
import { InspectionApi } from '@/api/apis'
import { InspectionHint, InspectionHints, Scoring } from '@/_clients/proposals'
import { PageHeader } from '@/components/PageHeader'
import {
  containsThreeWordTokens,
  containsTwoWordTokens,
  getAllHints,
  getAllTokens,
  getEliminatedHints,
  getInternalHints,
  getReferencedHintsPerHint,
  getReferencedHintsPerToken,
  getReferencedTokenPositions,
  getSingleTokens,
  getThreeWordTokens,
  getTwoWordTokens,
  getWinningHints,
  PositionAwareInspectionToken,
} from './utils/inspection.utils'
import { useTranslation } from 'react-i18next'

enum RootTokenType {
  LEMMA = 'LEMMA',
  TEXT = 'TEXT',
}

export const InspectionPage = () => {
  const { t } = useTranslation()
  const [text, setText] = React.useState('')
  const [result, setResult] = React.useState<InspectionHints | null>(null)
  const [showTwoWordTokens, setShowTwoWordTokens] = useBoolean(false)
  const [showThreeWordTokens, setShowThreeWordTokens] = useBoolean(false)
  const [highlightedTokens, setHighlightedTokens] = React.useState<number[]>([])
  const [highlightedHints, setHighlightedHints] = React.useState<string[]>([])
  const [rootToken, setRootToken] = React.useState<number | null>(null)
  const [rootHintId, setRootHintId] = React.useState<string | null>(null)
  const [rootTokenType, setRootTokenType] = React.useState<RootTokenType>(RootTokenType.TEXT)
  const [spellings, setSpellings] = React.useState<Array<string>>([])
  const instance = useParams<'instance'>().instance || ''

  const allTokens = React.useMemo(() => getAllTokens(result), [result])
  const singleTokens = React.useMemo(() => getSingleTokens(result), [result])
  const twoWordTokens = React.useMemo(() => getTwoWordTokens(result), [result])
  const threeWordTokens = React.useMemo(() => getThreeWordTokens(result), [result])

  const allHints = React.useMemo(() => getAllHints(result), [result])
  const winningHints = React.useMemo(() => getWinningHints(result), [result])
  const internalHints = React.useMemo(() => getInternalHints(result), [result])
  const eliminatedHints = React.useMemo(() => getEliminatedHints(result), [result])

  React.useEffect(() => {
    if (text) {
      InspectionApi.postForInspectionHints({
        instance,
        proposalInput: {
          text,
          views: ['all'],
          scoring: Scoring.Recall,
        },
      }).subscribe(setResult)
    }
  }, [text, instance])

  const handleTextAreaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(e.currentTarget.value)
  }

  const setTokenOriginatedHighlights = (pos: number, type: RootTokenType) => {
    setRootHintId(null)
    setRootTokenType(type)

    if (rootToken !== pos) {
      setRootToken(pos)
      setHighlightedTokens([pos])
      setHighlightedHints(getReferencedHintsPerToken(pos, allHints))
    } else {
      setRootToken(null)
      setHighlightedTokens([])
      setHighlightedHints([])
    }
  }

  const setHintOriginatedHighlights = (hint: InspectionHint) => {
    setRootToken(null)

    if (hint.id !== rootHintId) {
      setRootHintId(hint.id!)
      const hints = [hint, ...getReferencedHintsPerHint(hint, allHints)]
      const tokens = getReferencedTokenPositions(hints, allTokens)
      setHighlightedTokens(tokens)
      setHighlightedHints(hints.map((hint) => hint.id!))

      InspectionApi.getInspectionHintDetails({
        instance: instance,
        id: hint.id!,
      }).subscribe((result) => {
        if (result.spellings) {
          setSpellings(result.spellings)
        }
      })

      if (containsTwoWordTokens(tokens, result)) {
        setShowTwoWordTokens(true)
      }
      if (containsThreeWordTokens(tokens, result)) {
        setShowThreeWordTokens(true)
      }
    } else {
      setRootHintId(null)
      setHighlightedTokens([])
      setHighlightedHints([])
    }
  }

  const getTokenHighlightColor = (pos: number, type: RootTokenType) => {
    if (pos === rootToken && type === rootTokenType) {
      return 'yellow'
    }
    return getHighlightColor()
  }

  const getHintHighlightColor = (hint: InspectionHint) => {
    if (hint.id === rootHintId) {
      return 'yellow'
    }
    return getHighlightColor()
  }

  const getEliminatedHintColor = (hint: InspectionHint) => {
    if (hint.tokens && hint.tokens.filter((eachToken) => highlightedTokens.includes(eachToken)).length > 0) {
      return 'red'
    }
    return undefined
  }

  const getHighlightColor = () => {
    return 'green'
  }

  const renderTokens = (tokens: PositionAwareInspectionToken[], name: string) => {
    return (
      <VStack align="left">
        <Heading size="md">
          {name} <Badge>{tokens.length}</Badge>
        </Heading>
        <Flex wrap={'wrap'}>
          {tokens.map((token, index) => {
            const isHighlighted = highlightedTokens.includes(token.pos)
            return (
              <Box pb={2} pr={2} key={`${token.text}_${index}_${name}`}>
                <Tag
                  onClick={() => setTokenOriginatedHighlights(token.pos, RootTokenType.TEXT)}
                  colorScheme={isHighlighted ? getTokenHighlightColor(token.pos, RootTokenType.TEXT) : undefined}
                  cursor="pointer"
                >
                  {token.text}
                </Tag>
              </Box>
            )
          })}
        </Flex>
      </VStack>
    )
  }
  const renderLemmatizedTokens = (tokens: PositionAwareInspectionToken[], name: string) => {
    return (
      <VStack align="left">
        <Heading size="md">
          {name} <Badge>{tokens.length}</Badge>
        </Heading>
        <Flex wrap={'wrap'}>
          {tokens.map((token, index) => {
            const isHighlighted = highlightedTokens.includes(token.pos)
            return (
              <Box pb={2} pr={2} key={`${token.text}_${index}_${name}`}>
                <Tag
                  onClick={() => setTokenOriginatedHighlights(token.pos, RootTokenType.LEMMA)}
                  colorScheme={isHighlighted ? getTokenHighlightColor(token.pos, RootTokenType.LEMMA) : undefined}
                  cursor="pointer"
                >
                  {token.lemma}
                </Tag>
              </Box>
            )
          })}
        </Flex>
      </VStack>
    )
  }
  const renderHints = (hints: InspectionHint[], name: string, formatEliminatedHints?: boolean) => {
    return (
      <VStack align="left">
        <Heading size="md">
          {name} <Badge>{hints.length}</Badge>
        </Heading>
        <Flex wrap={'wrap'}>
          {hints.map((hint) => {
            const isHighlighted = highlightedHints.includes(hint.id!)
            const color = formatEliminatedHints ? getEliminatedHintColor(hint) : getHintHighlightColor(hint)
            return (
              <Box pb={2} pr={2} key={`${hint.id}/${color}`}>
                <Tag
                  onClick={() => setHintOriginatedHighlights(hint)}
                  colorScheme={isHighlighted || formatEliminatedHints ? color : undefined}
                  cursor="pointer"
                >
                  {hint.id}
                </Tag>
              </Box>
            )
          })}
        </Flex>
      </VStack>
    )
  }

  const renderInspectionResult = () => {
    return (
      <>
        {renderHints(winningHints, t('common.statements'))}
        {renderHints(internalHints, t('common.internalHints'))}
        {renderHints(eliminatedHints, t('common.eliminatedHints'), true)}

        {renderLemmatizedTokens(
          singleTokens,
          showTwoWordTokens || showThreeWordTokens ? t('inspection.singleLemmas') : t('common.lemmas')
        )}
        {showTwoWordTokens && renderLemmatizedTokens(twoWordTokens, t('inspection.twoWordsLemmas'))}
        {showThreeWordTokens && renderLemmatizedTokens(threeWordTokens, t('inspection.threeWordsLemmas'))}

        {renderTokens(
          singleTokens,
          showTwoWordTokens || showThreeWordTokens ? t('inspection.singleTokens') : t('common.tokens')
        )}
        {showTwoWordTokens && renderTokens(twoWordTokens, t('inspection.twoWordsTokens'))}
        {showThreeWordTokens && renderTokens(threeWordTokens, t('inspection.threeWordsTokens'))}
      </>
    )
  }

  return (
    <Box data-cy="inspection-page" h="100vh">
      <PageHeader title={t('inspection.pageTitle')} />
      <Flex width="full" h="full">
        <VStack spacing={5} align="left" w="full" flexGrow={1} h="full" pr={4} overflow="auto">
          <VStack align="left" w="full" mt="8px">
            <Heading size="md">{t('common.textInput')}</Heading>
            <Textarea
              placeholder={'Insert text to inspect'}
              size="sm"
              height={124}
              onChange={(e) => handleTextAreaChange(e)}
              value={text}
            />
          </VStack>
          <VStack align="left">
            <Heading size="md">{t('common.settings')}</Heading>
            <HStack alignItems="right" spacing="20px">
              {showTwoWordTokens && (
                <Checkbox
                  defaultChecked={showTwoWordTokens}
                  onChange={() => {
                    setShowTwoWordTokens(!showTwoWordTokens)
                  }}
                >
                  {t('inspection.twoWords')}
                </Checkbox>
              )}
              {!showTwoWordTokens && (
                <Checkbox
                  defaultChecked={showTwoWordTokens}
                  onChange={() => {
                    setShowTwoWordTokens(!showTwoWordTokens)
                  }}
                >
                  {t('inspection.twoWords')}
                </Checkbox>
              )}

              {showThreeWordTokens && (
                <Checkbox
                  defaultChecked={showThreeWordTokens}
                  onChange={() => {
                    setShowThreeWordTokens(!showThreeWordTokens)
                  }}
                >
                  {t('inspection.threeWords')}
                </Checkbox>
              )}
              {!showThreeWordTokens && (
                <Checkbox
                  defaultChecked={showThreeWordTokens}
                  onChange={() => {
                    setShowThreeWordTokens(!showThreeWordTokens)
                  }}
                >
                  {t('inspection.threeWords')}
                </Checkbox>
              )}
            </HStack>
          </VStack>
          {text && singleTokens.length > 0 && renderInspectionResult()}
        </VStack>

        <Box w={375} pl={8} flexGrow={0} flexShrink={0} h="full">
          <Tabs isFitted h="full">
            <TabList h="30px">
              {/*<Tab isDisabled={rootHintId === null}>Details</Tab>*/}
              <Tab isDisabled={rootHintId === null}>{t('inspection.spellings')}</Tab>
            </TabList>
            <TabPanels h="full" overflow="hidden">
              <TabPanel h="calc(100% - 30px)" p={0}>
                <Box overflowY="auto" h="full" mb={2} p={2}>
                  {rootHintId === null && <span>{t('inspection.noHint')}</span>}
                  {rootHintId !== null && (
                    <ul>
                      {spellings.map((spelling) => (
                        <li key={spelling}>{spelling}</li>
                      ))}
                    </ul>
                  )}
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Flex>
    </Box>
  )
}
