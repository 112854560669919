// tslint:disable
/**
 * ADMIN API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { MeaningExpression } from './'

/**
 * @export
 * @interface RelatedMeaningExpressionsRequest
 */
export interface RelatedMeaningExpressionsRequest {
    /**
     * @type {MeaningExpression}
     * @memberof RelatedMeaningExpressionsRequest
     */
    source: MeaningExpression;
    /**
     * @type {string}
     * @memberof RelatedMeaningExpressionsRequest
     */
    strategy?: RelatedMeaningExpressionsRequestStrategyEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum RelatedMeaningExpressionsRequestStrategyEnum {
    All = 'All',
    Default = 'Default',
    Hypernyms = 'Hypernyms',
    Hyponyms = 'Hyponyms',
    Meronyms = 'Meronyms',
    None = 'None'
}

