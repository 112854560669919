interface appConfigType {
  apiDateFormat: string
  dateFormat: string
  dateTimeFormat: string
  debounceTime: number
  defaultMaximumFilterLabelValueWidth: number | string
  maximumVisiblePagerNumberButtons: number
  noDataPlaceholder: string
  simulatedTypingDelay: number
  tableItemsPerPage: number
}

const appConfig: appConfigType = {
  apiDateFormat: 'yyyy-MM-dd',
  dateFormat: 'yyyy-MM-dd',
  dateTimeFormat: 'yyyy-MM-dd HH:mm:ss',
  debounceTime: 190,
  defaultMaximumFilterLabelValueWidth: '300px',
  maximumVisiblePagerNumberButtons: 15,
  simulatedTypingDelay: 10, // must be fewer than debounceTime (used by cypress)
  noDataPlaceholder: '[unknown]',
  tableItemsPerPage: 20,
}

export default appConfig
