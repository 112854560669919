import React, { useState } from 'react'
import {
  Box,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Badge,
  HStack,
  Tooltip,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  useToast,
  ModalFooter,
} from '@chakra-ui/react'
import { ExtractAndValidateResult } from '../helpers'

interface StatusIndicatorProps {
  result: ExtractAndValidateResult | null
}

const ExtractionStatusIndicator = ({ result }: StatusIndicatorProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const toast = useToast()
  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  const colorMap = {
    valid: 'green.500',
    invalid: 'red.500',
  }

  const status = result?.valid ? 'valid' : 'invalid'
  const color = useColorModeValue(colorMap[status], colorMap[status])

  const createTicket = () => {
    handleClose()
    setTimeout(() => {
      toast({
        title: 'Ticket Created',
        description: 'The ticket was successfully created.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    }, 2000)
  }

  return (
    <Box as="button" onClick={handleOpen}>
      <HStack spacing={3}>
        <Text>extraction</Text>
        <Tooltip label={status} aria-label="status">
          <Badge borderRadius="full" p="1" backgroundColor={color} boxSize="1.25rem" />
        </Tooltip>
      </HStack>

      <Modal isOpen={isOpen} onClose={handleClose} size={'lg'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{status.toUpperCase()}</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflow="auto">
            {result?.extractedJson && (
              <Table variant="simple">
                <Thead>
                  <Tr>
                    <Th>Key</Th>
                    <Th>Value</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {Object.entries(result.extractedJson).map(([key, value], index) => (
                    <Tr key={index}>
                      <Td>{key}</Td>
                      <Td style={{ backgroundColor: value === null ? 'rgba(255, 0, 0, 0.1)' : undefined }}>
                        {value === null ? 'N/A' : JSON.stringify(value)}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="green" onClick={createTicket}>
              Create a Ticket
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default ExtractionStatusIndicator
