import { Heading, Text, VStack } from '@chakra-ui/react'
import React from 'react'

interface Props {
  children: React.ReactNode
  title: string
}

const SimpleErrorPage = (props: Props) => {
  return (
    <VStack width="100vw" mt="20vh">
      <Heading as="h2">{props.title}</Heading>
      <Text>{props.children}</Text>
    </VStack>
  )
}

export default SimpleErrorPage
