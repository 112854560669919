import queryString from 'query-string'
import { FiltersType } from './types'
import { Location } from 'history'
import { NavigateFunction } from 'react-router'

type QueryParams = { [key: string]: string }

const readArrayQueryParam = (name: string, location: Location): string[] => {
  const bracketStringValue = queryString.parse(location.search)[name] as string
  const arrayValue = queryString.parse(bracketStringValue, { arrayFormat: 'bracket' })[name]
  if (bracketStringValue) {
    if (arrayValue instanceof Array) {
      return arrayValue
    } else {
      return []
    }
  } else {
    return []
  }
}
const readQueryParam = (name: string, location: Location): string | undefined => {
  const value = queryString.parse(location.search)[name]
  if (value) {
    if (value instanceof Array) {
      return value[0]
    } else {
      return value
    }
  } else {
    return undefined
  }
}
export const readFiltersFromQueryParams = (location: Location): FiltersType => {
  return {
    editor: readQueryParam('editor', location),
    externalId: readQueryParam('externalId', location),
    externalName: readQueryParam('externalName', location),
    statements: readArrayQueryParam('statements', location),
    modified: readQueryParam('modified', location),
    name: readQueryParam('name', location),
    views: readArrayQueryParam('views', location),
  }
}

export const applyFiltersAsQueryParams = (
  filters: FiltersType,
  location: Location,
  navigate: NavigateFunction
): void => {
  const queryParams: QueryParams = {}
  if (filters.editor) queryParams['editor'] = filters.editor
  if (filters.externalId) queryParams['externalId'] = filters.externalId
  if (filters.externalName) queryParams['externalName'] = filters.externalName
  if (filters.statements && filters.statements.length > 0)
    queryParams['hints'] = queryString.stringify({ hints: filters.statements }, { arrayFormat: 'bracket' })
  if (filters.modified) queryParams['modified'] = filters.modified
  if (filters.name) queryParams['name'] = filters.name
  if (filters.views && filters.views.length > 0)
    queryParams['views'] = queryString.stringify({ views: filters.views }, { arrayFormat: 'bracket' })
  const flatQueryParams = queryString.stringify(queryParams)
  const path = `${location.pathname}?${flatQueryParams}`
  navigate(path)
}
