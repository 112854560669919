import { Box, Button, Text, VStack } from '@chakra-ui/react'
import React, { useState } from 'react'
import { HiEye, HiEyeOff } from 'react-icons/hi'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import appConfig from '../../constants/appConfig'
import { authenticator } from '@/auth/authenticator'
import { PageHeader } from '@/components/PageHeader'
import { loadApiKeys } from './state/apiKeys.actions'
import { getCoreConfigurations } from './state/apiKeys.selectors'
import { Card } from './components/card/Card'
import { CardContent } from './components/card/CardContent'
import { CardHeader } from './components/card/CardHeader'
import { Property } from './components/card/Property'
import { useTranslation } from 'react-i18next'

type State = { [key: number]: boolean }

export const ApiKeysPage = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const instance = useParams<'instance'>().instance || ''
  const coreConfigurations = useSelector(getCoreConfigurations)
  const [showFlags, setShowFlags] = useState<State>({})
  const isUserAdmin = authenticator.hasRole('instances-admin')

  React.useEffect(() => {
    dispatch(loadApiKeys(instance))
  }, [dispatch, instance])

  return (
    <Box overflow="visible" data-cy="api-keys-page">
      <PageHeader title={t('apiKeys.pageTitle')} />
      <VStack spacing={10} align="left">
        {coreConfigurations.mapOrDefault(
          (configs) => (
            <>
              {configs.items?.map((config, index: number) => (
                <Card maxW="3xl" key={config.key}>
                  <CardHeader
                    title={`Key ${index + 1}`}
                    action={
                      isUserAdmin ? (
                        <Button
                          variant="outline"
                          minW="20"
                          leftIcon={showFlags[index] ? <HiEyeOff /> : <HiEye />}
                          onClick={() => setShowFlags({ ...showFlags, [index]: !showFlags[index] })}
                          data-cy={`toggle-details-${index}`}
                        >
                          {showFlags[index] ? t('apiKeys.hideDetails') : t('apiKeys.showDetails')}
                        </Button>
                      ) : undefined
                    }
                  />
                  <CardContent>
                    <Property label={t('apiKeys.key')} value={config.key || appConfig.noDataPlaceholder} />
                    <Property
                      label={t('apiKeys.perspectives')}
                      value={
                        config.perspectives &&
                        Object.entries(config.perspectives)
                          .map(([key, value]) =>
                            value.views ? key + ' (' + value.views.join(', ') + ')' : appConfig.noDataPlaceholder
                          )
                          .join(', ')
                      }
                    />
                    <Property label={t('apiKeys.preprocessing')} value={config.preprocessing} />
                    {showFlags[index] && (
                      <>
                        <Property label={t('apiKeys.scoring')} value={config.scoring || appConfig.noDataPlaceholder} />
                        <Property label={t('apiKeys.coreUri')} value={config.coreUri || appConfig.noDataPlaceholder} />
                        <Property
                          label={t('apiKeys.extractors')}
                          value={config.extractors?.join(', ') || appConfig.noDataPlaceholder}
                        />
                      </>
                    )}
                  </CardContent>
                </Card>
              ))}
            </>
          ),
          <Text>{t('common.noKeysData')}</Text>
        )}
      </VStack>
    </Box>
  )
}
