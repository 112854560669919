import produce from 'immer'
import { Maybe } from 'purify-ts'
import { AsyncErrorAction } from '@/store/state/AsyncAction'
import { StatementCollectionFilterType, StatementCollectionInfos } from '@/_clients/admin'
import {
  FilterInventoriesLoadSuccessAction,
  PredictiveClearAction,
  PredictiveLoadSucessAction,
  TopicsOfInterestAction,
  TopicsOfInterestActionKeys,
  TopicsOfInterestLoadSuccessAction,
} from './topicsOfInterest.actions'

export interface TopicOfInterestFilterInventoriesType {
  editors: string[]
  views: string[]
}

export interface PredictiveResultsType {
  name: Maybe<string[]>
  externalName: Maybe<string[]>
  externalId: Maybe<string[]>
  hint: Maybe<string[]>
}

export interface TopicsOfInterestState {
  topicsOfInterest: Maybe<StatementCollectionInfos>
  filterInventories: Maybe<TopicOfInterestFilterInventoriesType>
  predictiveResults: PredictiveResultsType
}

export const initialState: TopicsOfInterestState = {
  topicsOfInterest: Maybe.empty(),
  filterInventories: Maybe.empty(),
  predictiveResults: {
    name: Maybe.empty(),
    externalName: Maybe.empty(),
    externalId: Maybe.empty(),
    hint: Maybe.empty(),
  },
}

export const topicsOfInterestReducer = (
  state: TopicsOfInterestState = initialState,
  action: TopicsOfInterestAction
): TopicsOfInterestState => {
  return produce(state, (draft: TopicsOfInterestState) => {
    switch (action.type) {
      case TopicsOfInterestActionKeys.LOAD_SUCCESS: {
        const successAction = action as TopicsOfInterestLoadSuccessAction
        draft.topicsOfInterest = Maybe.of(successAction.topicsOfInterest)
        return
      }
      /*case BusinessCasesActionKeys.CLEAR: {
        //draft.inputText = initialState.inputText
        draft.businessCases = initialState.businessCases
        return
      }*/
      case TopicsOfInterestActionKeys.FILTER_INVENTORIES_LOAD_SUCCESS: {
        const successAction = action as FilterInventoriesLoadSuccessAction
        draft.filterInventories = Maybe.of(successAction.filterInventories)
        break
      }
      case TopicsOfInterestActionKeys.LOAD_ERROR: {
        const errorAction = action as AsyncErrorAction
        console.error('The following error occured when tried to retrieve business cases:')
        console.error(errorAction.error)
        break
      }
      case TopicsOfInterestActionKeys.FILTER_INVENTORIES_LOAD_ERROR: {
        const errorAction = action as AsyncErrorAction
        console.error('The following error(s) occured when tried to retrieve the inventories of the filters:')
        console.error(errorAction.error)
        break
      }
      case TopicsOfInterestActionKeys.PREDICTIVE_CLEAR: {
        const clearAction = action as PredictiveClearAction
        switch (clearAction.subject) {
          case StatementCollectionFilterType.Name: {
            draft.predictiveResults.name = Maybe.empty()
            break
          }
          case StatementCollectionFilterType.ExternalName: {
            draft.predictiveResults.externalName = Maybe.empty()
            break
          }
          case StatementCollectionFilterType.ExternalId: {
            draft.predictiveResults.externalId = Maybe.empty()
            break
          }
          case StatementCollectionFilterType.Statement: {
            draft.predictiveResults.hint = Maybe.empty()
            break
          }
        }
        break
      }
      case TopicsOfInterestActionKeys.PREDICTIVE_LOAD_SUCCESS: {
        const successAction = action as PredictiveLoadSucessAction
        switch (successAction.requestType) {
          case StatementCollectionFilterType.Name: {
            draft.predictiveResults.name = Maybe.of(successAction.payload)
            break
          }
          case StatementCollectionFilterType.ExternalName: {
            draft.predictiveResults.externalName = Maybe.of(successAction.payload)
            break
          }
          case StatementCollectionFilterType.ExternalId: {
            draft.predictiveResults.externalId = Maybe.of(successAction.payload)
            break
          }
          case StatementCollectionFilterType.Statement: {
            draft.predictiveResults.hint = Maybe.of(successAction.payload)
            break
          }
        }
        break
      }
    }
  })
}
