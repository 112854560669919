// tslint:disable
/**
 * ADMIN API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs'
import {
  BaseAPI,
  encodeURI,
  HttpHeaders,
  HttpQuery,
  OperationOpts,
  RawAjaxResponse,
  throwIfNullOrUndefined,
} from '../runtime'
import { CoreConfigurations, CoreHealth, InstanceConfiguration, Instances } from '../models'

export interface GetCoreConfigurationHealthLanguageModelRequest {
    instance: string;
    key: string;
}

export interface GetCoreConfigurationsRequest {
    instance: string;
    start?: number;
    count?: number;
}

export interface GetInstanceConfigurationRequest {
    instance: string;
}

/**
 * no description
 */
export class InstancesApi extends BaseAPI {

    /**
     * Get the Language Model health for a Core Configuration
     */
    getCoreConfigurationHealthLanguageModel({ instance, key }: GetCoreConfigurationHealthLanguageModelRequest): Observable<CoreHealth>
    getCoreConfigurationHealthLanguageModel({ instance, key }: GetCoreConfigurationHealthLanguageModelRequest, opts?: OperationOpts): Observable<RawAjaxResponse<CoreHealth>>
    getCoreConfigurationHealthLanguageModel({ instance, key }: GetCoreConfigurationHealthLanguageModelRequest, opts?: OperationOpts): Observable<CoreHealth | RawAjaxResponse<CoreHealth>> {
        throwIfNullOrUndefined(instance, 'instance', 'getCoreConfigurationHealthLanguageModel');
        throwIfNullOrUndefined(key, 'key', 'getCoreConfigurationHealthLanguageModel');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<CoreHealth>({
            url: '/instances/{instance}/core-configurations/{key}/health/language-model'.replace('{instance}', encodeURI(instance)).replace('{key}', encodeURI(key)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Get Core Configurations for the given instance.
     */
    getCoreConfigurations({ instance, start, count }: GetCoreConfigurationsRequest): Observable<CoreConfigurations>
    getCoreConfigurations({ instance, start, count }: GetCoreConfigurationsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<CoreConfigurations>>
    getCoreConfigurations({ instance, start, count }: GetCoreConfigurationsRequest, opts?: OperationOpts): Observable<CoreConfigurations | RawAjaxResponse<CoreConfigurations>> {
        throwIfNullOrUndefined(instance, 'instance', 'getCoreConfigurations');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (start != null) { query['start'] = start; }
        if (count != null) { query['count'] = count; }

        return this.request<CoreConfigurations>({
            url: '/instances/{instance}/core-configurations'.replace('{instance}', encodeURI(instance)),
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Get the Instance Configuration
     */
    getInstanceConfiguration({ instance }: GetInstanceConfigurationRequest): Observable<InstanceConfiguration>
    getInstanceConfiguration({ instance }: GetInstanceConfigurationRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InstanceConfiguration>>
    getInstanceConfiguration({ instance }: GetInstanceConfigurationRequest, opts?: OperationOpts): Observable<InstanceConfiguration | RawAjaxResponse<InstanceConfiguration>> {
        throwIfNullOrUndefined(instance, 'instance', 'getInstanceConfiguration');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<InstanceConfiguration>({
            url: '/instances/{instance}'.replace('{instance}', encodeURI(instance)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * DEEP.assist Instances
     */
    getInstances(): Observable<Instances>
    getInstances(opts?: OperationOpts): Observable<RawAjaxResponse<Instances>>
    getInstances(opts?: OperationOpts): Observable<Instances | RawAjaxResponse<Instances>> {
        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Instances>({
            url: '/instances',
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

}
