// tslint:disable
/**
 * ADMIN API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs'
import {
  BaseAPI,
  encodeURI,
  HttpHeaders,
  HttpQuery,
  OperationOpts,
  RawAjaxResponse,
  throwIfNullOrUndefined,
} from '../runtime'
import {
  KbUpdateEntries,
  Lemmatizations,
  RelatedMeaningExpressions,
  RelatedMeaningExpressionsRequest,
  SearchResult,
} from '../models'

export interface GetLemmatizationRequest {
    instance: string;
    input: string;
}

export interface PostForKbEntriesRequest {
    instance: string;
    kbUpdateEntries?: KbUpdateEntries;
}

export interface PostForKbUploadRequest {
    instance: string;
    body: Blob;
}

export interface PostForRelatedRequest {
    instance: string;
    relatedMeaningExpressionsRequest: RelatedMeaningExpressionsRequest;
}

export interface SearchKnowledgeBaseRequest {
    instance: string;
    q: string;
    limit?: number;
}

/**
 * no description
 */
export class KnowledgeBaseApi extends BaseAPI {

    /**
     * Get the lemmtization of the input
     */
    getLemmatization({ instance, input }: GetLemmatizationRequest): Observable<Lemmatizations>
    getLemmatization({ instance, input }: GetLemmatizationRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Lemmatizations>>
    getLemmatization({ instance, input }: GetLemmatizationRequest, opts?: OperationOpts): Observable<Lemmatizations | RawAjaxResponse<Lemmatizations>> {
        throwIfNullOrUndefined(instance, 'instance', 'getLemmatization');
        throwIfNullOrUndefined(input, 'input', 'getLemmatization');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'input': input,
        };

        return this.request<Lemmatizations>({
            url: '/instances/{instance}/kb/lemmatization'.replace('{instance}', encodeURI(instance)),
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Post UpdateEntries to be applied to the knowledge base.
     */
    postForKbEntries({ instance, kbUpdateEntries }: PostForKbEntriesRequest): Observable<void>
    postForKbEntries({ instance, kbUpdateEntries }: PostForKbEntriesRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    postForKbEntries({ instance, kbUpdateEntries }: PostForKbEntriesRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(instance, 'instance', 'postForKbEntries');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<void>({
            url: '/instances/{instance}/kb/entries'.replace('{instance}', encodeURI(instance)),
            method: 'POST',
            headers,
            body: kbUpdateEntries,
        }, opts?.responseOpts);
    };

    /**
     * Post an Excel sheet with modification to convert it into UpdateEntries
     */
    postForKbUpload({ instance, body }: PostForKbUploadRequest): Observable<KbUpdateEntries>
    postForKbUpload({ instance, body }: PostForKbUploadRequest, opts?: OperationOpts): Observable<RawAjaxResponse<KbUpdateEntries>>
    postForKbUpload({ instance, body }: PostForKbUploadRequest, opts?: OperationOpts): Observable<KbUpdateEntries | RawAjaxResponse<KbUpdateEntries>> {
        throwIfNullOrUndefined(instance, 'instance', 'postForKbUpload');
        throwIfNullOrUndefined(body, 'body', 'postForKbUpload');

        const headers: HttpHeaders = {
            'Content-Type': 'application/octet-stream',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<KbUpdateEntries>({
            url: '/instances/{instance}/kb/upload'.replace('{instance}', encodeURI(instance)),
            method: 'POST',
            headers,
            body: body as any,
        }, opts?.responseOpts);
    };

    /**
     * Get related meanings and expression
     */
    postForRelated({ instance, relatedMeaningExpressionsRequest }: PostForRelatedRequest): Observable<RelatedMeaningExpressions>
    postForRelated({ instance, relatedMeaningExpressionsRequest }: PostForRelatedRequest, opts?: OperationOpts): Observable<RawAjaxResponse<RelatedMeaningExpressions>>
    postForRelated({ instance, relatedMeaningExpressionsRequest }: PostForRelatedRequest, opts?: OperationOpts): Observable<RelatedMeaningExpressions | RawAjaxResponse<RelatedMeaningExpressions>> {
        throwIfNullOrUndefined(instance, 'instance', 'postForRelated');
        throwIfNullOrUndefined(relatedMeaningExpressionsRequest, 'relatedMeaningExpressionsRequest', 'postForRelated');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<RelatedMeaningExpressions>({
            url: '/instances/{instance}/kb/relations'.replace('{instance}', encodeURI(instance)),
            method: 'POST',
            headers,
            body: relatedMeaningExpressionsRequest,
        }, opts?.responseOpts);
    };

    /**
     * Search meanings and expressions
     */
    searchKnowledgeBase({ instance, q, limit }: SearchKnowledgeBaseRequest): Observable<Array<SearchResult>>
    searchKnowledgeBase({ instance, q, limit }: SearchKnowledgeBaseRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<SearchResult>>>
    searchKnowledgeBase({ instance, q, limit }: SearchKnowledgeBaseRequest, opts?: OperationOpts): Observable<Array<SearchResult> | RawAjaxResponse<Array<SearchResult>>> {
        throwIfNullOrUndefined(instance, 'instance', 'searchKnowledgeBase');
        throwIfNullOrUndefined(q, 'q', 'searchKnowledgeBase');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'q': q,
        };

        if (limit != null) { query['limit'] = limit; }

        return this.request<Array<SearchResult>>({
            url: '/instances/{instance}/kb/search'.replace('{instance}', encodeURI(instance)),
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

}
