// tslint:disable
/**
 * ADMIN API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Preprocessing type to be used with instances.
 * @export
 * @enum {string}
 */
export enum PreprocessingDTO {
    None = 'NONE',
    Cleaning = 'CLEANING',
    Translating = 'TRANSLATING',
    CleaningTranslating = 'CLEANING_TRANSLATING'
}

