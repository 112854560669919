import { ChangeEvent, useState } from 'react'
import { Select } from '@chakra-ui/react'
import { RelatedMeaningExpressionsRequestStrategyEnum } from '@/_clients/admin'

interface Props {
  changeStrategy: Function
}

export const StrategySelector = ({ changeStrategy }: Props) => {
  const [selectedValue, setSelectedValue] = useState<string | undefined>(undefined)

  const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    setSelectedValue(value)
    changeStrategy(value)
  }

  return (
    <Select
      value={selectedValue}
      defaultValue={RelatedMeaningExpressionsRequestStrategyEnum.All}
      onChange={handleChange}
      maxW="200px"
    >
      <option value={RelatedMeaningExpressionsRequestStrategyEnum.All}>All</option>
      <option value={RelatedMeaningExpressionsRequestStrategyEnum.Default}>Default</option>
      <option value={RelatedMeaningExpressionsRequestStrategyEnum.Hypernyms}>Hypernyms</option>
      <option value={RelatedMeaningExpressionsRequestStrategyEnum.Hyponyms}>Hyponyms</option>
      <option value={RelatedMeaningExpressionsRequestStrategyEnum.Meronyms}>Meronyms</option>
      <option value={RelatedMeaningExpressionsRequestStrategyEnum.None}>None</option>
    </Select>
  )
}
