// tslint:disable
/**
 * DEEP.assist Logging API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs'
import {
  BaseAPI,
  encodeURI,
  HttpHeaders,
  HttpQuery,
  OperationOpts,
  RawAjaxResponse,
  throwIfNullOrUndefined,
} from '../runtime'
import { DetailsResponse, OverviewResponse } from '../models'

export interface GetLoggingDetailsRequest {
    instance: string;
    apiKey: string;
    from: string;
    to: string;
    after?: string;
    count?: number;
}

export interface GetLoggingDetailsFileRequest {
    instance: string;
    apiKey: string;
    from: string;
    to: string;
    after?: string;
    count?: number;
}

export interface GetLoggingOverviewRequest {
    instance: string;
    from: string;
    to: string;
}

/**
 * no description
 */
export class LoggingApi extends BaseAPI {

    /**
     */
    getLoggingDetails({ instance, apiKey, from, to, after, count }: GetLoggingDetailsRequest): Observable<DetailsResponse>
    getLoggingDetails({ instance, apiKey, from, to, after, count }: GetLoggingDetailsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<DetailsResponse>>
    getLoggingDetails({ instance, apiKey, from, to, after, count }: GetLoggingDetailsRequest, opts?: OperationOpts): Observable<DetailsResponse | RawAjaxResponse<DetailsResponse>> {
        throwIfNullOrUndefined(instance, 'instance', 'getLoggingDetails');
        throwIfNullOrUndefined(apiKey, 'apiKey', 'getLoggingDetails');
        throwIfNullOrUndefined(from, 'from', 'getLoggingDetails');
        throwIfNullOrUndefined(to, 'to', 'getLoggingDetails');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'from': (from as any).toISOString().split('T')[0],
            'to': (to as any).toISOString().split('T')[0],
        };

        if (after != null) { query['after'] = after; }
        if (count != null) { query['count'] = count; }

        return this.request<DetailsResponse>({
            url: '/instances/{instance}/logging/{api-key}'.replace('{instance}', encodeURI(instance)).replace('{api-key}', encodeURI(apiKey)),
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    getLoggingDetailsFile({ instance, apiKey, from, to, after, count }: GetLoggingDetailsFileRequest): Observable<Blob>
    getLoggingDetailsFile({ instance, apiKey, from, to, after, count }: GetLoggingDetailsFileRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Blob>>
    getLoggingDetailsFile({ instance, apiKey, from, to, after, count }: GetLoggingDetailsFileRequest, opts?: OperationOpts): Observable<Blob | RawAjaxResponse<Blob>> {
        throwIfNullOrUndefined(instance, 'instance', 'getLoggingDetailsFile');
        throwIfNullOrUndefined(apiKey, 'apiKey', 'getLoggingDetailsFile');
        throwIfNullOrUndefined(from, 'from', 'getLoggingDetailsFile');
        throwIfNullOrUndefined(to, 'to', 'getLoggingDetailsFile');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'from': (from as any).toISOString().split('T')[0],
            'to': (to as any).toISOString().split('T')[0],
        };

        if (after != null) { query['after'] = after; }
        if (count != null) { query['count'] = count; }

        return this.request<Blob>({
            url: '/instances/{instance}/logging/{api-key}/file'.replace('{instance}', encodeURI(instance)).replace('{api-key}', encodeURI(apiKey)),
            method: 'GET',
            headers,
            query,
            responseType: 'blob',
        }, opts?.responseOpts);
    };

    /**
     * Logging overview
     */
    getLoggingOverview({ instance, from, to }: GetLoggingOverviewRequest): Observable<OverviewResponse>
    getLoggingOverview({ instance, from, to }: GetLoggingOverviewRequest, opts?: OperationOpts): Observable<RawAjaxResponse<OverviewResponse>>
    getLoggingOverview({ instance, from, to }: GetLoggingOverviewRequest, opts?: OperationOpts): Observable<OverviewResponse | RawAjaxResponse<OverviewResponse>> {
        throwIfNullOrUndefined(instance, 'instance', 'getLoggingOverview');
        throwIfNullOrUndefined(from, 'from', 'getLoggingOverview');
        throwIfNullOrUndefined(to, 'to', 'getLoggingOverview');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'from': (from as any).toISOString().split('T')[0],
            'to': (to as any).toISOString().split('T')[0],
        };

        return this.request<OverviewResponse>({
            url: '/instances/{instance}/logging'.replace('{instance}', encodeURI(instance)),
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

}
