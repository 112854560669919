import { AccordionButton, AccordionItem, AccordionPanel, HStack, Spacer, Text } from '@chakra-ui/react'
import * as React from 'react'
import { AddIcon, MinusIcon } from '@chakra-ui/icons'

interface NavGroupProps {
  label?: string
  children: React.ReactNode
}

export const NavGroup = ({ label, children }: NavGroupProps) => {
  return (
    <AccordionItem border={0} mb={6}>
      {({ isExpanded }) => (
        <>
          <AccordionButton pl={8}>
            <HStack flex={1}>
              <Text fontWeight={'medium'} color={'brand.primary.500'}>
                {label}
              </Text>
              <Spacer />
              {isExpanded ? <MinusIcon fontSize="8px" /> : <AddIcon fontSize="8px" />}
            </HStack>
          </AccordionButton>
          <AccordionPanel p={0} pr={5}>
            {children}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  )
}
