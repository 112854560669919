// tslint:disable
/**
 * DEEP.assist Logging API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AnalyticsCore } from './'

/**
 * @export
 * @interface TextInputCore
 */
export interface TextInputCore {
    /**
     * The text in UTF-8 to be analyzed by DEEP.assist. 
     * @type {string}
     * @memberof TextInputCore
     */
    text: string;
    /**
     * Additional texts to be analyzed by DEEP.assist. All texts will be used to produce the result. Hints can only use locations from a single text, so no word combinations across different texts will be examined. If a hint can occur in more than one text. it will still be returned as one hint, but with locations in \'textIndex\' set accordingly. If only one text is to be analyzed it should be provided in \'text\'. 
     * @type {Array<string>}
     * @memberof TextInputCore
     */
    additionalTexts?: Array<string>;
    /**
     * Choose the algorithm that should be used for scoring. * `recall` Measures how many of the recgonized hints are present in a business. Higher values are better. Hint boostings are applied. 
     * @type {string}
     * @memberof TextInputCore
     */
    scoring: TextInputCoreScoringEnum;
    /**
     * The maximum number of business cases to be returned. 
     * @type {number}
     * @memberof TextInputCore
     */
    limit?: number;
    /**
     * Provide one or more names of views of business cases. This defines the basic set of business cases that are included into the DEEP.assist analysis. Currently the view name corresponds to the field \'user-group\' of a business case. 
     * @type {Array<string>}
     * @memberof TextInputCore
     */
    views?: Array<string>;
    /**
     * The extractors to use for this request.
     * @type {Array<string>}
     * @memberof TextInputCore
     */
    extractors?: Array<string>;
    /**
     * @type {AnalyticsCore}
     * @memberof TextInputCore
     */
    analytics?: AnalyticsCore;
    /**
     * If set to true, the response will contain debugging information. 
     * @type {boolean}
     * @memberof TextInputCore
     */
    debug?: boolean;
    /**
     * Detect and apply negations in the text. Negations will lead to negated Hints, which exclude Business Cases instead of including them. 
     * @type {boolean}
     * @memberof TextInputCore
     */
    applyNegations?: boolean;
}

/**
 * @export
 * @enum {string}
 */
export enum TextInputCoreScoringEnum {
    Recall = 'recall'
}

