import { Store } from 'redux'
import { createStore } from './createStore'

export const initStore = (): Store<any> => {
  const daStore = createStore({
    rootReducer: require('./rootReducer.ts').default,
    rootEpic: require('./rootEpic.ts').default,
  })
  if (module.hot) {
    module.hot.accept('./rootReducer', () => {
      const nextRootReducer = require('./rootReducer.ts').default
      daStore.reduxStore.replaceReducer(nextRootReducer)
    })

    if (daStore.epicMiddleware) {
      module.hot.accept('./rootEpic', () => {
        daStore.reduxStore.dispatch({ type: 'END' })
        const nextRootEpic = require('./rootEpic.ts').default
        daStore.epicMiddleware.run(nextRootEpic)
      })
    }
  }
  return daStore.reduxStore
}
