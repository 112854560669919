import { isCypressMode } from '@/auth/authentication.utils'
import { Authenticator } from '@/auth/Authenticator.types'
import { CypressAuthenticator } from '@/auth/CypressAuthenticator'
import { KeycloakAuthenticator } from '@/auth/KeycloakAuthenticator'

const GROUP_APPS_APP = '/apps/app'

const getInstance = (): Authenticator => {
  if (isCypressMode()) {
    return new CypressAuthenticator()
  }
  return new KeycloakAuthenticator(GROUP_APPS_APP)
}

export const authenticator: Authenticator = getInstance()
