import produce from 'immer'
import { Instance } from '@/_clients/admin'
import { InstancesAction, InstancesActionKeys } from './instances.actions'

export interface InstancesState {
  instances: Instance[]
}

export const initialState: InstancesState = {
  instances: [],
}

export const instancesReducer = (state: InstancesState = initialState, action: InstancesAction): InstancesState => {
  return produce(state, (draft: InstancesState) => {
    switch (action.type) {
      case InstancesActionKeys.LOAD_SUCCESS: {
        draft.instances = action.instances
        return
      }
    }
  })
}
