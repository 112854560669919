import { ApiKeysAction } from '@/pages/api-keys/state/apiKeys.actions'
import { ApiKeysState } from '@/pages/api-keys/state/apiKeys.reducer'
import { BuildingBlocksAction } from '@/features/BuildingBlocks/state/buildingBlocks.actions'
import { BuildingBlocksState } from '@/features/BuildingBlocks/state/buildingBlocks.reducer'
import { TopicsOfInterestAction } from '@/features/TopicsOfInterest/state/topicsOfInterest.actions'
import { TopicsOfInterestState } from '@/features/TopicsOfInterest/state/topicsOfInterest.reducer'
import { InstancesAction } from '@/store/instances/state/instances.actions'
import { InstancesState } from '@/store/instances/state/instances.reducer'
import { ProposalAction } from '@/pages/proposal/state/proposal.actions'
import { ProposalState } from '@/pages/proposal/state/proposal.reducer'
import { services } from '@/store/rootEpic'
import { AppAction } from '@/store/state/app.actions'
import { AppState } from '@/store/state/app.reducer'
import { KnowledgeGraphAction } from '@/features/KnowledgeGraph/state/knowledgeGraph.actions'
import { KnowledgeGraphState } from '@/features/KnowledgeGraph/state/knowledgeGraph.reducer'
import { AjaxError } from 'rxjs/ajax'

export interface RootState {
  apiKeys: ApiKeysState
  app: AppState
  buildingBlocks: BuildingBlocksState
  topicsOfInterest: TopicsOfInterestState
  instances: InstancesState
  proposal: ProposalState
  knowledgeGraph: KnowledgeGraphState
}
export interface HttpRequestState<T> {
  isLoading: boolean
  value?: T
  error?: AjaxError | Error
}

export type RootAction =
  | ApiKeysAction
  | AppAction
  | BuildingBlocksAction
  | TopicsOfInterestAction
  | InstancesAction
  | ProposalAction
  | KnowledgeGraphAction

export type Services = typeof services

export interface SelectOptionType {
  label: string
  value: string
}

// since we run into issues with openAI we have to hardcode it here
export enum RelatedMeaningExpressionsStrategyEnumCustom {
  'All' = 'ALL',
  'Default' = 'DE',
  'Hypernyms' = 'HE',
  'Hyponyms' = 'HY',
  'Meronyms' = 'ME',
  'None' = 'NO',
}
