/* eslint-disable @typescript-eslint/no-unused-vars */
import { AddIcon, CloseIcon, DeleteIcon, SettingsIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Portal,
  Select,
  useDisclosure,
} from '@chakra-ui/react'
import React, { KeyboardEvent, useState } from 'react'
import GridLayout, { Layout } from 'react-grid-layout'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import { ProposalApi, TopicsOfInterestApi } from '@/api/apis'
import { ExtractedValue, LocatedHint, PreprocessingDTO, Proposal, ProposalInput, Scoring } from '@/_clients/proposals'
import { PageHeader } from '@/components/PageHeader'
//import {ProposalTextarea} from './ProposalTextarea'
import '@/styles/stylePerspectivePages.css'
import { TextExtraction } from './components/TextExtraction'
import { DummyWidget } from '@/components/DummyWidget'
//import {Views} from './Views'
import {
  getFromLS,
  initializeWidgets,
  moveDown,
  moveUp,
  replaceBetween,
  saveToLS,
  ViewHolder,
  Widget,
} from './perspectiveHelpers'
import { TreeMap } from './components/TreeMap'
import TextareaAutosize from 'react-textarea-autosize'
import { ViewsList } from './components/ViewsList'
import { GoKebabVertical } from 'react-icons/go'
import { useDebounce } from 'use-debounce'
import { AnnotatedText } from './components/AnnotatedText'
import { Views } from '@/_clients/admin'
import { useTranslation } from 'react-i18next'
import { SentimentAnalysis } from './components/SentimentAnalysis'
import { zip } from 'rxjs'
import { useParams } from 'react-router'
import { useComponentSize } from 'react-use-size'

//const ResponsiveReactGridLayout = WidthProvider(Responsive)

export const PerspectivesPage = () => {
  const { t } = useTranslation()
  const [widgetArray, setWidgetArray] = useState<Array<Widget>>(getFromLS('widgets') || initializeWidgets())
  const [menutIems, setMenuItems] = useState<Array<string>>([])
  const [views, setViews] = useState<Array<ViewHolder> | null>(getFromLS('views') || null)
  const [currentView, setCurrentView] = useState<ViewHolder>({
    title: '',
    name: '',
    proposal: null,
  })
  //const [text, setText] = useState(getFromLS('text') || '')
  const [text, setText] = useState(
    'Ich möchte meine Kontoabbuchung widerrufen. Mein Internationale Bankkontonummer = IBAN lautet DE91 1000 0000 0123 4567 89 Meine E-Mail lautet sybille.huber@example.com TestTest  5 kg'
  )
  const [debouncedText] = useDebounce(text, 500)

  // Test text
  //Ich möchte meine Kontoabbuchung widerrufen. Mein Internationale Bankkontonummer = IBAN lautet DE91 1000 0000 0123 4567 89 Meine E-Mail lautet sybille.huber@example.com TestTest  5 kg

  const [proposal, setProposal] = React.useState<Proposal | null>(null)
  const [proposals, setProposals] = React.useState<Array<Proposal> | null>(null)
  const [sentiment, setSentiment] = React.useState<Proposal | null>(null)
  const [counter, setcounter] = React.useState<number>(0)
  const [sentimentLoading, setSentimentLoading] = React.useState(false)
  const [toiResponseLimit, setToiResponseLimit] = useState('10')
  const [proposalInput, setProposalInput] = React.useState<ProposalInput>({
    text: text,
    scoring: Scoring.Recall,
    views: ['all'],
    limit: Number(toiResponseLimit),
    preprocessing: PreprocessingDTO.Cleaning,
  })
  const instance = useParams<'instance'>().instance || ''
  //const [renderSentiment, setRenderSentiment] = useState(widgetArray.filter((widget) => widget.i === 'sentiment')[0].render || false)

  React.useEffect(() => {
    const sentimentActive = widgetArray.filter((widget) => widget.i === 'sentiment')[0].render

    if (sentimentActive) {
      ProposalApi.postForProposal({
        instance,
        proposalInput: { ...proposalInput, views: ['sentiment'] },
      }).subscribe((proposal) => {
        setSentiment(proposal)
        setSentimentLoading(false)
      })
    }
    if (views && views.length) {
      setSentimentLoading(true)
      let tempProposalInput: ProposalInput = { ...proposalInput }
      const observables = views?.map((view, index) => {
        tempProposalInput.text = debouncedText
        tempProposalInput.views = [view.name ? view.name : 'all']
        if (index !== 0) tempProposalInput.preprocessing = PreprocessingDTO.None
        return ProposalApi.postForProposal({
          instance: instance,
          proposalInput: tempProposalInput,
        })
      })
      zip(...observables).subscribe((proposals) => {
        setProposal(proposals[0])
        setProposals(proposals)
        setcounter(counter + 1)
      })
    } else {
      setProposal(null)
      setProposals(null)
      setcounter(counter + 1)
    }
    reset()
    saveToLS('views', views)
    saveToLS('text', debouncedText)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedText, views, proposalInput, instance])

  const reset = () => {
    setHighlightExtractors(null)
    setHighlightedHint(null)
    setHighlightedHints([])
  }

  React.useEffect(() => {
    let newItems = []
    for (const widget of widgetArray) {
      if (widget.render) {
        newItems.push(widget.i)
      }
    }
    setMenuItems(newItems)
  }, [widgetArray])

  const handleModify = (layout: Layout[]) => {
    const tempArray = widgetArray
    layout?.forEach((position) => {
      tempArray[Number(position.i)].x = position.x
      tempArray[Number(position.i)].y = position.y
      tempArray[Number(position.i)].w = position.w
      tempArray[Number(position.i)].h = position.h
    })
    saveToLS('widgets', tempArray)
    setWidgetArray(tempArray)
  }
  const { isOpen, onOpen, onClose } = useDisclosure()
  const addView = (view: ViewHolder) => {
    if (views) {
      const tempArray = [...views]
      tempArray.push(view)
      setViews(tempArray)
    } else {
      setViews([view])
    }
    setCurrentView({
      title: '',
      name: '',
      proposal: null,
    })
    onClose()
  }

  const deleteView = (view: ViewHolder) => {
    const filteredItems = views ? views.filter((v) => v.title !== view.title) : null
    setViews(filteredItems)
  }

  const handleAdd = (components: [string]) => {
    const tempArray = [...widgetArray]
    for (const widget of tempArray) {
      widget.render = components.indexOf(widget.i) !== -1 || widget.i === 'textarea'
    }
    saveToLS('widgets', tempArray)
    setWidgetArray(tempArray)
    setMenuItems(components)
  }

  /*const handleAdd = () => {
    setWidgetArray([...widgetArray, {i: 'widget' + (widgetArray.length + 1), x: 0, y: 0, w: 4, h: 8}])
  }*/

  const handleDelete = (key: String) => {
    const tempArray = widgetArray.slice()
    const foundItem = tempArray.find((data) => data.i === key)
    if (foundItem) {
      foundItem.render = false
      saveToLS('widgets', tempArray)
      setWidgetArray(tempArray)
    }
  }

  const initialRef = React.useRef<HTMLSelectElement>(null)

  const renderAddWidgetMenu = () => {
    return (
      <Menu closeOnSelect={false}>
        <MenuButton as={IconButton} aria-label="Options" icon={<SettingsIcon />} variant="ghost" />
        <MenuList textAlign={'left'}>
          <MenuOptionGroup
            onChange={(value: [string]) => {
              handleAdd(value)
            }}
            type="checkbox"
            value={menutIems}
            title={t('common.widget')}
          >
            <MenuItemOption value="extractors">{t('analyzer.widget.extractors.id')}</MenuItemOption>
            <MenuItemOption value="views">{t('analyzer.widget.views.id')}</MenuItemOption>
            <MenuItemOption value="treemap">{t('analyzer.widget.treemap.id')}</MenuItemOption>
            <MenuItemOption value="sentiment">{t('analyzer.widget.sentiment.id')}</MenuItemOption>
            <MenuItemOption value="preprocessing">{t('analyzer.widget.preprocessing.id')}</MenuItemOption>
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    )
  }
  const renderModal = () => {
    return (
      <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('analyzer.widget.views.dialog.title')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl mt={4} onKeyDown={handleEnterPress}>
              <FormLabel>{t('analyzer.widget.views.dialog.viewId')}</FormLabel>
              <Select
                onChange={(e) => {
                  setCurrentView({ ...currentView, name: e.currentTarget.value, title: e.currentTarget.value })
                }}
                placeholder={t('analyzer.widget.views.dialog.viewId')}
                ref={initialRef}
              >
                {toiViews &&
                  toiViews.elements?.map((view) => {
                    return (
                      <option key={view.name} value={view.name}>
                        {view.name}
                      </option>
                    )
                  })}
              </Select>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t('analyzer.widget.views.dialog.viewName')}</FormLabel>
              <Input
                value={currentView.title}
                onChange={(e) => {
                  setCurrentView({ ...currentView, title: e.currentTarget.value })
                }}
                placeholder={t('analyzer.widget.views.dialog.viewName')}
                disabled={!currentView.name}
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Button
              onClick={() => {
                addView(currentView)
              }}
              colorScheme="blue"
              mr={3}
            >
              {t('common.save')}
            </Button>
            <Button onClick={closeModal}>{t('common.cancel')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    )
  }

  const closeModal = () => {
    setCurrentView({
      title: '',
      name: '',
      proposal: null,
    })
    onClose()
  }

  const handleEnterPress = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      addView(currentView)
    }
  }
  const preprocessingReverseMap = (p: PreprocessingDTO) => {
    switch (p) {
      case PreprocessingDTO.Cleaning:
        return 'multi-part-cleaner'
      case PreprocessingDTO.CleaningTranslating:
        return 'translating-multi-part-cleaner'
      case PreprocessingDTO.None:
        return 'off'
      default:
        return 'off'
    }
  }

  const handlePreprocessingChange = (value: string) => {
    setProposalInput({
      ...proposalInput,
      preprocessing: value ? preprocessingMap[value] : 'off',
    })
  }

  const preprocessingMap = {
    'multi-part-cleaner': PreprocessingDTO.Cleaning,
    'translating-multi-part-cleaner': PreprocessingDTO.CleaningTranslating,
    off: PreprocessingDTO.None,
  }

  const renderGeneralMenu = (widget: Widget) => {
    return (
      <Menu closeOnSelect={false} placement={'bottom-end'}>
        <MenuButton
          className="show-on-hover"
          as={IconButton}
          fontWeight="bold"
          bg="transparent"
          size="sm"
          aria-label="Options"
          icon={<Icon as={GoKebabVertical} boxSize={4} />}
        />
        <Portal>
          <MenuList>
            <MenuItem
              icon={<DeleteIcon />}
              onClick={() => {
                handleDelete(widget.i)
              }}
            >
              Hide widget
            </MenuItem>
          </MenuList>
        </Portal>
      </Menu>
    )
  }

  const renderPreprocessingMenu = () => {
    return (
      <Menu closeOnSelect={false} placement={'bottom-end'}>
        <MenuButton
          className="show-on-hover"
          as={IconButton}
          fontWeight="bold"
          bg="transparent"
          size="sm"
          aria-label="Options"
          icon={<Icon as={GoKebabVertical} boxSize={4} />}
        />
        <Portal>
          <MenuList>
            <MenuOptionGroup
              value={preprocessingReverseMap(
                proposalInput.preprocessing ? proposalInput.preprocessing : PreprocessingDTO.None
              )}
              title="Preprocessing"
              type="radio"
              onChange={handlePreprocessingChange}
            >
              <MenuItemOption value="multi-part-cleaner">{t('analyzer.widget.textarea.cleaning')}</MenuItemOption>
              <MenuItemOption value="translating-multi-part-cleaner">
                {t('analyzer.widget.textarea.cleaning-translation')}
              </MenuItemOption>
              <MenuItemOption value="off">{t('analyzer.widget.textarea.off')}</MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Portal>
      </Menu>
    )
  }

  const handleLimitChange = (limit: string) => {
    setToiResponseLimit(limit)
    setProposalInput({
      ...proposalInput,
      limit: Number(limit),
    })
  }

  const renderViewsMenu = (widget: Widget) => {
    return (
      <Menu closeOnSelect={false} placement={'bottom-end'}>
        <MenuButton
          className="show-on-hover"
          as={IconButton}
          fontWeight="bold"
          bg="transparent"
          size="sm"
          aria-label="Options"
          icon={<Icon as={GoKebabVertical} boxSize={4} />}
        />
        <Portal>
          <MenuList>
            <MenuItem icon={<AddIcon />} onClick={onOpen}>
              {t('analyzer.widget.views.addView')}
            </MenuItem>
            <MenuItem
              icon={<DeleteIcon />}
              onClick={() => {
                handleDelete(widget.i)
              }}
            >
              {t('analyzer.widget.common.hideWidget')}
            </MenuItem>
            <MenuOptionGroup onChange={handleChangeViewTable} type="checkbox" title="View Table" value={tableColumns}>
              <MenuItemOption value="score">{t('analyzer.widget.views.score')}</MenuItemOption>
              <MenuItemOption value="statement">{t('analyzer.widget.views.statement')}</MenuItemOption>
              <MenuItemOption value="hints">Hints</MenuItemOption>
            </MenuOptionGroup>
            <MenuOptionGroup onChange={handleLimitChange} type="radio" title="Limit" value={toiResponseLimit}>
              <MenuItemOption value="5">5</MenuItemOption>
              <MenuItemOption value="10">10</MenuItemOption>
              <MenuItemOption value="15">15</MenuItemOption>
            </MenuOptionGroup>
          </MenuList>
        </Portal>
      </Menu>
    )
  }

  const [tableColumns, setTableColumns] = React.useState(getFromLS('tableColumns') || ['score', 'statement'])
  const handleChangeViewTable = (columns: string[]) => {
    setTableColumns(columns)
    saveToLS('tableColumns', columns)
  }

  const handleMoveUp = (view: ViewHolder) => {
    if (views) setViews(moveUp<ViewHolder>(views, view))
  }

  const handleMoveDown = (view: ViewHolder) => {
    if (views) setViews(moveDown<ViewHolder>(views, view))
  }

  const [highlightExtractors, setHighlightExtractors] = useState<ExtractedValue[] | null>(null)
  const [highlightedHint, setHighlightedHint] = useState<LocatedHint | null>(null)
  const [highlightedHints, setHighlightedHints] = useState<LocatedHint[]>([])
  const [highlightedStatement, setHighlightedStatement] = useState<LocatedHint[]>([])
  const handleHintHover = (hint: string, view: ViewHolder) => {
    if (view && view.proposal && view.proposal.hints) {
      const foundHint = view.proposal?.hints.filter((locatedHint) => locatedHint.id === hint)[0]
      setHighlightedHint(foundHint)
    } else {
      setHighlightedHint(null)
    }
  }
  const handleHintChange = (hints: LocatedHint[]) => {
    setHighlightedHints(hints)
  }

  const handleStatementHover = (hints: string[], view: ViewHolder) => {
    if (hints && hints.length && view && view.proposal && view.proposal.hints) {
      const foundHints = view.proposal?.hints.filter((locatedHint) => hints.includes(locatedHint.id))
      setHighlightedStatement(foundHints)
    } else {
      setHighlightedStatement([])
    }
  }

  const [toiViews, setToiViews] = useState<Views | null>(null)

  React.useEffect(() => {
    TopicsOfInterestApi.getInstanceTopicsOfInterestViews({
      instance: instance,
    }).subscribe((views: React.SetStateAction<Views | null>) => setToiViews(views))
  }, [instance])

  const textareaRef = React.useRef<HTMLTextAreaElement>(null)

  const { ref, width } = useComponentSize()

  const [resizing, setResizing] = useState(false)

  const handleResizeStart = (_layout: Layout[]) => {
    setResizing(true)
  }

  const handleResizeEnd = (_layout: Layout[]) => {
    setResizing(false)
  }

  const handleResize = (e: React.MouseEvent<HTMLDivElement>) => {
    const el = document.getElementById('scroll-element')
    if (resizing && el!.scrollHeight > el!.clientHeight) {
      el!.scrollTo(0, e.clientY)
    }
    //el!.scrollTo(0,window.innerHeight)
  }

  /*React.useEffect(() => {
    const el = document.getElementById("scrolllll")
    if(resizing && el!.scrollHeight > el!.clientHeight){
      console.log(height)
      el!.scrollTo(0, el!.clientHeight)
    }
  }, [height, resizing])*/

  return (
    <Box ref={ref} overflow="visible" data-cy="perspectives-page" userSelect="none" onMouseMove={handleResize} mb={8}>
      <PageHeader title="Analyzer" action={renderAddWidgetMenu()} />
      <GridLayout
        onLayoutChange={handleModify}
        onResizeStart={handleResizeStart}
        onResizeStop={handleResizeEnd}
        width={width}
        preventCollision={false}
        cols={24}
        autoSize={true}
        containerPadding={[20, 20]}
        rowHeight={10}
        draggableHandle=".drag-me"
      >
        {widgetArray?.map((widget, index) => {
          if (widget.render) {
            return (
              <DummyWidget
                className="reactGridItem animation"
                key={index}
                action={
                  widget.i !== 'textarea' && widget.i !== 'views' ? (
                    renderGeneralMenu(widget)
                  ) : widget.i === 'views' ? (
                    <Box>
                      {renderViewsMenu(widget)}
                      {renderModal()}
                    </Box>
                  ) : widget.i === 'textarea' ? (
                    renderPreprocessingMenu()
                  ) : undefined
                }
                proposal={proposal}
                widget={widget}
                footer={
                  widget.i === 'preprocessing' && proposal?.language ? (
                    <Box>
                      {t('analyzer.widget.preprocessing.language')}: <strong>{proposal.language.toUpperCase()}</strong>
                    </Box>
                  ) : undefined
                }
                data-grid={{
                  x: widget?.x,
                  y: widget?.y,
                  w: widget?.w,
                  h: widget?.h,
                  i: widget.i,
                  minW: widget.minW ? widget.minW : 3,
                  maxW: widget.maxW ? widget.maxW : Infinity,
                  minH: widget.minH ? widget.minH : 6,
                  maxH: widget.maxH ? widget.maxH : Infinity,
                  isDraggable: true,
                  isResizable: true,
                }}
              >
                {widget.i === 'textarea' ? (
                  <>
                    <TextareaAutosize
                      value={text}
                      onPaste={(e) => {
                        e.preventDefault()
                        if (e.clipboardData) {
                          //e.clipboardData.clearData()
                          //setText(e.clipboardData.getData('Text'))

                          let paste = e.clipboardData.getData('text').replace(/<[^>]*>?/gm, '')
                          const start = textareaRef.current?.selectionStart
                          const end = textareaRef.current?.selectionEnd
                          if (start !== undefined && end !== undefined) {
                            paste = replaceBetween(text, start, end, paste)
                            setText(paste)
                          }
                        }
                      }}
                      onChange={(e) => {
                        setText(e.currentTarget.value)
                      }}
                      className="no-style-textarea"
                      placeholder={t('analyzer.widget.textarea.placeholder')}
                      minRows={5}
                      ref={textareaRef}
                      spellCheck="false"
                    />
                    {text && (
                      <Button
                        className="show-on-hover"
                        position={'absolute'}
                        top={'50px'}
                        right={'0px'}
                        onClick={() => setText('')}
                        bg="transparent"
                        size="xs"
                      >
                        <CloseIcon w={2} h={2} />
                      </Button>
                    )}
                  </>
                ) : widget.i === 'extractors' ? (
                  <TextExtraction
                    highlight={setHighlightExtractors}
                    key={`extraction-${counter}`}
                    extractedValues={proposal?.extractedValues}
                  />
                ) : widget.i === 'views' ? (
                  <ViewsList
                    moveDown={handleMoveDown}
                    moveUp={handleMoveUp}
                    key={`view-${counter}`}
                    deleteView={deleteView}
                    views={views}
                    proposals={proposals}
                    tableColumns={tableColumns}
                    highlightHint={handleHintHover}
                    highlightStatement={handleStatementHover}
                    setHints={handleHintChange}
                  />
                ) : widget.i === 'sentiment' ? (
                  <SentimentAnalysis
                    proposal={sentiment}
                    loading={sentimentLoading}
                    text={proposal?.preprocessedText ? proposal?.preprocessedText : text}
                  />
                ) : widget.i === 'treemap' ? (
                  <TreeMap key={`treemap-${counter}`} proposal={proposal} />
                ) : widget.i === 'preprocessing' ? (
                  <AnnotatedText
                    text={
                      proposal?.preprocessedText !== undefined || proposal?.preprocessedText === ''
                        ? proposal?.preprocessedText
                        : text
                    }
                    extractedValues={highlightExtractors}
                    hint={highlightedHint}
                    hints={[...highlightedHints, ...highlightedStatement]}
                  />
                ) : (
                  <div>This component is not defined</div>
                )}
              </DummyWidget>
            )
          } else {
            return false
          }
        })}
      </GridLayout>
    </Box>
  )
}
