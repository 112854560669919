import { ViewIcon } from '@chakra-ui/icons'
import { Box, Checkbox, CheckboxGroup, HStack, Table, Tag, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react'
import { StringOrNumber } from '@chakra-ui/utils'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ExtractedValue } from '@/_clients/proposals'

interface Props {
  extractedValues: ExtractedValue[] | undefined
  highlight: Function
}

const renderValue = (extractedValue: ExtractedValue) => {
  const value = extractedValue.value
  if (typeof value === 'string') {
    return value
  }
  if (typeof value === 'number') {
    return value
  }
  if (typeof value === 'object') {
    return JSON.stringify(value, null, ' ')
  }
  return 'Unknown value type.'
}
export const TextExtraction = ({ extractedValues, highlight }: Props) => {
  const noExtractedValues = !extractedValues || extractedValues.length === 0
  const [checked, setChecked] = useState<StringOrNumber[]>([])
  const [selectedExtractors, setSelectedExtractors] = useState<ExtractedValue[]>()
  const [hoveredExtractor, setHoveredExtractor] = useState<ExtractedValue | null>()
  const { t } = useTranslation()

  const hover = (index: number) => {
    if (index >= 0 && extractedValues) {
      setHoveredExtractor(extractedValues[Number(index)])
    } else setHoveredExtractor(null)
  }
  useEffect(() => {
    const tempArray = selectedExtractors && selectedExtractors.length !== 0 ? [...selectedExtractors] : []
    highlight([...tempArray, hoveredExtractor])
  }, [selectedExtractors, hoveredExtractor, highlight])

  const handleCheckboxChange = (arr: StringOrNumber[]) => {
    setChecked(arr)
    let checkboxValue: ExtractedValue[] = []
    if (extractedValues) {
      checkboxValue = arr.map((index) => {
        return extractedValues[Number(index)]
      })
    }
    setSelectedExtractors([...checkboxValue])
  }

  return noExtractedValues ? (
    <Tag data-cy="no-extracted-values">{t('analyzer.widget.extractors.noValues')}</Tag>
  ) : (
    <Box pl={1} pr={1}>
      <CheckboxGroup
        onChange={(arr) => {
          handleCheckboxChange(arr)
        }}
        value={checked}
      >
        <Table colorScheme="blackAlpha" size="xs">
          <Thead>
            <Tr>
              <Th w={'4%'} pr={2}>
                <ViewIcon />
              </Th>
              <Th w={'25%'} pr={2}>
                {t('analyzer.widget.extractors.name')}
              </Th>
              <Th w={'61%'}>{t('analyzer.widget.extractors.value')}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {extractedValues?.map((extractedValue, i) => (
              <Tr key={`${extractedValue.name}-${i.toString()}`}>
                <Td>
                  <HStack pr={2} alignItems={'center'}>
                    <Checkbox value={`${i}`} />
                  </HStack>
                </Td>
                <Td>
                  <Box pr={2} fontSize="sm">
                    <Tag>{extractedValue.name}</Tag>
                  </Box>
                </Td>
                <Td>
                  <Box fontSize="sm" onMouseOver={() => hover(i)} onMouseLeave={() => hover(-1)} cursor={'pointer'}>
                    {renderValue(extractedValue)}
                  </Box>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </CheckboxGroup>
    </Box>
  )
}
