import React, { useState } from 'react'
import { Box, CSSObject, Input, Text, useColorModeValue, VStack } from '@chakra-ui/react'

interface Props {
  /** To label the input for cypress */
  cyId?: string
  /** When the text is considered to be selected */
  onChange: (text: string) => void
  /** We'll trigger the prediction API with this one */
  onInputChange: (text: string) => void
  /** The prediction to be displayed, possibly coming from a prediction API */
  predictedItems: string[]
}

export const PredictiveInputWithInlineResults = (props: Props) => {
  let { cyId, onChange, onInputChange, predictedItems } = props
  let [inputValue, setInputValue] = useState<string>('')

  const inputHasChanged = (text: string) => {
    onChange(text)
    onInputChange(text)
    setInputValue(text)
  }
  const predictionItemSelected = (text: string) => {
    setInputValue(text)
    onChange(text)
  }

  const hoveredItemRowStyle: CSSObject = {
    backgroundColor: useColorModeValue('var(--chakra-colors-gray-100)', 'var(--chakra-colors-whiteAlpha-200)'),
    borderRadius: 2,
  }

  return (
    <VStack spacing={1}>
      <Input
        mb={1}
        size="sm"
        onChange={(e) => inputHasChanged(e.target.value as string)}
        px={1}
        value={inputValue}
        data-cy={cyId}
      />
      {predictedItems &&
        predictedItems.map((pItem, index) => (
          <Box
            key={`predictive-item-${index}`}
            px={1}
            py="2px"
            userSelect="none"
            cursor="pointer"
            _hover={hoveredItemRowStyle}
            width="full"
          >
            <Text fontSize="sm" onClick={() => predictionItemSelected(pItem)}>
              {pItem}
            </Text>
          </Box>
        ))}
    </VStack>
  )
}
