import * as React from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { selectAllInstances } from '@/store/instances/state/instances.selectors'
import { getPerspectivesPath } from './perspectives/perspectives.paths'

export const WelcomeRedirectPage = () => {
  const instances = useSelector(selectAllInstances)
  const navigate = useNavigate()
  React.useLayoutEffect(() => {
    if (instances.length > 0) {
      navigate(getPerspectivesPath(instances[0].name))
    }
  }, [instances, navigate])
  return null
}
