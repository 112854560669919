// tslint:disable
/**
 * ADMIN API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface StatementCollectionSortOrder
 */
export interface StatementCollectionSortOrder {
    /**
     * @type {string}
     * @memberof StatementCollectionSortOrder
     */
    field?: StatementCollectionSortOrderFieldEnum;
    /**
     * @type {string}
     * @memberof StatementCollectionSortOrder
     */
    order?: StatementCollectionSortOrderOrderEnum;
}

/**
 * @export
 * @enum {string}
 */
export enum StatementCollectionSortOrderFieldEnum {
    Name = 'name',
    Created = 'created',
    Modified = 'modified',
    Editor = 'editor',
    Status = 'status'
}
/**
 * @export
 * @enum {string}
 */
export enum StatementCollectionSortOrderOrderEnum {
    Asc = 'asc',
    Desc = 'desc'
}

