import { ajax } from 'rxjs/ajax'
import Ajv, { ErrorObject } from 'ajv'
import addFormats from 'ajv-formats'
import { Role, Message, ContextItem } from './types'

type ErrorType = ErrorObject<string, Record<string, any>, unknown>
export interface ExtractAndValidateResult {
  extractedJson: object | null
  valid: boolean
  errors?: ErrorType[] | null
}

export const extractAndValidate = async (
  instruction: string,
  extractorContext: string,
  prevConversation: Message[]
): Promise<ExtractAndValidateResult> => {
  // Construct the messages array
  /*const messages: Message[] = [
    {
      role: Role.SYSTEM,
      content:
        'You are extracting named entities into a JSON format as per schema definition. Always reply only by the JSON. No additional text.',
    },
    ...prevConversation,
    { role: Role.USER, content: instruction },
    { role: Role.USER, content: JSON.stringify(extractorContext, null, 2) },
  ]*/

  const prevContext = prevConversation
    .filter((conv) => conv.role !== Role.SYSTEM)
    .map((message) => message.content)
    .join('\n')

  const messages: Message[] = [
    {
      role: Role.SYSTEM,
      content:
        'You are extracting named entities into a JSON format as per schema definition. Always reply only by the JSON. No additional text.',
    },
    { role: Role.USER, content: prevContext + '\n' + instruction + '\n' + extractorContext },
  ]

  const response = await ajax({
    url: 'https://api.openai.com/v1/chat/completions',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
    },
    body: JSON.stringify({
      model: 'gpt-4',
      messages,
    }),
  }).toPromise()

  const assistantMessage = response.response.choices[0].message.content.trim()

  let parsedJson: object | null = null
  let valid = false

  try {
    parsedJson = preprocessAssistantMessage(assistantMessage)
    if (parsedJson && parsedJson['IBAN']) {
      parsedJson['IBAN'] = parsedJson['IBAN'].replace(/\s/g, '')
    }
  } catch (error) {
    console.error("Could not parse assistant's message as JSON:", error)
    return { extractedJson: null, valid: false }
  }

  const ajv = new Ajv({ allErrors: true })
  addFormats(ajv)
  const validate = ajv.compile(JSON.parse(extractorContext))
  valid = validate(parsedJson)

  if (!valid) {
    console.error('Validation failed:', validate.errors)
  }

  return { extractedJson: parsedJson, valid, errors: validate.errors }
}

export const filterContext = (toiContext: ContextItem[], contextData: ContextItem[] | null, tois: string[]) => {
  let newContextItem: ContextItem[] = []

  if (tois && tois.length > 0) {
    const filteredContexts = toiContext.filter((contextItem) => tois.includes(contextItem.name))
    if (filteredContexts) {
      newContextItem = [...newContextItem, ...filteredContexts]
    }
  }

  const noToiContext = toiContext.find((contextItem) => contextItem.name === 'General Support')
  if (noToiContext) newContextItem.push(noToiContext)

  if (contextData) {
    const updatedContextItem = [...contextData, ...newContextItem].filter(
      (v, i, a) => a.findIndex((t) => t.name === v.name) === i
    )
    return updatedContextItem
  }

  return newContextItem
}

export const chatApi = (
  context: ContextItem[],
  conversation: Message[],
  additionalText: string = '',
  instruction: string = ''
) => {
  let lastUserMessage = conversation[conversation.length - 1].content

  if (additionalText) {
    lastUserMessage += '\n\n' + additionalText
  }

  let systemMessage = context.map((item) => Object.values(item).join('\n')).join('\n')

  if (instruction) {
    systemMessage = instruction + '\n' + systemMessage
  }

  return ajax({
    url: 'https://api.openai.com/v1/chat/completions',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
    },
    body: JSON.stringify({
      model: 'gpt-4',
      messages: [
        {
          role: Role.SYSTEM,
          content: systemMessage,
        },
        ...conversation.slice(0, -1),
        {
          role: Role.USER,
          content: lastUserMessage,
        },
      ],
    }),
  })
}

const preprocessAssistantMessage = (message: string) => {
  if (typeof message === 'string' && message.startsWith('{') && message.endsWith('}')) {
    try {
      const parsedMessage = JSON.parse(message)
      return parsedMessage
    } catch (error) {
      const fixedMessage = message.replace(/([}\]])\s*("[^"]*"\s*:\s*({|\[))/g, '$1,$2')
      try {
        const parsedMessage = JSON.parse(fixedMessage)
        return parsedMessage
      } catch (error) {
        console.error('Failed to preprocess assistant message:', error)
      }
    }
  } else if (typeof message === 'string') {
    const jsonRegex = /\{([^}]*)\}/g
    let match
    while ((match = jsonRegex.exec(message)) !== null) {
      const json = match[0]
      try {
        const parsedMessage = JSON.parse(json)
        return parsedMessage
      } catch (error) {
        console.error('Failed to preprocess assistant message:', error)
      }
    }
  }
  return message
}
