import { Input } from '@chakra-ui/input'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Select,
  Tag,
  TagCloseButton,
  TagLabel,
  useBoolean,
  VStack,
} from '@chakra-ui/react'
import React, { ChangeEvent, KeyboardEvent } from 'react'
import {
  StatementCollection as BuildingBlock,
  StatementCollectionStatus as BuildingBlockStatus,
  Views,
} from '@/_clients/admin'
import ReactQuill, { Quill } from 'react-quill'
import QuillEditor from 'quill'
import 'react-quill/dist/quill.snow.css'

/*const icons = Quill.import("ui/icons");
icons["undo"] = `<svg viewbox="0 0 18 18">
<polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
<path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
</svg>`;
icons["redo"] = `<svg viewbox="0 0 18 18">
<polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
<path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
</svg>`;*/

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    ['blockquote', 'code-block'],

    [{ header: 1 }, { header: 2 }], // custom button values
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
    [{ direction: 'rtl' }], // text direction

    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ font: [] }],
    [{ align: [] }],

    ['clean'],
    //['undo'],
    //['redo']                                         // remove formatting button
  ],
  /*history: {
    delay: 2000,
    maxStack: 500,
    userOnly: true
  }*/
}

interface Props {
  buildingBlock: BuildingBlock
  availableViews: Views
  putBuildingBlock: Function
}

export const MetadataForm = ({ buildingBlock, availableViews, putBuildingBlock }: Props) => {
  const [modifiedState, setModifiedState] = React.useState<BuildingBlock>(
    buildingBlock || {
      name: '',
      externalId: '',
      externalName: '',
      status: BuildingBlockStatus.New,
      description: '',
      views: [],
      sampleSentence1: '',
      sampleSentence2: '',
      sampleSentence3: '',
    }
  )
  Quill.register(
    {
      modules: modules,
    },
    true
  )

  const [stateChanged, setStateChanged] = React.useState(false)

  React.useEffect(() => {
    let changed = false
    for (const key in modifiedState) {
      if (key === 'hints') continue
      else if (key === 'views') {
        if (JSON.stringify(modifiedState.views) !== JSON.stringify(buildingBlock.views)) {
          changed = true
        }
      } else {
        if (!modifiedState[key] && !buildingBlock[key]) continue
        if (modifiedState[key] !== buildingBlock[key]) {
          changed = true
        }
      }
    }
    setStateChanged(changed)
  }, [modifiedState, buildingBlock])

  const [potentialView, setPotentialView] = React.useState('')

  const handleRemoveView = (view: string) => {
    const index = modifiedState.views!.indexOf(view, 0)
    const temp = modifiedState.views
    temp!.splice(index, 1)
    setModifiedState({ ...modifiedState, views: [...temp!] })
    /*if(JSON.stringify(modifiedState.views) !== JSON.stringify(buildingBlock.views)) {
      setStateChanged(true)
    }
    else {
      setStateChanged(false)
    }*/
    //i have to fix removing of the view with backspace, since it does not trigger reactivity!
  }

  const handleChange = (val: any, property: string) => {
    let temp = { ...modifiedState }
    temp[property] = val
    setModifiedState(temp)
  }

  const addView = (event: KeyboardEvent<HTMLInputElement>) => {
    if ((event.code === 'Enter' || event.code === 'NumpadEnter') && potentialView !== '') {
      if (modifiedState.views!.includes(potentialView)) {
        setPotentialView('')
        setIsDuplicate(true)
        setTimeout(() => {
          setIsDuplicate(false)
        }, 5000)
      } else {
        setModifiedState({ ...modifiedState, views: [...modifiedState.views!, potentialView] })
        setPotentialView('')
      }
    } else if (event.code === 'Backspace' && potentialView === '') {
      if (selectedTag) {
        const temp = modifiedState.views
        temp?.pop()
        setModifiedState({ ...modifiedState, views: [...temp!] })
        setSelectedTag(undefined)
      } else setSelectedTag(modifiedState.views![modifiedState.views!.length - 1])
    } else {
      setSelectedTag(undefined)
    }
    if (isDuplicate) setIsDuplicate(false)
  }

  const [selectedTag, setSelectedTag] = React.useState<string | undefined>('')

  const modifyPotentialView = (e: ChangeEvent<HTMLInputElement>) => {
    setPotentialView(e.target.value)
  }

  const [isDuplicate, setIsDuplicate] = React.useState(false)
  const [viewActive, setViewActive] = useBoolean(false)

  const inputRef = React.useRef<HTMLInputElement>(null)

  const quillRef = React.useRef<ReactQuill & { editor: QuillEditor }>(null)
  React.useEffect(() => {
    quillRef.current?.editor.root.setAttribute('spellcheck', 'false')
  }, [])

  return (
    <VStack spacing={4} align="flex-start">
      <FormLabel htmlFor="name">Name</FormLabel>
      <Input
        id="name"
        name="name"
        type="text"
        variant="filled"
        onChange={(e) => handleChange(e.target.value, e.target.id)}
        value={modifiedState.name || ''}
        autoComplete={'off'}
      ></Input>
      <FormLabel htmlFor="externalId">External ID</FormLabel>
      <Input
        id="externalId"
        name="externalId"
        type="text"
        variant="filled"
        onChange={(e) => handleChange(e.target.value, e.target.id)}
        value={modifiedState.externalId || ''}
        autoComplete={'off'}
      ></Input>
      <FormLabel htmlFor="externalName">External name</FormLabel>
      <Input
        id="externalName"
        name="externalName"
        type="text"
        variant="filled"
        onChange={(e) => handleChange(e.target.value, e.target.id)}
        value={modifiedState.externalName || ''}
        autoComplete={'off'}
      ></Input>
      <FormLabel htmlFor="status">Status</FormLabel>
      <Select
        id="status"
        name="status"
        placeholder="Select option"
        variant={'filled'}
        onChange={(e) => handleChange(e.target.value, e.target.id)}
        value={modifiedState.status}
      >
        <option value={BuildingBlockStatus.New}>New</option>
        <option value={BuildingBlockStatus.Edited}>Edited</option>
        <option value={BuildingBlockStatus.Completed}>Completed</option>
      </Select>
      <FormLabel htmlFor="version">Version</FormLabel>
      <Input
        id="version"
        name="version"
        type="text"
        isReadOnly
        variant={'filled'}
        value="1.0" // version has to  be added to the BuildingBlock type later on
      ></Input>
      <FormControl isInvalid={isDuplicate}>
        <FormLabel>Views</FormLabel>
        <Box
          mt={4}
          w="full"
          onClick={() => inputRef.current?.focus()}
          _hover={viewActive ? {} : { bg: 'gray.200', borderColor: 'gray.200' }}
          bgColor={viewActive ? 'white' : 'gray.100'}
          border={'2px'}
          borderColor={viewActive ? 'blue.500' : 'gray.100'}
          rounded="md"
        >
          <Flex align="center" wrap="wrap" w="full" zIndex={2} ml={2} p={1}>
            {modifiedState.views!.map((view, index) => (
              <Tag
                size={'lg'}
                key={index}
                borderRadius="full"
                variant={view === selectedTag ? 'outline' : 'solid'}
                colorScheme="green"
                mt={1}
                mr={1}
              >
                <TagLabel>{view}</TagLabel>
                <TagCloseButton onClick={() => handleRemoveView(view)} />
              </Tag>
            ))}

            <Input
              ref={inputRef}
              type="text"
              placeholder="Add a new view"
              value={potentialView || ''}
              onChange={modifyPotentialView}
              onKeyDown={addView}
              w={'200px'}
              h="full"
              //size={"lg"}
              //pl={width + 'px'}
              variant={'unstyled'}
              onBlur={() => {
                setSelectedTag(undefined)
                setViewActive.off()
              }}
              m={1}
              onFocus={setViewActive.on}
              list="views"
              autoComplete={'off'}
            />
            <datalist id="views">
              {availableViews.elements?.map((view) => {
                return <option key={view.name} value={view.name}></option>
              })}
            </datalist>
          </Flex>
        </Box>
        {isDuplicate && <FormErrorMessage>Entered view already exists.</FormErrorMessage>}
      </FormControl>
      <FormControl>
        <FormLabel>Description</FormLabel>
        <ReactQuill
          ref={quillRef}
          modules={modules}
          theme="snow"
          value={modifiedState.description || ''}
          onChange={(value) => handleChange(value, 'description')}
        />
      </FormControl>
      <FormLabel>Sample sentences</FormLabel>
      <Input
        id="sampleSentence1"
        name="sampleSentence1"
        type="text"
        variant={'filled'}
        value={modifiedState.sampleSentence1 || ''}
        onChange={(e) => handleChange(e.target.value, e.target.id)}
        placeholder="Sample sentence 1..."
      ></Input>
      <Input
        id="sampleSentence2"
        name="sampleSentence2"
        type="text"
        variant={'filled'}
        value={modifiedState.sampleSentence2 || ''}
        onChange={(e) => handleChange(e.target.value, e.target.id)}
        placeholder="Sample sentence 2..."
      ></Input>
      <Input
        id="sampleSentence3"
        name="sampleSentence3"
        type="text"
        variant={'filled'}
        value={modifiedState.sampleSentence3 || ''}
        onChange={(e) => handleChange(e.target.value, e.target.id)}
        placeholder="Sample sentence 3..."
      ></Input>
      <HStack w="100%" justify="right">
        <Button onClick={() => setModifiedState(buildingBlock)} colorScheme="red" variant="outline" size="md">
          Cancel
        </Button>
        <Button
          disabled={!stateChanged}
          onClick={() => putBuildingBlock(modifiedState.id, modifiedState)}
          colorScheme="green"
          variant="outline"
          size="md"
        >
          Save
        </Button>
      </HStack>
    </VStack>
  )
}
