// tslint:disable
/**
 * ADMIN API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum KbUpdateEntryValidation {
    Valid = 'VALID',
    SourceMissing = 'SOURCE_MISSING',
    SourceUnknow = 'SOURCE_UNKNOW',
    SourceAlreadyExists = 'SOURCE_ALREADY_EXISTS',
    SourceAlreadyDeleted = 'SOURCE_ALREADY_DELETED',
    TargetMissing = 'TARGET_MISSING',
    TargetNotAllowed = 'TARGET_NOT_ALLOWED',
    TypeMissing = 'TYPE_MISSING',
    TypeInvalid = 'TYPE_INVALID',
    OperationInvalid = 'OPERATION_INVALID',
    OperationMissing = 'OPERATION_MISSING'
}

