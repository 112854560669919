import React, { useEffect, useState } from 'react'
import { TopicsOfInterestApi } from '@/api/apis'
import { Select } from 'chakra-react-select'
import { AjaxError } from 'rxjs/ajax'
import { Views } from '@/_clients/admin'

interface ViewSelectorProps extends React.SelectHTMLAttributes<HTMLSelectElement> {
  instance: string
  onViewsChange: (views: string[]) => void
  id: string
  placeholder: string
}

interface OptionType {
  label: string
  value: string
}

export const ViewSelector = ({ instance, onViewsChange, id, placeholder }: ViewSelectorProps) => {
  const [instanceViews, setInstanceViews] = useState<OptionType[]>([])

  useEffect(() => {
    const subscription = TopicsOfInterestApi.getInstanceTopicsOfInterestViews({
      instance: instance,
    }).subscribe({
      next: (views: Views) => {
        if (views.elements) {
          const mappedViews: OptionType[] = views.elements.reduce((accumulator: OptionType[], view) => {
            if (view.name !== undefined) {
              accumulator.push({ label: view.name, value: view.name })
            }
            return accumulator
          }, [])
          setInstanceViews(mappedViews)
        }
      },
      error: (error: AjaxError) => {
        console.error(`${error.response.message} - ${error.response.error}`)
      },
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [instance])

  const handleSelectedViewsChange = (views: OptionType[]) => {
    const selected = views.map((val) => val.value)
    onViewsChange(selected)
  }
  return (
    <Select id={id} placeholder={placeholder} options={instanceViews} isMulti onChange={handleSelectedViewsChange} />
  )
}
