import produce from 'immer'
import { AppAction, AppActionKeys } from './app.actions'
import { AsyncErrorAction } from './AsyncAction'

export interface AppState {
  asyncCallCount: number
  asyncErrorCount: number
  asyncErrorMessages: Array<string>
  sideMenuOpen: boolean
}

export const initialState: AppState = {
  sideMenuOpen: false,
  asyncCallCount: 0,
  asyncErrorCount: 0,
  asyncErrorMessages: [],
}

const appReducer = (state: AppState = initialState, action: AppAction): AppState => {
  return produce(state, (draft: AppState) => {
    switch (action.type) {
      case AppActionKeys.TOGGLE_MENU: {
        draft.sideMenuOpen = !draft.sideMenuOpen
        return
      }
      case AppActionKeys.REGISTER_ASYNC_CALL_START: {
        draft.asyncCallCount += 1
        return
      }
      case AppActionKeys.REGISTER_ASYNC_CALL_END: {
        draft.asyncCallCount -= 1
        return
      }
      case AppActionKeys.REGISTER_ASYNC_CALL_ERROR: {
        draft.asyncCallCount -= 1
        draft.asyncErrorCount += 1
        const errorAction = action as AsyncErrorAction
        errorAction.error.message && draft.asyncErrorMessages.push(errorAction.error.message)
        console.log('[DeepAssist client]: ASYNC ERROR')
        console.log(errorAction.error)
        return
      }
    }
  })
}

export default appReducer
