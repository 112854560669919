import { Action } from 'redux'

export enum AppActionKeys {
  INITIALIZED = 'app/INITIALIZED',
  TOGGLE_MENU = 'app/TOGGLE_MENU',
  REGISTER_ASYNC_CALL_START = 'app/REGISTER_ASYNC_CALL_START',
  REGISTER_ASYNC_CALL_END = 'app/REGISTER_ASYNC_CALL_END',
  REGISTER_ASYNC_CALL_ERROR = 'app/REGISTER_ASYNC_CALL_ERROR',
}

export type AppAction = AppInitializedAction | ToggleMenuAction | RegisterAsyncCallAction

// ---------------------------------------------------------------------------------------------------------------------

export interface AppInitializedAction extends Action {
  type: AppActionKeys.INITIALIZED
}

export const appInitialized = (): AppInitializedAction => ({
  type: AppActionKeys.INITIALIZED,
})

// ---------------------------------------------------------------------------------------------------------------------

export interface ToggleMenuAction extends Action {
  type: AppActionKeys.TOGGLE_MENU
}
export const toggleMenu = () => ({
  type: AppActionKeys.TOGGLE_MENU,
})

// ---------------------------------------------------------------------------------------------------------------------

export interface RegisterAsyncCallAction extends Action {
  type:
    | AppActionKeys.REGISTER_ASYNC_CALL_START
    | AppActionKeys.REGISTER_ASYNC_CALL_END
    | AppActionKeys.REGISTER_ASYNC_CALL_ERROR
}
export const registerAsyncCallStart = () => ({
  type: AppActionKeys.REGISTER_ASYNC_CALL_START,
})
export const registerAsyncCallEnd = () => ({
  type: AppActionKeys.REGISTER_ASYNC_CALL_END,
})
export const registerAsyncCallError = (error: any) => ({
  type: AppActionKeys.REGISTER_ASYNC_CALL_ERROR,
  error: error,
})
