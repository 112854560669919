import React, { useEffect, useState } from 'react'
import GaugeChart from 'react-gauge-chart'
import { Icon, Text, VStack } from '@chakra-ui/react'
import { BsEmojiAngry, BsEmojiFrown, BsEmojiNeutral, BsEmojiSmile, BsEmojiSunglasses } from 'react-icons/bs'
import { Proposal } from '@/_clients/proposals'

interface Props {
  proposal: Proposal | null
  loading: boolean
  text: string
}

enum Sentiment {
  StronglyPositive = 'Sentiment - Positiv - Stark',
  Positive = 'Sentiment - Positiv - Schwach',
  StronglyNegative = 'Sentiment - Negativ - Stark',
  Negative = 'Sentiment - Negativ - Schwach',
}

export const SentimentAnalysis = ({ proposal, loading, text }: Props) => {
  const [value, setValue] = useState(0)

  const getSentiment = () => {
    let sentiment = { icon: BsEmojiNeutral, color: 'gray.500', text: 'neutral' }
    switch (value) {
      case 0.125:
        sentiment = { icon: BsEmojiSunglasses, color: '#5BE12C', text: 'Strongly positive' }
        break
      case 0.375:
        sentiment = { icon: BsEmojiSmile, color: '#FFD700', text: 'Positive' }
        break
      case 0.625:
        sentiment = { icon: BsEmojiFrown, color: '#FF8C00', text: 'Negative' }
        break
      case 0.875:
        sentiment = { icon: BsEmojiAngry, color: '#EA4228', text: 'Strongly negative' }
        break
      default:
        break
    }
    return sentiment
  }

  useEffect(() => {
    if (!loading) {
      if (proposal && proposal.businessCases && proposal.businessCases.length !== 0) {
        const tois = proposal.businessCases
        let maxScore = 0
        for (const toi of tois) {
          if (toi.name === Sentiment.StronglyPositive) {
            maxScore = maxScore < 0.125 ? 0.125 : maxScore
          } else if (toi.name === Sentiment.Positive) {
            maxScore = maxScore < 0.375 ? 0.375 : maxScore
          } else if (toi.name === Sentiment.Negative) {
            maxScore = maxScore < 0.625 ? 0.625 : maxScore
          } else if (toi.name === Sentiment.StronglyNegative) {
            maxScore = maxScore < 0.875 ? 0.875 : maxScore
          }
        }
        setValue(maxScore)
      } else {
        setValue(0)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading])

  return (
    <VStack>
      <GaugeChart
        id="gauge-chart5"
        nrOfLevels={4}
        arcsLength={[0.25, 0.25, 0.25, 0.25]}
        colors={['#5BE12C', '#FFD700', '#FF8C00', '#EA4228']}
        percent={value}
        arcPadding={0.02}
        formatTextValue={() => ''}
      />
      <VStack>
        <Icon as={getSentiment().icon} w={14} h={14} color={getSentiment().color} />
        <Text fontSize={'2xl'} fontWeight={'700'} color={getSentiment().color}>
          {getSentiment().text}
        </Text>
      </VStack>
    </VStack>
  )
}
