import { useParams } from 'react-router'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { Column, DataItem } from '@/components/tables/types'
import DataTable from '@/components/tables/DataTable'
import { Box, Button, useToast } from '@chakra-ui/react'
import { FaFileExport } from 'react-icons/fa'
import { LoggingApi } from '@/api/apis'
import { saveAs } from 'file-saver'
import { HttpRequestState } from '@/types'
import { Just, Maybe } from 'purify-ts'
import { useApiLoggingDates } from '@/pages/api-logging/ApiLoggingPage'
import appConfig from '@/constants/appConfig'

const columns: Column[] = [
  {
    Header: 'ProcessingTime',
    accessor: 'processingTime',
    type: 'datetime',
    filtering: true,
    sorting: true,
    format: appConfig.dateTimeFormat,
  },
  { Header: 'InputText', accessor: 'inputText', type: 'string', filtering: true, sorting: true },
  { Header: 'PreprocessedText', accessor: 'preprocessedText', type: 'string', filtering: true, sorting: true },
  { Header: 'InputLength', accessor: 'inputLength', type: 'number', filtering: true, sorting: true },
  { Header: 'Duration', accessor: 'duration', type: 'number', filtering: true, sorting: true },
]

export default function ApiLoggingDetail() {
  const { apiKey = '', instance = '' } = useParams()
  const toast = useToast()
  const { minDate, maxDate } = useApiLoggingDates()

  const [overview, setOverview] = useState<HttpRequestState<Maybe<DataItem[]>>>({
    isLoading: false,
    value: Maybe.empty(),
  })

  function setOverviewLoading(status: boolean) {
    setOverview((prev) => {
      return { ...prev, isLoading: status }
    })
  }

  function getApiLoggingDetails() {
    setOverviewLoading(true)

    LoggingApi.getLoggingDetails({
      instance,
      apiKey,
      from: new Date(minDate) as unknown as string,
      to: new Date(maxDate) as unknown as string,
    }).subscribe({
      next: (data) => {
        if (data.entries) {
          const dataTableData: DataItem[] = Object.values(data.entries).flatMap((item) => ({
            processingTime: item.processingTime || '',
            inputText: item.request?.text || '',
            preprocessedText: item.response?.preprocessedText || '',
            inputLength: item.inputLength || '',
            duration: item.duration || '',
          }))

          setOverview((prev) => ({ ...prev, value: Just(dataTableData) }))
        } else {
          setOverview((prev) => ({ ...prev, value: Maybe.empty() }))
        }
      },
      error: (error) => {
        toast({
          title: error.response.name || error.name,
          description: error.response.message || error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
      complete: () => setOverviewLoading(false),
    })
  }

  function getApiLoggingDetailsFile() {
    LoggingApi.getLoggingDetailsFile({
      instance,
      apiKey,
      from: new Date(minDate) as unknown as string,
      to: new Date(maxDate) as unknown as string,
    }).subscribe({
      next: (data) => {
        const file = new File([data], `logfile-${apiKey}.xlsx`, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        })
        saveAs(file)
      },
      error: (error) => {
        toast({
          title: error.response.name || error.name,
          description: error.response.message || error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
    })
  }

  useEffect(() => {
    getApiLoggingDetails()
  }, [apiKey, instance, minDate, maxDate])

  return (
    <Box data-cy="api-logging-details">
      {overview.value?.caseOf({
        Just: (dataTableData) => (
          <DataTable
            data={dataTableData}
            columns={columns}
            isLoading={overview.isLoading}
            noDataText={'No data available'}
          />
        ),
        Nothing: () => <Box>No data available</Box>,
      })}

      <Button onClick={getApiLoggingDetailsFile} colorScheme={'teal'} rightIcon={<FaFileExport />} mt={8}>
        Export current selected data
      </Button>
    </Box>
  )
}
