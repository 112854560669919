import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import GermanFlag from '@/components/icons/GermanFlag'
import EnglishFlag from '@/components/icons/EnglishFlag'
import { Button, List, ListIcon, ListItem } from '@chakra-ui/react'

const LANGUAGES = [
  { code: 'en', name: 'English', icon: <EnglishFlag /> },
  { code: 'de', name: 'Deutsch', icon: <GermanFlag /> },
]

export const LanguageSwitcher = () => {
  const { i18n } = useTranslation()
  const [appLanguage, setAppLanguage] = useState(i18n.language)
  const handleLanguageChange = (lang: string) => {
    setAppLanguage(lang)
    i18n.changeLanguage(lang)
  }

  useEffect(() => {
    document.documentElement.lang = appLanguage
  }, [appLanguage])

  return (
    <List display={'flex'}>
      {LANGUAGES.map((language) => (
        <ListItem key={language.code} flexGrow={1}>
          <Button
            variant={'ghost'}
            w={'full'}
            onClick={() => handleLanguageChange(language.code)}
            isActive={appLanguage === language.code}
            _active={{ bg: 'brand.tertiary.300' }}
          >
            <ListIcon>{language.icon}</ListIcon>
          </Button>
        </ListItem>
      ))}
    </List>
  )
}
