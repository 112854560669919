import { useParams } from 'react-router'
import { useEffect, useState } from 'react'
import { PageHeader } from '@/components/PageHeader'
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useToast } from '@chakra-ui/react'
import { StatementCollection as TopicOfInterest, Views } from '@/_clients/admin'
import { Just, Maybe } from 'purify-ts'
import { useTranslation } from 'react-i18next'
import { TopicsOfInterestApi } from '@/api/apis'
import { MetadataForm } from '@/features/TopicsOfInterest/components/MetadataForm'
import { HintsTable } from '@/features/TopicsOfInterest/components/HintsTable'
import { Wizard } from '@/features/TopicsOfInterest/components/wizard/Wizard'

export const TopicsOfInterestDetail = () => {
  const { t } = useTranslation()
  const [topicOfInterest, setTopicOfInterest] = useState<Maybe<TopicOfInterest>>(Maybe.empty())
  const [availableViews, setAvailableViews] = useState<Maybe<Views>>(Maybe.empty())
  const toast = useToast()
  const instance = useParams<'instance'>().instance || ''

  const { topicOfInterestId = '' } = useParams()

  useEffect(() => {
    getTopicOfInterest()
    getInstanceTopicsOfInterestViews()
  }, [instance, topicOfInterestId])

  const putTopicOfInterest = (id: string, topicOfInterest: TopicOfInterest) => {
    TopicsOfInterestApi.putTopicOfInterest({
      instance,
      id: topicOfInterestId,
      statementCollection: topicOfInterest,
    }).subscribe(() => {
      getTopicOfInterest()
      toast({
        title: 'TOI updated',
        description: `TOI ${topicOfInterest.name} was successfully updated.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      })
    })
  }

  const getTopicOfInterest = () => {
    TopicsOfInterestApi.getTopicOfInterest({ instance, id: topicOfInterestId }).subscribe((toi) => {
      setTopicOfInterest(Just(toi))
    })
  }

  const getInstanceTopicsOfInterestViews = () => {
    TopicsOfInterestApi.getInstanceTopicsOfInterestViews({
      instance: instance,
    }).subscribe((views) => setAvailableViews(Just(views)))
  }

  return (
    <>
      <Box overflow="visible" data-cy="toi-details-page">
        <PageHeader title={t('toi.pageTitle')} />
      </Box>
      {topicOfInterest.mapOrDefault(
        (topicOfInterest: TopicOfInterest) => (
          <Tabs isLazy>
            <TabList>
              <Tab>Stammdaten</Tab>
              <Tab data-cy="toi-statements">Statements</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <MetadataForm
                  topicOfInterest={topicOfInterest}
                  availableViews={availableViews.orDefault({})}
                  putTopicOfInterest={putTopicOfInterest}
                />
              </TabPanel>
              <TabPanel>
                <Wizard onStatementAdded={getTopicOfInterest} />
                <HintsTable
                  key={topicOfInterest.statements?.length}
                  hints={topicOfInterest.statements || []}
                  reload={() => getTopicOfInterest()}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        ),
        <Text>No topic of interest found with such id</Text>
      )}
    </>
  )
}
