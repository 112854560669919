// tslint:disable
/**
 * DEEP.assist proposals
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs'
import {
  BaseAPI,
  encodeURI,
  HttpHeaders,
  HttpQuery,
  OperationOpts,
  RawAjaxResponse,
  throwIfNullOrUndefined,
} from '../runtime'
import { InspectionHintDetails, InspectionHints, InspectionToiDetails, ProposalInput } from '../models'

export interface GetInspectionHintDetailsRequest {
    instance: string;
    id: string;
}

export interface GetInspectionToiDetailsRequest {
    instance: string;
    id: string;
}

export interface PostForInspectionHintsRequest {
    instance: string;
    proposalInput: ProposalInput;
}

/**
 * no description
 */
export class InspectionApi extends BaseAPI {

    /**
     * Hint details
     */
    getInspectionHintDetails({ instance, id }: GetInspectionHintDetailsRequest): Observable<InspectionHintDetails>
    getInspectionHintDetails({ instance, id }: GetInspectionHintDetailsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InspectionHintDetails>>
    getInspectionHintDetails({ instance, id }: GetInspectionHintDetailsRequest, opts?: OperationOpts): Observable<InspectionHintDetails | RawAjaxResponse<InspectionHintDetails>> {
        throwIfNullOrUndefined(instance, 'instance', 'getInspectionHintDetails');
        throwIfNullOrUndefined(id, 'id', 'getInspectionHintDetails');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'id': id,
        };

        return this.request<InspectionHintDetails>({
            url: '/instances/{instance}/inspection/hint-details'.replace('{instance}', encodeURI(instance)),
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * ToI details
     */
    getInspectionToiDetails({ instance, id }: GetInspectionToiDetailsRequest): Observable<InspectionToiDetails>
    getInspectionToiDetails({ instance, id }: GetInspectionToiDetailsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InspectionToiDetails>>
    getInspectionToiDetails({ instance, id }: GetInspectionToiDetailsRequest, opts?: OperationOpts): Observable<InspectionToiDetails | RawAjaxResponse<InspectionToiDetails>> {
        throwIfNullOrUndefined(instance, 'instance', 'getInspectionToiDetails');
        throwIfNullOrUndefined(id, 'id', 'getInspectionToiDetails');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'id': id,
        };

        return this.request<InspectionToiDetails>({
            url: '/instances/{instance}/inspection/toi-details'.replace('{instance}', encodeURI(instance)),
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Inpsection hints
     */
    postForInspectionHints({ instance, proposalInput }: PostForInspectionHintsRequest): Observable<InspectionHints>
    postForInspectionHints({ instance, proposalInput }: PostForInspectionHintsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<InspectionHints>>
    postForInspectionHints({ instance, proposalInput }: PostForInspectionHintsRequest, opts?: OperationOpts): Observable<InspectionHints | RawAjaxResponse<InspectionHints>> {
        throwIfNullOrUndefined(instance, 'instance', 'postForInspectionHints');
        throwIfNullOrUndefined(proposalInput, 'proposalInput', 'postForInspectionHints');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<InspectionHints>({
            url: '/instances/{instance}/inspection/hints'.replace('{instance}', encodeURI(instance)),
            method: 'POST',
            headers,
            body: proposalInput,
        }, opts?.responseOpts);
    };

}
