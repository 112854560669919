import {
  BuildingBlocksApi as BuildingBlocksApiDelegate,
  Configuration as AdminApiConfiguration,
  ConfigurationParameters,
  InstancesApi as InstancesApiDelegate,
  KnowledgeBaseApi as KnowledgeBaseApiDelegate,
  TopicsOfInterestApi as TopicOfInterestApiDelegate,
} from '../_clients/admin'
import {
  Configuration as ProposalConfiguration,
  InspectionApi as InspectionApiDelegate,
  ProposalApi as ProposalApiDelegate,
} from '../_clients/proposals'
import { BillingApi as BillingApiDelegate, Configuration as BillingConfiguration } from '@/_clients/billing'
import { Configuration as TestingConfiguration, TestingApi as TestingApiDelegate } from '@/_clients/testing'
import { Configuration as LoggingConfiguration, LoggingApi as LoggingApiDelegate } from '@/_clients/logging'
import { isCypressMode } from '@/auth/authentication.utils'
import { authenticator } from '@/auth/authenticator'

const getBasePath = () => {
  if (isCypressMode()) {
    return `${window.location.protocol}//${window.location.hostname}:${window.location.port}/api`
  }
  return window['__API_BASE_URL__']
}

const confParams: ConfigurationParameters = {
  basePath: getBasePath(),
  middleware: [
    {
      pre: (req) => {
        const token = authenticator.getToken()
        if (!token) {
          return req
        }
        req.headers = {
          ...(req.headers || {}),
          Authorization: `Bearer ${token}`,
        }
        return req
      },
    },
  ],
}

export const ProposalApi: ProposalApiDelegate = new ProposalApiDelegate(new ProposalConfiguration(confParams))

export const InspectionApi: InspectionApiDelegate = new InspectionApiDelegate(new ProposalConfiguration(confParams))

export const BillingApi: BillingApiDelegate = new BillingApiDelegate(new BillingConfiguration(confParams))

export const BuildingBlocksApi: BuildingBlocksApiDelegate = new BuildingBlocksApiDelegate(
  new AdminApiConfiguration(confParams)
)

export const TopicsOfInterestApi: TopicOfInterestApiDelegate = new TopicOfInterestApiDelegate(
  new AdminApiConfiguration(confParams),
)

export const InstancesApi: InstancesApiDelegate = new InstancesApiDelegate(new AdminApiConfiguration(confParams))

export const KnowledgeBaseApi: KnowledgeBaseApiDelegate = new KnowledgeBaseApiDelegate(
  new AdminApiConfiguration(confParams)
)

export const LoggingApi: LoggingApiDelegate = new LoggingApiDelegate(new LoggingConfiguration(confParams))

export const TestingApi: TestingApiDelegate = new TestingApiDelegate(new TestingConfiguration(confParams))
