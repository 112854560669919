import { ActionsObservable, combineEpics, ofType, StateObservable } from 'redux-observable'
import { takeUntil } from 'rxjs/operators'
import { apiKeysEpics } from '@/pages/api-keys/state/apiKeys.epics'
import { buildingBlocksEpics } from '@/features/BuildingBlocks/state/buildingBlocks.epics'
import { topicsOfInterestEpics } from '@/features/TopicsOfInterest/state/topicsOfInterest.epics'
import { instancesEpics } from '@/store/instances/state/instances.epics'
import { proposalEpics } from '@/pages/proposal/state/proposal.epics'
import {
  BuildingBlocksApi,
  InspectionApi,
  InstancesApi,
  KnowledgeBaseApi,
  ProposalApi,
  TopicsOfInterestApi,
} from '@/api/apis'
import { appEpics } from '@/store/state/app.epics'
import { RootAction, RootState } from '@/types'
import { knowledgeGraphEpics } from '@/features/KnowledgeGraph/state/knowledgeGraph.epics'

export const services = {
  BuildingBlocksApi,
  TopicOfInterestApi: TopicsOfInterestApi,
  InspectionApi,
  InstancesApi,
  KnowledgeBaseApi,
  ProposalApi,
  scheduler: undefined,
}

// prettier-ignore
const combinedEpics = combineEpics(
  ...apiKeysEpics,
  ...appEpics,
  ...buildingBlocksEpics,
  ...topicsOfInterestEpics,
  ...knowledgeGraphEpics,
  ...instancesEpics,
  ...proposalEpics,
)

// prettier-ignore
const rootEpic = (action$: ActionsObservable<RootAction>, state$: StateObservable<RootState>) => {
  // force a type error because takeUntil causes other problems that can't be ignored otherwise
  return combinedEpics(action$, state$, services).pipe(takeUntil(action$.pipe(ofType('END'))))
}

export default rootEpic
