import { Action } from 'redux'
import { AsyncAction, AsyncActionPhaseKeys, AsyncErrorAction } from '@/store/state/AsyncAction'
import { StatementCollectionFilter, StatementCollectionFilterType, StatementCollectionInfos } from '@/_clients/admin'
import { TopicOfInterestFilterInventoriesType } from './topicsOfInterest.reducer'

export enum TopicsOfInterestActionKeys {
  LOAD = 'topicsOfInterest/LOAD',
  LOAD_SUCCESS = 'topicsOfInterest/LOAD_SUCCESS',
  LOAD_ERROR = 'topicsOfInterest/LOAD_ERROR',
  FILTER_INVENTORIES_LOAD = 'topicsOfInterest/FILTER_INVENTORIES_LOAD',
  FILTER_INVENTORIES_LOAD_SUCCESS = 'topicsOfInterest/FILTER_INVENTORIES_LOAD_SUCCESS',
  FILTER_INVENTORIES_LOAD_ERROR = 'topicsOfInterest/FILTER_INVENTORIES_LOAD_ERROR',
  PREDICTIVE_CLEAR = 'topicsOfInterest/PREDICTIVE_CLEAR',
  PREDICTIVE_LOAD = 'topicsOfInterest/PREDICTIVE_LOAD',
  PREDICTIVE_LOAD_SUCCESS = 'topicsOfInterest/PREDICTIVE_LOAD_SUCCESS',
  PREDICTIVE_LOAD_ERROR = 'topicsOfInterest/PREDICTIVE_LOAD_ERROR',
}

export type TopicsOfInterestAction =
  | TopicsOfInterestLoadAction
  | TopicsOfInterestLoadSuccessAction
  | FilterInventoriesLoadAction
  | FilterInventoriesLoadSuccessAction
  | PredictiveClearAction
  | PredictiveLoadAction
  | PredictiveLoadSucessAction
  | AsyncErrorAction

export interface TopicsOfInterestLoadAction extends AsyncAction {
  type: TopicsOfInterestActionKeys.LOAD
  instance: string
  filters: StatementCollectionFilter
}

export const loadTopicsOfInterest = (
  instance: string,
  filters: StatementCollectionFilter
): TopicsOfInterestLoadAction => ({
  type: TopicsOfInterestActionKeys.LOAD,
  instance,
  filters,
  phase: AsyncActionPhaseKeys.START,
})

export interface TopicsOfInterestLoadSuccessAction extends AsyncAction {
  type: TopicsOfInterestActionKeys.LOAD_SUCCESS
  topicsOfInterest: StatementCollectionInfos
}

export const loadTopicsOfInterestSuccess = (
  topicsOfInterest: StatementCollectionInfos
): TopicsOfInterestLoadSuccessAction => ({
  type: TopicsOfInterestActionKeys.LOAD_SUCCESS,
  topicsOfInterest: topicsOfInterest,
  phase: AsyncActionPhaseKeys.SUCCESS,
})

export const loadTopicsOfInterestError = (error: any): AsyncErrorAction => ({
  type: TopicsOfInterestActionKeys.LOAD_ERROR,
  error,
  phase: AsyncActionPhaseKeys.ERROR,
})

// filter inventories //
export interface FilterInventoriesLoadAction extends AsyncAction {
  type: TopicsOfInterestActionKeys.FILTER_INVENTORIES_LOAD
  instance: string
  phase: AsyncActionPhaseKeys.START
}

export const loadFilterInventories = (instance: string): FilterInventoriesLoadAction => ({
  type: TopicsOfInterestActionKeys.FILTER_INVENTORIES_LOAD,
  phase: AsyncActionPhaseKeys.START,
  instance,
})

export interface FilterInventoriesLoadSuccessAction extends AsyncAction {
  type: TopicsOfInterestActionKeys.FILTER_INVENTORIES_LOAD_SUCCESS
  filterInventories: TopicOfInterestFilterInventoriesType
  phase: AsyncActionPhaseKeys.SUCCESS
}

export const loadFilterInventoriesSuccess = (
  filterInventories: TopicOfInterestFilterInventoriesType
): FilterInventoriesLoadSuccessAction => ({
  type: TopicsOfInterestActionKeys.FILTER_INVENTORIES_LOAD_SUCCESS,
  filterInventories,
  phase: AsyncActionPhaseKeys.SUCCESS,
})
export const loadFilterInventoriesError = (error: any): AsyncErrorAction => ({
  type: TopicsOfInterestActionKeys.FILTER_INVENTORIES_LOAD_ERROR,
  error,
  phase: AsyncActionPhaseKeys.ERROR,
})

// predictive //
export interface PredictiveLoadAction extends AsyncAction {
  type: TopicsOfInterestActionKeys.PREDICTIVE_LOAD
  instance: string
  requestType: StatementCollectionFilterType
  inputText: string
}

export const loadPredictive = (
  instance: string,
  inputText: string,
  requestType: StatementCollectionFilterType
): PredictiveLoadAction => ({
  type: TopicsOfInterestActionKeys.PREDICTIVE_LOAD,
  instance,
  requestType,
  inputText,
  phase: AsyncActionPhaseKeys.START,
})

export interface PredictiveLoadSucessAction extends AsyncAction {
  type: TopicsOfInterestActionKeys.PREDICTIVE_LOAD_SUCCESS
  phase: AsyncActionPhaseKeys.SUCCESS
  requestType: StatementCollectionFilterType
  payload: string[]
}

export const loadPredictiveSuccess = (
  requestType: StatementCollectionFilterType,
  payload: string[]
): PredictiveLoadSucessAction => ({
  type: TopicsOfInterestActionKeys.PREDICTIVE_LOAD_SUCCESS,
  phase: AsyncActionPhaseKeys.SUCCESS,
  requestType,
  payload,
})
export const loadPredictiveError = (error: any): AsyncErrorAction => ({
  type: TopicsOfInterestActionKeys.PREDICTIVE_LOAD_ERROR,
  error,
  phase: AsyncActionPhaseKeys.ERROR,
})

export interface PredictiveClearAction extends Action {
  type: TopicsOfInterestActionKeys.PREDICTIVE_CLEAR
  subject: StatementCollectionFilterType
}

export const clearPredictive = (subject: StatementCollectionFilterType): PredictiveClearAction => ({
  subject,
  type: TopicsOfInterestActionKeys.PREDICTIVE_CLEAR,
})
