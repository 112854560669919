import produce from 'immer'
import { Maybe } from 'purify-ts'
import {
  KnowledgeGraphAction,
  KnowledgeGraphActionKeys,
  SearchKnowledgeGraphSuccessAction,
  SetInputValueAction,
} from './knowledgeGraph.actions'
import { SearchResult } from '@/_clients/admin'
import { ProposalActionKeys } from '@/pages/proposal/state/proposal.actions'
import { AsyncErrorAction } from '@/store/state/AsyncAction'

export interface KnowledgeGraphState {
  inputText: string
  isDebugChecked: boolean
  meaningExpression: Maybe<SearchResult[]>
}

export const initialState: KnowledgeGraphState = {
  inputText: '',
  isDebugChecked: false,
  meaningExpression: Maybe.empty(),
}

export const knowledgeGraphReducer = (
  state: KnowledgeGraphState = initialState,
  action: KnowledgeGraphAction
): KnowledgeGraphState => {
  return produce(state, (draft: KnowledgeGraphState) => {
    switch (action.type) {
      case KnowledgeGraphActionKeys.SEARCH_SUCCESS: {
        const successAction = action as SearchKnowledgeGraphSuccessAction
        draft.meaningExpression = Maybe.of(successAction.meaningExpression)
        return
      }
      case KnowledgeGraphActionKeys.SEARCH_CLEAR: {
        draft.inputText = initialState.inputText
        draft.meaningExpression = initialState.meaningExpression
        return
      }
      case KnowledgeGraphActionKeys.SET_INPUT_VALUE: {
        const setAction = action as SetInputValueAction
        draft.inputText = setAction.inputValue
        return
      }
      case ProposalActionKeys.LOAD_ERROR: {
        const errorAction = action as AsyncErrorAction
        console.error('The following error occurred when tried to retrieve meaning expression:')
        console.error(errorAction.error)
        return
      }
    }
  })
}
