import { Action } from 'redux'
import { AsyncAction, AsyncActionPhaseKeys, AsyncErrorAction } from '@/store/state/AsyncAction'
import { PreprocessingDTO, Proposal } from '@/_clients/proposals'

export enum ProposalActionKeys {
  LOAD = 'proposal/LOAD',
  LOAD_SUCCESS = 'proposal/LOAD_SUCCESS',
  CLEAR = 'proposal/CLEAR',
  VIEW_INVENTORY_LOAD = 'topicsOfInterest/VIEW_INVENTORY_LOAD',
  VIEW_INVENTORY_LOAD_SUCCESS = 'topicsOfInterest/VIEW_INVENTORY_LOAD_SUCCESS',
  VIEW_INVENTORY_LOAD_ERROR = 'topicsOfInterest/VIEW_INVENTORY_LOAD_ERROR',
  LOAD_ERROR = 'proposal/LOAD_ERROR',
  SET_DEBUG_CHECKED = 'proposal/SET_DEBUG_CHECKED',
  SET_INPUT_VALUE = 'proposal/SET_INPUT_VALUE',
  SET_SELECTED_VIEW = 'proposal/SET_SELECTED_VIEW',
  SET_SELECTED_PREPROCESSING = 'proposal/SET_SELECTED_PREPROCESSING',
}

export type ProposalAction =
  | ProposalLoadAction
  | ProposalLoadSuccessAction
  | ProposalClearAction
  | ViewInventoryLoadAction
  | ViewInventoryLoadSuccessAction
  | AsyncErrorAction
  | SetInputValueAction
  | SetSelectedPreprocessingAction
  | SetSelectedViewAction

export interface ProposalLoadAction extends AsyncAction {
  type: ProposalActionKeys.LOAD
  instance: string
  inputText: string
  preprocessing: PreprocessingDTO
  viewName: string
}

export const loadProposal = (
  instance: string,
  inputText: string,
  viewName: string,
  preprocessing: PreprocessingDTO
): ProposalLoadAction => ({
  type: ProposalActionKeys.LOAD,
  instance,
  inputText,
  viewName,
  preprocessing,
  phase: AsyncActionPhaseKeys.START,
})

export interface ProposalLoadSuccessAction extends AsyncAction {
  type: ProposalActionKeys.LOAD_SUCCESS
  proposal: Proposal
}

export const loadProposalSuccess = (proposal: Proposal): ProposalLoadSuccessAction => ({
  type: ProposalActionKeys.LOAD_SUCCESS,
  proposal,
  phase: AsyncActionPhaseKeys.SUCCESS,
})

export const loadProposalError = (error: any): AsyncErrorAction => ({
  type: ProposalActionKeys.LOAD_ERROR,
  error,
  phase: AsyncActionPhaseKeys.ERROR,
})

export interface ProposalClearAction extends AsyncAction {
  type: ProposalActionKeys.CLEAR
}

export const clearProposal = (): ProposalClearAction => ({
  type: ProposalActionKeys.CLEAR,
  phase: AsyncActionPhaseKeys.SUCCESS,
})

export interface ViewInventoryLoadAction extends AsyncAction {
  type: ProposalActionKeys.VIEW_INVENTORY_LOAD
  instance: string
  phase: AsyncActionPhaseKeys.START
}

export const loadViewInventory = (instance: string): ViewInventoryLoadAction => ({
  type: ProposalActionKeys.VIEW_INVENTORY_LOAD,
  phase: AsyncActionPhaseKeys.START,
  instance,
})

export interface ViewInventoryLoadSuccessAction extends AsyncAction {
  type: ProposalActionKeys.VIEW_INVENTORY_LOAD_SUCCESS
  viewInventory: string[]
  phase: AsyncActionPhaseKeys.SUCCESS
}

export const loadViewInventorySuccess = (viewInventory: any): ViewInventoryLoadSuccessAction => ({
  type: ProposalActionKeys.VIEW_INVENTORY_LOAD_SUCCESS,
  viewInventory,
  phase: AsyncActionPhaseKeys.SUCCESS,
})

export const loadViewInventoryError = (error: any): AsyncErrorAction => ({
  type: ProposalActionKeys.VIEW_INVENTORY_LOAD_ERROR,
  error,
  phase: AsyncActionPhaseKeys.ERROR,
})

export interface SetInputValueAction extends Action {
  type: ProposalActionKeys.SET_INPUT_VALUE
  inputValue: string
}

export const setInputValue = (inputValue: string): SetInputValueAction => ({
  type: ProposalActionKeys.SET_INPUT_VALUE,
  inputValue,
})

export interface SetSelectedViewAction extends Action {
  type: ProposalActionKeys.SET_SELECTED_VIEW
  viewName: string
}

export const setSelectedView = (viewName: string): SetSelectedViewAction => ({
  type: ProposalActionKeys.SET_SELECTED_VIEW,
  viewName,
})

export interface SetSelectedPreprocessingAction extends Action {
  type: ProposalActionKeys.SET_SELECTED_PREPROCESSING
  preprocessingType: PreprocessingDTO
}

export const setSelectedPreprocessing = (preprocessingType: PreprocessingDTO): SetSelectedPreprocessingAction => ({
  type: ProposalActionKeys.SET_SELECTED_PREPROCESSING,
  preprocessingType,
})
