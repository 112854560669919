import React from 'react'
import { Box, CheckboxGroup } from '@chakra-ui/react'
import { ViewHolder } from '../perspectiveHelpers'
import { View } from './View'
import { useTranslation } from 'react-i18next'
import { Proposal } from '@/_clients/proposals'

interface Props {
  views: ViewHolder[] | null
  proposals: Proposal[] | null
  deleteView: Function
  tableColumns: string[]
  moveUp: Function
  moveDown: Function
  highlightHint: Function
  highlightStatement: Function
  setHints: Function
}

export const ViewsList = ({
  views,
  proposals,
  deleteView,
  tableColumns,
  moveDown,
  moveUp,
  highlightHint,
  highlightStatement,
  setHints,
}: Props) => {
  const [counter, setCounter] = React.useState(0)
  React.useEffect(() => {
    views?.forEach((view, index) => {
      view.proposal = proposals && proposals[index] ? proposals[index] : null
    })
    setCounter((counter) => counter + 1)
  }, [views, proposals])
  const { t } = useTranslation()

  return (
    <CheckboxGroup
      onChange={(value) => {
        if (value.length === 0) {
          setHints([])
        } else {
          let test = value.flatMap((item) => {
            return JSON.parse(item.toString())
          })
          setHints(test)
        }
      }}
    >
      {views && views.length !== 0 ? (
        views.map((view, index) => {
          return (
            <View
              tableColumns={tableColumns}
              key={`${index}-${counter}`}
              view={view}
              deleteView={deleteView}
              moveDown={moveDown}
              moveUp={moveUp}
              first={index === 0}
              last={index === views.length - 1}
              highlightHint={highlightHint}
              highlightStatement={highlightStatement}
            ></View>
          )
        })
      ) : (
        <Box data-cy="no-views">{t('analyzer.widget.views.noViews')}</Box>
      )}
    </CheckboxGroup>
  )
}
