import { ChakraProvider } from '@chakra-ui/react'
import { Provider as Redux } from 'react-redux'
import { Store } from 'redux'
import { AppRouter } from '@/router/AppRouter'
import '@/styles/fonts.css'
import theme from '@/theme'

interface Props {
  store: Store<any>
}

const App = ({ store }: Props) => {
  return (
    <ChakraProvider theme={theme}>
      <Redux store={store}>
        <AppRouter />
      </Redux>
    </ChakraProvider>
  )
}

export default App
