import { Box, Button, HStack, Icon, Menu, MenuButton, MenuList, useColorModeValue, VStack } from '@chakra-ui/react'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { MdClear } from 'react-icons/md'
import appConfig from '@/constants/appConfig'

interface Props {
  /** To label the button for cypress */
  buttonCyId?: string
  clearAction: () => void
  disabled?: boolean
  labelValueMaxWidth?: string | number
  noPadding?: boolean
  title: string
  value: string
  valueCyId?: string
  children: React.ReactNode
}

export const FilterMenu = (props: Props) => {
  const { buttonCyId, children, clearAction, disabled, noPadding, labelValueMaxWidth, title, value, valueCyId } = props

  let labelValueMaximumWidth = labelValueMaxWidth || appConfig.defaultMaximumFilterLabelValueWidth

  const filterButtonColor: string = useColorModeValue(
    'var(--chakra-colors-gray-100)',
    'var(--chakra-colors-whiteAlpha-200)'
  )
  const filterButtonHoverColor: string = useColorModeValue(
    'var(--chakra-colors-gray-200)',
    'var(--chakra-colors-whiteAlpha-300)'
  )
  return (
    <Menu>
      {({ isOpen }) => (
        <HStack spacing={0} align="top" mr={2} mb={4}>
          <MenuButton
            isActive={isOpen}
            size="sm"
            as={Button}
            rightIcon={isOpen ? <Icon as={FiChevronUp} /> : <Icon as={FiChevronDown} />}
            disabled={disabled}
            borderTopEndRadius={value === '' ? 'lg' : 'none'}
            overflow="hidden"
            data-cy={buttonCyId}
          >
            <HStack spacing={1}>
              <Box>
                {title}
                {value !== '' && ':'}
              </Box>
              {value !== '' && (
                <Box fontWeight="normal" maxWidth={labelValueMaximumWidth} textOverflow="ellipsis" overflow="hidden">
                  <span cy-id={valueCyId}>{value}</span>
                </Box>
              )}
            </HStack>
          </MenuButton>
          <MenuList p={noPadding ? 0 : 2} maxHeight="400px" overflowY="auto">
            {children}
          </MenuList>
          {value !== '' && (
            <VStack
              height={5}
              width={5}
              backgroundColor={filterButtonColor}
              borderRadius="md"
              ml="122px"
              pt="2px"
              cursor="pointer"
              borderTopStartRadius="none"
              borderBottomStartRadius="none"
              onClick={() => clearAction()}
              style={{}}
              _hover={{
                background: filterButtonHoverColor,
              }}
              spacing={0}
            >
              <Icon as={MdClear} size={55} />
            </VStack>
          )}
        </HStack>
      )}
    </Menu>
  )
}
