import { Epic, ofType } from 'redux-observable'
import * as Rx from 'rxjs'
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators'
import appConfig from '../../../constants/appConfig'
import { RootAction, RootState, Services } from '@/types'
import {
  clearSearchKnowledgeGraph,
  KnowledgeGraphAction,
  KnowledgeGraphActionKeys,
  SearchKnowledgeGraphAction,
  searchKnowledgeGraphError,
  searchKnowledgeGraphSuccess,
} from './knowledgeGraph.actions'

interface KnowledgeGraphEpics extends Epic<RootAction, KnowledgeGraphAction, RootState, Services> {}

const searchKnowledgeGraph: KnowledgeGraphEpics = (action$, state$, { KnowledgeBaseApi }) => {
  return action$.pipe(
    ofType<SearchKnowledgeGraphAction>(KnowledgeGraphActionKeys.SEARCH),
    debounceTime(appConfig.debounceTime),
    switchMap((action) => {
      if (action.inputText.length === 0) {
        return Rx.of(clearSearchKnowledgeGraph())
      }
      return KnowledgeBaseApi.searchKnowledgeBase({
        instance: action.instance,
        q: action.inputText,
      }).pipe(
        map((searchResult) => searchKnowledgeGraphSuccess(searchResult)),
        catchError((error) => Rx.of(searchKnowledgeGraphError(error)))
      )
    })
  )
}

export const knowledgeGraphEpics = [searchKnowledgeGraph]
