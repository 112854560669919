export const renderFallbackErrorMessage = (msg: string, headerText: string = 'Initialization error') => {
  const parent = window.document.body

  const containerElement = document.createElement('div')
  parent.appendChild(containerElement)
  containerElement.style.fontFamily = 'sans-serif'

  const headerElement = document.createElement('h1')
  containerElement.appendChild(headerElement)
  headerElement.appendChild(document.createTextNode(headerText))

  const message1Element = document.createElement('p')
  containerElement.appendChild(message1Element)
  message1Element.appendChild(document.createTextNode(msg))

  const message2Element = document.createElement('p')
  containerElement.appendChild(message2Element)
  message2Element.appendChild(document.createTextNode('If this problem persists, contact support@deepsearch.at'))
}
