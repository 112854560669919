import * as React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { getApiKeysPath } from '@/pages/api-keys/apiKeys.paths'
import { ApiKeysPage } from '@/pages/api-keys/ApiKeysPage'
import { getBuildingBlocksPath } from '@/pages/building-blocks/buildingBlocks.paths'
import { ConfigurationPage } from '@/pages/configuration/ConfigurationPage'
import { getConfigurationPath } from '@/pages/configuration/configuration.paths'
import { DocumentationPage } from '@/pages/documentation/DocumentationPage'
import { getDocumentationPath } from '@/pages/documentation/documentation.paths'
import { InspectionPage } from '@/pages/inspection/InspectionPage'
import { getInspectionPath } from '@/pages/inspection/inspection.paths'
import { ProposalPage } from '@/pages/proposal/ProposalPage'
import { getProposalPath } from '@/pages/proposal/proposal.paths'
import { SettingsPage } from '@/pages/settings/SettingsPage'
import { getSettingsPath } from '@/pages/settings/settings.paths'
import { UsagePage } from '@/pages/usage/UsagePage'
import { getUsagePath } from '@/pages/usage/recentRequests.paths'
import { PageLayout } from '@/layouts/PageLayout'
import SimpleErrorPage from '../pages/SimpleErrorPage'
import { TopicsOfInterestDetail } from '@/features/TopicsOfInterest/TopicsOfInterestDetail'
import { getTopicsOfInterestPath } from '@/pages/topics-of-interest/topicsOfInterest.paths'
import { TopicsOfInterestPage } from '@/pages/topics-of-interest/TopicsOfInterestPage'
import { getPerspectivesPath } from '@/pages/perspectives/perspectives.paths'
import { PerspectivesPage } from '@/pages/perspectives/PerspectivesPage'
import { getKnowledgeGraphPath } from '@/pages/knowledge-graph/knowledgeGraph.paths'
import { WelcomeRedirectPage } from '@/pages/WelcomeRedirectPage'
import { getAnnotationTestPath } from '@/pages/annotation-testing/annotationTesting.path'
import { AnnotationTestingPage } from '@/pages/annotation-testing/AnnotationTestingPage'
import TopicsOfInterestOverview from '@/features/TopicsOfInterest/TopicsOfInterestOverview'
import KnowledgeGraphPage from '@/pages/knowledge-graph/KnowledgeGraphPage'
import KnowledgeGraphOverview from '@/features/KnowledgeGraph/KnowledgeGraphOverview'
import KnowledgeGraphDetail from '@/features/KnowledgeGraph/KnowledgeGraphDetail'
import BuildingBlocksDetails from '@/features/BuildingBlocks/BuildingBlocksDetails'
import BuildingBlocksPage from '@/pages/building-blocks/BuildingBlocksPage'
import BuildingBlocksOverview from '@/features/BuildingBlocks/BuildingBlocksOverview'
import { getApiLoggingPath } from '@/pages/api-logging/apiLogging.paths'
import ApiLoggingPage from '@/pages/api-logging/ApiLoggingPage'
import ApiLoggingDetail from '@/features/ApiLogging/ApiLoggingDetail'
import ApiLoggingOverview from '@/features/ApiLogging/ApiLoggingOverview'
import { getChatbotPath } from '@/pages/chatbot/chatbot.paths'
import ChatBotPage from '@/pages/chatbot/ChatBotPage'

const PATH_PARAM_NAMES = {
  INSTANCE: 'instance',
  ID: 'id',
}

const routerPaths = {
  knowledgeGraph: getKnowledgeGraphPath(`:${PATH_PARAM_NAMES.INSTANCE}`),
  buildingBlocks: getBuildingBlocksPath(`:${PATH_PARAM_NAMES.INSTANCE}`),
  topicsOfInterest: getTopicsOfInterestPath(`:${PATH_PARAM_NAMES.INSTANCE}`),
  perspectives: getPerspectivesPath(`:${PATH_PARAM_NAMES.INSTANCE}`),
  inspection: getInspectionPath(`:${PATH_PARAM_NAMES.INSTANCE}`),
  testing: getAnnotationTestPath(`:${PATH_PARAM_NAMES.INSTANCE}`),
  usage: getUsagePath(`:${PATH_PARAM_NAMES.INSTANCE}`),
  apiKeys: getApiKeysPath(`:${PATH_PARAM_NAMES.INSTANCE}`),
  proposal: getProposalPath(`:${PATH_PARAM_NAMES.INSTANCE}`),
  configuration: getConfigurationPath(`:${PATH_PARAM_NAMES.INSTANCE}`),
  documentation: getDocumentationPath(`:${PATH_PARAM_NAMES.INSTANCE}`),
  settings: getSettingsPath(`:${PATH_PARAM_NAMES.INSTANCE}`),
  apiLogging: getApiLoggingPath(`:${PATH_PARAM_NAMES.INSTANCE}`),
  chatbot: getChatbotPath(`:${PATH_PARAM_NAMES.INSTANCE}`),
}

export const AppRouter = () => {
  const Error404Page = () => <SimpleErrorPage title="404: Not found">The requested page was not found.</SimpleErrorPage>

  return (
    <Router>
      <Routes>
        <Route path="/" element={<PageLayout />}>
          {/* EDITING */}
          <Route path={routerPaths.knowledgeGraph} element={<KnowledgeGraphPage />}>
            <Route path=":knowledgeGraphId" element={<KnowledgeGraphDetail />} />
            <Route index element={<KnowledgeGraphOverview />} />
          </Route>
          <Route path={routerPaths.buildingBlocks} element={<BuildingBlocksPage />}>
            <Route path=":buildingBlockId" element={<BuildingBlocksDetails />} />
            <Route index element={<BuildingBlocksOverview />} />
          </Route>
          <Route path={routerPaths.topicsOfInterest} element={<TopicsOfInterestPage />}>
            <Route path=":topicOfInterestId" element={<TopicsOfInterestDetail />} />
            <Route index element={<TopicsOfInterestOverview />} />
          </Route>

          {/* TESTING */}
          <Route path={routerPaths.perspectives} element={<PerspectivesPage />} />
          <Route path={routerPaths.inspection} element={<InspectionPage />} />
          <Route path={routerPaths.testing} element={<AnnotationTestingPage />} />
          <Route path={routerPaths.chatbot} element={<ChatBotPage />} />

          {/* REPORTS */}
          <Route path={routerPaths.usage} element={<UsagePage />} />
          <Route path={routerPaths.apiLogging} element={<ApiLoggingPage />}>
            <Route path=":apiKey" element={<ApiLoggingDetail />} />
            <Route index element={<ApiLoggingOverview />} />
          </Route>

          {/* CONFIGURATION */}
          <Route path={routerPaths.apiKeys} element={<ApiKeysPage />} />

          {/* OTHER */}
          <Route path={routerPaths.proposal} element={<ProposalPage />} />
          <Route path={routerPaths.configuration} element={<ConfigurationPage />} />
          <Route path={routerPaths.documentation} element={<DocumentationPage />} />
          <Route path={routerPaths.settings} element={<SettingsPage />} />

          <Route index element={<WelcomeRedirectPage />} />
          <Route path={`/:${PATH_PARAM_NAMES.INSTANCE}/*`} element={<Error404Page />} />
        </Route>
      </Routes>
    </Router>
  )
}
