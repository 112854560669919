import { Action } from 'redux'
import { Instance } from '@/_clients/admin'

export enum InstancesActionKeys {
  LOAD_SUCCESS = 'instances/LOAD_SUCCESS',
}

export type InstancesAction = InstancesLoadSuccessAction

export interface InstancesLoadSuccessAction extends Action {
  type: InstancesActionKeys.LOAD_SUCCESS
  instances: Instance[]
}

export const loadInstancesSuccess = (instances: Instance[]): InstancesLoadSuccessAction => ({
  type: InstancesActionKeys.LOAD_SUCCESS,
  instances,
})
