import { Avatar, Flex, Menu, MenuButton, MenuDivider, MenuItem, MenuList } from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router'
import { authenticator } from '@/auth/authenticator'
import { getSettingsPath } from '@/pages/settings/settings.paths'
import appConfig from '@/constants/appConfig'
import { LanguageSwitcher } from '@/pages/perspectives/components/LanguageSwitcher'

interface Props {
  /** A label for cypress */
  cyId?: string
}

export const UserInfo = (props: Props) => {
  const { cyId } = props
  const navigate = useNavigate()
  const { instance = '' } = useParams()
  const user = authenticator.currentUser()
  const name = user?.name || appConfig.noDataPlaceholder

  return (
    <Flex data-cy={cyId}>
      <Menu>
        <MenuButton fontWeight={'regular'}>
          <Flex alignItems={'center'} gap={2}>
            <Avatar size="sm" />
            {name}
          </Flex>
        </MenuButton>
        <MenuList shadow={'lg'} fontWeight={'regular'}>
          <MenuItem rounded="md" cursor={'pointer'} onClick={() => navigate(getSettingsPath(instance))}>
            Edit Profile
          </MenuItem>
          <LanguageSwitcher />
          <MenuDivider />
          <MenuItem data-cy="button-logout" onClick={() => authenticator.logout()}>
            Logout
          </MenuItem>
        </MenuList>
      </Menu>
    </Flex>
  )
}
