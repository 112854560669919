import { createSelector } from 'reselect'
import { selectProposalState } from '@/store/selectors'
import { ProposalState } from './proposal.reducer'

export const getProposal = createSelector(selectProposalState, (state: ProposalState) => state.proposal)
export const getViewInventory = createSelector(selectProposalState, (state: ProposalState) => state.viewInventory)
export const getInputText = createSelector(selectProposalState, (state: ProposalState) => state.inputText)
export const isDebugChecked = createSelector(selectProposalState, (state: ProposalState) => state.isDebugChecked)
export const getSelectedPreprocessing = createSelector(
  selectProposalState,
  (state: ProposalState) => state.selectedPreprocessing
)
export const getSelectedView = createSelector(selectProposalState, (state: ProposalState) => state.selectedView)
