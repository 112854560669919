import * as React from 'react'
import { Button, useToast, VStack } from '@chakra-ui/react'
import { Operator, StatementBuilderState } from './topicOfInterest.types'
import OperatorInput from '../OperatorInput'
import { TopicsOfInterestApi } from '@/api/apis'
import StatementBuilder from '@/features/TopicsOfInterest/components/wizard/StatementBuilder'
import { useParams } from 'react-router'
import {
  bbStatementFields,
  groupStatementFields,
  rangeStatementFields,
  simpleStatementFields,
} from '@/features/TopicsOfInterest/components/wizard/constants'
import { FormProvider, useForm } from 'react-hook-form'
import { RelatedMeaningExpressionsStrategyEnumCustom } from '@/types'

const initialState: StatementBuilderState = {
  operator: Operator.GROUP,
  leftElement: null,
  leftStrategy: RelatedMeaningExpressionsStrategyEnumCustom.Default,
  rightElement: null,
  rightStrategy: RelatedMeaningExpressionsStrategyEnumCustom.Default,
  leftValue: null,
  leftSign: null,
  rightValue: null,
  rightSign: null,
  distance: 70,
  boosted: false,
  excluded: false,
}

interface WizardProps {
  onStatementAdded: () => void
}

export const Wizard = ({ onStatementAdded }: WizardProps) => {
  const instance = useParams<'instance'>().instance || ''
  const { topicOfInterestId = '' } = useParams()
  const toast = useToast()

  const methods = useForm({
    defaultValues: initialState,
  })
  const watchOperator = methods.watch(['operator'])

  function statementFields() {
    switch (watchOperator[0]) {
      case Operator.GROUP: {
        return groupStatementFields
      }
      case Operator.SIMPLE: {
        return simpleStatementFields
      }
      case Operator.RANGE: {
        return rangeStatementFields
      }
      case Operator.BB: {
        return bbStatementFields
      }
    }
  }

  function postStatement({
    operator,
    leftElement,
    leftStrategy,
    leftValue,
    leftSign,
    rightElement,
    rightStrategy,
    rightValue,
    rightSign,
    distance,
    boosted,
  }: // excluded,
  StatementBuilderState) {
    let statementQuery: string

    function formatElement(element: StatementBuilderState['leftElement'] | StatementBuilderState['rightElement']) {
      return typeof element === 'string' ? `\"${element}\"` : element?.meaning.value
    }

    if (operator === 'SIMPLE') {
      statementQuery = `${formatElement(leftElement)}${
        typeof leftElement !== 'string' && leftElement?.meaning.value ? `;${leftStrategy}` : ''
      }`
    } else {
      const elements = [
        formatElement(leftElement),
        leftStrategy,
        leftValue,
        leftSign,
        formatElement(rightElement),
        rightStrategy,
        rightValue,
        rightSign,
        distance,
      ].filter((value) => value !== null)
      statementQuery = `${operator}(${elements.join(';')})`
    }

    toast.closeAll()

    TopicsOfInterestApi.postTopicsOfInterestStatements({
      instance,
      id: topicOfInterestId,
      statementAdd: {
        name: statementQuery,
        boosted,
      },
    }).subscribe(
      () => {
        toast({
          title: 'Success',
          description: 'The statement was successfully added.',
          status: 'success',
          duration: 5000,
        })
        onStatementAdded()
        methods.setFocus('leftElement')
      },
      (error) =>
        toast({
          title: 'Error',
          description: `There was an error on adding the statement: ${error.response.message}`,
          status: 'warning',
          duration: 15000,
          isClosable: true,
        })
    )
  }

  return (
    <VStack spacing={4} alignItems={'flex-start'}>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(postStatement)} autoComplete="off">
          <OperatorInput title="Operator" listValues={Object.values(Operator)} />

          <StatementBuilder statementFields={statementFields()} onSubmit={postStatement} />

          <Button
            type="submit"
            colorScheme="teal"
            alignSelf={'flex-start'}
            isLoading={methods.formState.isSubmitting}
            loadingText="Submitting"
          >
            Submit
          </Button>
        </form>
      </FormProvider>
    </VStack>
  )
}
