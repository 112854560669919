import { Box, Text } from '@chakra-ui/layout'
import { Heading, Radio, RadioGroup, Stack } from '@chakra-ui/react'
import React, { Ref } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

interface OperatorInputProps {
  title: string
  value?: string
  listValues: string[]
  forwardRef?: Ref<any>
}

const OperatorInput = ({ listValues = [], title }: OperatorInputProps) => {
  const { control } = useFormContext()

  return (
    <Box w={'100%'} mb={4} data-cy={'operator-input'}>
      <Heading as={'h3'} size={'lg'} mb={4}>
        {title}
      </Heading>
      <Controller
        name={'operator'}
        control={control}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <RadioGroup onChange={onChange} onBlur={onBlur} value={value} ref={ref}>
            <Stack direction="row">
              {listValues.map((operator) => (
                <Radio key={operator} value={operator}>
                  <Text color="blue.500">{operator}</Text>
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        )}
      />
    </Box>
  )
}

export default OperatorInput
