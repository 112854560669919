import { Box } from '@chakra-ui/react'
interface Props {
  height: string
  width: string
}

export const DeepassistLogo = ({ width, height }: Props) => (
  <Box
    as="svg"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width={width}
    height={height}
    viewBox="0 0 2000 2000"
    fill="currentColor"
  >
    <defs>
      <linearGradient id="linear-gradient" x1="0.5" x2="0.5" y2="1" gradientUnits="objectBoundingBox">
        <stop offset="0" stopColor="#2d90ae" />
        <stop offset="0.1" stopColor="#2e93ae" />
        <stop offset="0.2" stopColor="#2e97ae" />
        <stop offset="0.3" stopColor="#2f9cad" />
        <stop offset="0.4" stopColor="#30a0ac" />
        <stop offset="0.5" stopColor="#31a5ab" />
        <stop offset="0.6" stopColor="#32aaaa" />
        <stop offset="0.7" stopColor="#33b0a9" />
        <stop offset="0.8" stopColor="#35b5a8" />
        <stop offset="0.9" stopColor="#36bba6" />
        <stop offset="1" stopColor="#37c0a4" />
      </linearGradient>
      <clipPath id="clipPath">
        <path
          id="Pfad_933"
          data-name="Pfad 933"
          d="M0,1748.146H2000v-2000H0Z"
          transform="translate(0 251.854)"
          fill="url(#linear-gradient)"
        />
      </clipPath>
      <clipPath id="clipPath-2">
        <rect
          id="Rechteck_26"
          data-name="Rechteck 26"
          width="2135.233"
          height="2063.528"
          fill="url(#linear-gradient)"
        />
      </clipPath>
      <clipPath id="clipPath-3">
        <path
          id="Pfad_3019"
          data-name="Pfad 3019"
          d="M40.69,269.094l297.8-147.7S327.515-60.41,316.883-64.464h0a.286.286,0,0,0-.191-.038h0c-13.615.038-276,333.6-276,333.6"
          transform="translate(-40.69 64.502)"
          fill="none"
        />
      </clipPath>
      <linearGradient id="linear-gradient-4" x1="0.256" y1="0.505" x2="0.364" y2="0.505" xlinkHref="#linear-gradient" />
      <clipPath id="clipPath-4">
        <path
          id="Pfad_3021"
          data-name="Pfad 3021"
          d="M40.69,88.055,239.632,301.911,338.491-59.641Z"
          transform="translate(-40.69 59.641)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-5"
        x1="0.275"
        y1="0.45"
        x2="0.367"
        y2="0.45"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#91c2d3" />
        <stop offset="0.1" stopColor="#92c4d3" />
        <stop offset="0.2" stopColor="#93c6d2" />
        <stop offset="0.3" stopColor="#93c8d2" />
        <stop offset="0.4" stopColor="#94cbd1" />
        <stop offset="0.5" stopColor="#95ced1" />
        <stop offset="0.6" stopColor="#96d1d0" />
        <stop offset="0.7" stopColor="#97d4d0" />
        <stop offset="0.8" stopColor="#98d7cf" />
        <stop offset="0.9" stopColor="#99dace" />
        <stop offset="1" stopColor="#9addce" />
      </linearGradient>
      <clipPath id="clipPath-5">
        <path
          id="Pfad_3023"
          data-name="Pfad 3023"
          d="M29.747,176.243l617.438-18.166L448.243-55.779Z"
          transform="translate(-29.747 55.779)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-6"
        x1="0.341"
        y1="0.31"
        x2="0.499"
        y2="0.31"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#7cb7ca" />
        <stop offset="0.1" stopColor="#7cb9ca" />
        <stop offset="0.2" stopColor="#7dbbca" />
        <stop offset="0.3" stopColor="#7ebeca" />
        <stop offset="0.4" stopColor="#7fc1c9" />
        <stop offset="0.5" stopColor="#80c5c9" />
        <stop offset="0.6" stopColor="#82c8c8" />
        <stop offset="0.7" stopColor="#83ccc8" />
        <stop offset="0.8" stopColor="#84d0c7" />
        <stop offset="0.9" stopColor="#86d4c7" />
        <stop offset="1" stopColor="#87d8c6" />
      </linearGradient>
      <clipPath id="clipPath-6">
        <path
          id="Pfad_3025"
          data-name="Pfad 3025"
          d="M29.747-32.022l407.406,265.1L647.185-50.187Z"
          transform="translate(-29.747 50.187)"
          fill="none"
        />
      </clipPath>
      <linearGradient id="linear-gradient-7" x1="0.341" y1="0.259" x2="0.499" y2="0.259" xlinkHref="#linear-gradient" />
      <clipPath id="clipPath-7">
        <path
          id="Pfad_3027"
          data-name="Pfad 3027"
          d="M45.289-34.753,67.623,146.635S375.176,456.482,388.255,456.482h0a.434.434,0,0,0,.306-.115h0c4.78-4.78-178.634-365.53-178.634-365.53h0Z"
          transform="translate(-45.289 34.753)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-8"
        x1="0.461"
        y1="0.738"
        x2="0.66"
        y2="0.738"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#174f62" />
        <stop offset="0.1" stopColor="#185267" />
        <stop offset="0.2" stopColor="#19566d" />
        <stop offset="0.3" stopColor="#1a5b74" />
        <stop offset="0.4" stopColor="#1b617c" />
        <stop offset="0.5" stopColor="#1c6784" />
        <stop offset="0.6" stopColor="#1d6e8d" />
        <stop offset="0.7" stopColor="#1f7597" />
        <stop offset="0.8" stopColor="#207da2" />
        <stop offset="0.9" stopColor="#2185ad" />
        <stop offset="1" stopColor="#238db9" />
      </linearGradient>
      <clipPath id="clipPath-8">
        <path
          id="Pfad_3029"
          data-name="Pfad 3029"
          d="M45.289-2.91,209.926,122.681,177.993-35.608Z"
          transform="translate(-45.289 35.608)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-9"
        x1="0.448"
        y1="0.505"
        x2="0.512"
        y2="0.505"
        xlinkHref="#linear-gradient-5"
      />
      <clipPath id="clipPath-9">
        <path
          id="Pfad_3031"
          data-name="Pfad 3031"
          d="M45.289,118.95l132.7-32.7L84.106-38.88Z"
          transform="translate(-45.289 38.88)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-10"
        x1="0.397"
        y1="0.505"
        x2="0.461"
        y2="0.505"
        xlinkHref="#linear-gradient-5"
      />
      <clipPath id="clipPath-10">
        <path
          id="Pfad_3033"
          data-name="Pfad 3033"
          d="M42.006-40.08,167.559,163.642,206.376,5.812Z"
          transform="translate(-42.006 40.08)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-11"
        x1="0.379"
        y1="0.581"
        x2="0.461"
        y2="0.581"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#ff8a57" />
        <stop offset="0.1" stopColor="#ff8a57" />
        <stop offset="0.2" stopColor="#ff8a56" />
        <stop offset="0.3" stopColor="#ff8956" />
        <stop offset="0.4" stopColor="#ff8956" />
        <stop offset="0.5" stopColor="#ff8956" />
        <stop offset="0.6" stopColor="#f85" />
        <stop offset="0.7" stopColor="#f85" />
        <stop offset="0.8" stopColor="#ff8755" />
        <stop offset="0.9" stopColor="#ff8754" />
        <stop offset="1" stopColor="#ff8654" />
      </linearGradient>
      <clipPath id="clipPath-11">
        <path
          id="Pfad_3035"
          data-name="Pfad 3035"
          d="M29.747-49.712,130.212,268.434,325.75,576.293,624.125,522.37,498.572,318.648,437.153,215.391Z"
          transform="translate(-29.747 49.712)"
          fill="none"
        />
      </clipPath>
      <linearGradient id="linear-gradient-12" x1="0.229" y1="1.04" x2="0.483" y2="1.04" xlinkHref="#linear-gradient" />
      <clipPath id="clipPath-12">
        <path
          id="Pfad_3037"
          data-name="Pfad 3037"
          d="M42.006,88.046l147.887,58.589L167.559-34.753Z"
          transform="translate(-42.006 34.753)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-13"
        x1="0.461"
        y1="0.565"
        x2="0.535"
        y2="0.565"
        xlinkHref="#linear-gradient-5"
      />
      <clipPath id="clipPath-13">
        <path
          id="Pfad_3039"
          data-name="Pfad 3039"
          d="M32.667,52.518,389.821,88.046l125.553-122.8Z"
          transform="translate(-32.667 34.753)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-14"
        x1="0.461"
        y1="0.649"
        x2="0.511"
        y2="0.649"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#b9d7e3" />
        <stop offset="0.1" stopColor="#bad9e3" />
        <stop offset="0.2" stopColor="#badae3" />
        <stop offset="0.3" stopColor="#bbdce3" />
        <stop offset="0.4" stopColor="#bcdee3" />
        <stop offset="0.5" stopColor="#bde0e2" />
        <stop offset="0.6" stopColor="#bee2e2" />
        <stop offset="0.7" stopColor="#bfe4e2" />
        <stop offset="0.8" stopColor="#c0e6e2" />
        <stop offset="0.9" stopColor="#c1e8e2" />
        <stop offset="1" stopColor="#c3eae1" />
      </linearGradient>
      <clipPath id="clipPath-14">
        <path
          id="Pfad_3041"
          data-name="Pfad 3041"
          d="M25.653-36.691,293.892,124.7,478.224,91.348Z"
          transform="translate(-25.653 36.691)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-15"
        x1="0.431"
        y1="0.77"
        x2="0.497"
        y2="0.77"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#ffaa82" />
        <stop offset="0.1" stopColor="#ffaa81" />
        <stop offset="0.2" stopColor="#ffaa81" />
        <stop offset="0.3" stopColor="#ffa981" />
        <stop offset="0.4" stopColor="#ffa981" />
        <stop offset="0.5" stopColor="#ffa980" />
        <stop offset="0.6" stopColor="#ffa980" />
        <stop offset="0.7" stopColor="#ffa880" />
        <stop offset="0.8" stopColor="#ffa880" />
        <stop offset="0.9" stopColor="#ffa87f" />
        <stop offset="1" stopColor="#ffa87f" />
      </linearGradient>
      <clipPath id="clipPath-15">
        <path
          id="Pfad_3043"
          data-name="Pfad 3043"
          d="M26.138-51.281l9.484,241.353,229,136.8L164.158,8.723Z"
          transform="translate(-26.138 51.281)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-16"
        x1="0.205"
        y1="0.915"
        x2="0.358"
        y2="0.915"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#174f62" />
        <stop offset="0.1" stopColor="#185266" />
        <stop offset="0.2" stopColor="#19566c" />
        <stop offset="0.3" stopColor="#1a5b73" />
        <stop offset="0.4" stopColor="#1b617b" />
        <stop offset="0.5" stopColor="#1c6784" />
        <stop offset="0.6" stopColor="#1d6e8d" />
        <stop offset="0.7" stopColor="#1f7597" />
        <stop offset="0.8" stopColor="#207da2" />
        <stop offset="0.9" stopColor="#2185ad" />
        <stop offset="1" stopColor="#238db9" />
      </linearGradient>
      <clipPath id="clipPath-16">
        <path
          id="Pfad_3045"
          data-name="Pfad 3045"
          d="M21-53.258C11.862-44.156,63.644,271.5,63.644,271.5h0L267.175,285.08,257.69,43.727S48.461-53.832,23.182-53.832h0a3.042,3.042,0,0,0-2.18.574"
          transform="translate(-19.921 53.832)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-17"
        x1="0.165"
        y1="0.973"
        x2="0.303"
        y2="0.973"
        xlinkHref="#linear-gradient-8"
      />
      <clipPath id="clipPath-17">
        <path
          id="Pfad_3047"
          data-name="Pfad 3047"
          d="M21.064-45.325l175.5,330.193L649.134,412.906,453.6,105.048l-229-136.8Z"
          transform="translate(-21.064 45.325)"
          fill="none"
        />
      </clipPath>
      <linearGradient
        id="linear-gradient-18"
        x1="0.297"
        y1="1.039"
        x2="0.483"
        y2="1.039"
        gradientUnits="objectBoundingBox"
      >
        <stop offset="0" stopColor="#ff7a43" />
        <stop offset="0.1" stopColor="#ff7a43" />
        <stop offset="0.2" stopColor="#ff7a43" />
        <stop offset="0.3" stopColor="#ff7942" />
        <stop offset="0.4" stopColor="#ff7942" />
        <stop offset="0.5" stopColor="#ff7842" />
        <stop offset="0.6" stopColor="#ff7842" />
        <stop offset="0.7" stopColor="#ff7841" />
        <stop offset="0.8" stopColor="#ff7741" />
        <stop offset="0.9" stopColor="#ff7741" />
        <stop offset="1" stopColor="#ff7640" />
      </linearGradient>
      <clipPath id="clip-DeepAssist_Logo_2000x2000">
        <rect width="2000" height="2000" />
      </clipPath>
    </defs>
    <g id="DeepAssist_Logo_2000x2000" clipPath="url(#clip-DeepAssist_Logo_2000x2000)">
      <rect width="2000" height="2000" fill="none" fillOpacity="1" />
      <g id="Gruppe_3136" data-name="Gruppe 3136" transform="translate(0 251.854)" opacity="0">
        <g id="Gruppe_1050" data-name="Gruppe 1050" transform="translate(0 -251.854)" clipPath="url(#clipPath)">
          <g id="Gruppe_1049" data-name="Gruppe 1049" transform="translate(-67.617 -55.588)" opacity="0.1">
            <g id="Gruppe_1048" data-name="Gruppe 1048">
              <g id="Gruppe_1047" data-name="Gruppe 1047" clipPath="url(#clipPath-2)">
                <path
                  id="Pfad_932"
                  data-name="Pfad 932"
                  d="M0,1748.146H2000v-2000H0Z"
                  transform="translate(67.617 307.442)"
                  fill="url(#linear-gradient)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
      <g id="Gruppe_3135" data-name="Gruppe 3135" transform="translate(304.11 186)">
        <g id="Gruppe_3106" data-name="Gruppe 3106" transform="translate(833.177)" clipPath="url(#clipPath-3)">
          <g
            id="Gruppe_3105"
            data-name="Gruppe 3105"
            transform="matrix(-0.914, 0.407, -0.407, -0.914, 2219.991, 2058.679)"
          >
            <path
              id="Pfad_3018"
              data-name="Pfad 3018"
              d="M2612.306,3416.587,0,2253.513,1003.329,0,3615.635,1163.074Z"
              transform="translate(0 0)"
              fill="url(#linear-gradient-4)"
            />
          </g>
        </g>
        <g id="Gruppe_3108" data-name="Gruppe 3108" transform="translate(833.177 185.901)" clipPath="url(#clipPath-4)">
          <g
            id="Gruppe_3107"
            data-name="Gruppe 3107"
            transform="matrix(-0.914, 0.407, -0.407, -0.914, 2219.991, 1872.779)"
          >
            <path
              id="Pfad_3020"
              data-name="Pfad 3020"
              d="M2612.307,3416.587,0,2253.513,1003.329,0,3615.635,1163.073Z"
              transform="translate(0 0)"
              fill="url(#linear-gradient-5)"
            />
          </g>
        </g>
        <g id="Gruppe_3110" data-name="Gruppe 3110" transform="translate(414.68 333.596)" clipPath="url(#clipPath-5)">
          <g
            id="Gruppe_3109"
            data-name="Gruppe 3109"
            transform="matrix(-0.914, 0.407, -0.407, -0.914, 2638.488, 1725.083)"
          >
            <path
              id="Pfad_3022"
              data-name="Pfad 3022"
              d="M2612.307,3416.587,0,2253.514,1003.329,0,3615.635,1163.074Z"
              transform="translate(0 0)"
              fill="url(#linear-gradient-6)"
            />
          </g>
        </g>
        <g id="Gruppe_3112" data-name="Gruppe 3112" transform="translate(414.68 547.453)" clipPath="url(#clipPath-6)">
          <g
            id="Gruppe_3111"
            data-name="Gruppe 3111"
            transform="matrix(-0.914, 0.407, -0.407, -0.914, 2638.488, 1511.226)"
          >
            <path
              id="Pfad_3024"
              data-name="Pfad 3024"
              d="M2612.307,3416.587,0,2253.514,1003.329,0,3615.635,1163.074Z"
              transform="translate(0 0)"
              fill="url(#linear-gradient-7)"
            />
          </g>
        </g>
        <g id="Gruppe_3114" data-name="Gruppe 3114" transform="translate(1009.057 1137.7)" clipPath="url(#clipPath-7)">
          <g id="Gruppe_3113" data-name="Gruppe 3113" transform="translate(1127.524 -1137.708) rotate(90)">
            <path
              id="Pfad_3026"
              data-name="Pfad 3026"
              d="M0,2859.525V0H2466.777V2859.525Z"
              fill="url(#linear-gradient-8)"
            />
          </g>
        </g>
        <g
          id="Gruppe_3116"
          data-name="Gruppe 3116"
          transform="translate(1009.057 1105.002)"
          clipPath="url(#clipPath-8)"
        >
          <g id="Gruppe_3115" data-name="Gruppe 3115" transform="translate(1127.523 -1105.01) rotate(90)">
            <path
              id="Pfad_3028"
              data-name="Pfad 3028"
              d="M0,2859.525V0H2466.777V2859.525Z"
              transform="translate(0 0)"
              fill="url(#linear-gradient-9)"
            />
          </g>
        </g>
        <g id="Gruppe_3118" data-name="Gruppe 3118" transform="translate(1009.057 979.87)" clipPath="url(#clipPath-9)">
          <g id="Gruppe_3117" data-name="Gruppe 3117" transform="translate(1127.523 -979.878) rotate(90)">
            <path
              id="Pfad_3030"
              data-name="Pfad 3030"
              d="M0,2859.525V0H2466.777V2859.525Z"
              transform="translate(0 0)"
              fill="url(#linear-gradient-10)"
            />
          </g>
        </g>
        <g id="Gruppe_3120" data-name="Gruppe 3120" transform="translate(883.506 933.978)" clipPath="url(#clipPath-10)">
          <g id="Gruppe_3119" data-name="Gruppe 3119" transform="translate(1253.076 -933.986) rotate(90)">
            <path
              id="Pfad_3032"
              data-name="Pfad 3032"
              d="M0,2859.525V0H2466.777V2859.525Z"
              fill="url(#linear-gradient-11)"
            />
          </g>
        </g>
        <g id="Gruppe_3122" data-name="Gruppe 3122" transform="translate(414.68 565.618)" clipPath="url(#clipPath-11)">
          <g id="Gruppe_3121" data-name="Gruppe 3121" transform="translate(1721.901 -565.626) rotate(90)">
            <path
              id="Pfad_3034"
              data-name="Pfad 3034"
              d="M0,2859.525V0H2466.777V2859.525Z"
              transform="translate(0 0)"
              fill="url(#linear-gradient-12)"
            />
          </g>
        </g>
        <g id="Gruppe_3124" data-name="Gruppe 3124" transform="translate(883.506 1137.7)" clipPath="url(#clipPath-12)">
          <g id="Gruppe_3123" data-name="Gruppe 3123" transform="translate(1253.076 -1137.708) rotate(90)">
            <path
              id="Pfad_3036"
              data-name="Pfad 3036"
              d="M0,2859.525V0H2466.777V2859.525Z"
              transform="translate(0)"
              fill="url(#linear-gradient-13)"
            />
          </g>
        </g>
        <g id="Gruppe_3126" data-name="Gruppe 3126" transform="translate(526.351 1137.7)" clipPath="url(#clipPath-13)">
          <g id="Gruppe_3125" data-name="Gruppe 3125" transform="translate(1610.231 -1137.708) rotate(90)">
            <path
              id="Pfad_3038"
              data-name="Pfad 3038"
              d="M0,2859.525V0H2466.777V2859.525Z"
              transform="translate(0 0)"
              fill="url(#linear-gradient-14)"
            />
          </g>
        </g>
        <g
          id="Gruppe_3128"
          data-name="Gruppe 3128"
          transform="translate(258.113 1063.584)"
          clipPath="url(#clipPath-14)"
        >
          <g id="Gruppe_3127" data-name="Gruppe 3127" transform="translate(1878.469 -1063.592) rotate(90)">
            <path
              id="Pfad_3040"
              data-name="Pfad 3040"
              d="M0,2859.525V0H2466.777V2859.525Z"
              transform="translate(0)"
              fill="url(#linear-gradient-15)"
            />
          </g>
        </g>
        <g id="Gruppe_3130" data-name="Gruppe 3130" transform="translate(276.661 505.615)" clipPath="url(#clipPath-15)">
          <g id="Gruppe_3129" data-name="Gruppe 3129" transform="translate(1859.921 -505.622) rotate(90)">
            <path
              id="Pfad_3042"
              data-name="Pfad 3042"
              d="M0,2859.525V0H2466.777V2859.525Z"
              transform="translate(0 0)"
              fill="url(#linear-gradient-16)"
            />
          </g>
        </g>
        <g id="Gruppe_3132" data-name="Gruppe 3132" transform="translate(38.89 408.056)" clipPath="url(#clipPath-16)">
          <g id="Gruppe_3131" data-name="Gruppe 3131" transform="translate(2097.69 -408.064) rotate(90)">
            <path
              id="Pfad_3044"
              data-name="Pfad 3044"
              d="M0,2859.525V0H2466.777V2859.525Z"
              transform="translate(0 0)"
              fill="url(#linear-gradient-17)"
            />
          </g>
        </g>
        <g id="Gruppe_3134" data-name="Gruppe 3134" transform="translate(82.614 733.392)" clipPath="url(#clipPath-17)">
          <g id="Gruppe_3133" data-name="Gruppe 3133" transform="translate(2053.968 -733.399) rotate(90)">
            <path
              id="Pfad_3046"
              data-name="Pfad 3046"
              d="M0,2859.525V0H2466.777V2859.525Z"
              fill="url(#linear-gradient-18)"
            />
          </g>
        </g>
      </g>
    </g>
  </Box>
)
