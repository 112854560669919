import { StatementInputTypes } from '@/features/TopicsOfInterest/components/wizard/topicOfInterest.types'

const leftElement = {
  name: 'left',
  label: 'left',
  type: StatementInputTypes.Element,
}

const rightElement = {
  name: 'right',
  label: 'right',
  type: StatementInputTypes.Element,
}

const distance = {
  name: 'distance',
  label: 'Distance',
  type: StatementInputTypes.Number,
}

export const groupStatementFields = [leftElement, rightElement, distance]

export const simpleStatementFields = [{ ...leftElement, label: '' }, distance]

// TODO: add leftValue, leftSign, rightValue, rightSign
export const rangeStatementFields = [leftElement, distance]

export const bbStatementFields = [leftElement, distance]
