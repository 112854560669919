import React from 'react'
import { Skeleton, Td, Tr } from '@chakra-ui/react'

type SkeletonRowsProps = {
  lines: number
  items: number
}

const SkeletonRows = ({ lines, items }: SkeletonRowsProps) => {
  return (
    <>
      {[...Array(lines).keys()].map((line) => (
        <Tr key={line.toString()}>
          {[...Array(items).keys()].map((item) => (
            <Td key={item.toString()}>
              <Skeleton height="30px" />
            </Td>
          ))}
        </Tr>
      ))}
    </>
  )
}

export default SkeletonRows
