import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { InstanceBar } from '@/components/menu/InstanceBar'
import { Menu } from '@/components/menu/Menu'
import { Outlet } from 'react-router-dom'

export const PageLayout = () => {
  return (
    <Box height="100vh" overflow="hidden">
      <Flex id={'side-and-main'} width={'100%'} height="full">
        <Menu />
        <Box flex="1" overflow={'auto'} id="scroll-element">
          <InstanceBar />
          <Box p={10}>
            <Outlet />
          </Box>
        </Box>
      </Flex>
    </Box>
  )
}
