export interface RolesToResource {
  [key: string]: string[]
}

const defaultResources: string[] = ['analyzer', 'help', 'documentation', 'chatbot']

const rolesToResourceMap: RolesToResource = {
  'sales-representative': ['usage', 'api-keys'],
  'client-business-owner': [
    'meanings',
    'building-block',
    'topic-of-interest',
    'usage',
    'api-keys',
    'package-manager',
    'uploads',
  ],
  'knowledge-officer': ['meanings', 'building-block', 'topic-of-interest', 'package-manager', 'uploads', 'testing'],
  'knowledge-consultant': [
    'meanings',
    'building-block',
    'topic-of-interest',
    'inspection',
    'deep-assist-api',
    'usage',
    'api-keys',
    'package-manager',
    'uploads',
    'request-and-data-logging',
    'testing',
  ],
  'instance-admin': ['instance', 'users', 'jobs'],
}

export const getUnionOfRolesToResource = (roles: string[]): string[] => {
  let unionSet: Set<string> = new Set(defaultResources)

  roles.forEach((role) => {
    const resourceArray = rolesToResourceMap[role]
    if (resourceArray === undefined) return

    resourceArray.forEach((resource) => {
      unionSet.add(resource)
    })
  })

  return [...unionSet]
}

export const checkResource = (resource: string[], param: string | string[]): boolean => {
  if (
    resource.length === 0 ||
    (typeof param === 'string' && param.length === 0) ||
    (Array.isArray(param) && param.length === 0)
  ) {
    return false
  }

  if (typeof param === 'string') {
    return resource.includes(param)
  }

  for (let i = 0; i < param.length; i++) {
    if (resource.includes(param[i])) {
      return true
    }
  }

  return false
}
