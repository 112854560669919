import { createSelector } from 'reselect'
import { selectTopicsOfInterestState } from '@/store/selectors'
import { TopicsOfInterestState } from './topicsOfInterest.reducer'

export const getTopicsOfInterest = createSelector(
  selectTopicsOfInterestState,
  (state: TopicsOfInterestState) => state.topicsOfInterest
)
export const getFilterInventories = createSelector(
  selectTopicsOfInterestState,
  (state: TopicsOfInterestState) => state.filterInventories
)
export const getPredictiveResults = createSelector(
  selectTopicsOfInterestState,
  (state: TopicsOfInterestState) => state.predictiveResults
)
