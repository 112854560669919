/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  List,
  ListIcon,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  useToast,
  VisuallyHidden,
  VisuallyHiddenInput,
} from '@chakra-ui/react'
import { Table, TableCaption, TableContainer, Td, Th, Thead, Tr } from '@chakra-ui/table'
import React, { ChangeEvent } from 'react'
import { Statement, StatementImport, StatementImportResult } from '@/_clients/admin'
import { TopicsOfInterestApi } from '@/api/apis'
import { useParams } from 'react-router'
import { AjaxError } from 'rxjs/ajax'
import { MdError } from 'react-icons/md'

interface Props {
  hints: Array<Statement>
  reload: Function
}

export const HintsTable = ({ hints, reload }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [newHints] = React.useState<Array<Statement>>(hints)
  const [ignoredStatements, setIgnoredStatements] = React.useState<Array<string>>([])
  const [importedStatements, setimportedStatements] = React.useState<Array<string>>([])
  const [statements, setStatements] = React.useState<Array<StatementImport>>([])
  const [loadedFile, setLoadedFile] = React.useState<string | null>(null)
  const [fileName, setFileName] = React.useState('')
  const inputRef = React.useRef<HTMLInputElement>(null)

  const toast = useToast()
  const instance = useParams<'instance'>().instance || ''
  const id = useParams<'id'>().id || ''

  // currently not used, later on will be used for handling of a potential state
  // const addHints = (hints: Array<Statement>) => {
  //   setNewHints([...newHints, ...hints])
  // }

  const renderPotentialHint = (hint: Statement) => {
    return (
      <Tr key={hint.name} bg="green.100">
        <Td>{hint.name || ''}</Td>
        <Td>{hint.dimension || ''}</Td>
        <Td>{hint.type || ''}</Td>
        <Td>{hint.boosted || ''}</Td>
      </Tr>
    )
  }
  const toBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () =>
        resolve(reader.result ? reader.result.toString().replace('data:', '').replace(/^.+,/, '') : '')
      reader.onerror = (error) => reject(error)
    })

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0]
      const suffix = file.name.substr(file.name.lastIndexOf('.') + 1)
      if (suffix === 'xlsx' || suffix === 'xls') {
        const binFile = await toBase64(file)
        event.target.value = ''
        setFileName('')
        TopicsOfInterestApi.postTopicsOfInterestStatementsFile({
          instance: instance,
          id: id,
          forceImport: false,
          file: binFile,
        }).subscribe(
          (res) => importSuccess(res),
          (err) => handleError(err, binFile)
        )
      } else {
        toast({
          title: 'Incorrect file',
          description: `File "${file.name}" cannot be imported. Please, use file in the xlsx or xls format.`,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      }
    }
  }

  const handleError = (err: AjaxError, file: string) => {
    console.log('error')
    if (err.status === 400) {
      setLoadedFile(file)
      setIgnoredStatements(err.response.ignoredStatements)
      setimportedStatements(err.response.importStatements)
      setStatements(err.response.statements)
      openImportModal()
    } else {
      toast({
        title: err.response.error,
        description: err.response.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    }
  }
  const [importModalOpen, setImportModalOpen] = React.useState(false)
  const closeImportModal = () => {
    setImportModalOpen(false)
    setLoadedFile(null)
  }
  const openImportModal = () => {
    setImportModalOpen(true)
  }

  const forceImport = () => {
    if (loadedFile) {
      TopicsOfInterestApi.postTopicsOfInterestStatementsFile({
        instance: instance,
        id: id,
        forceImport: true,
        file: loadedFile,
      }).subscribe(
        (res) => importSuccess(res),
        (err) => importError(err)
      )
    } else {
      toast({
        title: 'No file selected',
        description: 'Please, select you file again.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
    }
  }

  const importSuccess = (res: StatementImportResult) => {
    toast({
      title: 'Statements replaced',
      description: `${res.importStatements?.length} statements were successfully loaded`,
      status: 'success',
      duration: 5000,
      isClosable: true,
    })
    setLoadedFile(null)
    closeImportModal()
    reload()
  }

  const importError = (err: AjaxError) => {
    toast({
      title: 'Error in replacement',
      description: err.message,
      status: 'error',
      duration: 5000,
      isClosable: true,
    })
    setLoadedFile(null)
    closeImportModal()
  }

  return (
    <>
      <Box>
        <HStack m={2} mb={8} spacing={4} justify={'start'}>
          <VisuallyHidden>
            <Button>Add new</Button>
            <Button onClick={onOpen}>Import</Button>
          </VisuallyHidden>
          <VisuallyHiddenInput key={fileName} ref={inputRef} type="file" onChange={handleUpload} />
        </HStack>
        <Box>
          <Button onClick={() => inputRef.current?.click()}>Replace from file</Button>
        </Box>
        <TableContainer mt={4}>
          <Table colorScheme="gray" size="sm" variant={'striped'} overflowY="scroll">
            <TableCaption>Hints</TableCaption>
            <Thead>
              <Tr>
                <Th>Hint</Th>
                <Th>Dimension</Th>
                <Th>Strategie</Th>
                <Th>Boost</Th>
              </Tr>
              {newHints.map((hint) => {
                if (!hints.includes(hint)) {
                  return (
                    <Tr key={hint.name}>
                      <Td>{hint.name || ''}</Td>
                      <Td>{hint.dimension || ''}</Td>
                      <Td>{hint.type || ''}</Td>
                      <Td>{hint.boosted || ''}</Td>
                    </Tr>
                  )
                } else {
                  return renderPotentialHint(hint)
                }
              })}
            </Thead>
          </Table>
        </TableContainer>
      </Box>
      <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import statement</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. In dolores sapiente enim corporis quod accusamus
            adipisci sed consequuntur, accusantium ullam voluptates. Fuga quo provident eos? Fugiat id ducimus rem amet.
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme="green">Save</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={importModalOpen} onClose={closeImportModal} size={'xl'} scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading as="h4" size="md">
              Import error
            </Heading>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {ignoredStatements.length && (
              <>
                <Box mb={6}>
                  <Heading as="h4" size="sm">
                    Import summary
                  </Heading>
                  <Divider />
                  <HStack spacing={2} mt={3}>
                    <Text>correct statements: </Text>
                    <Text as="b" fontSize={'lg'} color={'green.500'}>
                      {importedStatements.length}
                    </Text>
                    <Text>incorect statements: </Text>
                    <Text as="b" fontSize={'lg'} color={'red.400'}>
                      {ignoredStatements.length}
                    </Text>
                  </HStack>
                </Box>
                <Box>
                  <Heading as="h4" size="sm">
                    Incorrect statements
                  </Heading>
                  <Divider />
                  <List spacing={3}>
                    {statements.map(
                      (statement, i) =>
                        !statement.valid && (
                          <ListItem borderBottom="1px solid" borderBottomColor="gray.300" mt={3} key={'invalid-' + i}>
                            <HStack justify="space-between">
                              <HStack spacing={0}>
                                <ListIcon as={MdError} color="red.500" />
                                <Text fontSize="md">{statement.draftStatement}</Text>
                              </HStack>
                              <Text fontSize="sm">{`line ${statement.position + 1}`}</Text>
                            </HStack>
                            <Text ml={6} as={'i'} fontSize="sm">
                              {statement.message}
                            </Text>
                          </ListItem>
                        )
                    )}
                  </List>
                </Box>
              </>
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="red" mr={3} onClick={closeImportModal}>
              Cancel
            </Button>
            <Button colorScheme="green" onClick={forceImport}>
              Import anyways
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
