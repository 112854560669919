import React, { useState } from 'react'
import { Box, CSSObject, HStack, Input, Text, useColorModeValue, VStack } from '@chakra-ui/react'
import { FiX } from 'react-icons/fi'

interface Props {
  /** To label the input for cypress */
  cyId?: string
  /** When the text list has been changed */
  onSelectedValuesChange: (texts: string[]) => void
  /** We'll trigger the prediction API with this one */
  onInputChange: (text: string) => void
  /** The prediction to be displayed, possibly coming from a prediction API */
  predictedItems: string[]
  /** The selected values */
  selectedValues: string[]
}

export const PredictiveInputWithInlineResultsMulti = (props: Props) => {
  let { cyId, onSelectedValuesChange, onInputChange, predictedItems, selectedValues } = props
  let [inputValue, setInputValue] = useState<string>('')
  let [hoveredIndex, setHoveredIndex] = useState<number>(-1)

  const inputHasChanged = (text: string) => {
    onInputChange(text)
    setInputValue(text)
  }
  const selectedHasRemoved = (index: number) => {
    onSelectedValuesChange(selectedValues.filter((val, ind) => ind !== index))
  }
  const predictionItemSelected = (text: string) => {
    setInputValue('')
    onSelectedValuesChange([...selectedValues, text])
  }

  const hoveredItemRowStyle: CSSObject = {
    backgroundColor: useColorModeValue('var(--chakra-colors-gray-100)', 'var(--chakra-colors-whiteAlpha-200)'),
    borderRadius: 2,
  }

  return (
    <VStack align="left">
      <HStack>
        <Input
          size="sm"
          value={inputValue}
          onChange={(e) => inputHasChanged(e.target.value as string)}
          data-cy={cyId}
        />
      </HStack>
      <VStack align="left">
        <Text fontSize="sm" fontWeight="bold" fontStyle="italic">
          Suggested hints:
        </Text>
        {predictedItems &&
          predictedItems.map((pItem, index) => (
            <Box
              key={`predictive-item-${index}`}
              px={1}
              py="2px"
              userSelect="none"
              cursor="pointer"
              _hover={hoveredItemRowStyle}
              width="full"
            >
              <Text fontSize="sm" onClick={() => predictionItemSelected(pItem)}>
                {pItem}
              </Text>
            </Box>
          ))}
      </VStack>
      <VStack align="left" spacing={1}>
        <Text fontSize="sm" fontWeight="bold" fontStyle="italic">
          Selected hints:
        </Text>
        {selectedValues.map((hint, index) => (
          <HStack
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(-1)}
            width="100%"
            px="1"
            borderRadius={2}
            _hover={hoveredItemRowStyle}
            key={`selected-item-${index}`}
          >
            <Text key={`hints-filter-entry-${index}`} width="90%" fontSize="sm">
              {hint}
            </Text>
            {hoveredIndex === index && (
              <FiX
                size="14"
                cursor="pointer"
                onClick={(e) => {
                  selectedHasRemoved(index)
                }}
              />
            )}
          </HStack>
        ))}
      </VStack>
    </VStack>
  )
}
