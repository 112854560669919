import { SearchResult } from '@/_clients/admin'
import { RelatedMeaningExpressionsStrategyEnumCustom } from '@/types'

export enum Operator {
  GROUP = 'GROUP',
  SIMPLE = 'SIMPLE',
  RANGE = 'RANGE',
  BB = 'BB',
}

export enum Sign {
  LE = '<=',
  L = '<',
}

export enum StatementInputTypes {
  Element = 'ELEMENT',
  Number = 'NUMBER',
}

export interface StatementField {
  name: string
  label: string
  type: StatementInputTypes
}

export type StatementBuilderState = {
  operator: Operator
  leftElement: SearchResult | string | null
  leftStrategy: RelatedMeaningExpressionsStrategyEnumCustom
  rightElement: SearchResult | string | null
  rightStrategy: RelatedMeaningExpressionsStrategyEnumCustom
  leftValue: number | null
  leftSign: Sign | null
  rightValue: number | null
  rightSign: Sign | null
  distance: number
  boosted: boolean
  excluded: boolean
}
