import { Flex, FlexProps, HStack, Text, useMenuButton } from '@chakra-ui/react'
import { TbArrowsShuffle } from 'react-icons/tb'

interface Props extends FlexProps {
  activeInstance: string
}

export const InstanceSwitcherButton = ({ activeInstance, ...flexProps }: Props) => {
  const buttonProps = useMenuButton(flexProps)

  return (
    <Flex as="button" {...buttonProps} alignItems="center" fontSize="xs" data-cy="instance-switcher">
      <HStack flex="1" spacing="3">
        <TbArrowsShuffle />
        <Text>change instance</Text>
      </HStack>
    </Flex>
  )
}
