import appConfig from '@/constants/appConfig'
import { Input, Text } from '@chakra-ui/react'
import { format } from 'date-fns'

interface DateInputProps {
  label: string
  dateValue: string
  minDate: string | null
  maxDate: string
  onDateChange: (date: string) => void
}

const formatDate = (dateString: string) => {
  try {
    return format(new Date(dateString), appConfig.dateFormat)
  } catch {
    throw new Error('Invalid date')
  }
}

const DateInput = ({ label, dateValue, minDate, maxDate, onDateChange }: DateInputProps) => {
  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onDateChange(formatDate(e.currentTarget.value))
  }
  return (
    <>
      <Text mr={2}>{label}: </Text>
      <Input
        type="date"
        title={label}
        w="180px"
        mr={4}
        value={dateValue}
        min={minDate || undefined}
        max={maxDate}
        onBlur={handleDateChange}
        onChange={handleDateChange}
      ></Input>
    </>
  )
}

export default DateInput
