import React, { useEffect, useState } from 'react'
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Input,
  Textarea,
  Box,
  VStack,
  HStack,
  IconButton,
} from '@chakra-ui/react'
import { DeleteIcon, AddIcon } from '@chakra-ui/icons'
import { ContextItem } from '../types'

interface ContextManagerProps {
  onContextChange: (context: ContextItem[]) => void
  type: 'toi' | 'extractor'
}

const initialToiContext: ContextItem[] = [
  {
    name: 'Fahrkarte - Rückgabe/Umtausch - Todesfall',
    context: `
  Kontext:
    Du bist ein Language Model und fungierst als Servicemitarbeiter einer öffentlichen Bahngesellschaft.
    Du erhältst eine Anfrage von einem Kunden, der seine geplante Zugreise aufgrund eines Todesfalls nicht antreten konnte und um Erstattung des Tickets bittet.
    Deine Aufgabe ist es, folgende Punkte einzuhalten:

  Anweisung:
    - Du antwortest in kurzen, leicht verständlichen Sätzen mit maximal 250 Zeichen. 
    - Du drückst zu Beginn der Konversation leicht empathisch dein Beileid aus, ohne die "ich-Form" zu verwenden.
    - Du fragst nacheinander und in einzelnen Sätzen nach den für die Rückerstattung erforderlichen Informationen: Zuerst die 16-stellige Ticketnummer. Danach frage nach der IBAN und schließlich Vor- und Nachname des Kontoinhabers. 
    - Du stellst keine Fragen zu bereits gegebenen Informationen.
    - Sobald du alle benötigten Daten hast, fragst du, ob der Kunde eine Bestätigung der Rückerstattung per E-Mail erhalten möchte. 
    - Bei Zustimmung des Kunden, fragst du nach seiner E-Mail-Adresse.
    - Du beendest die Konversation, indem du den Referenzcode UXC142 nennst und dich höflich verabschiedest. 
`,
  },
  {
    name: 'SDG Helpdesk Infrastruktur',
    context: `
Context:
  - We have 3 printers in our building
  - Printer 1; location: first floor next to the kitchen; type: HP LaserJet Enterprise MFP M430f; id: 'next_to_kitchen_001'; ip address: '192:168:0:1'; responsible person: Joseph Hotter (+43 676 5704543)
  - Printer 2; location: second floor next to the vending machine; type: Canon MAXIFY GX7021; id: 'next_to_machine_002'; ip address: '192:168:0:2'; responsible person: Bronislav Vymazal (+43 664 88114614)
  - Printer 3; location: at the reception desk; type: Kyocera ECOSYS M5526cdw; id: 'at_reception_003'; ip address: 'at_reception_003'; Brigitte Benian (+43 676 155655)

Instructions:
  - help to locate the printer
  - help to fix the problems with printer
  - if the problem relates to any supplies (paper, cartridge) or physical damage, give a contact to responsible person
`,
  },
]

const initialExtractorContext: ContextItem[] = [
  {
    name: 'Extractor Context',
    context: `{
      "type": "object",
      "properties": {
        "name": {
          "type": "string"
        },
        "email": {
          "type": "string",
          "format": "email"
        },
        "IBAN": {
          "type": "string",
          "pattern": "^[A-Za-z]{2}[0-9]{2}[A-Za-z0-9]{1,30}$"
        },
        "TicketNumber": {
          "type": "string",
          "pattern": "^[0-9]{16}$"
        }
      },
      "required": ["name", "email", "TicketNumber", "IBAN"]
    }    
    `,
  },
]

const ContextManager = ({ onContextChange, type }: ContextManagerProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const initialContext = type === 'toi' ? initialToiContext : initialExtractorContext
  const [context, setContext] = useState<ContextItem[]>(initialContext)
  const [tempContext, setTempContext] = useState<ContextItem[]>(initialContext)

  useEffect(() => {
    onContextChange(context)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOpenModal = () => {
    setTempContext([...context])
    onOpen()
  }

  const handleAddContext = () => {
    const newContext = [...tempContext, { name: '', context: '' }]
    setTempContext(newContext)
  }

  const handleDeleteContext = (index: number) => {
    const newContext = [...tempContext]
    newContext.splice(index, 1)
    setTempContext(newContext)
  }

  const handleContextChange = (index: number, field: 'name' | 'context', value: string) => {
    const newContext = [...tempContext]
    newContext[index][field] = value
    setTempContext(newContext)
  }

  const handleSave = () => {
    setContext(tempContext)
    onContextChange(tempContext)
    onClose()
  }

  return (
    <Box>
      <Button onClick={handleOpenModal}>{`Manage ${type === 'toi' ? 'TOI' : 'Extractor'} Context`}</Button>
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{`${type} Context`}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {tempContext.map((item, index) => (
              <VStack key={index} align="stretch" mb={4}>
                <HStack width="100%">
                  <Input
                    placeholder="Name"
                    value={item.name}
                    onChange={(e) => handleContextChange(index, 'name', e.target.value)}
                  />
                  <IconButton
                    aria-label="Delete context item"
                    icon={<DeleteIcon />}
                    onClick={() => handleDeleteContext(index)}
                  />
                </HStack>
                <Textarea
                  placeholder="Context"
                  value={item.context}
                  onChange={(e) => handleContextChange(index, 'context', e.target.value)}
                  rows={15}
                />
              </VStack>
            ))}
            <Button leftIcon={<AddIcon />} onClick={handleAddContext}>
              {`Add ${type} Context`}
            </Button>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default ContextManager
