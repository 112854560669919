import React from 'react'
import { Button, HStack, Text } from '@chakra-ui/react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import appConfig from '../constants/appConfig'

interface Props {
  activePageIndex: number
  onPageTo: (index: number) => void
  totalPageCount: number
}

export const Pagination = (props: Props) => {
  let { activePageIndex, onPageTo, totalPageCount } = props
  let pageIndexes = Array.from(Array(totalPageCount).keys())
  // there are (max) 3 clusters
  let pagerButtonClusterSize = Math.floor(appConfig.maximumVisiblePagerNumberButtons / 3)
  let visiblePageIndexes = pageIndexes.filter(
    (pageIndex: number) =>
      pageIndex < pagerButtonClusterSize ||
      pageIndex >= totalPageCount - pagerButtonClusterSize ||
      Math.abs(pageIndex - activePageIndex) <= pagerButtonClusterSize / 2
  )
  let colorScheme = 'gray'
  return (
    <HStack>
      <Button
        disabled={activePageIndex <= 0}
        fontSize="sm"
        outline="none"
        onClick={() => onPageTo(activePageIndex - 1)}
        colorScheme={colorScheme}
        variant="outline"
      >
        <FiChevronLeft size="20" />
      </Button>
      {visiblePageIndexes.map((vpIndex: number, iteratorIndex: number) => (
        <HStack key={`buttonstack-${iteratorIndex}`}>
          {vpIndex - visiblePageIndexes[iteratorIndex - 1] > 1 && (
            <Text userSelect="none" width={8} textAlign="center">
              {`...`}
            </Text>
          )}
          {activePageIndex === vpIndex ? (
            <Button
              disabled
              fontSize="sm"
              outline="none"
              colorScheme={colorScheme}
              data-cy={`pager-button-${vpIndex + 1}`}
            >
              {vpIndex + 1}
            </Button>
          ) : (
            <Button
              onClick={() => onPageTo(vpIndex)}
              fontSize="sm"
              outline="none"
              colorScheme={colorScheme}
              variant="outline"
              data-cy={`pager-button-${vpIndex + 1}`}
            >
              {vpIndex + 1}
            </Button>
          )}
        </HStack>
      ))}
      <Button
        disabled={activePageIndex >= totalPageCount - 1}
        fontSize="sm"
        outline="none"
        onClick={() => onPageTo(activePageIndex + 1)}
        colorScheme={colorScheme}
        variant="outline"
      >
        <FiChevronRight size="20" />
      </Button>
    </HStack>
  )
}
