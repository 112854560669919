import { Box, Button, Flex, Switch, Text, useColorMode, useColorModeValue } from '@chakra-ui/react'
import { authenticator } from '@/auth/authenticator'
import { PageHeader } from '@/components/PageHeader'

interface Props {
  children: React.ReactNode
}

export const SettingsPage = () => {
  const { toggleColorMode } = useColorMode()
  const stickyHeight = '70px'
  const menuItemBackgroundColor = useColorModeValue('gray.300', 'gray.800')
  const darkModeCheckerChecked = useColorModeValue(false, true)

  const SettingsGroupHeader = (props: Props) => (
    <Text fontSize="lg" mb={2} mt={8}>
      {props.children}
    </Text>
  )
  const SettingsGroup = (props: Props) => (
    <Box borderRadius={5} borderWidth={1} borderColor={menuItemBackgroundColor}>
      {props.children}
    </Box>
  )
  interface SettingsItemProps {
    py?: number
    withBottomBorder?: boolean
    children: React.ReactNode
  }
  const SettingsItem = (props: SettingsItemProps) => {
    const { py, withBottomBorder } = props
    return (
      <Flex
        px={4}
        py={py || 4}
        width="100%"
        justifyContent="space-between"
        borderColor={menuItemBackgroundColor}
        borderBottomWidth={withBottomBorder ? 1 : 0}
      >
        {props.children}
      </Flex>
    )
  }

  return (
    <>
      <PageHeader title="Help" />
      <Box mt={stickyHeight}>
        <SettingsGroupHeader>Appereance</SettingsGroupHeader>
        <SettingsGroup>
          <SettingsItem withBottomBorder>
            <Text>Dark mode</Text>
            <Switch isChecked={darkModeCheckerChecked} onChange={() => toggleColorMode()} />
          </SettingsItem>
        </SettingsGroup>
        <Flex justifyContent="flex-end" width="100%" mt={10}>
          <Button onClick={() => authenticator.logout()} width={200} mr={4}>
            Logout
          </Button>
        </Flex>
      </Box>
    </>
  )
}
