export const styles = {
  global: {
    body: {
      color: '#171717',
      bg: '#FAFAFA',
    },
  },
  fonts: {
    body: "'Montserrat', sans-serif",
    heading: "'Montserrat', serif",
  },
}
