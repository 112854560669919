// tslint:disable
/**
 * ADMIN API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The type of the statement. This will determine which parameters are possible and expected. 
 * @export
 * @enum {string}
 */
export enum StatementType {
    Text = 'TEXT',
    Meaning = 'MEANING',
    Range = 'RANGE',
    Group = 'GROUP',
    Parameter = 'PARAMETER',
    BuildingBlock = 'BUILDING_BLOCK'
}

