import { Box, Heading, HStack, Text, VStack } from '@chakra-ui/react'
import React from 'react'

interface PageHeaderProps {
  /** usually a button, aligned to the right */
  action?: JSX.Element
  details?: JSX.Element | string
  title?: string
}

export const PageHeader = ({ action, details, title }: PageHeaderProps) => {
  return (
    <VStack align="left" pb={5} width="100%">
      <HStack width="100%" pb="1">
        {title && (
          <Heading as="h3" fontSize={'3xl'} width="100%">
            {title}
          </Heading>
        )}
        {action && (
          <Box textAlign="end" width="100%" pr="2">
            {action}
          </Box>
        )}
      </HStack>
      {details && <Text>{details}</Text>}
    </VStack>
  )
}
