// tslint:disable
/**
 * ADMIN API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs'
import {
  BaseAPI,
  encodeURI,
  HttpHeaders,
  HttpQuery,
  OperationOpts,
  RawAjaxResponse,
  throwIfNullOrUndefined,
} from '../runtime'
import {
  Editors,
  Statement,
  StatementAdd,
  StatementCollection,
  StatementCollectionFilter,
  StatementCollectionFilterType,
  StatementCollectionInfos,
  StatementImportResult,
  Views,
} from '../models'

export interface DeleteBuildingBlockRequest {
    instance: string;
    id: string;
}

export interface GetBuildingBlockRequest {
    instance: string;
    id: string;
}

export interface GetBuildingBlockFiltersRequest {
    instance: string;
    type: StatementCollectionFilterType;
    input: string;
}

export interface GetInstanceBuildingBlockEditorsRequest {
    instance: string;
}

export interface GetInstanceBuildingBlockViewsRequest {
    instance: string;
}

export interface ListBuildingBlocksInfosRequest {
    instance: string;
    statementCollectionFilter: StatementCollectionFilter;
}

export interface PostBuildingBlockRequest {
    instance: string;
    statementCollection: StatementCollection;
}

export interface PostBuildingBlockStatementsRequest {
    instance: string;
    id: string;
    statementAdd: StatementAdd;
}

export interface PostBuildingBlockStatementsFileRequest {
    instance: string;
    id: string;
    forceImport?: boolean;
    file?: string;
}

export interface PutBuildingBlockRequest {
    instance: string;
    id: string;
    statementCollection: StatementCollection;
}

/**
 * no description
 */
export class BuildingBlocksApi extends BaseAPI {

    /**
     */
    deleteBuildingBlock({ instance, id }: DeleteBuildingBlockRequest): Observable<void>
    deleteBuildingBlock({ instance, id }: DeleteBuildingBlockRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteBuildingBlock({ instance, id }: DeleteBuildingBlockRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(instance, 'instance', 'deleteBuildingBlock');
        throwIfNullOrUndefined(id, 'id', 'deleteBuildingBlock');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<void>({
            url: '/instances/{instance}/building-blocks/{id}'.replace('{instance}', encodeURI(instance)).replace('{id}', encodeURI(id)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a BuildingBlock.
     */
    getBuildingBlock({ instance, id }: GetBuildingBlockRequest): Observable<StatementCollection>
    getBuildingBlock({ instance, id }: GetBuildingBlockRequest, opts?: OperationOpts): Observable<RawAjaxResponse<StatementCollection>>
    getBuildingBlock({ instance, id }: GetBuildingBlockRequest, opts?: OperationOpts): Observable<StatementCollection | RawAjaxResponse<StatementCollection>> {
        throwIfNullOrUndefined(instance, 'instance', 'getBuildingBlock');
        throwIfNullOrUndefined(id, 'id', 'getBuildingBlock');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<StatementCollection>({
            url: '/instances/{instance}/building-blocks/{id}'.replace('{instance}', encodeURI(instance)).replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getBuildingBlockFilters({ instance, type, input }: GetBuildingBlockFiltersRequest): Observable<Array<string>>
    getBuildingBlockFilters({ instance, type, input }: GetBuildingBlockFiltersRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<string>>>
    getBuildingBlockFilters({ instance, type, input }: GetBuildingBlockFiltersRequest, opts?: OperationOpts): Observable<Array<string> | RawAjaxResponse<Array<string>>> {
        throwIfNullOrUndefined(instance, 'instance', 'getBuildingBlockFilters');
        throwIfNullOrUndefined(type, 'type', 'getBuildingBlockFilters');
        throwIfNullOrUndefined(input, 'input', 'getBuildingBlockFilters');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'type': type,
            'input': input,
        };

        return this.request<Array<string>>({
            url: '/instances/{instance}/building-blocks-filters'.replace('{instance}', encodeURI(instance)),
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * The editors of the building blocks of the given DEEP.assist instance.
     */
    getInstanceBuildingBlockEditors({ instance }: GetInstanceBuildingBlockEditorsRequest): Observable<Editors>
    getInstanceBuildingBlockEditors({ instance }: GetInstanceBuildingBlockEditorsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Editors>>
    getInstanceBuildingBlockEditors({ instance }: GetInstanceBuildingBlockEditorsRequest, opts?: OperationOpts): Observable<Editors | RawAjaxResponse<Editors>> {
        throwIfNullOrUndefined(instance, 'instance', 'getInstanceBuildingBlockEditors');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Editors>({
            url: '/instances/{instance}/building-blocks-editors'.replace('{instance}', encodeURI(instance)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * The views used by building blocks of the given DEEP.assist instance.
     */
    getInstanceBuildingBlockViews({ instance }: GetInstanceBuildingBlockViewsRequest): Observable<Views>
    getInstanceBuildingBlockViews({ instance }: GetInstanceBuildingBlockViewsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Views>>
    getInstanceBuildingBlockViews({ instance }: GetInstanceBuildingBlockViewsRequest, opts?: OperationOpts): Observable<Views | RawAjaxResponse<Views>> {
        throwIfNullOrUndefined(instance, 'instance', 'getInstanceBuildingBlockViews');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Views>({
            url: '/instances/{instance}/building-blocks-views'.replace('{instance}', encodeURI(instance)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Returns BuildingBlockInfos.
     */
    listBuildingBlocksInfos({ instance, statementCollectionFilter }: ListBuildingBlocksInfosRequest): Observable<StatementCollectionInfos>
    listBuildingBlocksInfos({ instance, statementCollectionFilter }: ListBuildingBlocksInfosRequest, opts?: OperationOpts): Observable<RawAjaxResponse<StatementCollectionInfos>>
    listBuildingBlocksInfos({ instance, statementCollectionFilter }: ListBuildingBlocksInfosRequest, opts?: OperationOpts): Observable<StatementCollectionInfos | RawAjaxResponse<StatementCollectionInfos>> {
        throwIfNullOrUndefined(instance, 'instance', 'listBuildingBlocksInfos');
        throwIfNullOrUndefined(statementCollectionFilter, 'statementCollectionFilter', 'listBuildingBlocksInfos');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<StatementCollectionInfos>({
            url: '/instances/{instance}/building-blocks-infos'.replace('{instance}', encodeURI(instance)),
            method: 'POST',
            headers,
            body: statementCollectionFilter,
        }, opts?.responseOpts);
    };

    /**
     * Creates a new Building Block.
     */
    postBuildingBlock({ instance, statementCollection }: PostBuildingBlockRequest): Observable<void>
    postBuildingBlock({ instance, statementCollection }: PostBuildingBlockRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    postBuildingBlock({ instance, statementCollection }: PostBuildingBlockRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(instance, 'instance', 'postBuildingBlock');
        throwIfNullOrUndefined(statementCollection, 'statementCollection', 'postBuildingBlock');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<void>({
            url: '/instances/{instance}/building-blocks'.replace('{instance}', encodeURI(instance)),
            method: 'POST',
            headers,
            body: statementCollection,
        }, opts?.responseOpts);
    };

    /**
     * Add a statement to the Building Block
     */
    postBuildingBlockStatements({ instance, id, statementAdd }: PostBuildingBlockStatementsRequest): Observable<Array<Statement>>
    postBuildingBlockStatements({ instance, id, statementAdd }: PostBuildingBlockStatementsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<Statement>>>
    postBuildingBlockStatements({ instance, id, statementAdd }: PostBuildingBlockStatementsRequest, opts?: OperationOpts): Observable<Array<Statement> | RawAjaxResponse<Array<Statement>>> {
        throwIfNullOrUndefined(instance, 'instance', 'postBuildingBlockStatements');
        throwIfNullOrUndefined(id, 'id', 'postBuildingBlockStatements');
        throwIfNullOrUndefined(statementAdd, 'statementAdd', 'postBuildingBlockStatements');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<Statement>>({
            url: '/instances/{instance}/building-blocks/{id}/statements'.replace('{instance}', encodeURI(instance)).replace('{id}', encodeURI(id)),
            method: 'POST',
            headers,
            body: statementAdd,
        }, opts?.responseOpts);
    };

    /**
     * Import a list of statements to an existing BuildingBlock. If an statement is syntactically incorrect, an error with the list of faulty statements is returned. If errors should be ignored, a commit can be forced using request param forceCommit
     */
    postBuildingBlockStatementsFile({ instance, id, forceImport, file }: PostBuildingBlockStatementsFileRequest): Observable<StatementImportResult>
    postBuildingBlockStatementsFile({ instance, id, forceImport, file }: PostBuildingBlockStatementsFileRequest, opts?: OperationOpts): Observable<RawAjaxResponse<StatementImportResult>>
    postBuildingBlockStatementsFile({ instance, id, forceImport, file }: PostBuildingBlockStatementsFileRequest, opts?: OperationOpts): Observable<StatementImportResult | RawAjaxResponse<StatementImportResult>> {
        throwIfNullOrUndefined(instance, 'instance', 'postBuildingBlockStatementsFile');
        throwIfNullOrUndefined(id, 'id', 'postBuildingBlockStatementsFile');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (forceImport != null) { query['forceImport'] = forceImport; }

        const formData = new FormData();
        if (file !== undefined) { formData.append('file', file as any); }

        return this.request<StatementImportResult>({
            url: '/instances/{instance}/building-blocks/{id}/statements/file'.replace('{instance}', encodeURI(instance)).replace('{id}', encodeURI(id)),
            method: 'POST',
            headers,
            query,
            body: formData,
        }, opts?.responseOpts);
    };

    /**
     * Stores a BuildingBlock.
     */
    putBuildingBlock({ instance, id, statementCollection }: PutBuildingBlockRequest): Observable<void>
    putBuildingBlock({ instance, id, statementCollection }: PutBuildingBlockRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    putBuildingBlock({ instance, id, statementCollection }: PutBuildingBlockRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(instance, 'instance', 'putBuildingBlock');
        throwIfNullOrUndefined(id, 'id', 'putBuildingBlock');
        throwIfNullOrUndefined(statementCollection, 'statementCollection', 'putBuildingBlock');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<void>({
            url: '/instances/{instance}/building-blocks/{id}'.replace('{instance}', encodeURI(instance)).replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: statementCollection,
        }, opts?.responseOpts);
    };

}
