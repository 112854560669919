import { createSelector } from 'reselect'
import { selectBuildingBlocksState } from '@/store/selectors'
import { BuildingBlocksState } from './buildingBlocks.reducer'

export const getTopicsOfInterest = createSelector(
  selectBuildingBlocksState,
  (state: BuildingBlocksState) => state.topicsOfInterest
)
export const getFilterInventories = createSelector(
  selectBuildingBlocksState,
  (state: BuildingBlocksState) => state.filterInventories
)
export const getPredictiveResults = createSelector(
  selectBuildingBlocksState,
  (state: BuildingBlocksState) => state.predictiveResults
)
