import {
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Tfoot,
  Th,
  Thead,
  Tooltip,
  Tr,
  useToast,
  VisuallyHiddenInput,
  VStack,
} from '@chakra-ui/react'
import { CheckIcon, DeleteIcon, DownloadIcon } from '@chakra-ui/icons'
import { Select } from 'chakra-react-select'
import { t } from 'i18next'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'
import { TestingApi, TopicsOfInterestApi } from '@/api/apis'
import { TestRunOverview, TestRunOverviewStatusEnum } from '@/_clients/testing'
import { PageHeader } from '@/components/PageHeader'
import { AjaxError } from 'rxjs/ajax'
import { saveAs } from 'file-saver'
import { concatMap, map } from 'rxjs/operators'
import { Just, Maybe, Nothing } from 'purify-ts'
import { from } from 'rxjs/internal/observable/from'
import { Buffer } from 'buffer/'
import { format, parseISO } from 'date-fns'
import appConfig from '@/constants/appConfig'
import { formatDurationInSeconds } from '@/utils/dateUtils'
import { Views } from '@/_clients/admin'

const getStatusColor = (status: TestRunOverviewStatusEnum): string => {
  switch (status) {
    case TestRunOverviewStatusEnum.Pending:
      return 'orange'
    case TestRunOverviewStatusEnum.Executing:
      return 'blue'
    case TestRunOverviewStatusEnum.Failed:
      return 'red'
    case TestRunOverviewStatusEnum.Finished:
      return 'green'
    default:
      return 'gray'
  }
}

interface OptionType {
  label: string
  value: string
}

export const AnnotationTestingPage = () => {
  const instance = useParams<'instance'>().instance || ''
  const [testRuns, setTestRuns] = useState<Maybe<TestRunOverview[]>>(Nothing)
  const intervalRef = useRef<NodeJS.Timeout | null>(null) // Ref to store the interval ID
  const toast = useToast() // Initialize the Chakra toast hook
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [isDragActive, setIsDragActive] = useState(false)
  const fileInputRef = useRef<HTMLInputElement | null>(null)
  const dropAreaRef = useRef<HTMLDivElement | null>(null)
  const [instanceViews, setInstanceViews] = useState<OptionType[]>([])
  const [selectedViews, setSelectedViews] = useState<string[]>([])
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    const subscription = TopicsOfInterestApi.getInstanceTopicsOfInterestViews({
      instance: instance,
    }).subscribe({
      next: (views: Views) => {
        if (views.elements) {
          const mappedViews: OptionType[] = views.elements.reduce((accumulator: OptionType[], view) => {
            if (view.name !== undefined) {
              accumulator.push({ label: view.name, value: view.name })
            }
            return accumulator
          }, [])
          setInstanceViews(mappedViews)
        }
      },
      error: (error: AjaxError) => {
        console.error(`${error.response.message} - ${error.response.error}`)
      },
    })

    fetchData()
    setSelectedViews([])

    return () => {
      subscription.unsubscribe()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance])

  useEffect(() => {
    setSelectedViews([])
  }, [selectedFile])

  const fetchData = () => {
    TestingApi.getAllTestRuns({ instance }).subscribe({
      next: (runs) => {
        setTestRuns(Just(runs))
      },
      error: (error: AjaxError) => {
        console.error(`${error.response.message} - ${error.response.error}`)
      },
    })
  }

  const getTestFile = (testRunId: string) => {
    TestingApi.downloadTestRunFile({ instance, testRunId })
      .pipe(
        // Convert the Blob (containing the base64 string) to a Blob (containing the binary data)
        map((base64Blob: Blob) => {
          return new Promise<Blob>((resolve) => {
            const reader = new FileReader()
            reader.onloadend = () => {
              const base64String = reader.result as string
              const binaryString = Buffer.from(base64String, 'base64').toString('binary')
              const byteNumbers = new Array(binaryString.length)

              for (let i = 0; i < binaryString.length; i++) {
                byteNumbers[i] = binaryString.charCodeAt(i)
              }

              const byteArray = new Uint8Array(byteNumbers)
              const blob = new Blob([byteArray], { type: 'application/vnd.ms-excel' })
              resolve(blob)
            }
            reader.readAsText(base64Blob)
          })
        }),
        concatMap((blobPromise) => from(blobPromise)),
        // Convert the Blob to a File object
        map((blob: Blob) => {
          return new File([blob], `testfile-${testRunId}.xlsx`, {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
        })
      )
      .subscribe(
        (file) => {
          saveAs(file)
        },
        (error: AjaxError) => {
          toast({
            title: error.response.error,
            description: error.response.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        }
      )
  }

  // Function to reset the timer, fetch data, and start the timer again. This is done to get immediatelly the newly created test into the table.
  const resetTimerAndFetchData = () => {
    if (intervalRef.current !== null) {
      clearInterval(intervalRef.current)
    }
    fetchData()
    intervalRef.current = setInterval(fetchData, 5000)
  }

  useEffect(() => {
    fetchData()
    intervalRef.current = setInterval(fetchData, 5000) // Set the interval to call the API every 5 seconds

    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedFile(e.target.files[0])
    }
  }

  const removeSelectedFile = () => {
    setSelectedFile(null)
    if (fileInputRef.current) {
      fileInputRef.current.value = ''
    }
  }

  const handleDragDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault()
    e.stopPropagation()
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      setSelectedFile(e.dataTransfer.files[0])
    }
    setIsDragActive(false)
  }

  const handleDrag = (e: React.DragEvent<HTMLDivElement>, isEnter: boolean) => {
    e.preventDefault()
    e.stopPropagation()
    setIsDragActive(isEnter)
  }

  const handleDropAreaClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const startTestRun = () => {
    if (selectedFile) {
      setIsSubmitting(true)
      TestingApi.startNewTestRunFromFile({
        instance,
        file: selectedFile,
        views: selectedViews.length > 0 ? selectedViews.join(', ') : undefined,
      }).subscribe({
        next: () => {
          toast({
            title: 'Test started',
            description: 'Your test has successfully started.',
            status: 'success',
            duration: 5000,
            isClosable: true,
          })
          setSelectedFile(null)
          if (fileInputRef.current) {
            fileInputRef.current.value = ''
          }
          setTimeout(() => {
            resetTimerAndFetchData()
          }, 500)
          setIsSubmitting(false)
        },
        error: (error: AjaxError) => {
          setSelectedFile(null)
          if (fileInputRef.current) {
            fileInputRef.current.value = ''
          }
          toast({
            title: 'Error',
            description: error.response.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
          setIsSubmitting(false)
        },
      })
    }
  }

  const handleSelectedViewsChange = (views: OptionType[]) => {
    setSelectedViews(views.map((val) => val.value))
  }

  const renderStatus = (status: TestRunOverviewStatusEnum): React.ReactNode => {
    if (status === TestRunOverviewStatusEnum.Executing) {
      return <Spinner size="sm" />
    }

    return (
      <Tooltip label={status} aria-label="status">
        <Badge
          borderRadius="full"
          p="1"
          backgroundColor={getStatusColor(status)}
          boxSize="1.25rem"
          display="inline-block"
        ></Badge>
      </Tooltip>
    )
  }

  const getTooltipText = (): string => {
    const isDisabled = !selectedFile || (selectedFile.type === 'application/json' && selectedViews.length === 0)
    if (isDisabled && !selectedFile) {
      return 'Select a file to start the test'
    } else if (isDisabled && selectedFile && selectedFile.type === 'application/json') {
      return 'Select at least one view to start the test'
    }

    return ''
  }

  return (
    <Box overflow="visible" data-cy="annotation-test-page">
      <PageHeader title={t('testing.pageTitle')} />

      <Table data-cy="test-runs-table" size="sm" variant="simple">
        <Thead>
          <Tr>
            <Th>Name</Th>
            <Th>Started</Th>
            <Th>Duration</Th>
            <Th>Status</Th>
            <Th>Total Testdata</Th>
            <Th>Successful</Th>
            <Th>Failed</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {testRuns.caseOf({
            Just: (runs) => (
              <>
                {runs.map((testRun, index) => (
                  <Tr key={testRun.id + '-' + index} data-cy={index === 0 ? 'first-row' : undefined}>
                    <Td>{testRun.name}</Td>
                    <Td>{testRun.started ? format(parseISO(testRun.started), appConfig.dateFormat) : '-'}</Td>
                    <Td>{testRun.duration ? formatDurationInSeconds(testRun.duration) : '-'}</Td>
                    <Td>{renderStatus(testRun.status)}</Td>
                    <Td>{testRun.totalTestdataCount || 0}</Td>
                    <Td>{testRun.totalSuccessful || 0}</Td>
                    <Td>{testRun.totalFailed || 0}</Td>
                    <Td>
                      {testRun.status === TestRunOverviewStatusEnum.Finished && (
                        <IconButton
                          aria-label="Download"
                          icon={<DownloadIcon />}
                          onClick={() => getTestFile(testRun.id)}
                        />
                      )}
                    </Td>
                  </Tr>
                ))}
              </>
            ),
            Nothing: () => (
              <Tr>
                <Td colSpan={8}>
                  <Box textAlign="center" py={4}>
                    No test runs available
                  </Box>
                </Td>
              </Tr>
            ),
          })}
        </Tbody>
        <Tfoot>
          <Tr>
            <Td colSpan={8} fontSize="sm" textAlign="left" py={2}>
              <Text as="i"> Displaying the latest 5 test runs</Text>
            </Td>
          </Tr>
        </Tfoot>
      </Table>
      <VStack spacing={4} mt={6}>
        <FormControl w="35%">
          <FormLabel>Test file</FormLabel>
          <Box
            ref={dropAreaRef}
            minW="350px"
            borderWidth={2}
            borderRadius="md"
            borderColor={isDragActive ? 'blue.300' : 'gray.300'}
            _hover={{ borderColor: 'blue.300' }}
            transition="border-color 0.3s ease-in"
            cursor="pointer"
            boxShadow={isDragActive ? 'lg' : ''}
          >
            <Text
              onDrop={handleDragDrop}
              onDragEnter={(e) => handleDrag(e, true)}
              onDragLeave={(e) => handleDrag(e, false)}
              onDragOver={(e) => e.preventDefault()}
              onClick={handleDropAreaClick}
              data-cy="drag-area"
              w="100%"
              h="100%"
              p={4}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              {selectedFile
                ? `Selected file: ${selectedFile.name}`
                : isDragActive
                ? 'Drop the file here...'
                : 'Drag and drop a file here or click to select a file.'}
            </Text>
            <VisuallyHiddenInput data-cy="file-input" type="file" ref={fileInputRef} onChange={handleFileChange} />
          </Box>
        </FormControl>
        {selectedFile && selectedFile.type === 'application/json' && (
          <FormControl w="35%" key={'views_' + instance}>
            <FormLabel>Test views</FormLabel>
            <Select
              id="cyViewSelector"
              placeholder="Please, select the views against which the test should be performed"
              options={instanceViews}
              isMulti
              onChange={handleSelectedViewsChange}
            />
          </FormControl>
        )}

        <Flex alignItems="center" mt={2}>
          <Button
            disabled={!selectedFile}
            leftIcon={<DeleteIcon />}
            colorScheme="red"
            onClick={removeSelectedFile}
            mr={2}
            data-cy="delete-file"
          >
            Remove file
          </Button>
          <Tooltip label={getTooltipText()} aria-label="Select the test file" openDelay={300}>
            <Button
              data-cy="start-test"
              disabled={!selectedFile || (selectedFile.type === 'application/json' && selectedViews.length === 0)}
              leftIcon={<CheckIcon />}
              colorScheme="green"
              onClick={startTestRun}
              isLoading={isSubmitting}
              loadingText="Submitting"
            >
              Start test
            </Button>
          </Tooltip>
        </Flex>
      </VStack>
    </Box>
  )
}
