// tslint:disable
/**
 * ADMIN API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface ProcessingState
 */
export interface ProcessingState {
    /**
     * The overall state of the processing.
     * @type {string}
     * @memberof ProcessingState
     */
    state?: ProcessingStateStateEnum;
    /**
     * The number of processed entries.
     * @type {number}
     * @memberof ProcessingState
     */
    processed?: number;
    /**
     * The number of entries to be processed.
     * @type {number}
     * @memberof ProcessingState
     */
    maximum?: number;
    /**
     * The duration of either the currrent or a previously completed execution in milliseconds.
     * @type {number}
     * @memberof ProcessingState
     */
    duration?: number;
    /**
     * The last time the processing was started.
     * @type {string}
     * @memberof ProcessingState
     */
    started?: string;
    /**
     * The estimated time to completion in milliseconds.
     * @type {number}
     * @memberof ProcessingState
     */
    etc?: number;
}

/**
 * @export
 * @enum {string}
 */
export enum ProcessingStateStateEnum {
    Active = 'ACTIVE',
    Success = 'SUCCESS',
    Error = 'ERROR'
}

