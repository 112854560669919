import { RefObject, useEffect, useRef, useState } from 'react'
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Highlight,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  List,
  ListIcon,
  ListItem,
  Spinner,
  Text,
  useOutsideClick,
  VStack,
} from '@chakra-ui/react'
import { ArrowDownIcon, ArrowUpIcon, SearchIcon } from '@chakra-ui/icons'
import { KnowledgeBaseApi } from '@/api/apis'
import { from } from 'rxjs'
import { Just, Maybe } from 'purify-ts'
import { SearchResult } from '@/_clients/admin'
import { AiOutlineFontSize } from 'react-icons/ai'
import { useController, useFormContext } from 'react-hook-form'
import { UseControllerProps } from 'react-hook-form/dist/types'
import { useParams } from 'react-router'

type SearchInputProps = {
  label: string
} & UseControllerProps

const rowHeight = 90
const rowWidth = 600
const rowNum = 6

const SearchInput = ({ name, control, label, rules, ...props }: SearchInputProps) => {
  const { setValue, setFocus } = useFormContext()
  const {
    field: { ref },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules,
  })

  const instance = useParams<'instance'>().instance || ''
  const [lastSearch, setLastSearch] = useState('')

  const [inputFocused, setInputFocused] = useState<boolean>(false)
  const wrapperRef = useRef(null)

  const [query, setQuery] = useState<string>('')
  const [results, setResults] = useState<Maybe<Array<SearchResult | string>>>(Maybe.empty())
  const [loading, setLoading] = useState<boolean>(false)
  const [itemsToRender, setItemsToRender] = useState<number>(10)
  const resultListRef = useRef<HTMLUListElement>(null)
  const [resLength, setResLength] = useState(0)
  const [isListOpen, setIsListOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState<SearchResult | string | null>(null)
  const [highlightedIndex, setHighlightedIndex] = useState<number>(0)
  const [highlightedRef, setHighlightedRef] = useState<RefObject<HTMLLIElement> | null>(null)

  const showList = (status: boolean) => {
    setIsListOpen(status)
    setInputFocused(status)
  }

  useOutsideClick({
    ref: wrapperRef,
    handler: () => {
      showList(false)
    },
  })

  useEffect(() => {
    fetchListItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, instance])

  const fetchListItems = () => {
    if (query && query !== '') {
      setLoading(true)

      const results$ = from(KnowledgeBaseApi.searchKnowledgeBase({ instance, q: query }))
      const subscription = results$.subscribe({
        next: (res) => {
          if (selectedItem && typeof selectedItem !== 'string') {
            setResults(
              Just([selectedItem, query, ...res.filter((meaning) => meaning.meaning.id !== selectedItem.meaning.id)])
            )
            setResLength(res.length + 2)
          } else {
            setResults(Just([query, ...res]))
            setResLength(res.length + 1)
          }
          setLoading(false)
          setIsListOpen(true)
        },
        error: (err) => {
          console.error(err)
          setLoading(false)
          setIsListOpen(false)
        },
      })
      return () => subscription.unsubscribe()
    } else {
      setResLength(0)
      setResults(Maybe.empty())
      setHighlightedIndex(0)
      setLoading(false)
      setIsListOpen(false)
      return () => {}
    }
  }

  const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = event.target.value
    setItemsToRender(10)
    setQuery(newQuery)
    setHighlightedIndex(0)
    scrollUp()
    showList(true)
  }

  const selectItem = (selectedItem: SearchResult | string) => {
    setSelectedItem(selectedItem)
    setLastSearch(query)

    setFocus(name)

    const formValue = selectedItem
    if (formValue) {
      setValue(name, formValue)
    }

    if (typeof selectedItem !== 'string') {
      setQuery(selectedItem?.meaning.value as string)
    } else {
      setQuery(selectedItem)
    }
    setResults(Maybe.empty())

    showList(false)

    highlightedRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }

  const handleKeyBoardSelect = () => {
    if (results.isJust()) {
      const itemToSelect = results.extract()[highlightedIndex]
      selectItem(itemToSelect)
    }
  }

  const handleFocus = () => {
    setQuery(lastSearch)
  }

  const handleItemMouseEnter = (index: number) => {
    setHighlightedIndex(index)
    const newRef = resultListRef.current?.querySelector(`li:nth-child(${index + 1})`) as HTMLLIElement | null
    setHighlightedRef(newRef ? { current: newRef } : null)
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault()
        const newDownIndex = Math.min(resLength - 1, highlightedIndex + 1)
        const newDownRef = resultListRef.current?.querySelector(
          `li:nth-child(${newDownIndex + 1})`
        ) as HTMLLIElement | null
        setHighlightedRef(newDownRef ? { current: newDownRef } : null)
        setHighlightedIndex(newDownIndex)
        scroll('down', newDownIndex)
        break
      case 'ArrowUp':
        event.preventDefault()
        const newUpIndex = Math.max(0, highlightedIndex - 1)
        const newUpRef = resultListRef.current?.querySelector(`li:nth-child(${newUpIndex + 1})`) as HTMLLIElement | null
        setHighlightedRef(newUpRef ? { current: newUpRef } : null)
        setHighlightedIndex(newUpIndex)
        scroll('up', newUpIndex)
        break
      case 'Enter':
      case 'NumpadEnter':
        event.preventDefault()
        if (results.isJust()) {
          handleKeyBoardSelect()
        }
        break
      case 'Tab': {
        showList(false)
        break
      }
      case 'Escape':
        event.preventDefault()
        setIsListOpen(false)
    }
  }

  const scroll = (direction: 'up' | 'down', index: number) => {
    // if highlightedRef and resultListRef exist
    if (highlightedRef?.current && resultListRef?.current) {
      const listNode = resultListRef.current
      // calculate the offset of the highlighted item from the top of the list
      const itemOffsetTop = index * rowHeight

      // if the highlighted item is at the bottom of the visible list, scroll down 60px
      if (itemOffsetTop - listNode.scrollTop >= rowHeight * (rowNum - 1) && direction === 'down') {
        listNode.scrollTop = itemOffsetTop - rowHeight * (rowNum - 1)
      }
      // if the highlighted item is at the top of the visible list, scroll up 60px
      else if (itemOffsetTop + rowHeight <= listNode.scrollTop && direction === 'up') {
        listNode.scrollTop = itemOffsetTop
      }
    }
  }

  const scrollUp = () => {
    if (resultListRef?.current) {
      const listNode = resultListRef.current
      listNode.scrollTop = 0
    }
  }

  const handleLoadMore = () => {
    const list = resultListRef.current
    if (list && list.scrollTop + list.clientHeight >= list.scrollHeight) {
      setItemsToRender(itemsToRender + 10)
    }
  }

  return (
    <Box position="relative" w={200} ref={wrapperRef}>
      <FormControl w={180} id={name} isInvalid={!!error}>
        <FormLabel>{label}</FormLabel>
        <InputGroup>
          <InputLeftElement pointerEvents="none" children={<SearchIcon />} />
          <Input
            name={name}
            type="text"
            placeholder="…meaning"
            value={query}
            onChange={handleQueryChange}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            ref={ref}
            {...props}
          />
          {loading && <InputRightElement children={<Spinner />} />}
          {!loading && typeof selectedItem === 'string' && <InputRightElement children={<AiOutlineFontSize />} />}
        </InputGroup>
        <FormErrorMessage>{error && error.message}</FormErrorMessage>
      </FormControl>
      {isListOpen &&
        inputFocused &&
        results.caseOf({
          Just: (res) => (
            <List
              id="meaning-list"
              ref={resultListRef}
              maxH={rowHeight * rowNum}
              w={rowWidth}
              overflowY="scroll"
              onScroll={handleLoadMore}
              top={78}
              position="absolute"
              zIndex="dropdown"
              bgColor="white"
              border={1}
              borderStyle={'solid'}
              borderColor={'var(--chakra-colors-chakra-border-color)'}
              py={'var(--chakra-space-2)'}
              borderRadius="md"
            >
              {res.slice(0, itemsToRender).map((expression, index) => (
                <ListItem
                  key={index}
                  onClick={() => selectItem(expression)}
                  onMouseEnter={() => handleItemMouseEnter(index)}
                  py="2"
                  px="3"
                  display="flex"
                  alignItems="center"
                  bgColor={highlightedIndex === index ? 'gray.100' : 'transparent'}
                  ref={highlightedIndex === index ? highlightedRef : null}
                  h={rowHeight}
                >
                  {typeof expression === 'string' ? (
                    <>
                      <ListIcon as={AiOutlineFontSize} color="orange.500" mr="1" />
                      <Text fontSize="sm" color="blue.500" _before={{ content: `'"'` }} _after={{ content: `'"'` }}>
                        {expression}
                      </Text>
                    </>
                  ) : (
                    <>
                      <VStack spacing={0} align="start" justify="start">
                        <Text fontSize="md" color="blue.500">
                          {expression.meaning?.value}
                        </Text>
                        <HStack spacing={1}>
                          {[
                            expression.preferredExpression,
                            ...expression.expressions.filter(
                              (expr) => expr.value !== expression.preferredExpression.value
                            ),
                          ]
                            .slice(0, 3)
                            .map((expression, index) => (
                              <Flex key={index} gap={1}>
                                {index > 0 && <Text fontSize="xs">|</Text>}
                                <Text fontSize="xs" whiteSpace="nowrap" fontWeight={index === 0 ? 'medium' : 'regular'}>
                                  <Highlight
                                    query={query}
                                    styles={{ px: '0', py: '0', bg: 'yellow.100', opacity: 0.5 }}
                                  >
                                    {`${expression.value}`}
                                  </Highlight>
                                </Text>
                              </Flex>
                            ))}
                        </HStack>
                        <HStack>
                          <ArrowUpIcon color="green.500" mr="1" />
                          <HStack whiteSpace="nowrap">
                            {expression.hierarchicalContext.hypernyms?.slice(0, 3).map((hypernym, index) => (
                              <Text color="green.500" fontSize="xs" key={index}>
                                {hypernym.meaning?.value}
                              </Text>
                            ))}
                          </HStack>
                        </HStack>
                        <HStack>
                          <ArrowDownIcon color="gray.500" mr="1" />
                          <HStack whiteSpace="nowrap">
                            {expression.hierarchicalContext.hyponyms?.slice(0, 3).map((hyponym, index) => (
                              <Text color="gray.500" fontSize="xs" key={index}>
                                {hyponym.meaning?.value}
                              </Text>
                            ))}
                          </HStack>
                        </HStack>
                      </VStack>
                    </>
                  )}
                </ListItem>
              ))}
            </List>
          ),
          Nothing: () => null,
        })}
    </Box>
  )
}

export default SearchInput
