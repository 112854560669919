import { useParams } from 'react-router'
import React, { useEffect, useState } from 'react'
import { PageHeader } from '@/components/PageHeader'
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useBoolean, useToast } from '@chakra-ui/react'
import { StatementCollection as BuildingBlock, Views } from '@/_clients/admin'
import { Just, Maybe } from 'purify-ts'
import { useTranslation } from 'react-i18next'
import { HintsTable } from '@/features/BuildingBlocks/components/HintsTable'
import { MetadataForm } from '@/features/BuildingBlocks/components/MetadataForm'
import { BuildingBlocksApi } from '@/api/apis'
import { delay } from 'rxjs/operators'

export default function BuildingBlocksDetails() {
  const { t } = useTranslation()
  const [buildingBlock, setBuildingBlock] = useState<Maybe<BuildingBlock>>(Maybe.empty())
  const { buildingBlockId = '', instance = '' } = useParams()
  const [, setEdit] = useBoolean(false)
  const [availableViews, setAvailableViews] = useState<Maybe<Views>>(Maybe.empty())
  const toast = useToast()

  useEffect(() => {
    BuildingBlocksApi.getBuildingBlock({ instance, id: buildingBlockId }).subscribe((bb) => {
      setBuildingBlock(Just(bb))
    })
    BuildingBlocksApi.getInstanceBuildingBlockViews({
      instance: instance,
    }).subscribe((views) => setAvailableViews(Just(views)))
  }, [instance, buildingBlockId])

  const ref = React.useRef<HTMLDivElement>(null)

  const putBuildingBlock = (id: string, buildingBlock: BuildingBlock) => {
    BuildingBlocksApi.putBuildingBlock({ instance, id: buildingBlockId, statementCollection: buildingBlock }).subscribe(
      () => reloadBuildingBlock()
    )
  }

  const reloadBuildingBlock = () => {
    BuildingBlocksApi.getBuildingBlock({ instance, id: buildingBlockId })
      .pipe<BuildingBlock>(delay(500))
      .subscribe((bb) => {
        setBuildingBlock(Just(bb))
        toast({
          title: 'BB updated',
          description: `BB ${bb.name} was successfully updated.`,
          status: 'success',
          duration: 5000,
          isClosable: true,
        })
      })
  }

  return (
    <Box overflow="visible" data-cy="bb-details-page">
      <PageHeader title={t('bb.pageTitle')} />

      {buildingBlock.mapOrDefault(
        (buildingBlock: BuildingBlock) => (
          <Tabs size={'md'}>
            <TabList>
              <Tab>Stammdaten</Tab>
              <Tab>Statements</Tab>
            </TabList>
            <TabPanels>
              <TabPanel onClick={setEdit.on} ref={ref}>
                <MetadataForm
                  buildingBlock={buildingBlock}
                  availableViews={availableViews.orDefault({})}
                  putBuildingBlock={putBuildingBlock}
                />
              </TabPanel>
              <TabPanel>
                <HintsTable
                  key={buildingBlock.statements?.length}
                  hints={buildingBlock.statements || []}
                  reload={() => reloadBuildingBlock()}
                />
              </TabPanel>
            </TabPanels>
          </Tabs>
        ),
        <Text>No building block found with such id</Text>
      )}
    </Box>
  )
}
