import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import { Button, Menu, MenuButton, MenuItemOption, MenuList, MenuOptionGroup } from '@chakra-ui/react'
import { TimeLabel } from './types'

interface DatePeriodProps {
  timeLabel: TimeLabel
  getTimeSpan: (start?: string, end?: string) => string
  onPeriodChange: (period: TimeLabel) => void
}

const timeLabelOptions = Object.keys(TimeLabel).map((key) => {
  return { label: TimeLabel[key], value: key }
})

const DatePeriod = ({ timeLabel, getTimeSpan, onPeriodChange }: DatePeriodProps) => {
  return (
    <Menu closeOnSelect={true}>
      {({ isOpen }) => (
        <>
          <MenuButton
            mr={4}
            minW="180px"
            as={Button}
            colorScheme="gray"
            rightIcon={isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
          >
            {`${timeLabel} - ${getTimeSpan()}`}
          </MenuButton>
          <MenuList minWidth="240px">
            <MenuOptionGroup
              defaultValue={TimeLabel.MAX}
              value={timeLabel}
              title="Period"
              type="radio"
              onChange={onPeriodChange}
            >
              {timeLabelOptions.map((option) => (
                <MenuItemOption key={option.label} value={option.label}>
                  {option.label}
                </MenuItemOption>
              ))}
            </MenuOptionGroup>
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default DatePeriod
