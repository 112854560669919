// tslint:disable
/**
 * DEEP.assist proposals
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs'
import { BaseAPI, encodeURI, HttpHeaders, OperationOpts, RawAjaxResponse, throwIfNullOrUndefined } from '../runtime'
import { Proposal, ProposalInput } from '../models'

export interface PostForProposalRequest {
    instance: string;
    proposalInput: ProposalInput;
}

/**
 * no description
 */
export class ProposalApi extends BaseAPI {

    /**
     * DEEP.assist proposal
     */
    postForProposal({ instance, proposalInput }: PostForProposalRequest): Observable<Proposal>
    postForProposal({ instance, proposalInput }: PostForProposalRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Proposal>>
    postForProposal({ instance, proposalInput }: PostForProposalRequest, opts?: OperationOpts): Observable<Proposal | RawAjaxResponse<Proposal>> {
        throwIfNullOrUndefined(instance, 'instance', 'postForProposal');
        throwIfNullOrUndefined(proposalInput, 'proposalInput', 'postForProposal');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Proposal>({
            url: '/instances/{instance}/proposal'.replace('{instance}', encodeURI(instance)),
            method: 'POST',
            headers,
            body: proposalInput,
        }, opts?.responseOpts);
    };

}
