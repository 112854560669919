import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, useToast } from '@chakra-ui/react'
import {
  add,
  format,
  formatDistanceStrict,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  sub,
} from 'date-fns'
import appConfig from '@/constants/appConfig'
import { TimeLabel } from './types'
import DateInput from './DateInput'
import DatePeriod from './DatePeriod'

interface DateRangePickerProps {
  minDate: string
  onApply: (startDate: string, endDate: string) => void
}

const DateRangePicker = ({ onApply, minDate, ...rest }: DateRangePickerProps) => {
  const toast = useToast()
  const maxDate = format(new Date(), appConfig.dateFormat)
  const [selectedPeriod, setSelectedPeriod] = useState(TimeLabel.MAX)
  const [timeLabel, setTimeLabel] = useState(TimeLabel.MAX)
  const [startDate, setStartDate] = useState(minDate || format(new Date(), appConfig.dateFormat))
  const [endDate, setEndDate] = useState(maxDate)
  const [originalStartDate, setOriginalStartDate] = useState(startDate)
  const [originalEndDate, setOriginalEndDate] = useState(endDate)

  const modifyStartDate = (date: string) => {
    setStartDate(date)
    setOriginalStartDate(date)
  }

  const modifyEndDate = (date: string) => {
    setEndDate(date)
    setOriginalEndDate(date)
  }

  const handleStartDate = (newStartDate: string) => {
    if (new Date(newStartDate) > new Date()) {
      setStartDate(originalStartDate)
      toast({
        title: 'Start date',
        description: 'Start date cannot exceed the current date',
        status: 'error',
        duration: 5000,
        isClosable: true,
      })
    } else if (new Date(newStartDate) > new Date(endDate)) {
      toast({
        title: 'Start date',
        description: 'Start date exceeded the end date. We have adjusted the end date accordingly.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
      modifyEndDate(newStartDate)
    } else if (new Date(newStartDate) < new Date(minDate)) {
      toast({
        title: 'Start date',
        description: `Start date cannot be lower than ${minDate}.`,
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
      modifyStartDate(minDate)
    }
  }

  const handleEndDate = (newEndDate: string) => {
    if (new Date(newEndDate) > new Date()) {
      setEndDate(originalEndDate)
      toast({
        title: 'End date',
        description: 'Start date cannot exceed the current date. We have adjusted the end date accordingly.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
      modifyEndDate(maxDate)
    } else if (new Date(newEndDate) < new Date(startDate)) {
      toast({
        title: 'End date',
        description: 'End date is lower than the start date. We have adjusted the start date accordingly.',
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
      modifyStartDate(newEndDate)
      modifyEndDate(newEndDate)
    } else if (new Date(newEndDate) < new Date(minDate)) {
      toast({
        title: 'End date',
        description: `End date cannot be lower than ${minDate}. We have adjusted both start date and end date accordingly.`,
        status: 'warning',
        duration: 5000,
        isClosable: true,
      })
      modifyStartDate(minDate)
      modifyEndDate(minDate)
    }
  }

  const handlePeriodChange = (period: TimeLabel) => {
    const today = new Date()
    try {
      const before = getBeforeDate(period)
      setSelectedPeriod(period)
      modifyEndDate(format(today, appConfig.dateFormat))
      modifyStartDate(format(before, appConfig.dateFormat))
    } catch {
      console.log('invalid date')
    }
  }

  const getBeforeDate = (period: string): Date => {
    let date: Date
    const today = new Date()
    switch (period) {
      case TimeLabel.TODAY:
        date = today
        break
      case TimeLabel.CURRENT_WEEK:
        date = startOfWeek(today, { weekStartsOn: 1 })
        break
      case TimeLabel.CURRENT_MONTH:
        date = startOfMonth(today)
        break
      case TimeLabel.CURRENT_QUARTER:
        date = startOfQuarter(today)
        break
      case TimeLabel.CURRENT_YEAR:
        date = startOfYear(today)
        break
      case TimeLabel.MAX:
        date = new Date(minDate)
        break
      default:
        date = today
        break
    }
    return date
  }

  const getTimeSpan = (start?: string, end?: string) => {
    if (start && end) {
      return formatDistanceStrict(new Date(start), sub(new Date(end), { days: 1 }), { unit: 'day' })
    } else {
      return formatDistanceStrict(new Date(startDate), add(new Date(endDate), { days: 1 }), { unit: 'day' })
    }
  }

  useEffect(() => {
    const today = new Date()
    if (endDate !== maxDate) setTimeLabel(TimeLabel.CUSTOM)
    else {
      let calculatedPeriod: Array<TimeLabel> = []
      if (startDate === endDate) calculatedPeriod.push(TimeLabel.TODAY)
      if (startDate === format(startOfWeek(today, { weekStartsOn: 1 }), appConfig.dateFormat))
        calculatedPeriod.push(TimeLabel.CURRENT_WEEK)
      if (startDate === format(startOfMonth(today), appConfig.dateFormat))
        calculatedPeriod.push(TimeLabel.CURRENT_MONTH)
      if (startDate === format(startOfQuarter(today), appConfig.dateFormat))
        calculatedPeriod.push(TimeLabel.CURRENT_QUARTER)
      if (startDate === format(startOfYear(today), appConfig.dateFormat)) calculatedPeriod.push(TimeLabel.CURRENT_YEAR)
      if (startDate === minDate) calculatedPeriod.push(TimeLabel.MAX)
      if (calculatedPeriod.length === 0) setTimeLabel(TimeLabel.CUSTOM)
      else {
        if (calculatedPeriod.includes(selectedPeriod)) setTimeLabel(selectedPeriod)
        else setTimeLabel(calculatedPeriod.at(-1) || TimeLabel.CUSTOM)
      }
    }
  }, [endDate, maxDate, minDate, selectedPeriod, startDate])

  const onApplyClick = () => {
    onApply(startDate, endDate)
  }

  return (
    <Box {...rest}>
      <Flex align="center" mb={8} mt={4}>
        <DatePeriod timeLabel={timeLabel} getTimeSpan={getTimeSpan} onPeriodChange={handlePeriodChange} />
        <DateInput
          label="Start date"
          dateValue={startDate}
          minDate={minDate}
          maxDate={maxDate}
          onDateChange={(date: string) => {
            setStartDate(date)
            handleStartDate(date)
          }}
        />

        <DateInput
          label="End date"
          dateValue={endDate}
          minDate={minDate}
          maxDate={maxDate}
          onDateChange={(date: string) => {
            setEndDate(date)
            handleEndDate(date)
          }}
        />
        <Button minW="180px" onClick={onApplyClick} colorScheme="orange">
          Apply
        </Button>
      </Flex>
    </Box>
  )
}

export default DateRangePicker
