import { Box, Button, Text } from '@chakra-ui/react'
import { PageHeader } from '@/components/PageHeader'
import * as React from 'react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, Outlet } from 'react-router-dom'
import { format, sub } from 'date-fns'
import appConfig from '@/constants/appConfig'
import DateRangePicker from '@/components/inputs/DateRangePicker'
import { useOutletContext, useParams } from 'react-router'
import { FaArrowLeft } from 'react-icons/fa'

export default function ApiLoggingPage() {
  const { t } = useTranslation()
  const { apiKey = '' } = useParams()
  const [minDate, setMinDate] = useState(format(sub(new Date(), { years: 1, days: -1 }), appConfig.dateFormat))
  const [maxDate, setMaxDate] = useState(format(new Date(), appConfig.dateFormat))

  function handleDateRangeChange(start: string, end: string) {
    setMinDate(start)
    setMaxDate(end)
  }

  return (
    <Box data-cy="api-logging-page" h="100vh">
      <PageHeader title={t('apiLogging.pageTitle')} />
      {apiKey && (
        <>
          <Button as={Link} to={'./'} colorScheme={'teal'} leftIcon={<FaArrowLeft />} mt={4} mb={8}>
            Back to API Logging
          </Button>
          <Text mb={8}>
            Selected key:{' '}
            <Text as={'span'} fontWeight={'semibold'}>
              {apiKey}
            </Text>
          </Text>
        </>
      )}

      <DateRangePicker onApply={handleDateRangeChange} minDate={minDate} />
      <Outlet context={{ minDate, maxDate }} />
    </Box>
  )
}

type ContextType = {
  minDate: string
  maxDate: string
}
export function useApiLoggingDates() {
  return useOutletContext<ContextType>()
}
