// tslint:disable
/**
 * DEEP.assist proposals
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * The scoring algorithm for business cases in a proposal  \"recall\": Measures how many of the recgonized hints are present in a business. Higher values are better. Hint boostings are applied. 
 * @export
 * @enum {string}
 */
export enum Scoring {
    Recall = 'recall'
}

