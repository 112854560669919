import React, { ChangeEvent, KeyboardEvent } from 'react'
import { MeaningExpression } from '@/_clients/admin'
import {
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  List,
  ListItem,
  Text,
  VStack,
} from '@chakra-ui/react'
import { AddIcon, SmallCloseIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router'
import { getKnowledgeGraphPath } from '@/pages/knowledge-graph/knowledgeGraph.paths'

interface Props {
  instance: string
  title: string
  listItems: Array<MeaningExpression>
  addItem: Function
  deleteItem: Function
  resetStrategy: Function
}

const SemanticList = ({ instance, title, listItems, deleteItem, addItem, resetStrategy }: Props) => {
  const navigate = useNavigate()
  const [tempItem, setTempItem] = React.useState<MeaningExpression>({
    meaning: { value: '' },
    expression: { value: '' },
  })
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTempItem({ meaning: { value: e.target.value }, expression: { value: e.target.value } })
  }
  const reset = () => {
    setTempItem({ meaning: { value: '' }, expression: { value: '' } })
  }

  const keyPress = (e: KeyboardEvent<HTMLInputElement>) => {
    if ((e.code === 'Enter' || e.code === 'NumpadEnter') && tempItem.meaning?.value !== '') {
      addItem(tempItem)
      reset()
    }
  }

  return (
    <VStack data-cy={`semantic-list-${title}`} border="1px solid black" w="calc(calc(100vw - 320px)/6)" minW="250px">
      <Heading as="h4" size="sm">
        {title}
      </Heading>
      <VStack w="100%">
        <List className="sem-list" h="400px" w="100%" overflowY="scroll">
          {listItems.map((item, index) => {
            return (
              <ListItem key={`${item.meaning?.value}-${index}`} m={1} bg={index % 2 === 0 ? 'gray.100' : 'white'}>
                <HStack justify="space-between">
                  <VStack
                    spacing={0}
                    align="start"
                    onClick={() => {
                      resetStrategy()
                      navigate(
                        getKnowledgeGraphPath(
                          instance,
                          item.meaning === undefined || item.expression === undefined
                            ? ''
                            : `meaningId=${item.meaning.id}&meaningValue=${item.meaning.value}&expressionId=${
                                item.expression.id
                              }&expressionValue=${item.expression.value!}`
                        )
                      )
                    }}
                    cursor={'pointer'}
                  >
                    <Text color={'blue.500'}>{item.meaning?.value}</Text>
                    <Text color={'orange.400'}>{item.expression?.value}</Text>
                  </VStack>
                  <IconButton
                    aria-label="delete"
                    variant="unstyled"
                    onClick={() => {
                      deleteItem(item)
                    }}
                    icon={<SmallCloseIcon />}
                  />
                </HStack>
              </ListItem>
            )
          })}
        </List>
        <InputGroup>
          <Input
            value={tempItem.meaning?.value}
            onChange={handleChange}
            onKeyDown={keyPress}
            variant="filled"
            placeholder="..."
            pl={2}
          />
          <InputRightElement
            children={
              <AddIcon
                onClick={() => {
                  addItem(tempItem)
                  reset()
                }}
                color="green.500"
                cursor="pointer"
              />
            }
          />
        </InputGroup>
      </VStack>
    </VStack>
  )
}
export default SemanticList
