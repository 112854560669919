import { Box, Flex, VStack } from '@chakra-ui/react'
import { addHours, getHours, getMinutes, getSeconds } from 'date-fns'
import * as React from 'react'
import { useParams } from 'react-router'
import { filter, switchMap } from 'rxjs/operators'
import { InstancesApi } from '@/api/apis'
import { CoreConfigurations, CoreHealth, InstanceConfiguration } from '@/_clients/admin'
import { PageHeader } from '@/components/PageHeader'
import { Card } from '@/pages/api-keys/components/card/Card'
import { CardContent } from '@/pages/api-keys/components/card/CardContent'
import { CardHeader } from '@/pages/api-keys/components/card/CardHeader'
import { Property } from '@/pages/api-keys/components/card/Property'

const stickyHeight = 80
const MINUTES_IN_HOUR = 60

export const convertToDuration = (milliseconds: number) => {
  const normalizeTime = (time: string): string => (time.length === 1 ? `0${time}` : time)

  const date = new Date(milliseconds)
  const timezoneDiff = date.getTimezoneOffset() / MINUTES_IN_HOUR
  const dateWithoutTimezoneDiff = addHours(date, timezoneDiff)

  const hours = normalizeTime(String(getHours(dateWithoutTimezoneDiff)))
  const minutes = normalizeTime(String(getMinutes(dateWithoutTimezoneDiff)))
  const seconds = normalizeTime(String(getSeconds(dateWithoutTimezoneDiff)))

  const hoursOutput = hours !== '00' ? `${hours}:` : '00:'

  return `${hoursOutput}${minutes}:${seconds}`
}

export const ConfigurationPage = () => {
  const instance = useParams<'instance'>().instance || ''
  const [instanceConfiguration, setInstanceConfiguration] = React.useState<InstanceConfiguration | null>(null)
  const [coreHealth, setCoreHealth] = React.useState<CoreHealth | null>(null)
  React.useEffect(() => {
    setInstanceConfiguration(null)
    setCoreHealth(null)

    InstancesApi.getInstanceConfiguration({ instance }).subscribe((instanceConfiguration) =>
      setInstanceConfiguration(instanceConfiguration)
    )
    InstancesApi.getCoreConfigurations({ instance, start: 0, count: 1 })
      .pipe(
        filter(
          (coreConfigurations) =>
            coreConfigurations !== undefined &&
            coreConfigurations.items !== undefined &&
            coreConfigurations.items.length > 0 &&
            coreConfigurations.items[0].key !== undefined
        ),
        switchMap((coreConfigurations: CoreConfigurations) => {
          const firstConfiguration = coreConfigurations.items![0]
          return InstancesApi.getCoreConfigurationHealthLanguageModel({ instance, key: firstConfiguration.key! })
        })
      )
      .subscribe(setCoreHealth)
  }, [instance])

  return (
    <Box data-cy="configuration-page">
      <PageHeader title="Configuration" />
      <Flex width="full" wrap="nowrap" pt={`${stickyHeight + 3}px`} pl={4}>
        <VStack spacing={12} align="left" pb={12}>
          {instanceConfiguration && (
            <Card maxW="3xl" minWidth={750}>
              <CardHeader title="DEEP.assist instance" />
              <CardContent>
                <Property label="Id" value={instanceConfiguration.id} />
                <Property label="Username" value={instanceConfiguration.username} />
                <Property label="Storage URI" value={instanceConfiguration.storageUri} />
                <Property label="Delphi URI" value={instanceConfiguration.delphiUri} />
                <Property label="Search URI" value={instanceConfiguration.searchUri} />
              </CardContent>
            </Card>
          )}

          {coreHealth && (
            <Card maxW="3xl" minWidth={750}>
              <CardHeader title="Core (via Kubernetes cluster)" />
              <CardContent>
                <Property label="Status" value={coreHealth.status} />
              </CardContent>
            </Card>
          )}

          {coreHealth && coreHealth.details && (
            <Card maxW="3xl" minWidth={750}>
              <CardHeader title="Core language model" />
              <CardContent>
                <Property label="Created" value={coreHealth.details.created} />
                <Property label="Duration" value={convertToDuration(coreHealth.details.duration || 0)} />
                <Property label="Topics count" value={coreHealth.details.topics} />
                <Property label="Statements count" value={coreHealth.details.statements} />
                <Property label="Hints count" value={coreHealth.details.hints} />
                <Property label="Spellings count" value={coreHealth.details.spellings} />
                <Property label="Errors count" value={coreHealth.details.error || 0} />
              </CardContent>
            </Card>
          )}
        </VStack>
      </Flex>
    </Box>
  )
}
