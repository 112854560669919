import produce from 'immer'
import { Maybe } from 'purify-ts'
import { AsyncErrorAction } from '@/store/state/AsyncAction'
import { CoreConfigurations } from '@/_clients/admin'
import { ApiKeysAction, ApiKeysActionKeys, ApiKeysLoadSuccessAction } from './apiKeys.actions'

export interface ApiKeysState {
  coreConfigurations: Maybe<CoreConfigurations>
}

export const initialState: ApiKeysState = {
  coreConfigurations: Maybe.empty(),
}

export const apiKeysReducer = (state: ApiKeysState = initialState, action: ApiKeysAction): ApiKeysState => {
  return produce(state, (draft: ApiKeysState) => {
    switch (action.type) {
      case ApiKeysActionKeys.LOAD_SUCCESS: {
        const successAction = action as ApiKeysLoadSuccessAction
        draft.coreConfigurations = Maybe.of(successAction.coreConfigurations)
        return
      }
      case ApiKeysActionKeys.LOAD_ERROR: {
        const errorAction = action as AsyncErrorAction
        console.error('The following error occurred when trying to retrieve API keys:')
        console.error(errorAction.error)
        return
      }
    }
  })
}
