import { Epic, ofType } from 'redux-observable'
import * as Rx from 'rxjs'
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators'
import appConfig from '../../../constants/appConfig'
import { RootAction, RootState, Services } from '@/types'
import { Editor, Editors, View, Views } from '@/_clients/admin'
import {
  BuildingBlocksAction,
  BuildingBlocksActionKeys,
  BusinessCasesLoadAction,
  FilterInventoriesLoadAction,
  loadBusinessCasesError,
  loadBusinessCasesSuccess,
  loadFilterInventoriesError,
  loadFilterInventoriesSuccess,
  loadPredictiveError,
  loadPredictiveSuccess,
  PredictiveLoadAction,
} from './buildingBlocks.actions'

interface BuildingBlocksEpics extends Epic<RootAction, BuildingBlocksAction, RootState, Services> {}

const loadBuildingBlocks: BuildingBlocksEpics = (action$, state$, { BuildingBlocksApi }) => {
  return action$.pipe(
    ofType<BusinessCasesLoadAction>(BuildingBlocksActionKeys.LOAD),
    debounceTime(appConfig.debounceTime),
    switchMap((action) => {
      return BuildingBlocksApi.listBuildingBlocksInfos({
        instance: action.instance,
        statementCollectionFilter: action.filters,
      }).pipe(
        map((businessCases) => loadBusinessCasesSuccess(businessCases)),
        catchError((error) => Rx.of(loadBusinessCasesError(error)))
      )
    })
  )
}

const reShapeInventoryAnswers = (inventoryItem: Views | Editors): string[] =>
  inventoryItem.elements ? inventoryItem.elements.map((elem: View | Editor) => (elem.name ? elem.name : '')) : []

const loadFilterInventories$: BuildingBlocksEpics = (action$, state$, { BuildingBlocksApi }) => {
  return action$.pipe(
    ofType<FilterInventoriesLoadAction>(BuildingBlocksActionKeys.FILTER_INVENTORIES_LOAD),
    switchMap((action) => {
      const { instance } = action
      return Rx.forkJoin({
        editors: BuildingBlocksApi.getInstanceBuildingBlockEditors({ instance }).pipe(
          map((editorInventoryItem) => reShapeInventoryAnswers(editorInventoryItem)) // catchError((error) => Rx.of(loadFilterInventoriesError(error)))
        ),
        views: BuildingBlocksApi.getInstanceBuildingBlockViews({ instance }).pipe(
          map((viewInventoryItem) => reShapeInventoryAnswers(viewInventoryItem)) // catchError((error) => Rx.of(loadFilterInventoriesError(error)))
        ),
      }).pipe(
        map((filterInventories) => loadFilterInventoriesSuccess(filterInventories)),
        catchError((error) => Rx.of(loadFilterInventoriesError(error)))
      )
    })
  )
}

const loadPredictive$: BuildingBlocksEpics = (action$, state$, { BuildingBlocksApi }) => {
  return action$.pipe(
    ofType<PredictiveLoadAction>(BuildingBlocksActionKeys.PREDICTIVE_LOAD),
    debounceTime(appConfig.debounceTime),
    switchMap((action) => {
      return BuildingBlocksApi.getBuildingBlockFilters({
        instance: action.instance,
        type: action.requestType,
        input: action.inputText,
      }).pipe(
        map((payload) => loadPredictiveSuccess(action.requestType, payload)),
        catchError((error) => Rx.of(loadPredictiveError(error)))
      )
    })
  )
}

export const buildingBlocksEpics = [loadBuildingBlocks, loadFilterInventories$, loadPredictive$]
