import styled from '@emotion/styled'
import { keyframes } from '@emotion/react'
import { Box } from '@chakra-ui/react'

const bounce = keyframes`
  0%, 80%, 100% { transform: scale(0); }
  40% { transform: scale(1.0); }
`

const Dot = styled.div`
  background-color: gray;
  border-radius: 50%;
  width: 6px;
  height: 6px;
  margin: 0 2px;
  animation: ${bounce} 1.4s infinite ease-in-out both;

  &:nth-of-type(1) {
    animation-delay: -0.32s;
  }

  &:nth-of-type(2) {
    animation-delay: -0.16s;
  }
`

interface TypingAnimationProps {
  isTyping: boolean
}

const TypingAnimation: React.FC<TypingAnimationProps> = ({ isTyping }) => {
  if (!isTyping) return null

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Dot />
      <Dot />
      <Dot />
    </Box>
  )
}

export default TypingAnimation
