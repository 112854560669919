// tslint:disable
/**
 * ADMIN API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.5.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs'
import {
  BaseAPI,
  encodeURI,
  HttpHeaders,
  HttpQuery,
  OperationOpts,
  RawAjaxResponse,
  throwIfNullOrUndefined,
} from '../runtime'
import {
  Editors,
  Statement,
  StatementAdd,
  StatementCollection,
  StatementCollectionFilter,
  StatementCollectionFilterType,
  StatementCollectionInfos,
  StatementImportResult,
  Views,
} from '../models'

export interface DeleteTopicOfInterestRequest {
    instance: string;
    id: string;
}

export interface GetInstanceTopicsOfInterestEditorsRequest {
    instance: string;
}

export interface GetInstanceTopicsOfInterestViewsRequest {
    instance: string;
}

export interface GetTopicOfInterestRequest {
    instance: string;
    id: string;
}

export interface GetTopicsOfInterestFiltersRequest {
    instance: string;
    type: StatementCollectionFilterType;
    input: string;
}

export interface ListTopicsOfInterestRequest {
    instance: string;
    statementCollectionFilter: StatementCollectionFilter;
}

export interface PostTopicOfInterestRequest {
    instance: string;
    statementCollection: StatementCollection;
}

export interface PostTopicsOfInterestStatementsRequest {
    instance: string;
    id: string;
    statementAdd: StatementAdd;
}

export interface PostTopicsOfInterestStatementsFileRequest {
    instance: string;
    id: string;
    forceImport?: boolean;
    file?: string;
}

export interface PutTopicOfInterestRequest {
    instance: string;
    id: string;
    statementCollection: StatementCollection;
}

/**
 * no description
 */
export class TopicsOfInterestApi extends BaseAPI {

    /**
     */
    deleteTopicOfInterest({ instance, id }: DeleteTopicOfInterestRequest): Observable<void>
    deleteTopicOfInterest({ instance, id }: DeleteTopicOfInterestRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteTopicOfInterest({ instance, id }: DeleteTopicOfInterestRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(instance, 'instance', 'deleteTopicOfInterest');
        throwIfNullOrUndefined(id, 'id', 'deleteTopicOfInterest');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<void>({
            url: '/instances/{instance}/topics-of-interest/{id}'.replace('{instance}', encodeURI(instance)).replace('{id}', encodeURI(id)),
            method: 'DELETE',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * The editors of the Topic of Interests of the given DEEP.assist instance.
     */
    getInstanceTopicsOfInterestEditors({ instance }: GetInstanceTopicsOfInterestEditorsRequest): Observable<Editors>
    getInstanceTopicsOfInterestEditors({ instance }: GetInstanceTopicsOfInterestEditorsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Editors>>
    getInstanceTopicsOfInterestEditors({ instance }: GetInstanceTopicsOfInterestEditorsRequest, opts?: OperationOpts): Observable<Editors | RawAjaxResponse<Editors>> {
        throwIfNullOrUndefined(instance, 'instance', 'getInstanceTopicsOfInterestEditors');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Editors>({
            url: '/instances/{instance}/topics-of-interest-editors'.replace('{instance}', encodeURI(instance)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * The views used by Topic of Interests of the given DEEP.assist instance.
     */
    getInstanceTopicsOfInterestViews({ instance }: GetInstanceTopicsOfInterestViewsRequest): Observable<Views>
    getInstanceTopicsOfInterestViews({ instance }: GetInstanceTopicsOfInterestViewsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Views>>
    getInstanceTopicsOfInterestViews({ instance }: GetInstanceTopicsOfInterestViewsRequest, opts?: OperationOpts): Observable<Views | RawAjaxResponse<Views>> {
        throwIfNullOrUndefined(instance, 'instance', 'getInstanceTopicsOfInterestViews');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Views>({
            url: '/instances/{instance}/topics-of-interest-views'.replace('{instance}', encodeURI(instance)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     * Retrieves a Topic of Interest.
     */
    getTopicOfInterest({ instance, id }: GetTopicOfInterestRequest): Observable<StatementCollection>
    getTopicOfInterest({ instance, id }: GetTopicOfInterestRequest, opts?: OperationOpts): Observable<RawAjaxResponse<StatementCollection>>
    getTopicOfInterest({ instance, id }: GetTopicOfInterestRequest, opts?: OperationOpts): Observable<StatementCollection | RawAjaxResponse<StatementCollection>> {
        throwIfNullOrUndefined(instance, 'instance', 'getTopicOfInterest');
        throwIfNullOrUndefined(id, 'id', 'getTopicOfInterest');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<StatementCollection>({
            url: '/instances/{instance}/topics-of-interest/{id}'.replace('{instance}', encodeURI(instance)).replace('{id}', encodeURI(id)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    getTopicsOfInterestFilters({ instance, type, input }: GetTopicsOfInterestFiltersRequest): Observable<Array<string>>
    getTopicsOfInterestFilters({ instance, type, input }: GetTopicsOfInterestFiltersRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<string>>>
    getTopicsOfInterestFilters({ instance, type, input }: GetTopicsOfInterestFiltersRequest, opts?: OperationOpts): Observable<Array<string> | RawAjaxResponse<Array<string>>> {
        throwIfNullOrUndefined(instance, 'instance', 'getTopicsOfInterestFilters');
        throwIfNullOrUndefined(type, 'type', 'getTopicsOfInterestFilters');
        throwIfNullOrUndefined(input, 'input', 'getTopicsOfInterestFilters');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = { // required parameters are used directly since they are already checked by throwIfNullOrUndefined
            'type': type,
            'input': input,
        };

        return this.request<Array<string>>({
            url: '/instances/{instance}/topics-of-interest-filters'.replace('{instance}', encodeURI(instance)),
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     * Returns TopicOfInterestInfos.
     */
    listTopicsOfInterest({ instance, statementCollectionFilter }: ListTopicsOfInterestRequest): Observable<StatementCollectionInfos>
    listTopicsOfInterest({ instance, statementCollectionFilter }: ListTopicsOfInterestRequest, opts?: OperationOpts): Observable<RawAjaxResponse<StatementCollectionInfos>>
    listTopicsOfInterest({ instance, statementCollectionFilter }: ListTopicsOfInterestRequest, opts?: OperationOpts): Observable<StatementCollectionInfos | RawAjaxResponse<StatementCollectionInfos>> {
        throwIfNullOrUndefined(instance, 'instance', 'listTopicsOfInterest');
        throwIfNullOrUndefined(statementCollectionFilter, 'statementCollectionFilter', 'listTopicsOfInterest');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<StatementCollectionInfos>({
            url: '/instances/{instance}/topics-of-interest-infos'.replace('{instance}', encodeURI(instance)),
            method: 'POST',
            headers,
            body: statementCollectionFilter,
        }, opts?.responseOpts);
    };

    /**
     * Creates a new Topic Of Interest.
     */
    postTopicOfInterest({ instance, statementCollection }: PostTopicOfInterestRequest): Observable<void>
    postTopicOfInterest({ instance, statementCollection }: PostTopicOfInterestRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    postTopicOfInterest({ instance, statementCollection }: PostTopicOfInterestRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(instance, 'instance', 'postTopicOfInterest');
        throwIfNullOrUndefined(statementCollection, 'statementCollection', 'postTopicOfInterest');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<void>({
            url: '/instances/{instance}/topics-of-interest'.replace('{instance}', encodeURI(instance)),
            method: 'POST',
            headers,
            body: statementCollection,
        }, opts?.responseOpts);
    };

    /**
     * Add a statement to the Topic of Interest
     */
    postTopicsOfInterestStatements({ instance, id, statementAdd }: PostTopicsOfInterestStatementsRequest): Observable<Array<Statement>>
    postTopicsOfInterestStatements({ instance, id, statementAdd }: PostTopicsOfInterestStatementsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<Statement>>>
    postTopicsOfInterestStatements({ instance, id, statementAdd }: PostTopicsOfInterestStatementsRequest, opts?: OperationOpts): Observable<Array<Statement> | RawAjaxResponse<Array<Statement>>> {
        throwIfNullOrUndefined(instance, 'instance', 'postTopicsOfInterestStatements');
        throwIfNullOrUndefined(id, 'id', 'postTopicsOfInterestStatements');
        throwIfNullOrUndefined(statementAdd, 'statementAdd', 'postTopicsOfInterestStatements');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Array<Statement>>({
            url: '/instances/{instance}/topics-of-interest/{id}/statements'.replace('{instance}', encodeURI(instance)).replace('{id}', encodeURI(id)),
            method: 'POST',
            headers,
            body: statementAdd,
        }, opts?.responseOpts);
    };

    /**
     * Import a list of Statements to an existing Topic Of Interest. If an statement is syntactically incorrect, an error with the list of faulty statements is returned. If errors should be ignored, a commit can be forced using request param forceCommit
     */
    postTopicsOfInterestStatementsFile({ instance, id, forceImport, file }: PostTopicsOfInterestStatementsFileRequest): Observable<StatementImportResult>
    postTopicsOfInterestStatementsFile({ instance, id, forceImport, file }: PostTopicsOfInterestStatementsFileRequest, opts?: OperationOpts): Observable<RawAjaxResponse<StatementImportResult>>
    postTopicsOfInterestStatementsFile({ instance, id, forceImport, file }: PostTopicsOfInterestStatementsFileRequest, opts?: OperationOpts): Observable<StatementImportResult | RawAjaxResponse<StatementImportResult>> {
        throwIfNullOrUndefined(instance, 'instance', 'postTopicsOfInterestStatementsFile');
        throwIfNullOrUndefined(id, 'id', 'postTopicsOfInterestStatementsFile');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (forceImport != null) { query['forceImport'] = forceImport; }

        const formData = new FormData();
        if (file !== undefined) { formData.append('file', file as any); }

        return this.request<StatementImportResult>({
            url: '/instances/{instance}/topics-of-interest/{id}/statements/file'.replace('{instance}', encodeURI(instance)).replace('{id}', encodeURI(id)),
            method: 'POST',
            headers,
            query,
            body: formData,
        }, opts?.responseOpts);
    };

    /**
     * Stores a Topic of Interest.
     */
    putTopicOfInterest({ instance, id, statementCollection }: PutTopicOfInterestRequest): Observable<void>
    putTopicOfInterest({ instance, id, statementCollection }: PutTopicOfInterestRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    putTopicOfInterest({ instance, id, statementCollection }: PutTopicOfInterestRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(instance, 'instance', 'putTopicOfInterest');
        throwIfNullOrUndefined(id, 'id', 'putTopicOfInterest');
        throwIfNullOrUndefined(statementCollection, 'statementCollection', 'putTopicOfInterest');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<void>({
            url: '/instances/{instance}/topics-of-interest/{id}'.replace('{instance}', encodeURI(instance)).replace('{id}', encodeURI(id)),
            method: 'PUT',
            headers,
            body: statementCollection,
        }, opts?.responseOpts);
    };

}
