// tslint:disable
/**
 * TESTING API
 * The API to provide Testdata for deepassist and to execute Test Runs
 *
 * The version of the OpenAPI document: 0.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * @export
 * @interface TestRunOverview
 */
export interface TestRunOverview {
    /**
     * The ID of the test run
     * @type {string}
     * @memberof TestRunOverview
     */
    id: string;
    /**
     * The name of the test run instance
     * @type {string}
     * @memberof TestRunOverview
     */
    name: string;
    /**
     * The timestamp when this test run was created. 
     * @type {string}
     * @memberof TestRunOverview
     */
    created: string;
    /**
     * The timestamp when this test run was started. 
     * @type {string}
     * @memberof TestRunOverview
     */
    started?: string;
    /**
     * The timestamp when this test run was finished. 
     * @type {string}
     * @memberof TestRunOverview
     */
    finished?: string;
    /**
     * The duration of the test run in seconds
     * @type {number}
     * @memberof TestRunOverview
     */
    duration?: number;
    /**
     * The status of the test run
     * @type {string}
     * @memberof TestRunOverview
     */
    status: TestRunOverviewStatusEnum;
    /**
     * The total count of testdata included
     * @type {number}
     * @memberof TestRunOverview
     */
    totalTestdataCount: number;
    /**
     * The total amount of successful tests
     * @type {number}
     * @memberof TestRunOverview
     */
    totalSuccessful: number;
    /**
     * The total amount of failed tests
     * @type {number}
     * @memberof TestRunOverview
     */
    totalFailed: number;
    /**
     * The detailed message to the test-run status
     * @type {string}
     * @memberof TestRunOverview
     */
    statusMessage?: string;
}

/**
 * @export
 * @enum {string}
 */
export enum TestRunOverviewStatusEnum {
    Pending = 'PENDING',
    Executing = 'EXECUTING',
    Failed = 'FAILED',
    Finished = 'FINISHED'
}

