import { useColorModeValue } from '@chakra-ui/react'
import { Box } from '@chakra-ui/layout'
import { RedocStandalone } from 'redoc'
import '@/styles/styleOverrideDark.css'
import '@/styles/styleOverrideLight.css'

export const DocumentationPage = () => {
  const currentTheme = useColorModeValue('light', 'dark')
  return (
    <Box
      style={{ marginLeft: 'calc(-1 * var(--chakra-space-4))', marginRight: 'calc(-1 * var(--chakra-space-4))' }}
      data-cy="documentation-page"
    >
      <div style={{ height: '100vh', overflowY: 'auto' }} className={currentTheme}>
        <RedocStandalone
          specUrl="/documentation/deep-assist-api.yaml"
          options={{
            theme: {
              spacing: {
                sectionVertical: 10,
              },
            },
          }}
        />
      </div>
    </Box>
  )
}
