import {
  Badge,
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  Icon,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import { format, parse } from 'date-fns'
import { Maybe } from 'purify-ts'
import { useEffect, useState } from 'react'
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from 'react-icons/fa'
import { AiOutlineClear } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '@/styles/react-datepicker-style-override.css'
import '@/styles/react-datepicker-style-override-light.css'
import '@/styles/react-datepicker-style-override-dark.css'
import appConfig from '../../constants/appConfig'
import {
  StatementCollectionFilterType as TopicOfInterestFilterType,
  StatementCollectionInfo as TopicOfInterestInfo,
  StatementCollectionInfos as TopicOfInterestInfos,
  StatementCollectionSortOrderFieldEnum as SortFieldEnum,
  StatementCollectionSortOrderOrderEnum as SortOrderEnum,
} from '@/_clients/admin'
import { PageHeader } from '@/components/PageHeader'
import { Pagination } from '@/components/Pagination'
import { PredictiveInputWithInlineResults } from '@/components/filters/PredictiveInputWithInlineResults'
import { PredictiveInputWithInlineResultsMulti } from '@/components/filters/PredictiveInputWithInlineResultsMulti'
import { getFilterInventories, getPredictiveResults, getTopicsOfInterest } from './state/buildingBlocks.selectors'
import { FilterInventoriesType, PredictiveResultsType } from './state/buildingBlocks.reducer'
import {
  clearPredictive,
  loadBusinessCases,
  loadFilterInventories,
  loadPredictive,
} from './state/buildingBlocks.actions'
import { FilterMenu } from '@/components/filters/FilterMenu'
import { FiltersType } from '@/components/filters/types'
import { applyFiltersAsQueryParams, readFiltersFromQueryParams } from '@/components/filters/queryParams'
import { useTranslation } from 'react-i18next'
import { getBuildingBlocksPath } from '@/pages/building-blocks/buildingBlocks.paths'

let filtersInitialState: FiltersType = {
  views: [],
  statements: [],
}

interface State {
  activePageIndex: number
  sorting?: {
    field: SortFieldEnum
    isAsc: boolean
  }
  filters: FiltersType
}

let initialState: State = {
  activePageIndex: 0,
  filters: filtersInitialState,
  sorting: {
    field: SortFieldEnum.Name,
    isAsc: true,
  },
}

export default function BuildingBlocksOverview() {
  const dispatch = useDispatch()
  const { t } = useTranslation()

  const filterInventories: Maybe<FilterInventoriesType> = useSelector(getFilterInventories)
  const buildingblocks: Maybe<TopicOfInterestInfos> = useSelector(getTopicsOfInterest)
  const predictiveResults: PredictiveResultsType = useSelector(getPredictiveResults)

  const [state, setState] = useState<State>(initialState)
  const { activePageIndex, filters, sorting } = state
  const setFilters = (filters: FiltersType) => setState({ ...state, filters: filters })

  const instance = useParams<'instance'>().instance || ''

  const location = useLocation()
  const navigate = useNavigate()

  const currentTheme = useColorModeValue('light', 'dark')

  const setSort = (field: SortFieldEnum, isAsc: boolean) =>
    setState({ ...state, sorting: { field: field, isAsc: isAsc } })
  const renderSortActionHeader = (field: SortFieldEnum, title: string) => {
    const isThisFilterActive = state.sorting !== undefined && state.sorting.field === field
    const isAsc = state.sorting?.isAsc || false
    return (
      <HStack
        spacing={1}
        cursor="pointer"
        width="fit-content"
        onClick={() => setSort(field, isThisFilterActive ? !isAsc : true)}
        data-cy={`table-header-${field}`}
      >
        <Text userSelect="none" _hover={{ textDecoration: 'underline' }}>
          {title}
        </Text>
        {isThisFilterActive ? (
          isAsc ? (
            <Icon as={FaArrowAltCircleDown} data-cy="order-icon-asc" />
          ) : (
            <Icon as={FaArrowAltCircleUp} data-cy="order-icon-desc" />
          )
        ) : (
          <></>
        )}
      </HStack>
    )
  }

  useEffect(() => {
    dispatch(
      loadBusinessCases(instance, {
        start: activePageIndex * appConfig.tableItemsPerPage,
        count: appConfig.tableItemsPerPage,
        sort: sorting && {
          field: sorting.field,
          order: sorting.isAsc ? SortOrderEnum.Asc : SortOrderEnum.Desc,
        },
        modified:
          filters.modified &&
          format(parse(filters.modified, appConfig.dateFormat, new Date()), appConfig.apiDateFormat) + 'T00:00:00.000Z',
        editors: filters.editor ? [filters.editor] : undefined,
        views: filters.views,
        names: filters?.name ? [filters.name] : undefined,
        statements: filters?.statements,
      })
    )
  }, [activePageIndex, filters, instance, sorting, dispatch])

  useEffect(() => {
    dispatch(loadFilterInventories(instance))
  }, [instance, dispatch])

  useEffect(() => {
    setState((state) => ({ ...state, viewFilterInputValue: '' }))
  }, [filters.statements])

  useEffect(() => {
    applyFiltersAsQueryParams(filters, location, navigate)
    // eslint-disable-next-line
  }, [filters])

  useEffect(() => {
    setFilters(readFiltersFromQueryParams(location))
    // eslint-disable-next-line
  }, [])

  return (
    <Box data-cy="building-blocks-page">
      <PageHeader title={t('buildingBlocks.pageTitle')} />
      {buildingblocks.mapOrDefault(
        (buildingblock: TopicOfInterestInfos) => (
          <>
            {filterInventories.mapOrDefault(
              (filterInventories: FilterInventoriesType) => (
                <Flex wrap="wrap" width="100%">
                  <FilterMenu
                    clearAction={() => setFilters({ ...filters, modified: undefined })}
                    title={t('buildingBlocks.modified')}
                    value={filters?.modified || ''}
                    noPadding
                  >
                    <div className={currentTheme}>
                      <DatePicker
                        selected={null}
                        onChange={(date: Date) => {
                          setFilters({ ...filters, modified: format(date, appConfig.dateFormat) })
                        }}
                        dateFormat={appConfig.dateFormat}
                        inline
                      />
                    </div>
                  </FilterMenu>
                  <FilterMenu
                    clearAction={() => setFilters({ ...filters, editor: undefined })}
                    title={t('buildingBlocks.editor')}
                    value={filters?.editor || ''}
                    buttonCyId="filter-button-editor"
                    valueCyId="filter-value-editor"
                  >
                    <VStack align="left">
                      {Array.isArray(filterInventories.editors) &&
                        filterInventories.editors.map((editor: any, index: number) => (
                          <Checkbox
                            onChange={(e) =>
                              e.target.checked
                                ? setFilters({ ...filters, editor })
                                : setFilters({ ...filters, editor: undefined })
                            }
                            key={`editor-filter-option-${index}`}
                            isChecked={editor === filters.editor}
                            data-cy={`filter-checkbox-editor-${editor as string}`}
                          >
                            {editor}
                          </Checkbox>
                        ))}
                    </VStack>
                  </FilterMenu>
                  <FilterMenu
                    clearAction={() => setFilters({ ...filters, views: [] })}
                    title={t('buildingBlocks.views')}
                    value={filters?.views?.join(', ') || ''}
                  >
                    <VStack align="left">
                      {Array.isArray(filterInventories.views) &&
                        filterInventories.views.map((view: any, index: number) => (
                          <Checkbox
                            onChange={(e) =>
                              e.target.checked
                                ? setFilters({ ...filters, views: [...filters?.views, view as string] })
                                : setFilters({
                                    ...filters,
                                    views: filters?.views.filter((val) => val !== (view as string)),
                                  })
                            }
                            key={`views-filter-option-${index}`}
                            isChecked={filters.views.includes(view)}
                          >
                            {view}
                          </Checkbox>
                        ))}
                    </VStack>
                  </FilterMenu>
                  <FilterMenu
                    clearAction={() => setFilters({ ...filters, name: undefined })}
                    title={t('buildingBlocks.name')}
                    value={filters?.name || ''}
                    buttonCyId="filter-button-name"
                    valueCyId="filter-value-name"
                  >
                    <PredictiveInputWithInlineResults
                      onChange={(text: string) => setFilters({ ...filters, name: text })}
                      onInputChange={(text: string) => {
                        dispatch(loadPredictive(instance, text, TopicOfInterestFilterType.Name))
                      }}
                      predictedItems={predictiveResults.name.mapOrDefault((name) => name, [])}
                      cyId="filter-input-name"
                    />
                  </FilterMenu>
                  <FilterMenu
                    clearAction={() => setFilters({ ...filters, statements: [] })}
                    title={t('buildingBlocks.hints')}
                    value={filters?.statements ? filters.statements.join(', ') : ''}
                  >
                    <PredictiveInputWithInlineResultsMulti
                      onSelectedValuesChange={(list: string[]) => {
                        setFilters({ ...filters, statements: list })
                        dispatch(clearPredictive(TopicOfInterestFilterType.Statement))
                      }}
                      onInputChange={(text: string) => {
                        dispatch(loadPredictive(instance, text, TopicOfInterestFilterType.Statement))
                      }}
                      predictedItems={predictiveResults.hint.mapOrDefault((hint) => hint, [])}
                      selectedValues={filters.statements || []}
                    />
                  </FilterMenu>
                  <Button
                    leftIcon={<Icon as={AiOutlineClear} />}
                    colorScheme="gray"
                    variant="outline"
                    height={8}
                    onClick={() => setFilters(filtersInitialState)}
                  >
                    {t('buildingBlocks.clearAll')}
                  </Button>
                </Flex>
              ),
              <></>
            )}
            <Table colorScheme="gray" size="sm" variant={'striped'}>
              <Thead>
                <Tr>
                  <Th px={2}>{renderSortActionHeader(SortFieldEnum.Name, t('buildingBlocks.name'))}</Th>
                  <Th px={2}>{renderSortActionHeader(SortFieldEnum.Created, t('buildingBlocks.created'))}</Th>
                  <Th px={2}>{renderSortActionHeader(SortFieldEnum.Modified, t('buildingBlocks.lastModified'))}</Th>
                  <Th px={2}>{renderSortActionHeader(SortFieldEnum.Editor, t('buildingBlocks.editor'))}</Th>
                  <Th px={2} data-cy="table-header-views">
                    {t('buildingBlocks.views')}
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {buildingblock.items?.map(
                  (buildingblock: TopicOfInterestInfo, index: number) =>
                    buildingblock.id && (
                      <Tr
                        key={`table-row-${index}`}
                        className={'linkbox-overlay'}
                        onClick={() => navigate(getBuildingBlocksPath(instance, buildingblock.id!))}
                        cursor={'pointer'}
                      >
                        <Td px={2}>
                          <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" maxWidth="600px">
                            {buildingblock.name}
                          </Box>
                        </Td>
                        <Td px={2} width="105px">
                          <Box>{format(new Date(buildingblock.created || ''), appConfig.dateFormat)}</Box>
                        </Td>
                        <Td px={2} width="105px">
                          <Box>{format(new Date(buildingblock.modified || ''), appConfig.dateFormat)}</Box>
                        </Td>
                        <Td px={2} width="115px">
                          <Box>{buildingblock.editor || appConfig.noDataPlaceholder}</Box>
                        </Td>
                        <Td px={2} width="365px">
                          <HStack width="340px" overflow="hidden">
                            {buildingblock.views?.map((viewName, viewIndex) => (
                              <Badge key={`row-${index}-badge-${viewIndex}`}>{viewName}</Badge>
                            ))}
                          </HStack>
                        </Td>
                      </Tr>
                    )
                )}
              </Tbody>
            </Table>
            <VStack align="center" pt={3} pb={2}>
              <Pagination
                activePageIndex={activePageIndex}
                onPageTo={(pageIndex: number) => {
                  setState({ ...state, activePageIndex: pageIndex })
                }}
                totalPageCount={Math.floor((buildingblock?.totalCount || 0) / appConfig.tableItemsPerPage) + 1}
              />
            </VStack>
          </>
        ),
        <></>
      )}
    </Box>
  )
}
