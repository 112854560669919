import { Epic, ofType } from 'redux-observable'
import * as Rx from 'rxjs'
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators'
import appConfig from '../../../constants/appConfig'
import { RootAction, RootState, Services } from '@/types'
import { CoreConfigurations } from '@/_clients/admin'

import {
  ApiKeysAction,
  ApiKeysActionKeys,
  ApiKeysLoadAction,
  loadApiKeysError,
  loadApiKeysSuccess,
} from './apiKeys.actions'

interface ApiKeysEpics extends Epic<RootAction, ApiKeysAction, RootState, Services> {}

const loadApiKeys$: ApiKeysEpics = (action$, state$, { InstancesApi }) => {
  return action$.pipe(
    ofType<ApiKeysLoadAction>(ApiKeysActionKeys.LOAD),
    debounceTime(appConfig.debounceTime),
    switchMap((action) => {
      return InstancesApi.getCoreConfigurations({
        instance: action.instance,
        start: 0,
        count: 10,
      }).pipe(
        map((coreConfigurations: CoreConfigurations) => loadApiKeysSuccess(coreConfigurations)),
        catchError((error) => Rx.of(loadApiKeysError(error)))
      )
    })
  )
}

export const apiKeysEpics = [loadApiKeys$]
