import {
  Box,
  Heading,
  HStack,
  Select,
  Table,
  Tag,
  Tbody,
  Td,
  Textarea,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react'
import { Maybe } from 'purify-ts'
import React, { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { PreprocessingDTO, Proposal } from '@/_clients/proposals'
import { PageHeader } from '@/components/PageHeader'
import {
  loadProposal,
  loadViewInventory,
  setInputValue,
  setSelectedPreprocessing,
  setSelectedView,
} from './state/proposal.actions'
import {
  getInputText,
  getProposal,
  getSelectedPreprocessing,
  getSelectedView,
  getViewInventory,
} from './state/proposal.selectors'
import { TextExtraction } from './components/TextExtraction'

export const ProposalPage = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const viewInventory: Maybe<string[]> = useSelector(getViewInventory)
  const proposal: Maybe<Proposal> = useSelector(getProposal)
  const textAreaValue: string = useSelector(getInputText)
  const selectedView: string = useSelector(getSelectedView)
  const selectedPreprocessing: PreprocessingDTO = useSelector(getSelectedPreprocessing)

  const instance = useParams<'instance'>().instance || ''

  const handleTextAreaChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setInputValue(e.target.value))
  }
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setSelectedView(e.target.value))
  }
  const preprocessingMap = {
    cleaning: PreprocessingDTO.Cleaning,
    'cleaning-and-translation': PreprocessingDTO.CleaningTranslating,
    translating: PreprocessingDTO.Translating,
    off: PreprocessingDTO.None,
  }
  const preprocessingReverseMap = (p: PreprocessingDTO) => {
    switch (p) {
      case PreprocessingDTO.Cleaning:
        return 'cleaning'
      case PreprocessingDTO.Translating:
        return 'translating'
      case PreprocessingDTO.CleaningTranslating:
        return 'cleaning-and-translation'
      case PreprocessingDTO.None:
        return 'off'
      default:
        return 'off'
    }
  }
  const handlePreprocessingChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    dispatch(setSelectedPreprocessing(preprocessingMap[e.target.value]))
  }

  React.useEffect(() => {
    const text = textAreaValue ? textAreaValue.trim() : ''
    if (selectedView) {
      dispatch(loadProposal(instance, text, selectedView, selectedPreprocessing))
    }
  }, [instance, selectedView, selectedPreprocessing, textAreaValue, dispatch])

  React.useEffect(() => {
    dispatch(loadViewInventory(instance))
  }, [instance, dispatch])

  React.useEffect(() => {
    if (!selectedView && selectedView.trim() !== '')
      dispatch(setSelectedView(viewInventory.mapOrDefault((viewInventory) => viewInventory[0], '')))
  }, [viewInventory, dispatch, selectedView])

  const hintTypeToColorScheme = (hintType: string): string => {
    switch (hintType) {
      case 'TEXTUAL':
        return 'green'
      case 'NUMERICAL':
        return 'yellow'
      case 'GROUP':
        return 'blue'
      default:
        return 'gray'
    }
  }
  const renderHintsCellContent = (hints?: string[]) => {
    return hints?.map((hintInfo: string) => {
      let textArray = hintInfo.split('_')
      let hintType = textArray[0]
      let hintText = textArray[2]
      return <Tag colorScheme={hintTypeToColorScheme(hintType)}>{hintText}</Tag>
    })
  }

  const borderColor = useColorModeValue('gray.400', 'gray.600')

  return (
    <Box overflow="visible" data-cy="proposals-page">
      <PageHeader title={t('textInterpretation.pageTitle')} />
      <VStack spacing={5} align="left">
        <VStack spacing={3} align="left">
          <Textarea size="sm" height={200} onChange={handleTextAreaChange} value={textAreaValue} />
          <HStack spacing={4}>
            {viewInventory.mapOrDefault(
              (viewInventory: any) => (
                <HStack>
                  <Box>View:</Box>
                  <Select
                    onChange={handleSelectChange}
                    size="sm"
                    colorScheme="gray"
                    borderColor={borderColor}
                    value={selectedView}
                    data-cy="proposal-view-select"
                  >
                    {viewInventory.map((viewName: string, index: number) => (
                      <option key={`view_select_option_${index}`} value={viewName}>
                        {viewName}
                      </option>
                    ))}
                  </Select>
                </HStack>
              ),
              <></>
            )}
            <HStack>
              <Box>{t('textInterpretation.preprocesssing')}</Box>
              <Select
                onChange={handlePreprocessingChange}
                size="sm"
                colorScheme="gray"
                borderColor={borderColor}
                value={preprocessingReverseMap(selectedPreprocessing)}
                data-cy="proposal-preprocessing-select"
              >
                <option key={`preprocessing_select_option_0`} value="cleaning">
                  {t('textInterpretation.cleaning')}
                </option>
                <option key={`preprocessing_select_option_1`} value="translating">
                  {t('textInterpretation.translating')}
                </option>
                <option key={`preprocessing_select_option_2`} value="cleaning-and-translation">
                  {t('textInterpretation.cleaning-translation')}
                </option>
                <option key={`preprocessing_select_option_3`} value="off">
                  {t('textInterpretation.off')}
                </option>
              </Select>
            </HStack>
          </HStack>
        </VStack>

        {proposal.mapOrDefault(
          (proposal) => (
            <VStack align="left">
              <Heading fontSize="sm" pt={3}>
                {t('textInterpretation.toi')}
              </Heading>
              <Box>
                {proposal.businessCases && proposal.businessCases.length > 0 ? (
                  <Table colorScheme="gray" size="sm">
                    <Thead>
                      <Tr>
                        <Th>{t('textInterpretation.name')}</Th>
                        <Th isNumeric>{t('textInterpretation.score')}</Th>
                        <Th>{t('textInterpretation.statements')}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {proposal.businessCases?.map((businessCase, index) => (
                        <Tr key={`table-row-${index}`}>
                          <Td>{businessCase.name}</Td>
                          <Td isNumeric>{Math.round(businessCase.score * 10_000) / 10_000}</Td>
                          <Td>
                            <HStack>{renderHintsCellContent(businessCase.matchedHints)}</HStack>
                          </Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                ) : (
                  <Box data-cy="no-topics-of-interest">{t('textInterpretation.noTois')}</Box>
                )}
              </Box>

              <Heading fontSize="sm" pt={3}>
                {t('textInterpretation.statements')}
              </Heading>
              <HStack spacing={2}>
                {proposal.hints && proposal.hints?.length > 0 ? (
                  proposal.hints?.map((hint, index) => {
                    let hintType = hint.type.split('_')[0]
                    return (
                      <Tag fontSize="sm" key={`tag-${index}`} colorScheme={hintTypeToColorScheme(hintType)}>
                        {hint.name}
                      </Tag>
                    )
                  })
                ) : (
                  <Tag data-cy="no-hints">{t('textInterpretation.noHints')}</Tag>
                )}
              </HStack>

              <TextExtraction extractedValues={proposal.extractedValues} />

              {proposal.language && (
                <>
                  <Heading fontSize="sm" pt={3}>
                    {t('textInterpretation.language')}
                  </Heading>
                  <p>{proposal.language}</p>
                </>
              )}

              {proposal.preprocessedText && proposal.preprocessedText.length > 0 && (
                <>
                  <Heading fontSize="sm" pt={3}>
                    {t('textInterpretation.preprocessedText')}
                  </Heading>
                  <p>{proposal.preprocessedText}</p>
                </>
              )}
            </VStack>
          ),
          <Box data-cy="no-results">{t('textInterpretation.noResults')}</Box>
        )}
      </VStack>
    </Box>
  )
}
