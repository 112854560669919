import { Action } from 'redux'
import { AsyncAction, AsyncActionPhaseKeys, AsyncErrorAction } from '@/store/state/AsyncAction'
import { SearchResult } from '@/_clients/admin'

export enum KnowledgeGraphActionKeys {
  SEARCH = 'knowledgeGraph/SEARCH',
  SEARCH_SUCCESS = 'knowledgeGraph/SEARCH_SUCCESS',
  SEARCH_CLEAR = 'knowledgeGraph/SEARCH_CLEAR',
  SEARCH_ERROR = 'knowledgeGraph/SEARCH_ERROR',
  SET_INPUT_VALUE = 'knowledgeGraph/SET_INPUT_VALUE',
}

export type KnowledgeGraphAction =
  | SearchKnowledgeGraphAction
  | SearchKnowledgeGraphSuccessAction
  | SearchKnowledgeGraphClearAction
  | AsyncErrorAction
  | SetInputValueAction

export interface SearchKnowledgeGraphAction extends AsyncAction {
  type: KnowledgeGraphActionKeys.SEARCH
  instance: string
  inputText: string
}

export interface SearchKnowledgeGraphSuccessAction extends AsyncAction {
  type: KnowledgeGraphActionKeys.SEARCH_SUCCESS
  meaningExpression: SearchResult[]
}

export const searchKnowledgeGraphSuccess = (meaningExpression: SearchResult[]): SearchKnowledgeGraphSuccessAction => ({
  type: KnowledgeGraphActionKeys.SEARCH_SUCCESS,
  meaningExpression,
  phase: AsyncActionPhaseKeys.SUCCESS,
})

export interface SearchKnowledgeGraphClearAction extends AsyncAction {
  type: KnowledgeGraphActionKeys.SEARCH_CLEAR
}

export const clearSearchKnowledgeGraph = (): SearchKnowledgeGraphClearAction => ({
  type: KnowledgeGraphActionKeys.SEARCH_CLEAR,
  phase: AsyncActionPhaseKeys.SUCCESS,
})

export const searchKnowledgeGraphError = (error: any): AsyncErrorAction => ({
  type: KnowledgeGraphActionKeys.SEARCH_ERROR,
  error,
  phase: AsyncActionPhaseKeys.ERROR,
})

export const loadKnowledgeGraph = (instance: string, inputText: string): SearchKnowledgeGraphAction => ({
  type: KnowledgeGraphActionKeys.SEARCH,
  instance,
  inputText,
  phase: AsyncActionPhaseKeys.START,
})

export interface SetInputValueAction extends Action {
  type: KnowledgeGraphActionKeys.SET_INPUT_VALUE
  inputValue: string
}

export const setInputValue = (inputValue: string): SetInputValueAction => ({
  type: KnowledgeGraphActionKeys.SET_INPUT_VALUE,
  inputValue,
})
