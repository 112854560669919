import React, { useState, useEffect } from 'react'
import { Box, Button, Flex } from '@chakra-ui/react'
import { ViewSelector } from '@/components/inputs/ViewSelector'
import ChatWidget from '@/features/Chatbot/components/Chatbot'
import ContextManager from '@/features/Chatbot/components/ContextManager'
import { ContextItem } from '@/features/Chatbot/types'
import { BoxProps } from '@chakra-ui/react'
import StatusIndicator from './components/StatusIndicator'
import { Proposal } from '@/_clients/proposals'
import { ExtractAndValidateResult } from './helpers'
import ExtractionStatusIndicator from './components/ExtractionStatusIndicator'

interface ChatBotFeatureProps extends BoxProps {
  instance: string
}

const ChatBotFeature = ({ instance, ...props }: ChatBotFeatureProps) => {
  const [selectedViews, setSelectedViews] = useState<string[]>([])
  const [toiContext, setToiContext] = useState<ContextItem[]>([])
  const [extractorContext, setExtractorContext] = useState<ContextItem[]>([])
  const [resetKey, setResetKey] = useState(false)
  const [status, setStatus] = useState<'idle' | 'pending' | 'success' | 'error'>('idle')
  const [response, setResponse] = useState<Proposal | null>(null)
  const [error, setError] = useState<any>(null)
  const [validationResults, setValidationResults] = useState<ExtractAndValidateResult>({
    extractedJson: {},
    valid: false,
  })
  const [useTextarea, setUseTextarea] = useState(true)

  const handleViewsChange = (views: string[]) => setSelectedViews(views)
  const handleToiContextChange = (newContext: ContextItem[]) => setToiContext(newContext)
  const handleExtractorContextChange = (newContext: ContextItem[]) => setExtractorContext(newContext)

  const handleValidationResults = (results: ExtractAndValidateResult) => {
    setValidationResults(results)
  }

  const handleToggle = () => {
    setUseTextarea(!useTextarea)
  }

  const handleReset = () => setResetKey(!resetKey)

  useEffect(() => {
    setResetKey(!resetKey)
    setStatus('idle')
    setResponse(null)
    setError(null)
    setValidationResults({
      extractedJson: {},
      valid: false,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedViews, toiContext, extractorContext, instance])

  return (
    <Box {...props} borderWidth={1} borderRadius="lg" p={5}>
      <Flex alignItems="center" justifyContent="space-between" mx={5}>
        <Flex gap={2} mb={4}>
          <ViewSelector
            instance={instance}
            onViewsChange={handleViewsChange}
            id={'cyViewSelector'}
            placeholder={'select a view'}
          />
          <ContextManager onContextChange={handleToiContextChange} type={'toi'} />
          <ContextManager onContextChange={handleExtractorContextChange} type={'extractor'} />
          <Button onClick={handleToggle}>Switch to {useTextarea ? 'Input' : 'Textarea'}</Button>
          <Button onClick={handleReset}>Reset</Button>
        </Flex>
        <Flex gap={2} mb={4}>
          <StatusIndicator status={status} response={response} error={error} />
          <ExtractionStatusIndicator result={validationResults} />
        </Flex>
      </Flex>

      <ChatWidget
        instance={instance}
        toiContext={toiContext}
        extractorContext={extractorContext}
        views={selectedViews}
        resetKey={resetKey}
        useTextarea={useTextarea}
        handleValidationResults={handleValidationResults}
        setStatus={setStatus}
        setResponse={setResponse}
        setError={setError}
      />
    </Box>
  )
}

export default ChatBotFeature
