import { Box, HStack, Text, useToast, Wrap } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { PageHeader } from '@/components/PageHeader'
import { KnowledgeBaseApi } from '@/api/apis'
import { RelatedMeaningExpressions, RelatedMeaningExpressionsRequestStrategyEnum } from '@/_clients/admin'
import { HttpRequestState } from '@/types'
import { Just, Maybe, Nothing } from 'purify-ts'
import SemanticList from '@/features/KnowledgeGraph/components/SemanticList'
import { StrategySelector } from '@/features/KnowledgeGraph/components/StrategySelector'
import '@/styles/semanticListStyle.css'

const encodeString = (str: string): string => {
  let encodedStr = ''

  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i)

    // Alphanumeric characters remain the same
    if (
      (charCode >= 48 && charCode <= 57) ||
      (charCode >= 65 && charCode <= 90) ||
      (charCode >= 97 && charCode <= 122)
    ) {
      encodedStr += str[i]

      // Special characters remain the same
    } else if (
      str[i] === '.' ||
      str[i] === '-' ||
      str[i] === '*' ||
      str[i] === '_' ||
      str[i] === ':' ||
      str[i] === '+'
    ) {
      encodedStr += str[i]

      // Space character is converted to plus sign
    } else if (charCode === 32 || str[i] === ' ') {
      encodedStr += '+'

      // All other characters are unsafe and are first converted into one or more bytes using UTF-8 encoding scheme. Then each byte is represented by the 3-character string "%xy", where xy is the two-digit hexadecimal representation of the byte.
    } else {
      const utf8Bytes = unescape(encodeURIComponent(str[i]))

      for (let j = 0; j < utf8Bytes.length; j++) {
        const byte = utf8Bytes.charCodeAt(j).toString(16).toUpperCase()

        encodedStr += `%${byte}`
      }
    }
  }
  return encodedStr
}

export default function KnowledgeGraphDetail() {
  const tableOrder = ['Synonyms', 'Hypernyms', 'Hyponyms', 'Holonyms', 'Meronyms', 'Homonyms']
  const toast = useToast()
  const params = useParams<'knowledgeGraphId'>().knowledgeGraphId || ''
  const { instance = '' } = useParams()

  const paramsArray: Array<string> = params.split('&')
  const meaningId = getParameterValue(paramsArray, 'meaningId')
  const meaningValue = getParameterValue(paramsArray, 'meaningValue')
  const expressionId = getParameterValue(paramsArray, 'expressionId')
  const expressionValue = getParameterValue(paramsArray, 'expressionValue')
  const [strategy, setStrategy] = useState(RelatedMeaningExpressionsRequestStrategyEnum.All)
  const [relatedMeaningExpressions, setRelatedMeaningExpressions] = useState<
    HttpRequestState<Maybe<RelatedMeaningExpressions>>
  >({
    isLoading: false,
    value: Maybe.empty(),
  })

  function getParameterValue(nameAndValue: string[], parameterName: string) {
    const value = nameAndValue.find((part) => part.startsWith(parameterName))
    return value === undefined ? undefined : value.replace(parameterName + '=', '')
  }

  useEffect(() => {
    const fetchData = () => {
      setRelatedMeaningExpressions((prev) => {
        return { ...prev, isLoading: true }
      })

      const request = {
        source: {
          meaning: {
            id: meaningId ? encodeString(meaningId) : '',
            value: meaningValue || '',
          },
          expression: {
            id: expressionId || '',
            value: expressionValue || '',
          },
        },
        strategy: strategy,
      }

      KnowledgeBaseApi.postForRelated({
        instance,
        relatedMeaningExpressionsRequest: request,
      }).subscribe({
        next: (relations) => {
          setRelatedMeaningExpressions({ isLoading: false, value: Just(relations) })
        },
        error: (err) => {
          setRelatedMeaningExpressions({ isLoading: false, value: Nothing, error: err })
          toast({
            title: err.response ? err.response.name : err.name,
            description: err.response ? err.response.message : err.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
        },
      })
    }

    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, strategy, instance])

  return (
    <Box data-cy="knowledge-graph-relations-page">
      <PageHeader title={meaningValue} />
      <HStack justify="space-between">
        <HStack spacing={2}>
          <Text>meaning: </Text>
          <Text as="b" fontSize={'lg'} color={'blue.500'}>
            {meaningValue}
          </Text>
          <Text>expression: </Text>
          <Text as="b" fontSize={'lg'} color={'orange.400'}>
            {expressionValue}
          </Text>
        </HStack>
        <HStack>
          <Text>Strategy:</Text>
          <StrategySelector key={`${meaningId}+${expressionId}`} changeStrategy={setStrategy} />
        </HStack>
      </HStack>
      <Wrap spacing={'10px'} justify="left">
        {relatedMeaningExpressions.value?.caseOf({
          Just: (relations) => (
            <>
              {tableOrder.map((key) => (
                <SemanticList
                  key={key}
                  instance={instance}
                  listItems={relations[key.toLowerCase()] || []}
                  title={key}
                  addItem={(item: any) => {
                    console.log(item)
                  }}
                  deleteItem={(item: any) => {
                    console.log(item)
                  }}
                  resetStrategy={() => setStrategy(RelatedMeaningExpressionsRequestStrategyEnum.All)}
                ></SemanticList>
              ))}
            </>
          ),
          Nothing: () => <Box>No data</Box>,
        })}
      </Wrap>
    </Box>
  )
}
