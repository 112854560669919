// tslint:disable
/**
 * TESTING API
 * The API to provide Testdata for deepassist and to execute Test Runs
 *
 * The version of the OpenAPI document: 0.0.2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs'
import { BaseAPI, encodeURI, HttpHeaders, OperationOpts, RawAjaxResponse, throwIfNullOrUndefined } from '../runtime'
import { TestdataOverview, TestdataResponse, TestdataUpload, TestRunOverview, TestRunResponse } from '../models'

export interface DownloadTestRunFileRequest {
    instance: string;
    testRunId: string;
}

export interface GetAllTestDataRequest {
    instance: string;
}

export interface GetAllTestRunsRequest {
    instance: string;
}

export interface GetTestdataDetailsRequest {
    instance: string;
    testdataId: string;
}

export interface GetTestdataTestRunRequest {
    instance: string;
    testdataId: string;
    testRunId: string;
}

export interface GetTestdataTestRunDetailsRequest {
    instance: string;
    testRunId: string;
}

export interface GetTestdataTestRunsRequest {
    instance: string;
    testdataId: string;
}

export interface ImportTestdataRequest {
    instance: string;
    testdataUpload?: TestdataUpload;
}

export interface StartNewTestRunFromFileRequest {
    instance: string;
    views?: string;
    file?: Blob;
}

export interface StartTestRunForTestdataRequest {
    instance: string;
    testdataId: string;
}

/**
 * no description
 */
export class TestingApi extends BaseAPI {

    /**
     * Get the list of test results included in the test run as excel file
     */
    downloadTestRunFile({ instance, testRunId }: DownloadTestRunFileRequest): Observable<Blob>
    downloadTestRunFile({ instance, testRunId }: DownloadTestRunFileRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Blob>>
    downloadTestRunFile({ instance, testRunId }: DownloadTestRunFileRequest, opts?: OperationOpts): Observable<Blob | RawAjaxResponse<Blob>> {
        throwIfNullOrUndefined(instance, 'instance', 'downloadTestRunFile');
        throwIfNullOrUndefined(testRunId, 'testRunId', 'downloadTestRunFile');

        return this.request<Blob>({
            url: '/instances/{instance}/test-runs/{testRunId}/file'.replace('{instance}', encodeURI(instance)).replace('{testRunId}', encodeURI(testRunId)),
            method: 'GET',
            responseType: 'blob',
        }, opts?.responseOpts);
    };

    /**
     * Get Overview of all Available Testdata
     */
    getAllTestData({ instance }: GetAllTestDataRequest): Observable<Array<TestdataOverview>>
    getAllTestData({ instance }: GetAllTestDataRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<TestdataOverview>>>
    getAllTestData({ instance }: GetAllTestDataRequest, opts?: OperationOpts): Observable<Array<TestdataOverview> | RawAjaxResponse<Array<TestdataOverview>>> {
        throwIfNullOrUndefined(instance, 'instance', 'getAllTestData');

        return this.request<Array<TestdataOverview>>({
            url: '/instances/{instance}/testdata'.replace('{instance}', encodeURI(instance)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Get all list of all test runs
     */
    getAllTestRuns({ instance }: GetAllTestRunsRequest): Observable<Array<TestRunOverview>>
    getAllTestRuns({ instance }: GetAllTestRunsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<TestRunOverview>>>
    getAllTestRuns({ instance }: GetAllTestRunsRequest, opts?: OperationOpts): Observable<Array<TestRunOverview> | RawAjaxResponse<Array<TestRunOverview>>> {
        throwIfNullOrUndefined(instance, 'instance', 'getAllTestRuns');

        return this.request<Array<TestRunOverview>>({
            url: '/instances/{instance}/test-runs'.replace('{instance}', encodeURI(instance)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Get the details to the Testdata including annotations, extractions
     */
    getTestdataDetails({ instance, testdataId }: GetTestdataDetailsRequest): Observable<TestdataResponse>
    getTestdataDetails({ instance, testdataId }: GetTestdataDetailsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TestdataResponse>>
    getTestdataDetails({ instance, testdataId }: GetTestdataDetailsRequest, opts?: OperationOpts): Observable<TestdataResponse | RawAjaxResponse<TestdataResponse>> {
        throwIfNullOrUndefined(instance, 'instance', 'getTestdataDetails');
        throwIfNullOrUndefined(testdataId, 'testdataId', 'getTestdataDetails');

        return this.request<TestdataResponse>({
            url: '/instances/{instance}/testdata/{testdataId}'.replace('{instance}', encodeURI(instance)).replace('{testdataId}', encodeURI(testdataId)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Get the summary with analytic data to a test run
     */
    getTestdataTestRun({ instance, testdataId, testRunId }: GetTestdataTestRunRequest): Observable<Array<TestRunOverview>>
    getTestdataTestRun({ instance, testdataId, testRunId }: GetTestdataTestRunRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<TestRunOverview>>>
    getTestdataTestRun({ instance, testdataId, testRunId }: GetTestdataTestRunRequest, opts?: OperationOpts): Observable<Array<TestRunOverview> | RawAjaxResponse<Array<TestRunOverview>>> {
        throwIfNullOrUndefined(instance, 'instance', 'getTestdataTestRun');
        throwIfNullOrUndefined(testdataId, 'testdataId', 'getTestdataTestRun');
        throwIfNullOrUndefined(testRunId, 'testRunId', 'getTestdataTestRun');

        return this.request<Array<TestRunOverview>>({
            url: '/instances/{instance}/testdata/{testdataId}/test-runs/{testRunId}'.replace('{instance}', encodeURI(instance)).replace('{testdataId}', encodeURI(testdataId)).replace('{testRunId}', encodeURI(testRunId)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Get the list of test results included in the test run
     */
    getTestdataTestRunDetails({ instance, testRunId }: GetTestdataTestRunDetailsRequest): Observable<TestRunResponse>
    getTestdataTestRunDetails({ instance, testRunId }: GetTestdataTestRunDetailsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<TestRunResponse>>
    getTestdataTestRunDetails({ instance, testRunId }: GetTestdataTestRunDetailsRequest, opts?: OperationOpts): Observable<TestRunResponse | RawAjaxResponse<TestRunResponse>> {
        throwIfNullOrUndefined(instance, 'instance', 'getTestdataTestRunDetails');
        throwIfNullOrUndefined(testRunId, 'testRunId', 'getTestdataTestRunDetails');

        return this.request<TestRunResponse>({
            url: '/instances/{instance}/test-runs/{testRunId}/data'.replace('{instance}', encodeURI(instance)).replace('{testRunId}', encodeURI(testRunId)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Get all list of all test runs for the testdata
     */
    getTestdataTestRuns({ instance, testdataId }: GetTestdataTestRunsRequest): Observable<void>
    getTestdataTestRuns({ instance, testdataId }: GetTestdataTestRunsRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    getTestdataTestRuns({ instance, testdataId }: GetTestdataTestRunsRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(instance, 'instance', 'getTestdataTestRuns');
        throwIfNullOrUndefined(testdataId, 'testdataId', 'getTestdataTestRuns');

        return this.request<void>({
            url: '/instances/{instance}/testdata/{testdataId}/test-runs'.replace('{instance}', encodeURI(instance)).replace('{testdataId}', encodeURI(testdataId)),
            method: 'GET',
        }, opts?.responseOpts);
    };

    /**
     * Import new Testdata (with annotations or without)
     */
    importTestdata({ instance, testdataUpload }: ImportTestdataRequest): Observable<Array<TestdataOverview>>
    importTestdata({ instance, testdataUpload }: ImportTestdataRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<TestdataOverview>>>
    importTestdata({ instance, testdataUpload }: ImportTestdataRequest, opts?: OperationOpts): Observable<Array<TestdataOverview> | RawAjaxResponse<Array<TestdataOverview>>> {
        throwIfNullOrUndefined(instance, 'instance', 'importTestdata');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
        };

        return this.request<Array<TestdataOverview>>({
            url: '/instances/{instance}/testdata'.replace('{instance}', encodeURI(instance)),
            method: 'POST',
            headers,
            body: testdataUpload,
        }, opts?.responseOpts);
    };

    /**
     * Start a new test run from an file
     */
    startNewTestRunFromFile({ instance, views, file }: StartNewTestRunFromFileRequest): Observable<void>
    startNewTestRunFromFile({ instance, views, file }: StartNewTestRunFromFileRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    startNewTestRunFromFile({ instance, views, file }: StartNewTestRunFromFileRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(instance, 'instance', 'startNewTestRunFromFile');

        const formData = new FormData();
        if (views !== undefined) { formData.append('views', views as any); }
        if (file !== undefined) { formData.append('file', file as any); }

        return this.request<void>({
            url: '/instances/{instance}/test-runs'.replace('{instance}', encodeURI(instance)),
            method: 'POST',
            body: formData,
        }, opts?.responseOpts);
    };

    /**
     * Start a new test run for the testdata ID
     */
    startTestRunForTestdata({ instance, testdataId }: StartTestRunForTestdataRequest): Observable<Array<TestRunOverview>>
    startTestRunForTestdata({ instance, testdataId }: StartTestRunForTestdataRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<TestRunOverview>>>
    startTestRunForTestdata({ instance, testdataId }: StartTestRunForTestdataRequest, opts?: OperationOpts): Observable<Array<TestRunOverview> | RawAjaxResponse<Array<TestRunOverview>>> {
        throwIfNullOrUndefined(instance, 'instance', 'startTestRunForTestdata');
        throwIfNullOrUndefined(testdataId, 'testdataId', 'startTestRunForTestdata');

        return this.request<Array<TestRunOverview>>({
            url: '/instances/{instance}/testdata/{testdataId}/test-runs'.replace('{instance}', encodeURI(instance)).replace('{testdataId}', encodeURI(testdataId)),
            method: 'POST',
        }, opts?.responseOpts);
    };

}
