import React, { useState } from 'react'
import {
  Box,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Badge,
  HStack,
  Tooltip,
} from '@chakra-ui/react'
import { Proposal, ScoredBusinessCase } from '@/_clients/proposals'

interface StatusIndicatorProps {
  status: 'idle' | 'pending' | 'success' | 'error'
  response: Proposal | null
  error: any
}

const StatusIndicator = ({ status, response, error }: StatusIndicatorProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleOpen = () => setIsOpen(true)
  const handleClose = () => setIsOpen(false)

  const colorMap = {
    idle: 'gray.200',
    pending: 'yellow.400',
    success: 'green.500',
    error: 'red.500',
  }

  const color = useColorModeValue(colorMap[status], colorMap[status])

  return (
    <Box as="button" onClick={handleOpen}>
      <HStack spacing={3}>
        <Text>deepassist</Text>
        <Tooltip label={status} aria-label="status">
          <Badge borderRadius="full" p="1" backgroundColor={color} boxSize="1.25rem" />
        </Tooltip>
      </HStack>

      <Modal isOpen={isOpen} onClose={handleClose} size={'lg'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{status.toUpperCase()}</ModalHeader>
          <ModalCloseButton />
          <ModalBody overflow="auto">
            {status === 'success' &&
              response &&
              response.businessCases &&
              response.businessCases.map((businessCase: ScoredBusinessCase, index: number) => (
                <pre key={index}>{`Name: ${businessCase.name}, Score: ${businessCase.score}`}</pre>
              ))}
            {status === 'error' && <pre>{JSON.stringify(error, null, 2)}</pre>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default StatusIndicator
