import { InspectionHint, InspectionHints, InspectionToken } from '@/_clients/proposals'

type InspectionTokenPredicate = (token: PositionAwareInspectionToken) => boolean
type InspectionHintPredicate = (token: InspectionHint) => boolean

export interface PositionAwareInspectionToken extends InspectionToken {
  pos: number
}

export const getAllTokens = (result?: InspectionHints | null): PositionAwareInspectionToken[] =>
  getTokens(() => true, result)

export const getSingleTokens = (result?: InspectionHints | null): PositionAwareInspectionToken[] =>
  getTokens(hasNoSpace, result)

export const getTwoWordTokens = (result?: InspectionHints | null): PositionAwareInspectionToken[] =>
  getTokens(hasOneSpace, result)

export const getThreeWordTokens = (result?: InspectionHints | null): PositionAwareInspectionToken[] =>
  getTokens(hasTwoSpaces, result)

export const containsTwoWordTokens = (tokenPositions: number[], result?: InspectionHints | null): boolean => {
  return getTokens((token) => tokenPositions?.includes(token.pos), result).filter(hasOneSpace).length > 0
}

export const containsThreeWordTokens = (tokenPositions: number[], result?: InspectionHints | null): boolean => {
  return getTokens((token) => tokenPositions?.includes(token.pos), result).filter(hasTwoSpaces).length > 0
}

const getTokens = (
  predicate: InspectionTokenPredicate,
  result?: InspectionHints | null
): PositionAwareInspectionToken[] => {
  if (!result || !result.tokens) {
    return []
  }
  return result.tokens
    .map((token, index) => {
      const nextToken: PositionAwareInspectionToken = {
        ...token,
        pos: index,
      }
      return nextToken
    })
    .filter(predicate)
}

const hasNoSpace = (token: InspectionToken): boolean => hasSpaces(token, 0)
const hasOneSpace = (token: InspectionToken): boolean => hasSpaces(token, 1)
const hasTwoSpaces = (token: InspectionToken): boolean => hasSpaces(token, 2)

const hasSpaces = (token: InspectionToken | undefined, count: number): boolean => {
  if (!token || !token.text) {
    return false
  }
  return token.text.split(' ').length - 1 === count
}

// ------------------------------------------------------------------------------------------------------------------------------------

export const getAllHints = (result?: InspectionHints | null): InspectionHint[] => getHints(all, result)
export const getWinningHints = (result?: InspectionHints | null): InspectionHint[] => getHints(winningHints, result)
export const getInternalHints = (result?: InspectionHints | null): InspectionHint[] => getHints(internalHints, result)
export const getEliminatedHints = (result?: InspectionHints | null): InspectionHint[] =>
  getHints(eliminatedHints, result)

const all = () => true
const winningHints = (hint: InspectionHint): boolean => !hint.internal && !hint.eliminated
const internalHints = (hint: InspectionHint): boolean => hint.internal === true
const eliminatedHints = (hint: InspectionHint): boolean => hint.eliminated === true

const getHints = (predicate: InspectionHintPredicate, result?: InspectionHints | null): InspectionHint[] => {
  if (!result || !result.hints) {
    return []
  }
  return result.hints.filter(predicate)
}

// ------------------------------------------------------------------------------------------------------------------------------------

export const getReferencedHintsPerToken = (tokenPos: number, hints: InspectionHint[]): string[] => {
  const tokenReferencedHints = hints.filter((hint) => hint.tokens && hint.tokens.includes(tokenPos))
  const hintReferencedHints = hints
    .filter((hint) => {
      return (
        tokenReferencedHints
          .map((tokenReferencedHint) => tokenReferencedHint.id!)
          .filter((hintId) => hint.hints?.includes(hintId)).length > 0
      )
    })
    .map((hint) => hint.id!)
  return [...tokenReferencedHints.map((hint) => hint.id!), ...hintReferencedHints]
}

export const getReferencedHintsPerHint = (baseHint: InspectionHint, hints?: InspectionHint[]): InspectionHint[] => {
  if (!hints) {
    return []
  }
  const invisibleHints = hints.filter((eachHint) => baseHint.hints && baseHint.hints.includes(eachHint.id!))
  const resultHints = hints.filter((eachHint) => eachHint.hints && eachHint.hints.includes(baseHint.id!))

  const numericParentHints = hints
    .filter((eachHint) => eachHint.type === 'NUMERICAL_HINT')
    .filter((eachHint) => eachHint.units && eachHint.units.includes(baseHint.name!))
  const numericUnitHints = !baseHint.units
    ? []
    : hints
        .filter((eachHint) => eachHint.type === 'NUMERICAL_UNIT_HINT')
        .filter((eachHint) => baseHint.units?.includes(eachHint.name!))
  return [...invisibleHints, ...resultHints, ...numericParentHints, ...numericUnitHints]
}

export const getReferencedTokenPositions = (
  hints: InspectionHint[],
  tokens: PositionAwareInspectionToken[]
): number[] => {
  return tokens
    .filter((token) => {
      return hints.filter((hint) => hint.tokens?.includes(token.pos)).length > 0
    })
    .map((token) => token.pos)
}
