import { AuthenticatedUser, Authenticator } from './Authenticator.types'

export class CypressAuthenticator implements Authenticator {
  private initialized: boolean
  private timeout: any

  constructor(applicationGroup?: string) {
    this.initialized = false
    this.timeout = null
  }

  init(done: (isAuthenticated: boolean) => void) {
    this.initialized = true
    return done(true)
  }

  isInitialized(): boolean {
    return this.initialized
  }

  getToken(): string | undefined {
    return 'cypress-test'
  }

  hasGroup(expectedGroup: string): boolean {
    return true
  }

  hasRole(expectedRole: string): boolean {
    return true
  }

  isAuthenticated(): boolean {
    return true
  }

  login(): void {
    // do nothing
  }

  logout(): void {
    // do nothing
  }

  currentUser(): AuthenticatedUser | undefined {
    return {
      sub: 'Cypress',
      email: 'cypress@test.com',
      name: 'Cypress Test',
    }
  }

  getRoles(): string[] | undefined {
    return ['knowledge-consultant', 'instance-admin']
  }
}
