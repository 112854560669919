import {
  Checkbox,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  VStack,
} from '@chakra-ui/react'
import * as React from 'react'
import {
  StatementBuilderState,
  StatementField,
  StatementInputTypes,
} from '@/features/TopicsOfInterest/components/wizard/topicOfInterest.types'
import { useFormContext } from 'react-hook-form'
import ElementSelection from '@/features/TopicsOfInterest/components/wizard/ElementSelection'

interface StatementBuilderProps {
  statementFields: StatementField[]
  onSubmit: (values: StatementBuilderState) => void
}

export default function StatementBuilder({ statementFields }: StatementBuilderProps) {
  const { register } = useFormContext()

  return (
    <HStack spacing={4} alignItems={'flex-start'} mb={4}>
      {statementFields.map(({ name, label, type }) => {
        switch (type) {
          case StatementInputTypes.Element: {
            return <ElementSelection name={name} label={label} />
          }
          case StatementInputTypes.Number: {
            return (
              <FormControl w={180} key={name}>
                <FormLabel>Distance</FormLabel>
                <NumberInput precision={0} defaultValue={70} min={0} max={100}>
                  <NumberInputField {...register('distance')} />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </FormControl>
            )
          }
          default: {
            return null
          }
        }
      })}

      <VStack align={'flex-start'}>
        <Checkbox icon={<CustomBoltIcon />} iconSize={16} colorScheme={'green'} {...register('boosted')}>
          Boosted
        </Checkbox>
        <Checkbox icon={<CustomEyeIcon />} iconSize={16} colorScheme={'red'} {...register('excluded')}>
          Excluded
        </Checkbox>
      </VStack>
    </HStack>
  )
}

/**
 * Custom Icons for Chakra-Bug with CheckBox-icons
 * see: https://github.com/chakra-ui/chakra-ui/issues/1589
 * https://chakra-ui.com/docs/components/checkbox/usage#icon
 *
 * @param isChecked
 * @param isIndeterminate
 * @param rest
 * @constructor
 */
function CustomBoltIcon({ isChecked, isIndeterminate, ...rest }: { isChecked?: boolean; isIndeterminate?: boolean }) {
  return (
    <>
      {isChecked ? (
        <Icon viewBox="0 0 24 24" {...rest}>
          <path
            fill="currentColor"
            d="M14.615 1.595a.75.75 0 01.359.852L12.982 9.75h7.268a.75.75 0 01.548 1.262l-10.5 11.25a.75.75 0 01-1.272-.71l1.992-7.302H3.75a.75.75 0 01-.548-1.262l10.5-11.25a.75.75 0 01.913-.143z"
          />
        </Icon>
      ) : null}
    </>
  )
}
function CustomEyeIcon({ isChecked, isIndeterminate, ...rest }: { isChecked?: boolean; isIndeterminate?: boolean }) {
  return (
    <>
      {isChecked ? (
        <Icon viewBox="0 0 24 24" {...rest}>
          <g fill="currentColor">
            <path d="M23.2,10.549a20.954,20.954,0,0,0-4.3-3.6l4-3.995a1,1,0,1,0-1.414-1.414l-.018.018a.737.737,0,0,1-.173.291l-19.5,19.5c-.008.007-.018.009-.026.017a1,1,0,0,0,1.631,1.088l4.146-4.146a11.26,11.26,0,0,0,4.31.939h.3c4.256,0,8.489-2.984,11.051-5.8A2.171,2.171,0,0,0,23.2,10.549ZM16.313,13.27a4.581,4.581,0,0,1-3,3.028,4.3,4.3,0,0,1-3.1-.19.253.253,0,0,1-.068-.407l5.56-5.559a.252.252,0,0,1,.407.067A4.3,4.3,0,0,1,16.313,13.27Z"></path>
            <path d="M7.615,13.4a.244.244,0,0,0,.061-.24A4.315,4.315,0,0,1,7.5,12,4.5,4.5,0,0,1,12,7.5a4.276,4.276,0,0,1,1.16.173.244.244,0,0,0,.24-.062l1.941-1.942a.254.254,0,0,0-.1-.421A10.413,10.413,0,0,0,12,4.75C7.7,4.692,3.4,7.7.813,10.549a2.15,2.15,0,0,0-.007,2.9,21.209,21.209,0,0,0,3.438,3.03.256.256,0,0,0,.326-.029Z"></path>
          </g>
        </Icon>
      ) : null}
    </>
  )
}
