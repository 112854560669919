import SearchInput from '@/features/TopicsOfInterest/components/wizard/SearchInput'
import { Control, FieldValues, useFormContext } from 'react-hook-form'
import * as React from 'react'
import { useEffect, useState } from 'react'
import ControlledSelect from '@/components/ControlledSelect'
import { RelatedMeaningExpressionsStrategyEnumCustom } from '@/types'
import { RelatedMeaningExpressionsRequestStrategyEnum } from '@/_clients/admin'

const meaningOptions = Object.entries(RelatedMeaningExpressionsStrategyEnumCustom)
  .filter((entry) => entry[0] !== RelatedMeaningExpressionsRequestStrategyEnum.All)
  .map((entry) => ({
    label: entry[0],
    value: entry[1],
  }))

type ElementSelectionProps = {
  name: string
  label: string
}

export default function ElementSelection({ name, label }: ElementSelectionProps) {
  const { control, getValues, setValue, watch } = useFormContext()
  const [isText, setIsText] = useState(true)

  const searchInputName = `${name}Element`
  const strategyInputName = `${name}Strategy`

  useEffect(() => {
    if (typeof getValues(searchInputName) === 'string') {
      setIsText(true)
      setValue(strategyInputName, RelatedMeaningExpressionsStrategyEnumCustom.Default)
    } else {
      setIsText(false)
    }
  }, [watch(searchInputName)])

  return (
    <>
      <SearchInput
        name={searchInputName}
        label={`${label} Element`}
        control={control as unknown as Control<FieldValues>}
        rules={{ required: 'This field is required' }}
      />
      <ControlledSelect
        name={strategyInputName}
        label={`${label} Strategy`}
        control={control as unknown as Control<FieldValues>}
        isDisabled={isText}
        options={meaningOptions}
      />
    </>
  )
}
