import produce from 'immer'
import { Maybe } from 'purify-ts'
import { AsyncErrorAction } from '@/store/state/AsyncAction'
import { PreprocessingDTO, Proposal } from '@/_clients/proposals'
import {
  ProposalAction,
  ProposalActionKeys,
  ProposalLoadSuccessAction,
  SetInputValueAction,
  SetSelectedPreprocessingAction,
  SetSelectedViewAction,
  ViewInventoryLoadSuccessAction,
} from './proposal.actions'

export interface ProposalState {
  inputText: string
  isDebugChecked: boolean
  proposal: Maybe<Proposal>
  selectedView: string
  selectedPreprocessing: PreprocessingDTO
  viewInventory: Maybe<string[]>
}

export const initialState: ProposalState = {
  inputText: '',
  isDebugChecked: false,
  proposal: Maybe.empty(),
  selectedView: 'all',
  selectedPreprocessing: PreprocessingDTO.Cleaning,
  viewInventory: Maybe.empty(),
}

export const proposalReducer = (state: ProposalState = initialState, action: ProposalAction): ProposalState => {
  return produce(state, (draft: ProposalState) => {
    switch (action.type) {
      case ProposalActionKeys.LOAD_SUCCESS: {
        const successAction = action as ProposalLoadSuccessAction
        draft.proposal = Maybe.of(successAction.proposal)
        return
      }
      case ProposalActionKeys.CLEAR: {
        draft.inputText = initialState.inputText
        draft.proposal = initialState.proposal
        return
      }
      case ProposalActionKeys.VIEW_INVENTORY_LOAD_SUCCESS: {
        const successAction = action as ViewInventoryLoadSuccessAction
        draft.viewInventory = Maybe.of(successAction.viewInventory)
        if (successAction.viewInventory.length > 0) {
          draft.selectedView = successAction.viewInventory[0]
        }
        return
      }
      case ProposalActionKeys.LOAD_ERROR: {
        const errorAction = action as AsyncErrorAction
        console.error('The following error occurred when tried to retrieve proposals:')
        console.error(errorAction.error)
        return
      }
      case ProposalActionKeys.VIEW_INVENTORY_LOAD_ERROR: {
        const errorAction = action as AsyncErrorAction
        console.error('The following error occurred when tried to retrieve view inventory:')
        console.error(errorAction.error)
        return
      }
      case ProposalActionKeys.SET_INPUT_VALUE: {
        const setAction = action as SetInputValueAction
        draft.inputText = setAction.inputValue
        return
      }
      case ProposalActionKeys.SET_SELECTED_PREPROCESSING: {
        const setAction = action as SetSelectedPreprocessingAction
        draft.selectedPreprocessing = setAction.preprocessingType
        return
      }
      case ProposalActionKeys.SET_SELECTED_VIEW: {
        const setAction = action as SetSelectedViewAction
        draft.selectedView = setAction.viewName
        return
      }
    }
  })
}
