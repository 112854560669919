import { Epic, ofType } from 'redux-observable'
import * as Rx from 'rxjs'
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators'
import appConfig from '../../../constants/appConfig'
import { RootAction, RootState, Services } from '@/types'
import { Editor, Editors, View, Views } from '@/_clients/admin'
import {
  FilterInventoriesLoadAction,
  loadFilterInventoriesError,
  loadFilterInventoriesSuccess,
  loadPredictiveError,
  loadPredictiveSuccess,
  loadTopicsOfInterestError,
  loadTopicsOfInterestSuccess,
  PredictiveLoadAction,
  TopicsOfInterestAction,
  TopicsOfInterestActionKeys,
  TopicsOfInterestLoadAction,
} from './topicsOfInterest.actions'

interface TopicsOfInterestEpics extends Epic<RootAction, TopicsOfInterestAction, RootState, Services> {}

const loadTopicsOfInterest$: TopicsOfInterestEpics = (action$, state$, { TopicOfInterestApi }) => {
  return action$.pipe(
    ofType<TopicsOfInterestLoadAction>(TopicsOfInterestActionKeys.LOAD),
    debounceTime(appConfig.debounceTime),
    switchMap((action) => {
      return TopicOfInterestApi.listTopicsOfInterest({
        instance: action.instance,
        statementCollectionFilter: action.filters,
      }).pipe(
        map((topicsOfInterest) => loadTopicsOfInterestSuccess(topicsOfInterest)),
        catchError((error) => Rx.of(loadTopicsOfInterestError(error)))
      )
    })
  )
}

const reShapeInventoryAnswers = (inventoryItem: Views | Editors): string[] =>
  inventoryItem.elements ? inventoryItem.elements.map((elem: View | Editor) => (elem.name ? elem.name : '')) : []

const loadFilterInventories$: TopicsOfInterestEpics = (action$, state$, { TopicOfInterestApi }) => {
  return action$.pipe(
    ofType<FilterInventoriesLoadAction>(TopicsOfInterestActionKeys.FILTER_INVENTORIES_LOAD),
    switchMap((action) => {
      const { instance } = action
      return Rx.forkJoin({
        editors: TopicOfInterestApi.getInstanceTopicsOfInterestEditors({ instance }).pipe(
          map((editorInventoryItem) => reShapeInventoryAnswers(editorInventoryItem)) // catchError((error) => Rx.of(loadFilterInventoriesError(error)))
        ),
        views: TopicOfInterestApi.getInstanceTopicsOfInterestViews({ instance }).pipe(
          map((viewInventoryItem) => reShapeInventoryAnswers(viewInventoryItem)) // catchError((error) => Rx.of(loadFilterInventoriesError(error)))
        ),
      }).pipe(
        map((filterInventories) => loadFilterInventoriesSuccess(filterInventories)),
        catchError((error) => Rx.of(loadFilterInventoriesError(error)))
      )
    })
  )
}

const loadPredictive$: TopicsOfInterestEpics = (action$, state$, { TopicOfInterestApi }) => {
  return action$.pipe(
    ofType<PredictiveLoadAction>(TopicsOfInterestActionKeys.PREDICTIVE_LOAD),
    debounceTime(appConfig.debounceTime),
    switchMap((action) => {
      return TopicOfInterestApi.getTopicsOfInterestFilters({
        instance: action.instance,
        type: action.requestType,
        input: action.inputText,
      }).pipe(
        map((payload) => loadPredictiveSuccess(action.requestType, payload)),
        catchError((error) => Rx.of(loadPredictiveError(error)))
      )
    })
  )
}

export const topicsOfInterestEpics = [loadTopicsOfInterest$, loadFilterInventories$, loadPredictive$]
