import { Box, Flex, Text } from '@chakra-ui/react'
import * as React from 'react'
import { InstanceSwitcher } from './InstanceSwitcher'
import { useLocation, useParams } from 'react-router'

export function InstanceBar() {
  const instance = useParams<'instance'>().instance || ''

  const location = useLocation()
  const locationArray = location.pathname.split('/')
  const currentPageName = locationArray.length === 2 ? '' : locationArray[locationArray.length - 1]

  return (
    <Box bg={'brand.quarternary.default'} color={'white'} zIndex={2} px={4}>
      <Flex justifyContent={'space-between'}>
        <Flex alignItems={'center'} my={2}>
          <Text fontSize={'xs'}>
            {process.env.NODE_ENV === 'production' ? 'Production Instance' : 'Test Instance'}{' '}
            <Text as={'span'} ml={6} fontWeight={'semibold'}>
              {instance}
            </Text>
          </Text>
        </Flex>
        <InstanceSwitcher activeInstance={instance} currentPageName={currentPageName} />
      </Flex>
    </Box>
  )
}
