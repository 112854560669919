import { Box, Flex } from '@chakra-ui/react'
import React from 'react'
import { NavLink } from 'react-router-dom'

interface NavItemProps {
  href: string
  label: string
  icon?: React.ReactElement
  cyId?: string
}

export const NavItem = ({ href, icon, label, cyId }: NavItemProps) => {
  return (
    <Box
      to={href}
      as={NavLink}
      display={'block'}
      px={8}
      py={1.5}
      cursor="pointer"
      userSelect="none"
      borderRightRadius={8}
      transition="all 0.2s"
      _hover={{ bgGradient: 'linear(to-r, brand.tertiary.300, brand.quinary.300)' }}
      _activeLink={{ bgGradient: 'linear(to-r, brand.tertiary.300, brand.secondary.300)' }}
    >
      <Flex gap={2} alignItems={'center'} data-cy={cyId}>
        {icon} {label}
      </Flex>
    </Box>
  )
}
