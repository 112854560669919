import { Box, HStack, Input, Table, Tbody, Td, Text, Th, Thead, Tr } from '@chakra-ui/react'
import React, { ChangeEvent, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { PageHeader } from '@/components/PageHeader'
import { loadKnowledgeGraph, setInputValue } from './state/knowledgeGraph.actions'
import { getInputText, searchKnowledgeGraph } from './state/knowledgeGraph.selectors'
import { Maybe } from 'purify-ts'
import { SearchResult } from '@/_clients/admin'
import { useTranslation } from 'react-i18next'
import { getKnowledgeGraphPath } from '@/pages/knowledge-graph/knowledgeGraph.paths'

export default function KnowledgeGraphOverview() {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const meaningExpression: Maybe<SearchResult[]> = useSelector(searchKnowledgeGraph)
  const textAreaValue: string = useSelector(getInputText)

  const instance = useParams<'instance'>().instance || ''

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    dispatch(setInputValue(e.target.value))
  }

  useEffect(() => {
    const text = textAreaValue ? textAreaValue.trim() : ''
    dispatch(loadKnowledgeGraph(instance, text))
  }, [instance, textAreaValue, dispatch])

  const navigate = useNavigate()

  const renderTableHeader = (title: string) => {
    return (
      <HStack spacing={1} cursor="pointer" width="fit-content" data-cy={`table-header-${title}`}>
        <Text userSelect="none" _hover={{ textDecoration: 'underline' }}>
          {title}
        </Text>
      </HStack>
    )
  }

  return (
    <Box data-cy="knowledge-graph-page">
      <PageHeader title={t('knowledgeGraph.pageTitle')} />
      <Box flex="1" maxW={'400px'}>
        <Input
          placeholder={t('knowledgeGraph.typeToSearch')}
          textAlign={'center'}
          onChange={handleInputChange}
          value={textAreaValue}
          id={'searchArea'}
        />
      </Box>
      {meaningExpression.mapOrDefault(
        (meaningExpressions) => (
          <Table colorScheme="gray" size="sm" variant={'striped'}>
            <Thead>
              <Tr>
                <Th px={2}>{renderTableHeader(t('knowledgeGraph.meaning'))}</Th>
                <Th px={2}>{renderTableHeader(t('knowledgeGraph.expression'))}</Th>
              </Tr>
            </Thead>
            <Tbody>
              {meaningExpressions.map(
                (meaningExpression: SearchResult, index: number) =>
                  meaningExpression.meaning &&
                  meaningExpression.meaning.id &&
                  meaningExpression.preferredExpression &&
                  meaningExpression.preferredExpression.id && (
                    <Tr
                      key={`table-row-${index}`}
                      className={'linkbox-overlay'}
                      onClick={() => {
                        navigate(
                          getKnowledgeGraphPath(
                            instance,
                            meaningExpression.meaning === undefined ||
                              meaningExpression.preferredExpression === undefined
                              ? ''
                              : `meaningId=${meaningExpression.meaning.id}&meaningValue=${
                                  meaningExpression.meaning.value
                                }&expressionId=${
                                  meaningExpression.preferredExpression.id
                                }&expressionValue=${meaningExpression.preferredExpression.value!}`
                          )
                        )
                      }}
                      cursor={'pointer'}
                    >
                      <Td px={2}>
                        <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" maxWidth="600px">
                          {meaningExpression.meaning.value}
                        </Box>
                      </Td>
                      <Td px={2}>
                        <Box>{meaningExpression?.preferredExpression?.value}</Box>
                      </Td>
                    </Tr>
                  )
              )}
            </Tbody>
          </Table>
        ),
        <></>
      )}
    </Box>
  )
}
