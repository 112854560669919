import { Box, useToast } from '@chakra-ui/react'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Column, DataItem } from '@/components/tables/types'
import DataTable from '@/components/tables/DataTable'
import { LoggingApi } from '@/api/apis'
import { HttpRequestState } from '@/types'
import { Just, Maybe } from 'purify-ts'
import { useApiLoggingDates } from '@/pages/api-logging/ApiLoggingPage'
import appConfig from '@/constants/appConfig'

const columns: Column[] = [
  { Header: 'API Key', accessor: 'apiKey', type: 'string', filtering: true, sorting: true },
  { Header: 'Count', accessor: 'count', type: 'number', filtering: true, sorting: true },
  {
    Header: 'FirstLog',
    accessor: 'firstLogTime',
    type: 'datetime',
    filtering: true,
    sorting: true,
    format: appConfig.dateTimeFormat,
  },
  {
    Header: 'LastLog',
    accessor: 'lastLogTime',
    type: 'datetime',
    filtering: true,
    sorting: true,
    format: appConfig.dateTimeFormat,
  },
]

export default function ApiLoggingOverview() {
  const { instance = '' } = useParams()
  const toast = useToast()
  const { minDate, maxDate } = useApiLoggingDates()

  const [overview, setOverview] = useState<HttpRequestState<Maybe<DataItem[]>>>({
    isLoading: false,
    value: Maybe.empty(),
  })

  function setOverviewLoading(status: boolean) {
    setOverview((prev) => {
      return { ...prev, isLoading: status }
    })
  }

  function getApiLoggingOverview() {
    setOverviewLoading(true)

    LoggingApi.getLoggingOverview({
      instance,
      from: new Date(minDate) as unknown as string,
      to: new Date(maxDate) as unknown as string,
    }).subscribe({
      next: (data) => {
        if (data.apiKeys) {
          const dataTableData: DataItem[] = Object.entries(data.apiKeys).flatMap((item) => ({
            apiKey: item[0] || '',
            count: item[1].logCount || '',
            firstLogTime: item[1].firstLogTime || '',
            lastLogTime: item[1].lastLogTime || '',
          }))

          setOverview((prev) => ({ ...prev, value: Just(dataTableData) }))
        } else {
          setOverview((prev) => ({ ...prev, value: Maybe.empty() }))
        }
      },
      error: (error) => {
        toast({
          title: error.response.name || error.name,
          description: error.response.message || error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
      },
      complete: () => setOverviewLoading(false),
    })
  }

  useEffect(() => {
    getApiLoggingOverview()
  }, [instance, minDate, maxDate])

  return (
    <Box data-cy="api-logging-overview">
      {overview.value?.caseOf({
        Just: (dataTableData) => (
          <DataTable
            data={dataTableData}
            columns={columns}
            isLoading={overview.isLoading}
            noDataText={'No data available'}
            rowHref={'apiKey'}
          />
        ),
        Nothing: () => <Box>No data available</Box>,
      })}
    </Box>
  )
}
