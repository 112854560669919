import { Box, BoxProps } from '@chakra-ui/react'
import { DeepassistLogo } from './icons/DeepassistLogo'

interface LogoAvatarProps extends BoxProps {
  width: string
  height: string
}

const LogoAvatar = ({ width, height, ...props }: LogoAvatarProps) => {
  return (
    <Box
      position="absolute"
      top="-10px"
      right="-10px"
      borderRadius="50%"
      overflow="hidden"
      bg={'yellow.300'}
      width={width}
      height={height}
      {...props}
    >
      <DeepassistLogo width={width} height={height} />
    </Box>
  )
}

export default LogoAvatar
