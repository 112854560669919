import * as React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { renderFallbackErrorMessage } from '@/auth/renderFallbackErrorMessage'
import { authenticator } from '@/auth/authenticator'
import { initStore } from '@/store/initStore'
import { appInitialized } from '@/store/state/app.actions'
import * as serviceWorker from './serviceWorker'
import '@/i18n/i18n'

const store = initStore()

const root = ReactDOM.createRoot(document.getElementById('root')!)

const renderApplication = () => {
  authenticator.init((isAuthenticated) => {
    if (isAuthenticated) {
      store.dispatch(appInitialized())
      root.render(
        <React.StrictMode>
          <App store={store} />
        </React.StrictMode>
      )
    } else {
      renderFallbackErrorMessage('There was a problem accessing the authentication server.', 'Failed authorization')
    }
  })
}

renderApplication()
serviceWorker.unregister()
