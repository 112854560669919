import { combineReducers } from 'redux'
import { apiKeysReducer } from '@/pages/api-keys/state/apiKeys.reducer'
import { buildingBlocksReducer } from '@/features/BuildingBlocks/state/buildingBlocks.reducer'
import { topicsOfInterestReducer } from '@/features/TopicsOfInterest/state/topicsOfInterest.reducer'
import { instancesReducer } from '@/store/instances/state/instances.reducer'
import { proposalReducer } from '@/pages/proposal/state/proposal.reducer'
import appReducer from '@/store/state/app.reducer'
import { knowledgeGraphReducer } from '@/features/KnowledgeGraph/state/knowledgeGraph.reducer'

const rootReducer = combineReducers({
  apiKeys: apiKeysReducer,
  app: appReducer,
  buildingBlocks: buildingBlocksReducer,
  topicsOfInterest: topicsOfInterestReducer,
  instances: instancesReducer,
  proposal: proposalReducer,
  knowledgeGraph: knowledgeGraphReducer,
})

export default rootReducer
