import { formatDuration, intervalToDuration } from 'date-fns'

export const formatDurationInSeconds = (durationInMiliseconds: number): string => {
  const duration = intervalToDuration({
    start: 0,
    end: durationInMiliseconds,
  })

  return formatDuration(duration, { delimiter: ', ' })
}

export const generateDateTimeFilterLabel = (fromDate: string, toDate: string) => {
  if (fromDate && toDate) {
    return `From: ${fromDate} To: ${toDate}`
  } else if (fromDate) {
    return `From: ${fromDate} (and later)`
  } else if (toDate) {
    return `Until: ${toDate} (and earlier)`
  } else {
    return ''
  }
}
